import React from "react";
import styled from "@emotion/styled";

const Centered = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px;
`;

export default ({ harmonicSavedSearchId }: { harmonicSavedSearchId: number }) => (
  <Centered>
    <h2>
      This email is associated with a{" "}
      <a
        href={`https://console.harmonic.ai/dashboard/people/urn:harmonic:saved_search:${harmonicSavedSearchId}`}
        target="_blank"
      >
        Harmonic Saved Search.{" "}
      </a>
    </h2>
    <p>There's no need to set filters - it will be sent to relevant recipients when triggered.</p>
  </Centered>
);
