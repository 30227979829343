import React from "react";
import { useController, useFormContext } from "react-hook-form";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";
import BaseAutocomplete, { AutocompleteProps } from "../statelessForms/Autocomplete";

type Props = CustomFieldProps<Omit<AutocompleteProps, "onChange">>;

export default function Autocomplete(props: Props) {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { required: fieldProps.required },
  });

  const selectedValue = otherProps.multiple
    ? otherProps.options.filter(({ value }) => field.value.includes(value))
    : otherProps.options.find(({ value }) => field.value === value);

  const onChange = (_: any, option: { value: any } | { value: any }[]) => {
    if (props.multiple) {
      field.onChange((option as { value: any }[])?.map(({ value }) => value));
    } else {
      const o = option as { value: any };
      field.onChange(o ? o.value : null);
    }
  };

  return (
    <Field {...fieldProps}>
      <BaseAutocomplete {...otherProps} value={selectedValue} onChange={onChange} />
    </Field>
  );
}
