import React from "react";

import Autocomplete from "react-google-autocomplete";
import { FormControl } from "@material-ui/core";
import Input from "./statelessForms/Input";

const InputAutocomplete = ({ inputRef, onFocus, onSelect, onBlur, ...props }: any) => (
  <Autocomplete {...props} onPlaceSelected={onSelect} options={{ types: ["(cities)"] }} />
);

export default function UserLocation(props: { location: string | null; error?: string }) {
  const [newLocation, setNewLocation] = React.useState<string | null>(props.location);

  const onSelect = (place: any) => {
    if (place.formatted_address) {
      setNewLocation(place.formatted_address);
    }
  };

  return (
    <FormControl required fullWidth error={!!props.error}>
      <label>Location (City, Country)</label>
      <Input
        name="user[new_location]"
        value={newLocation}
        onChange={(e) => setNewLocation(e.target.value)}
        size="small"
        inputComponent={InputAutocomplete}
        inputProps={{ onSelect }}
      />
      {props.error && <div className="ui above pointing red basic label">{props.error}</div>}
      <div className="hint">
        Where are you going to be based during Startup School? We use this information to group you
        with nearby companies.
      </div>
    </FormControl>
  );
}
