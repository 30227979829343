import React, { useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import { Grid } from "@material-ui/core";
import { addHours, format } from "date-fns";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { omit } from "lodash";
import {
  EDIT_MEETUP_PAGE,
  EDIT_MEETUP_PAGE_admin_adminMeetup,
} from "./__generated__/EDIT_MEETUP_PAGE";
import { loadContinueUrl } from "../../../components/forms/util";
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  LocationPicker,
  Submit,
  Textarea,
  useForm,
} from "../../../components/forms";
import { Pane, PaneItem } from "../../../components/Pane";
import SpacedGrid from "../../../components/statelessForms/SpacedGrid";
import MarkdownTextarea from "../../../components/forms/MarkdownTextarea";
import { ExtendedSelectValueType } from "../../../components/statelessForms/Select";
import {
  adminMeetupsPath,
  editAdminMeetupPath,
  newAdminMeetupPath,
} from "../../../__generated__/routes";
import Button from "../../../components/statelessForms/Button";
import { DELETE_MEETUP, DELETE_MEETUPVariables } from "./__generated__/DELETE_MEETUP";
import {
  CHECK_SLUG_AVAILABILITY,
  CHECK_SLUG_AVAILABILITY_admin_adminMeetupBySlug,
} from "./__generated__/CHECK_SLUG_AVAILABILITY";
import S3FileUpload from "../../../components/forms/S3FileUpload";
import useUpdateMeetup from "./hooks/useUpdateMeetup";
import { StyledFormLabel } from "../../../components/forms/Field";

type Props = {
  data: EDIT_MEETUP_PAGE | null;
  afterUpdate: (id: string) => void;
  meetupId?: string | undefined;
  eventTypeOptions: [string, string][];
  canBeDeleted?: boolean;
};

const ATTENDEE_TYPE_OPTIONS: [ExtendedSelectValueType, string][] = [
  ["founder", 'Founders ("founder")'],
  ["student", 'Students ("student")'],
  ["candidate", 'WaaS candidates ("candidate")'],
  ["alum", 'Alumni ("alum")'],
  ["other", 'Other/generic ("person"/"people"/"someone else")'],
];

export default ({ data, eventTypeOptions, canBeDeleted, meetupId, afterUpdate }: Props) => {
  const isNewEvent = !meetupId;
  const copiedMeetupId = new URLSearchParams(window.location.search).get("copiedMeetupId");
  const updateMeetup = useUpdateMeetup();

  const [deleteMeetup] = useMutation<DELETE_MEETUP, DELETE_MEETUPVariables>(gql`
    mutation DELETE_MEETUP($meetupId: ID!) {
      adminDeleteEvent(meetupId: $meetupId) {
        success
      }
    }
  `);

  const getFormDataFromMeetup = (meetup: EDIT_MEETUP_PAGE_admin_adminMeetup | undefined) => ({
    ...meetup,
    locationForGeocoding: meetup?.locationForGeocoding || "San Francisco, CA, USA",
    startsAt: meetup?.startsAt ? new Date(meetup.startsAt) : null,
    endsAt: meetup?.endsAt ? new Date(meetup.endsAt) : null,
    registrationClosesAt: meetup?.registrationClosesAt
      ? new Date(meetup.registrationClosesAt)
      : null,
  });

  const formData = getFormDataFromMeetup(data?.admin.adminMeetup);

  const onSubmit = async (fields: NonNullable<typeof formData>) => {
    const resp = await updateMeetup({
      meetupId,
      input: {
        ...omit({ ...fields }, ["__typename", "campaignId", "url", "ohSlots"]),
        startsAt: format(fields.startsAt!, "yyyy-MM-dd'T'HH:mm:ss"),
        endsAt: format(fields.endsAt!, "yyyy-MM-dd'T'HH:mm:ss"),
        attendeeType: fields.attendeeType === "other" ? "" : fields.attendeeType,
        locationForGeocoding: fields.locationForGeocoding!,
        registrationClosesAt: fields.registrationClosesAt
          ? format(fields.registrationClosesAt, "yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        ...(fields.eventType === "tracking_only"
          ? {
              publicLocation: fields.locationForGeocoding,
              friendlyName: fields.title,
            }
          : {}),
      },
    });

    const id = resp.data?.adminUpdateEvent?.id;
    if (id) {
      afterUpdate(id);
    }
  };

  // @ts-ignore
  const { ConnectedForm, connectedFormProps, formMethods } = useForm(formData, onSubmit);

  useEffect(() => {
    const meetup = data?.admin.adminMeetup;
    if (!meetup) {
      return;
    }
    formMethods.reset(getFormDataFromMeetup(meetup));
  }, [data?.admin.adminMeetup]);

  const [meetupBySlug, setMeetupBySlug] = useState<{
    [slug: string]: CHECK_SLUG_AVAILABILITY_admin_adminMeetupBySlug | null;
  }>({});
  const [checkSlugAvailability, { data: slugMeetupData }] =
    useLazyQuery<CHECK_SLUG_AVAILABILITY>(gql`
      query CHECK_SLUG_AVAILABILITY($slug: ID!) {
        admin {
          adminMeetupBySlug(slug: $slug) {
            id
            slug
            title
            startsAt
          }
        }
      }
    `);
  useEffect(() => {
    const meetupData = slugMeetupData?.admin.adminMeetupBySlug;
    if (meetupData && `${meetupData.id}` !== `${meetupId}`) {
      setMeetupBySlug((prev) => ({ ...prev, [meetupData.slug]: meetupData }));
    }
  }, [slugMeetupData]);

  const attendeeType = formMethods.watch("attendeeType");
  const eventType = formMethods.watch("eventType");
  const startsAt = formMethods.watch("startsAt");
  const capacity = formMethods.watch("capacity");
  const slug = formMethods.watch("slug");
  const landingPageDisabled = formMethods.watch("landingPageDisabled");

  useEffect(() => {
    const formattedSlug = slug?.trim();
    if (!formattedSlug || formattedSlug in meetupBySlug) {
      return;
    }
    setMeetupBySlug((prev) => ({ ...prev, [formattedSlug]: null }));
    checkSlugAvailability({ variables: { slug: formattedSlug } });
  }, [slug]);

  useEffect(() => {
    if (isNewEvent && eventType) {
      formMethods.setValue("landingPageDisabled", eventType === "tracking_only");
      formMethods.setValue(
        "inviteExpirationDisabled",
        eventType === "tracking_only" || eventType === "investor_reception"
      );
    }

    switch (eventType) {
      case "summerconf":
        formMethods.setValue("attendeeType", "student");
        break;
      case "waas":
        formMethods.setValue("attendeeType", "candidate");
        break;
      case "alumni":
        formMethods.setValue("attendeeType", "alum");
        break;
      case "recruiting":
        formMethods.setValue("linkedinRequired", true);
        formMethods.setValue("attendeeType", "other");
        break;
      default:
        formMethods.setValue("attendeeType", "founder");
        break;
    }
  }, [eventType]);

  useEffect(() => {
    if (attendeeType === "") {
      formMethods.setValue("attendeeType", "other");
    }
  }, [attendeeType]);

  useEffect(() => {
    if (isNewEvent && !copiedMeetupId && startsAt) {
      formMethods.setValue("endsAt", addHours(startsAt, 3));
    }
  }, [startsAt]);

  useEffect(() => {
    const capacityInt = parseInt(`${capacity}`, 10);
    if (!isNaN(capacityInt)) {
      formMethods.setValue("capacity", capacityInt);
    }
  }, [capacity]);

  const onDeleteEvent = async () => {
    if (!meetupId) {
      return;
    }

    if (confirm("Are you sure you want to delete this meetup? This cannot be undone.")) {
      const resp = await deleteMeetup({ variables: { meetupId } });
      if (resp?.data?.adminDeleteEvent?.success) {
        loadContinueUrl(adminMeetupsPath());
      }
    }
  };

  useEffect(() => {
    if (!isNewEvent || !copiedMeetupId || !data) {
      return;
    }

    // If loading from copied event, clear the slug as it must be unique
    formMethods.setValue("slug", "");
  }, [data]);

  const validateSlug = (value: string) => {
    const meetupForSlug = meetupBySlug[value?.trim()];
    if (meetupForSlug) {
      return "This URL path is not available. Please choose another.";
    }
  };

  const addressField = (
    <Textarea
      minRows={2}
      fieldName="address"
      label="Address"
      subLabel="This will show up on attendees' calendar invites."
      required
    />
  );

  const descriptionField = (
    <MarkdownTextarea
      fieldName="description"
      label="Description"
      subLabel="Hint: Shift+Enter adds a single newline"
      initialHeight={200}
      required
    />
  );

  const instructionsField = (
    <MarkdownTextarea
      fieldName="instructions"
      label="Special instructions for attendees"
      subLabel={
        'Visible only to confirmed attendees on the event page and in the "attendance confirmed" and reminder emails (by default).\nHint: Shift+Enter adds a single newline'
      }
      initialHeight={200}
      placeholder={
        "- When you arrive, take the elevator up to the 4th floor \n- If you have a startup idea that you're committed to working on, please come prepared to share a short pitch."
      }
    />
  );

  const renderSlugUsageFeedback = () => {
    const formattedSlug = slug?.trim();
    if (
      !formattedSlug ||
      formattedSlug === data?.admin.adminMeetup.slug ||
      !(formattedSlug in meetupBySlug) ||
      formattedSlug.length < 6
    ) {
      return;
    }

    const meetupForSlug = meetupBySlug[formattedSlug];
    return (
      <div css={css({ fontSize: 12, marginTop: -7 })}>
        {meetupForSlug ? (
          <div css={css({ color: "#DB2828", display: "flex", alignItems: "center" })}>
            <Cancel fontSize="small" />
            <span css={css({ marginLeft: 2 })}>
              This URL path is already in use for{" "}
              <a href={editAdminMeetupPath(meetupForSlug.id)} target="_blank">
                {meetupForSlug.title} ({format(new Date(meetupForSlug.startsAt), "MMM d, yyyy")}).
              </a>
            </span>
          </div>
        ) : (
          <div css={css({ color: "#21BA45", display: "flex", alignItems: "center" })}>
            <CheckCircle fontSize="small" />
            <span css={css({ fontWeight: "bold", marginLeft: 2 })}>Available</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <ConnectedForm {...connectedFormProps}>
      <Pane title="Basics">
        <PaneItem>
          <Input label="Title" fieldName="title" required />

          <SpacedGrid container marginBottom="0" spacing={1} justifyContent="space-between">
            <Grid item xs={4}>
              <DatePicker
                label="Start date & time"
                fieldName="startsAt"
                showTimeSelect
                required
                allowEmpty
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="End date & time"
                fieldName="endsAt"
                showTimeSelect
                required
                allowEmpty
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="Registration close date & time"
                fieldName="registrationClosesAt"
                showTimeSelect
                allowEmpty
              />
            </Grid>
          </SpacedGrid>

          <SpacedGrid container marginBottom="0" spacing={1} justifyContent="space-between">
            <Grid item xs={6}>
              <Dropdown
                required
                fieldName="eventType"
                label="What type of event is this?"
                options={eventTypeOptions}
              />
            </Grid>

            <Grid item xs={6}>
              <Input label="Capacity" fieldName="capacity" type="number" required />
            </Grid>
          </SpacedGrid>

          <LocationPicker
            fieldName="locationForGeocoding"
            label="City"
            subLabel="This isn't displayed - it's only used for geocoding."
            required
          />

          <Checkbox fieldName="inviteExpirationDisabled" label="Invites never expire?" />
        </PaneItem>
      </Pane>

      <Pane title="Content for the public landing page">
        <PaneItem>
          <div css={css({ marginTop: -15 })}>
            <Checkbox fieldName="landingPageDisabled" label="Public landing page disabled?" />
          </div>
          {!landingPageDisabled && (
            <>
              <Input
                label="Unique URL path"
                fieldName="slug"
                validate={validateSlug}
                subLabel={
                  'e.g. a value of "my-meetup" will make the public page live at "events.ycombinator.com/my-meetup"'
                }
                minLength={6}
              />
              {renderSlugUsageFeedback()}

              <Input label="Public location" fieldName="publicLocation" required />

              {descriptionField}

              <S3FileUpload
                fieldName="socialImageUrl"
                label="Social image"
                subLabel={
                  "This image will only be shown when the event link is shared on social media.\nImage must be under 5MB. Recommended size: 1200 x 630px."
                }
                accept="image/jpg, image/png, image/webp, image/gif"
                maxSizeMB={5}
                previewDimensions={[1200, 630]}
              />

              <div css={css({ marginTop: 20 })}>
                <StyledFormLabel>Require LinkedIn URL</StyledFormLabel>
                <div css={css({ marginTop: -15 })}>
                  <Checkbox fieldName="linkedinRequired" label="LinkedIn required?" />
                </div>
              </div>
            </>
          )}
        </PaneItem>
      </Pane>

      {eventType !== "tracking_only" ? (
        <>
          <Pane title="Defaults for event email copy">
            <PaneItem>
              <SpacedGrid container marginBottom="0" spacing={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <Input
                    fieldName="friendlyName"
                    label="Friendly name"
                    subLabel={'e.g. "you\'re invited to the [friendly_name]"'}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <Dropdown
                    required
                    fieldName="attendeeType"
                    label="What type of attendee is this for?"
                    subLabel={'e.g. "your spot has been freed for another [founder/student/etc]"'}
                    displayEmpty
                    options={ATTENDEE_TYPE_OPTIONS}
                  />
                </Grid>
              </SpacedGrid>
            </PaneItem>
          </Pane>

          <Pane title="For confirmed attendees">
            <PaneItem>
              {addressField}
              {instructionsField}
            </PaneItem>
          </Pane>
        </>
      ) : (
        <Pane title="For calendar invites">
          <PaneItem>
            {addressField}
            {descriptionField}
            {instructionsField}
          </PaneItem>
        </Pane>
      )}
      <div css={css({ display: "flex", justifyContent: "space-between" })}>
        <Submit />
        {canBeDeleted && (
          <Button color="gray" onClick={onDeleteEvent}>
            Delete event
          </Button>
        )}
      </div>
      {!isNewEvent && (
        <div css={css({ marginTop: 20 })}>
          <a href={newAdminMeetupPath({ copiedMeetupId: meetupId })}>Copy as a new event</a>
        </div>
      )}
    </ConnectedForm>
  );
};
