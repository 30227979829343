import React from "react";
import { FormControl, Grid } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import authorizedFetch from "@yc/shared/authorizedFetch";
import styled from "@emotion/styled";
import Input from "./statelessForms/Input";
import Button, { BasicButton } from "./statelessForms/Button";
import ErrorLabel from "./statelessForms/ErrorLabel";
import Form from "./forms/Form";

const Image = styled.img`
  margin-right: 0.25em;
  display: inline-block;
  width: 2em;
  height: 2em;
  max-width: 100%;
  vertical-align: middle;
  border-radius: 500rem;
  -webkit-border-radius: 500rem;
  -moz-border-radius: 500rem;
`;

const Segment = styled.div`
  background: #ffffff;
  font-size: 1rem;
  top: 0;
  bottom: 0;
  border-radius: 0;
  margin: 0 -1px;
  width: calc(100% + 2px);
  max-width: calc(100% + 2px);
  box-shadow: none;
  border: 1px solid #d4d4d5;
  padding: 1em 1em;
  &:not(:last-child) {
    border-bottom: none;
  }
`;

export default class Invites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      invites: this.props.invites,
      cofounders: this.props.cofounders,
      loading: false,
      errors: {},
    };
  }

  params() {
    return { invite: { email: this.state.email } };
  }

  sendInvite = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, errors: {} }, async () => {
      const resp = await authorizedFetch(this.props.url, {
        method: "POST",
        body: JSON.stringify(this.params()),
      });

      if (resp.status === 200) {
        this.setState({
          loading: false,
          email: "",
          invites: this.state.invites.concat([await resp.json()]),
        });
      } else {
        const json = await resp.json();
        this.setState({ loading: false, errors: json.errors });
      }
    });
  };

  handleChange = (email) => {
    this.setState({ email });
  };

  handleRemoveInvite = async (invite) => {
    const resp = await authorizedFetch(`/invites/${invite.id}`, {
      method: "DELETE",
    });

    if (resp.status === 200) {
      const invites = this.state.invites.filter((i) => i.id !== invite.id);
      this.setState({ invites });
    } else {
      alert("Error removing invite!");
    }
  };

  handleRemoveCofounder = async (cofounder) => {
    const resp = await authorizedFetch("/company/remove_cofounder", {
      method: "DELETE",
      body: JSON.stringify({
        user_id: cofounder.id,
      }),
    });

    if (resp.status === 200) {
      this.setState({ cofounders: this.state.cofounders.filter((c) => c.id !== cofounder.id) });
    } else {
      alert("Error removing cofounder.");
    }
  };

  renderCofounders() {
    return this.state.cofounders.map((cofounder) => (
      <Segment key={cofounder.id}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <div className="invite">
              <Image avatar src={cofounder.avatar_url} />
              <span>{cofounder.name}</span>
            </div>
          </Grid>
          <Grid item>
            <BasicButton
              onClick={() => this.handleRemoveCofounder(cofounder)}
              size="small"
              startIcon={<RemoveCircleOutlineIcon />}
            >
              Remove
            </BasicButton>
          </Grid>
        </Grid>
      </Segment>
    ));
  }

  renderInvites() {
    return this.state.invites.map((invite) => (
      <Segment key={invite.id}>
        <Grid container justifyContent="space-between">
          <Grid item>{invite.email}</Grid>
          <Grid item>
            <BasicButton
              onClick={() => this.handleRemoveInvite(invite)}
              size="small"
              startIcon={<RemoveCircleOutlineIcon />}
            >
              Remove
            </BasicButton>
          </Grid>
        </Grid>
      </Segment>
    ));
  }

  renderForm() {
    const errors = this.state.errors.email;
    const emailError = errors && errors.length > 0;
    const errorLabel = emailError && <ErrorLabel>{errors[0]}</ErrorLabel>;
    return (
      <Segment>
        <Form onSubmit={this.sendInvite} loading={this.state.loading}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <FormControl fullWidth error={emailError}>
                <Input
                  outlined
                  size="small"
                  name="email"
                  value={this.state.email}
                  onChange={(e) => this.handleChange(e.target.value)}
                  placeholder="Email"
                />
                {errorLabel}
              </FormControl>
            </Grid>
            <Grid item xs>
              <Button type="submit">Send Invite</Button>
            </Grid>
          </Grid>
        </Form>
      </Segment>
    );
  }

  render() {
    return (
      <div className="invites">
        {this.renderCofounders()}
        {this.renderInvites()}
        {this.renderForm()}
      </div>
    );
  }
}
