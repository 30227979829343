import React from "react";
import styled from "@emotion/styled";
import authorizedFetch from "@yc/shared/authorizedFetch";

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

export const handleSignOut = async (e: any, noRedirect?: boolean) => {
  e.preventDefault();
  window.posthog.reset();
  const resp = await authorizedFetch("/users/sign_out", { method: "POST" }).then((r) => r.json());

  let continueUrl = resp.continue;

  if (noRedirect) {
    continueUrl = new URL(continueUrl);
    continueUrl.searchParams.set("continue", window.location.href);
  }

  window.location.href = continueUrl;
};

type Props = {
  noRedirect?: boolean;
};

export default ({ noRedirect }: Props) => (
  <StyledLink className="footer-links" onClick={(e) => handleSignOut(e, !!noRedirect)}>
    Sign Out
  </StyledLink>
);
