import React from "react";
import ReactPDF, { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import Styles = ReactPDF.Styles;
import { YC_ORANGE } from "../../../../components/forms/util";
import { BottomBarContent, NametagContent } from "./NametagTypes";

type Props = {
  withBleed: (styles: Styles) => Styles;
  nametag: NametagContent;
  bottomBar: BottomBarContent;
  alumniDetails?: string | undefined;
};

export default ({ withBleed, nametag, bottomBar, alumniDetails }: Props) => {
  const styles = StyleSheet.create(
    withBleed({
      nametag: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontFamily: "Helvetica",
      },
      mainSection: {
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      },
      nameContainer: {
        marginTop: 60,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
      name: {
        fontSize: 46,
        fontWeight: 500,
        textAlign: "center",
        alignSelf: "center",
      },
      subtitle: {
        fontWeight: 300,
        fontSize: 34,
      },
      oneLiner: {
        fontSize: 28,
        marginTop: 30,
        maxWidth: 350,
      },
      divider: {
        backgroundColor: YC_ORANGE,
        height: 3,
        width: 100,
        margin: "20px 0",
      },
      ycLogo: {
        marginBottom: 20,
        width: 175,
        height: 35,
      },
      qrCode: {
        height: 240,
        width: 240,
      },
      bottomBar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
      },
      bottomBarText: {
        fontSize: 24,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontFamily: "Helvetica",
      },
    })
  );

  return (
    <View style={styles.nametag}>
      <View style={styles.mainSection}>
        <View style={styles.ycLogoContainer}>
          <Image src={`${window.location.origin}/ycombinator-logo.png`} style={styles.ycLogo} />
        </View>
        <View style={styles.nameContainer}>
          <Text style={styles.name}>
            {nametag.firstName} {nametag.lastName}
          </Text>
        </View>
        <View style={styles.divider} />
        <Text style={styles.subtitle}>{nametag.subtitle}</Text>
        <Text style={styles.oneLiner}>{nametag.secondSubtitle || nametag.oneLiner}</Text>
      </View>
      <View style={[styles.bottomBar, { backgroundColor: bottomBar.backgroundColor }]}>
        <Text style={[styles.bottomBarText, { color: bottomBar.textColor }]}>{bottomBar.text}</Text>
      </View>
    </View>
  );
};
