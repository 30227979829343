import React from "react";
import { css } from "@emotion/core";
import { Rating } from "@material-ui/lab";
import { gql, useMutation } from "@apollo/client";
import {
  RATE_CURRICULUM_ITEM,
  RATE_CURRICULUM_ITEMVariables,
} from "./__generated__/RATE_CURRICULUM_ITEM";

type Props = {
  curriculumItemId: number;
  rating: number | null;
};

export default function CurriculumItemRating({ curriculumItemId, rating }: Props) {
  const [rateCurriculumItem] = useMutation<RATE_CURRICULUM_ITEM, RATE_CURRICULUM_ITEMVariables>(gql`
    mutation RATE_CURRICULUM_ITEM($curriculumItemId: ID!, $rating: Int) {
      rateCurriculumItem(curriculumItemId: $curriculumItemId, rating: $rating) {
        id
        curriculumItemFeedback {
          status
          rating
        }
      }
    }
  `);

  const updateRating = async (value: number | null) => {
    if (!curriculumItemId) {
      return;
    }

    await rateCurriculumItem({
      variables: {
        curriculumItemId: `${curriculumItemId}`,
        rating: value,
      },
    });
  };

  const ratingNames = [
    "Not useful at all",
    "Slightly useful",
    "Worth my time",
    "Really great",
    "Transformational",
  ];

  return (
    <div css={css({ textAlign: "center" })}>
      <Rating
        max={5}
        name={`rating-${curriculumItemId}`}
        size="large"
        value={rating || null}
        onChange={(_, newRating) => updateRating(newRating)}
      />
      {rating !== null && rating > 0 && (
        <div css={css({ color: "#737373", margin: "12px auto 0 auto" })}>
          {ratingNames[rating - 1]}
        </div>
      )}
    </div>
  );
}
