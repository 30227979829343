import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { MEETUP_YC_APP_STATS_admin_meetupYcApps } from "./__generated__/MEETUP_YC_APP_STATS";
import { MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions } from "./__generated__/MEETUP_YC_APP_MENTIONS";
import {
  ADMIN_SET_APP_MEETUP_MENTION,
  ADMIN_SET_APP_MEETUP_MENTIONVariables,
} from "./__generated__/ADMIN_SET_APP_MEETUP_MENTION";

const AcceptedApplicantRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    margin-left: 10px;
    margin-right: 3px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const MentionTag = styled.div`
  margin-left: 10px;
  border-radius: 5px;
  color: white;
  padding: 0 5px;
  font-size: 12px;
`;

const EditButton = styled.div`
  margin-left: 10px;
  color: gray;
  text-decoration: underline;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  app: MEETUP_YC_APP_STATS_admin_meetupYcApps;
  names: string;
  meetupId: string;
  mention: MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions | undefined;
  refetch: (appId: number) => Promise<any>;
};

export default ({ app, names, mention, meetupId, refetch }: Props) => {
  const [isEditing, setIsEditing] = useState(!mention);

  const [setMentioned] = useMutation<
    ADMIN_SET_APP_MEETUP_MENTION,
    ADMIN_SET_APP_MEETUP_MENTIONVariables
  >(gql`
    mutation ADMIN_SET_APP_MEETUP_MENTION(
      $meetupId: ID!
      $internalAppId: Int!
      $mentioned: Boolean!
    ) {
      adminSetAppMeetupMention(
        meetupId: $meetupId
        internalAppId: $internalAppId
        mentioned: $mentioned
      ) {
        id
        mentioned
      }
    }
  `);

  const onSetMention = async (mentioned: boolean) => {
    await setMentioned({ variables: { mentioned, internalAppId: app.internalAppId, meetupId } });
    await refetch(app.internalAppId);
    setIsEditing(false);
  };

  return (
    <AcceptedApplicantRow key={app.appUrl}>
      <div css={css({ marginRight: 5 })}>
        {app.batch}: {names}
      </div>
      <a href={app.appUrl} target="_blank">
        (view app)
      </a>
      <div css={css({ color: "gray", marginLeft: 20, fontSize: 12 })}>
        Mentioned this event in app?
      </div>
      {mention && (
        <MentionTag css={css({ backgroundColor: mention.mentioned ? "green" : "gray" })}>
          {mention.mentioned ? "Yes" : "No"}
        </MentionTag>
      )}
      {isEditing && (
        <AcceptedApplicantRow>
          <input
            type="checkbox"
            checked={mention && mention.mentioned}
            onChange={() => onSetMention(true)}
          />
          <div>Yes</div>
          <input
            type="checkbox"
            checked={mention && !mention.mentioned}
            onChange={() => onSetMention(false)}
          />
          <div>No</div>
        </AcceptedApplicantRow>
      )}
      {mention && (
        <EditButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? "cancel" : "edit"}
        </EditButton>
      )}
    </AcceptedApplicantRow>
  );
};
