import React from "react";
import { css } from "@emotion/core";
import Icon from "../../components/icon";

const Checkbox: React.FC<JSX.IntrinsicElements["svg"] & { checked: boolean; size?: number }> = ({
  checked,
  size = 20,
  ...rest
}) => (
  <Icon
    css={css({ color: checked ? "#35B95D" : "#ddd", width: size, height: size })}
    icon="checkCircle"
    {...rest}
  />
);

export default Checkbox;
