import { useMutation, gql } from "@apollo/client";
import {
  ADMIN_SET_RSVP_STATUS,
  ADMIN_SET_RSVP_STATUSVariables,
} from "./__generated__/ADMIN_SET_RSVP_STATUS";

export default function useSetRsvpStatus() {
  const [setRsvpStatus] = useMutation<ADMIN_SET_RSVP_STATUS, ADMIN_SET_RSVP_STATUSVariables>(gql`
    mutation ADMIN_SET_RSVP_STATUS($ids: [ID!]!, $status: String!, $active: Boolean!) {
      adminSetMeetupRsvpStatus(ids: $ids, status: $status, active: $active) {
        id
        status
      }
    }
  `);

  return (variables: ADMIN_SET_RSVP_STATUSVariables) => setRsvpStatus({ variables });
}
