import React, { ChangeEvent, useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Papa from "papaparse";
import ExpandableSection from "../../../components/navigation/ExpandableSection";
import { Pane, PaneItem } from "../../../components/Pane";
import { ExternalRecipientInput } from "../../../types/graphqlTypes";
import { EDIT_EMAIL_PUSH_admin_emailCampaign_emailPush } from "./__generated__/EDIT_EMAIL_PUSH";
import TextareaAutosize from "../../../components/statelessForms/TextareaAutosize";
import Button from "../../../components/statelessForms/Button";

const AddRecipientsSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviewRecipientsSection = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
`;

const RecipientsPreview = styled.div`
  max-height: 200px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  overflow: scroll;
`;

const AddRecipientsTitle = styled.b`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  text-decoration: underline;
`;

const AddRecipientsSubtitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const ImportFileSection = styled.div`
  padding-right: 20px;
  border-right: 1px solid #e0e0e0;
`;

const EnterManuallySection = styled.div`
  padding: 0 20px;
  flex-grow: 1;
`;

const TextSuggestion = styled.pre`
  font-family: monospace;
  background-color: #ffeff0;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0 0 0 5px;
`;

const PAPAPARSE_HEADER = "email,firstName";

export const EmailRecipientsPreview = ({
  recipients,
}: {
  recipients: ExternalRecipientInput[];
}) => (
  <RecipientsPreview>
    <ul>
      {recipients.map(({ email, firstName }) => (
        <li key={email}>
          {firstName || <i css={css({ color: "#9ca3af", fontWeight: 100 })}>No name</i>}{" "}
          {`<${email}>`}
        </li>
      ))}
    </ul>
  </RecipientsPreview>
);

type Props = {
  emailPush?: EDIT_EMAIL_PUSH_admin_emailCampaign_emailPush;
  recipients: ExternalRecipientInput[];
  setRecipients: (r: ExternalRecipientInput[]) => void;
};

const formatRecipientsAsString = (recipients: ExternalRecipientInput[]) =>
  recipients.map(({ email, firstName }) => `${email}, ${firstName}`).join("\n");

export default ({ emailPush, recipients, setRecipients }: Props) => {
  const [newRecipientsStr, setNewRecipientsStr] = useState(formatRecipientsAsString(recipients));
  const [lastStrValue, setLastStrValue] = useState(formatRecipientsAsString(recipients));

  if (emailPush?.lifecycleOnly) {
    return <div />;
  }

  const dedupeRecipients = (list: ExternalRecipientInput[]) =>
    Object.values(
      list.reduce((map, recipient) => {
        if (recipient.email.toLowerCase()) {
          map[recipient.email.toLowerCase()] = recipient;
        }
        return map;
      }, {} as { [email: string]: ExternalRecipientInput })
    );

  const papaparseConfig = {
    header: true,
    skipEmptyLines: true,
    transformHeader: (h: string, idx: number) => {
      if (idx === 0) {
        return "email";
      }
      if (idx === 1) {
        return "firstName";
      }
      return h;
    },
    transform: (val: string) => val.trim(),
    complete: ({ data }: { data: ExternalRecipientInput[] }) => {
      const formattedRecipients = data.map(({ firstName, email }) => ({ firstName, email }));
      const dedupedRecipients = dedupeRecipients(formattedRecipients);
      setRecipients(dedupedRecipients);
      const asStr = formatRecipientsAsString(dedupedRecipients);
      setNewRecipientsStr(asStr);
      setLastStrValue(asStr);
    },
  };

  const onSelectFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }
    Papa.parse(e.target.files[0], papaparseConfig);
  };

  const onConfirmText = () => {
    Papa.parse(`${PAPAPARSE_HEADER}\n${newRecipientsStr}`, papaparseConfig);
  };

  return (
    <ExpandableSection title="Import external recipients" count={recipients.length}>
      <Pane>
        <PaneItem>
          <h2 css={css({ marginBottom: 30 })}>
            Import a CSV of emails and first names, or add recipients directly
          </h2>
          <AddRecipientsSection>
            <ImportFileSection>
              <AddRecipientsTitle>Option 1: upload from CSV</AddRecipientsTitle>
              <AddRecipientsSubtitle>
                CSV should have the header row: <TextSuggestion>email,firstName</TextSuggestion>
              </AddRecipientsSubtitle>
              <div>
                <input
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={onSelectFiles}
                />
              </div>
            </ImportFileSection>
            <EnterManuallySection>
              <AddRecipientsTitle>Option 2: enter manually</AddRecipientsTitle>
              <AddRecipientsSubtitle>
                Enter recipients formatted as{" "}
                <TextSuggestion>{"<email>,<firstName>"}</TextSuggestion>
                <div css={css({ fontWeight: "normal" })}>
                  <i>(1 recipient per line</i>)
                </div>
              </AddRecipientsSubtitle>
              <TextareaAutosize
                fullWidth
                value={newRecipientsStr}
                onChange={(e) => setNewRecipientsStr(e.target.value)}
                placeholder={"joan@ycombinator.com, Joan\nkatherine@ycombinator.com, Katherine"}
                maxRows={10}
              />
              <Button
                color="blue"
                disabled={lastStrValue === newRecipientsStr}
                onClick={onConfirmText}
              >
                Set from text
              </Button>
            </EnterManuallySection>
          </AddRecipientsSection>

          {!!recipients.length && (
            <PreviewRecipientsSection>
              <h2>Preview custom recipients:</h2>
              <div css={css({ marginTop: -15, marginBottom: 15, fontStyle: "italic" })}>
                Note: newly added recipients will <b>not</b> be saved until you hit one of the
                "Save" buttons below.
              </div>
              <EmailRecipientsPreview recipients={recipients} />
            </PreviewRecipientsSection>
          )}
        </PaneItem>
      </Pane>
    </ExpandableSection>
  );
};
