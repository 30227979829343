import React, { useCallback, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import { addMinutes, differenceInMinutes, format } from "date-fns";
import { Add, AddCircleOutlined, Close, Delete } from "@material-ui/icons";
import styled from "@emotion/styled";
import ReactDatePicker from "react-datepicker";
import {
  EDIT_MEETUP_PAGE,
  EDIT_MEETUP_PAGE_admin_adminMeetup_ohSlots,
} from "./__generated__/EDIT_MEETUP_PAGE";
import { Pane, PaneItem } from "../../../components/Pane";
import useYcStaff from "./hooks/useYcStaff";
import Button from "../../../components/statelessForms/Button";
import Autocomplete from "../../../components/statelessForms/Autocomplete";
import Input from "../../../components/statelessForms/Input";
import TextareaAutosize from "../../../components/statelessForms/TextareaAutosize";
import {
  UPDATE_OFFICE_HOURS,
  UPDATE_OFFICE_HOURSVariables,
} from "./__generated__/UPDATE_OFFICE_HOURS";
import { AdminUpdateMeetupOfficeHoursInput } from "../../../types/graphqlTypes";

type Props = {
  data: EDIT_MEETUP_PAGE | null;
  afterUpdate: (id: string) => void;
  meetupId?: string | undefined;
};

type OHDefinition = {
  id?: string;
  ownerId: string | undefined;
  owner2Id?: string | undefined | null;
  location: string;
  description: string;
  date: Date | undefined;
  durationInMinutes: number | undefined;
  slots: (Date | undefined)[];
};

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

const Label = styled.div`
  font-weight: bold;
  width: 150px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

const SubLabel = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
`;

const Value = styled.div`
  flex-grow: 1;
`;

const TimeSelector = ({
  slots,
  time,
  slotIdx,
  onChange,
}: {
  slots: (Date | undefined)[];
  time: Date | undefined;
  slotIdx: number;
  onChange: (newFields: { [fieldName: string]: string | Date | (Date | undefined)[] }) => void;
}) => {
  const onRemove = useCallback(() => {
    const newSlots = [...slots];
    newSlots.splice(slotIdx, 1);
    onChange({ slots: newSlots });
  }, [slots, slots.length]);

  const onSetTime = useCallback(
    (newTime: Date | undefined) => {
      const newSlots = [...slots];
      newSlots[slotIdx] = newTime;
      onChange({ slots: newSlots });
    },
    [slots, slots.length]
  );

  return (
    <div css={css({ display: "flex", alignItems: "center" })}>
      <ReactDatePicker
        selected={time}
        onChange={(newTime) => {
          if (newTime instanceof Array) {
            onSetTime(newTime[0]);
          } else {
            onSetTime(newTime || undefined);
          }
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        customInput={<Input size="small" />}
      />
      <div
        onClick={onRemove}
        css={css({ display: "flex", alignItems: "center", "&:hover": { cursor: "pointer " } })}
      >
        <Close />
      </div>
    </div>
  );
};

export default ({ data, meetupId, afterUpdate }: Props) => {
  const ycStaff = useYcStaff();
  const [officeHours, setOfficeHours] = useState<OHDefinition[]>([]);

  useEffect(() => {
    const allSlotsFlat = data?.admin.adminMeetup.ohSlots || [];

    const groupedItems: { [ownerId: string]: EDIT_MEETUP_PAGE_admin_adminMeetup_ohSlots[] } = {};

    allSlotsFlat.forEach((oh) => {
      if (!groupedItems[oh.ownerId]) {
        groupedItems[oh.ownerId] = [oh];
      } else {
        groupedItems[oh.ownerId].push(oh);
      }
    });

    const ohItems = Object.entries(groupedItems)
      .filter(([_, ohSlots]) => ohSlots && ohSlots.length > 0)
      .map(([ownerId, ohSlots]) => {
        const { location, description, endsAt, startsAt, owner2Id } = ohSlots[0];

        const times = ohSlots.map((slot) => new Date(slot.startsAt)).sort();
        const durationInMinutes = differenceInMinutes(new Date(endsAt), new Date(startsAt), {
          roundingMethod: "round",
        });

        return {
          ownerId,
          owner2Id: owner2Id?.toString(),
          location,
          description,
          date: times[0],
          durationInMinutes,
          slots: times,
        };
      });

    setOfficeHours(ohItems);
  }, [data]);

  const [updateOfficeHours] = useMutation<UPDATE_OFFICE_HOURS, UPDATE_OFFICE_HOURSVariables>(gql`
    mutation UPDATE_OFFICE_HOURS($meetupId: ID!, $ohSlots: [AdminUpdateMeetupOfficeHoursInput!]!) {
      adminUpdateMeetupOfficeHours(meetupId: $meetupId, ohSlots: $ohSlots) {
        id
        description
        location
        startsAt
        endsAt
        ownerId
        owner2Id
        owner {
          id
          name
        }
      }
    }
  `);

  const onSave = useCallback(async () => {
    const slotsToCreate: AdminUpdateMeetupOfficeHoursInput[] = [];
    if (!meetupId) {
      return;
    }

    officeHours.forEach((oh) => {
      const slots = oh.slots.filter((s) => s instanceof Date);
      if (slots.length === 0 || !oh.date || !oh.durationInMinutes || !oh.ownerId) {
        return;
      }

      const formattedDate = format(oh.date, "yyyy-MM-dd");
      oh.slots
        .filter((s) => !!s)
        .forEach((time) => {
          const formattedStartTime = format(time!, "HH:mm a");
          const formattedEndTime = format(addMinutes(time!, oh.durationInMinutes!), "HH:mm a");
          slotsToCreate.push({
            id: oh.id,
            ownerId: oh.ownerId,
            owner2Id: oh.owner2Id,
            location: oh.location,
            description: oh.description,
            startsAt: `${formattedDate} ${formattedStartTime}`,
            endsAt: `${formattedDate} ${formattedEndTime}`,
          });
        });
    });

    await updateOfficeHours({
      variables: { meetupId, ohSlots: slotsToCreate },
    });

    afterUpdate(meetupId);
  }, [officeHours]);

  const onAdd = () => {
    const startsAt = data?.admin.adminMeetup.startsAt;
    const date =
      officeHours[officeHours.length - 1]?.date || (startsAt && new Date(startsAt)) || undefined;
    const location = officeHours[officeHours.length - 1]?.location || "";
    setOfficeHours((oh) => [
      ...oh,
      {
        ownerId: undefined,
        owner2Id: undefined,
        location,
        description: "",
        slots: [undefined],
        date,
        durationInMinutes: undefined,
      },
    ]);
  };

  const renderOh = (oh: OHDefinition, idx: number) => {
    const owner = ycStaff.find(({ id }) => oh.ownerId === id);
    const owner2 = ycStaff.find(({ id }) => oh.owner2Id === id);

    const onRemove = () => {
      setOfficeHours((ohs) => {
        const newOhs = [...ohs];
        newOhs.splice(idx, 1);
        return newOhs;
      });
    };

    const onChange = (newFields: { [fieldName: string]: string | Date | (Date | undefined)[] }) => {
      setOfficeHours((ohs) =>
        ohs.map((stateOh, ohIdx) => {
          if (idx === ohIdx) {
            return { ...stateOh, ...newFields };
          }
          return stateOh;
        })
      );
    };

    const onAddTimeSlot = () => {
      const sortedSlots = [...oh.slots].sort();
      let newTime;
      if (oh.slots.length > 1) {
        const lastTwo = sortedSlots.filter((d) => d instanceof Date).slice(-2);
        const diff = differenceInMinutes(lastTwo[1] as Date, lastTwo[0] as Date, {
          roundingMethod: "round",
        });
        newTime = addMinutes(lastTwo[1] as Date, diff);
      }
      onChange({ slots: [...oh.slots, newTime] });
    };

    return (
      <PaneItem>
        <FieldRow>
          <Label>Office hours with</Label>
          <Value>
            <Autocomplete
              options={ycStaff}
              getOptionLabel={(option) => option.name}
              value={owner || null}
              onChange={(_, newOwner) => onChange({ ownerId: newOwner?.id })}
              getOptionSelected={(option, value) => option.id === value.id}
            />
          </Value>
        </FieldRow>

        <FieldRow>
          <Label>
            ...and with
            <SubLabel>(optional)</SubLabel>
          </Label>
          <Value>
            <Autocomplete
              options={ycStaff}
              getOptionLabel={(option) => option.name}
              value={owner2 || null}
              onChange={(_, newOwner) => onChange({ owner2Id: newOwner?.id })}
              getOptionSelected={(option, value) => option.id === value.id}
            />
          </Value>
        </FieldRow>

        <FieldRow>
          <Label>Location</Label>
          <Value>
            <Input
              size="small"
              value={oh.location}
              onChange={(e) => onChange({ location: e.target.value })}
            />
          </Value>
        </FieldRow>

        <FieldRow css={css({ alignItems: "flex-start" })}>
          <Label>
            <div>Description</div>
            <SubLabel>(for calendar event)</SubLabel>
          </Label>
          <Value>
            <TextareaAutosize
              fullWidth
              value={oh.description}
              minRows={3}
              onChange={(e) => onChange({ description: e.target.value })}
            />
          </Value>
        </FieldRow>

        <FieldRow>
          <Label>Date</Label>
          <Value>
            <ReactDatePicker
              selected={oh.date}
              dateFormat="PP"
              showTimeSelect={false}
              onChange={(date: Date) => onChange({ date })}
              customInput={<Input size="small" />}
            />
          </Value>
        </FieldRow>

        <FieldRow>
          <Label>
            <div>Meeting length</div>
            <SubLabel>(in minutes)</SubLabel>
          </Label>
          <Value>
            <Input
              size="small"
              value={oh.durationInMinutes}
              onChange={(e) => onChange({ durationInMinutes: e.target.value })}
            />
          </Value>
        </FieldRow>

        <FieldRow css={css({ alignItems: "flex-start" })}>
          <Label>Times</Label>
          <Value>
            <div css={css({ display: "flex", flexDirection: "column", gap: 8 })}>
              {oh.slots.map((time, slotIdx) => (
                <TimeSelector
                  key={idx}
                  slots={oh.slots}
                  time={time}
                  slotIdx={slotIdx}
                  onChange={onChange}
                />
              ))}
              <div
                onClick={onAddTimeSlot}
                css={css({
                  display: "flex",
                  alignItems: "center",
                  "&:hover": { cursor: "pointer " },
                })}
              >
                <Add />
                <div css={css({ marginLeft: "8px" })}>Add slot</div>
              </div>
            </div>
          </Value>
        </FieldRow>

        <div css={css({ width: "100%", display: "flex", justifyContent: "flex-end" })}>
          <div
            onClick={onRemove}
            css={css({
              display: "flex",
              alignItems: "center",
              color: "gray",
              "&:hover": { cursor: "pointer" },
            })}
          >
            <Delete />
            <div>Delete</div>
          </div>
        </div>
      </PaneItem>
    );
  };

  return (
    <>
      <Pane title="Office hour slots">
        {officeHours.map((oh, idx) => renderOh(oh, idx))}
        <PaneItem>
          <Button color="gray" onClick={onAdd}>
            <div css={css({ display: "flex", alignItems: "center" })}>
              <AddCircleOutlined />
              <div css={css({ marginLeft: "8px" })}>Add new office hours</div>
            </div>
          </Button>
        </PaneItem>
      </Pane>

      <Button onClick={onSave} color="orange">
        Save
      </Button>
    </>
  );
};
