import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import { AdminUserWithCfmProfileFragment } from "../cofounderMatching/fragments";
import RateUser from "./RateUser";
import { ADMIN_UNRATED_USERS } from "./__generated__/ADMIN_UNRATED_USERS";

export default function UnratedUsers() {
  const { data, loading } = useQuery<ADMIN_UNRATED_USERS>(
    gql`
      query ADMIN_UNRATED_USERS {
        admin {
          unratedUsers {
            ...AdminUserWithCfmProfileFragment
          }
        }
      }
      ${AdminUserWithCfmProfileFragment}
    `
  );

  const users = data?.admin.unratedUsers;
  if (!users) {
    return null;
  }

  let remainingCount: string | number =
    users?.filter((u) => !u.adminRatingComplete).length ?? "error!";
  if (loading) remainingCount = "...";

  return (
    <div css={css({ margin: -100 })}>
      <Page title={`${remainingCount} Unrated Users`}>
        <Container>
          <Pane>
            {users.map((user) => (
              <PaneItem key={user.slug}>
                <RateUser adminUser={user} />
              </PaneItem>
            ))}
          </Pane>
        </Container>
      </Page>
    </div>
  );
}
