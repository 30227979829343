import { css } from "@emotion/core";
import React from "react";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import Button from "../../components/statelessForms/Button";
import { confirmSanctionRequirementUsersPath } from "../../__generated__/routes";

type Props = {
  continue_url?: string;
  cfm?: boolean;
};

export default ({ continue_url, cfm }: Props) => {
  const app = cfm ? "Co-Founder Matching" : "Startup School";

  return (
    <Page title={`${app} sanction requirements`}>
      <Container css={css({ maxWidth: 900, fontSize: 17 })}>
        <p>
          Due to the recent sanctions in the United States, we are legally required to ask every{" "}
          {app} participant to confirm the following statement. You will be able to continue on to
          the rest of {app} afterward.
        </p>
        <Pane>
          <PaneItem>
            <p>
              By clicking “I CONFIRM”, you certify that you are not located in, ordinarily resident
              in (or acting on behalf of any other party ordinarily resident in or located in)
              Russia, Cuba, Iran, North Korea, Syria, the Crimea, Donetsk, Luhansk Regions of
              Ukraine, or any other country or region that is subject to applicable U.S. sanctions
              laws and regulations. Use of a virtual private network service, IP routing services,
              or other similar service for the purpose of circumventing these laws is strictly
              prohibited. You further certify that you are not named on any United States government
              prohibited, denied, unverified-party, sanctions, debarment, or exclusion list or
              export-controlled related restricted party list.{" "}
            </p>
            <br />
            <div css={css({ textAlign: "center" })}>
              <a href={`${confirmSanctionRequirementUsersPath()}?continue_url=${continue_url}`}>
                <Button color="orange" disabled={false}>
                  I CONFIRM
                </Button>
              </a>
            </div>
          </PaneItem>
        </Pane>
      </Container>
    </Page>
  );
};
