import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "@emotion/styled";
import { withStyles } from "@material-ui/core/styles";
import {
  ADMIN_ADD_MEETUP_HNIDS,
  ADMIN_ADD_MEETUP_HNIDSVariables,
} from "./__generated__/ADMIN_ADD_MEETUP_HNIDS";
import TextareaAutosize from "../../../components/statelessForms/TextareaAutosize";
import Button from "../../../components/statelessForms/Button";

type Props = {
  meetupId: string;
  reloadRsvps: () => void;
};

const AccordionSummary = withStyles(() => ({
  root: {
    backgroundColor: "#fdf6d5",
  },
}))(MuiAccordionSummary);

const AddAttendeesWrapper = styled.div`
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;

export default ({ meetupId, reloadRsvps }: Props) => {
  const [hnidsStr, setHnidsStr] = useState("");
  const [addHnids, { loading }] = useMutation<
    ADMIN_ADD_MEETUP_HNIDS,
    ADMIN_ADD_MEETUP_HNIDSVariables
  >(gql`
    mutation ADMIN_ADD_MEETUP_HNIDS($meetupId: ID!, $hnids: [String!]!, $waitlist: Boolean) {
      adminAddMeetupAttendees(meetupId: $meetupId, hnids: $hnids, waitlist: $waitlist) {
        id
        ssoUser {
          id
          hnid
        }
      }
    }
  `);

  const onAddAttendees = async (waitlist: boolean) => {
    const hnids = [...new Set(hnidsStr.split(/(?:,| |\n)/).filter((val) => !!val))];
    const resp = await addHnids({ variables: { hnids, meetupId, waitlist } });
    const hnidsCreated =
      resp?.data?.adminAddMeetupAttendees
        .map((rsvp) => rsvp.ssoUser.hnid?.toLowerCase().trim())
        .filter((hnid) => !!hnid) || [];
    const missingHnids = hnids.filter((hnid) => !hnidsCreated.includes(hnid.toLowerCase().trim()));
    setHnidsStr(missingHnids.join("\n"));
    reloadRsvps();
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b>Add attendees by HNID</b>
      </AccordionSummary>
      <AccordionDetails>
        <AddAttendeesWrapper>
          <TextareaAutosize
            value={hnidsStr}
            onChange={(e) => setHnidsStr(e.target.value)}
            fullWidth
            placeholder="Enter HNIDs separated by commas, spaces, or newlines"
            minRows={3}
            disabled={loading}
          />
          <ButtonRow>
            <Button color="orange" onClick={() => onAddAttendees(true)} disabled={loading}>
              {loading ? "Submitting..." : "Add to waitlist"}
            </Button>
            <Button color="orange" onClick={() => onAddAttendees(false)} disabled={loading}>
              {loading ? "Submitting..." : "Mark as invited"}
            </Button>
          </ButtonRow>
        </AddAttendeesWrapper>
      </AccordionDetails>
    </Accordion>
  );
};
