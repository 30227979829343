import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { Container } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import { AdminUserWithCfmProfileFragment } from "../cofounderMatching/fragments";
import RateUser from "./RateUser";
import { ADMIN_RATED_USERS } from "./__generated__/ADMIN_RATED_USERS";

export default function RatedUsersList(props: { raterId: number }) {
  const { data } = useQuery<ADMIN_RATED_USERS>(
    gql`
      query ADMIN_RATED_USERS($raterId: ID!) {
        admin {
          ratedUsers(raterId: $raterId) {
            ...AdminUserWithCfmProfileFragment
          }
        }
      }
      ${AdminUserWithCfmProfileFragment}
    `,
    { variables: { raterId: props.raterId } }
  );

  const adminUsers = data?.admin.ratedUsers;
  if (!adminUsers) {
    return <></>;
  }

  return (
    <div css={css({ margin: "0 -65px" })}>
      <Container>
        <Pane>
          {adminUsers.map((adminUser) => (
            <PaneItem key={adminUser.slug}>
              <RateUser adminUser={adminUser} />
            </PaneItem>
          ))}
        </Pane>
      </Container>
    </div>
  );
}
