import React from "react";
import { css } from "@emotion/core";
import { addHours, formatDistance, isBefore } from "date-fns";
import { Row } from "./EmailPushFiltersForm";
import { YC_ORANGE } from "../../../components/forms/util";

const AlertMessage = ({ color, message }: { color: string; message: string | JSX.Element }) => (
  <Row css={css({ justifyContent: "center" })}>
    <div css={css({ color, fontWeight: "bold" })}>{message}</div>
  </Row>
);

type Props = {
  editing?: boolean;
  sendsAt?: string | undefined;
  meetupStartsAt?: string;
};

export default ({ sendsAt, meetupStartsAt, editing }: Props) => {
  if (!sendsAt) {
    return null;
  }

  const sendsAtDate = new Date(sendsAt);
  const meetupStartDate = meetupStartsAt && new Date(meetupStartsAt);

  if (isBefore(sendsAtDate, new Date())) {
    return editing ? (
      <AlertMessage
        color="red"
        message="Scheduled time is in the past. This means the email will *not* be sent."
      />
    ) : (
      <AlertMessage color="gray" message="This email is not scheduled to be sent in the future." />
    );
  }

  const distanceFromNow = formatDistance(sendsAtDate, new Date());

  let willBeSentText: string | JSX.Element = `will be sent in ${distanceFromNow}`;
  if (meetupStartDate) {
    const before = isBefore(sendsAtDate, meetupStartDate);
    const distanceFromMeetup = formatDistance(sendsAtDate, meetupStartDate);
    willBeSentText = (
      <span>
        {willBeSentText} ({distanceFromMeetup} <i>{before ? "BEFORE" : "AFTER"}</i> the event
        starts)
      </span>
    );
  }

  if (isBefore(sendsAtDate, addHours(new Date(), 1))) {
    return (
      <AlertMessage
        color={YC_ORANGE}
        message={<div>Coming up soon! The email {willBeSentText}</div>}
      />
    );
  }

  return <AlertMessage color="blue" message={<div>Email {willBeSentText}</div>} />;
};
