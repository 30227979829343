import { gql, useQuery } from "@apollo/client";
import { MEETUP_YC_STAFF } from "./__generated__/MEETUP_YC_STAFF";

export default function useYcStaff() {
  const { data: ycStaffData } = useQuery<MEETUP_YC_STAFF>(
    gql`
      query MEETUP_YC_STAFF {
        admin {
          ycStaff {
            id
            name
          }
        }
      }
    `
  );

  return ycStaffData?.admin.ycStaff || [];
}
