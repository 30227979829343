import React from "react";
import styled from "@emotion/styled";
import { MeetupColumnType, SortType } from "./MeetupColumns";
import { Row } from "./AdminMeetupRsvp";

type ClickableThProps = {
  selected: boolean;
};
const ClickableTh = styled.th<ClickableThProps>`
  box-shadow: ${({ selected }) => (selected ? "0px 0px 5px 3px #D4F1F4 inset" : "none")};
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 10px 5px rgb(34 36 38 / 5%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    background-color: #fbfbfb;
  }
`;

const HeaderRow = styled(Row)`
  position: sticky;
  top: 0;
  background-color: whitesmoke;
  z-index: 1;
`;

type Props = {
  isOver: boolean;
  sortType: SortType | null;
  setSortType: (sortType: SortType) => void;
  columns: MeetupColumnType[];
  hideActions?: boolean;
  checkInView: boolean;
  setAttendeesView: boolean;
  isOhEvent: boolean;
};

export default ({
  isOver,
  sortType,
  setSortType,
  columns,
  hideActions,
  checkInView,
  setAttendeesView,
  isOhEvent,
}: Props) => {
  const renderActionHeaders = () => {
    if (hideActions) {
      return null;
    }

    if (checkInView) {
      return (
        <>
          <th>Checked in?</th>
          <th>No show?</th>
        </>
      );
    }

    if (isOver) {
      return <th>No show?</th>;
    }

    if (setAttendeesView) {
      return (
        <>
          <th>Going?</th>
          <th>Not going?</th>
        </>
      );
    }

    return (
      <>
        <th>Reject</th>
        <th>Accept</th>
      </>
    );
  };

  return (
    <thead>
      <HeaderRow>
        {renderActionHeaders()}
        {isOhEvent && <th>OH Slot</th>}

        {columns.map(({ title, sort }) =>
          sort ? (
            <ClickableTh
              key={sort.key}
              onClick={() => setSortType(sort)}
              selected={sortType?.key === sort.key}
            >
              {title}
            </ClickableTh>
          ) : (
            <th key={title}>{title}</th>
          )
        )}

        <th>View</th>
      </HeaderRow>
    </thead>
  );
};
