import React from "react";
import styled from "@emotion/styled";

const Centered = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px;
`;

export default () => (
  <Centered>
    <h2>This is a lifecycle email.</h2>
    <p>There's no need to set filters - it will be sent to relevant users at the relevant time.</p>
    <p>
      <i>e.g. as they join the waitlist for an event, confirm their attendance, etc.</i>
    </p>
  </Centered>
);
