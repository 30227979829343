import React, { useState, useRef } from "react";

import { Tabs, Tab, Paper } from "@material-ui/core";
import useInterval from "react-useinterval";
import { addHours, formatDistanceToNow, formatRelative } from "date-fns";
import DailyIframe from "@daily-co/daily-js";

import authorizedFetch from "@yc/shared/authorizedFetch";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { gql, useMutation, useQuery } from "@apollo/client";
import Button from "../../components/statelessForms/Button";
import { CompanyItem } from "../companies/Company";
import { Session } from "./Session";
import maybeHandleMergedSession from "./maybeHandleMergedSession";
import Agenda from "./Agenda";

import { getSlugFromWindowLocation } from "../../components/forms/util";
import { GROUP_SESSION } from "./__generated__/GROUP_SESSION";
import {
  SUBMIT_GS_FEEDBACK,
  SUBMIT_GS_FEEDBACKVariables,
} from "./__generated__/SUBMIT_GS_FEEDBACK";
import { useForm } from "../../components/forms";

type Props = {
  current_company_slug?: string | null;
  session: Session;
  user_token: string;
  auto_record: boolean;
};

let callFrame: any = null;

const LeftSide = styled.div({ width: "66.67%", height: "100%", position: "relative" });
const RightSide = styled.div({
  width: "33.3%",
  borderLeft: "1px solid #dadce0",
  display: "flex",
  flexDirection: "column",
  ".ui.secondary.menu": {
    marginBottom: 0,
  },
});

const PreCall = styled.div({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: 20,
  backgroundColor: "white",
});

const TabPane = styled.div({
  "&&&": {
    border: 0,
    margin: 0,
    maxWidth: "100%",
    padding: 30,
    overflow: "scroll",
  },
});

export default function GroupSession(props: Props): React.ReactElement {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [inCall, setInCall] = useState<boolean>(false);
  const [, setCallLoaded] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [submittingFeedback, setSubmittingFeedback] = useState<boolean>(false);

  const iframeContainer = useRef(null);
  const slug = getSlugFromWindowLocation();

  const { data, refetch } = useQuery<GROUP_SESSION>(
    gql`
      query GROUP_SESSION($slug: ID!) {
        groupSession(slug: $slug) {
          slug
          feedback {
            id
            feedbackSubmitted
            usefulness
            callQuality
            textFeedback
          }
        }
      }
    `,
    { variables: { slug } }
  );

  const [submitFeedback] = useMutation<SUBMIT_GS_FEEDBACK, SUBMIT_GS_FEEDBACKVariables>(gql`
    mutation SUBMIT_GS_FEEDBACK(
      $slug: ID!
      $usefulness: Int
      $callQuality: Int
      $textFeedback: String
    ) {
      submitGroupSessionFeedback(
        slug: $slug
        usefulness: $usefulness
        callQuality: $callQuality
        textFeedback: $textFeedback
      ) {
        id
        feedbackSubmitted
        usefulness
        callQuality
        textFeedback
      }
    }
  `);

  const onSubmitFeedback = async (input: any) => {
    if (!slug) {
      return;
    }
    setSubmittingFeedback(true);
    await submitFeedback({
      variables: {
        slug,
        usefulness: input.usefulness,
        callQuality: input.callQuality,
        textFeedback: input.textFeedback,
      },
    });
    await refetch();
    setSubmittingFeedback(false);
  };

  const { ConnectedForm, connectedFormProps } = useForm(
    data?.groupSession?.feedback,
    onSubmitFeedback
  );

  useInterval(() => setCurrentTime(new Date()), 1000);

  const joinCall = async () => {
    // @ts-ignore
    callFrame = DailyIframe.createFrame(iframeContainer.current);

    // enable screen sharing
    callFrame.iframe().setAttribute("allow", "microphone; camera; autoplay; display-capture");

    callFrame
      .join({
        url: `https://daily-api.daily.co/${props.session.daily_co_room}`,
        token: props.user_token,
      })
      .then(() => {
        setCallLoaded(true);
        if (props.auto_record) {
          callFrame.startRecording();
        }
      });
    setInCall(true);
    authorizedFetch(`/group_sessions/${props.session.slug}/attendance`, { method: "POST" }).then(
      maybeHandleMergedSession
    );
  };

  const leaveCall = () => {
    setInCall(false);
    setCallLoaded(false);
    callFrame.leave();
    callFrame.destroy();
  };

  const startedAt = new Date(props.session.started_at);
  const callStarted = startedAt < currentTime;

  const renderAgenda = () => {
    const mergeVisible = false; // disable merge temporarily

    return (
      <Agenda
        startedAt={startedAt}
        currentTime={currentTime}
        mergeVisible={mergeVisible}
        feedback={data?.groupSession?.feedback}
        session={props.session}
        submittingFeedback={submittingFeedback}
      />
    );
  };

  const renderInvitees = () => (
    <div css={css({ margin: -30 })}>
      {props.session.companies.map((c) => (
        <CompanyItem c={c} key={c.slug} />
      ))}
    </div>
  );

  const endedAt = addHours(startedAt, 3);
  let scheduledAt: string | React.ReactFragment = "";
  if (endedAt < currentTime) {
    scheduledAt = (
      <>
        Group Session started <strong>{formatRelative(startedAt, currentTime)}</strong> and is now
        over.
      </>
    );
  } else if (callStarted) {
    scheduledAt = (
      <>
        Group Session started <strong>{formatDistanceToNow(startedAt, { addSuffix: true })}</strong>
        .
      </>
    );
  } else {
    scheduledAt = (
      <>
        Group Session is scheduled to begin{" "}
        <strong>{formatRelative(startedAt, currentTime)}</strong>.
      </>
    );
  }

  const tabPanes = props.session.companies
    ? [
        { menuItem: "Agenda", render: renderAgenda },
        { menuItem: "Participants", render: renderInvitees },
      ]
    : [{ menuItem: "Agenda", render: renderAgenda }];

  const leftSide = (
    <LeftSide>
      <div ref={iframeContainer} css={css({ height: "100%", width: "100%" })} />
      {!inCall && (
        <PreCall>
          <p>{scheduledAt}</p>
          {endedAt > currentTime && (
            <>
              <p>
                <Button color="orange" onClick={joinCall}>
                  Join Video Call Now
                </Button>
              </p>
              <p>
                Technical difficulties joining the call? Read Daily.co's{" "}
                <a
                  href="https://help.daily.co/en/articles/2303117-top-troubleshooting-tips"
                  target="_blank"
                >
                  5 tips that fix 99% of issues
                </a>{" "}
                or{" "}
                <a href="https://daily.co/contact-us" target="_blank">
                  get live support
                </a>
                .
              </p>
            </>
          )}
        </PreCall>
      )}
    </LeftSide>
  );

  const rightSide = (
    <RightSide>
      {inCall && (
        <button
          css={css({ marginRight: "0 !important", minHeight: "inherit" })}
          className="ui orange button"
          onClick={leaveCall}
        >
          Leave Call
        </button>
      )}
      <Paper>
        <Tabs
          value={currentTabIndex}
          onChange={(_, newTabIndex) => setCurrentTabIndex(newTabIndex)}
          variant="fullWidth"
          aria-label="tabs"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabPanes.map(({ menuItem }) => (
            <Tab key={menuItem} label={menuItem} />
          ))}
        </Tabs>
      </Paper>
      <ConnectedForm {...connectedFormProps} css={css({ overflow: "scroll" })}>
        <TabPane>{tabPanes[currentTabIndex].render()}</TabPane>
      </ConnectedForm>
    </RightSide>
  );

  return (
    <div
      css={css({ height: "100vh", display: "flex", flexDirection: "row", alignItems: "stretch" })}
    >
      {leftSide}
      {rightSide}
    </div>
  );
}
