import React from "react";
import styled, { CSSObject } from "@emotion/styled";
import { css } from "@emotion/core";
import { formatDistanceStrict } from "date-fns";
import { VoteType } from "./meetupUtils";

type Props = {
  vote: VoteType;
};

const VOTE_STYLES: { [val: string]: CSSObject } = {
  Y: {
    backgroundColor: "#22c55e",
    color: "white",
  },
  m: {
    backgroundColor: "#fcba03",
    color: "white",
  },
  n: {
    backgroundColor: "#eb4034",
    color: "white",
  },
};

const SKIP_STYLE: CSSObject = {
  backgroundColor: "white",
  border: "1px solid #d1d5db",
};

const AdminVoteItem = styled.p`
  min-width: 200px;
  padding: 2px 0;
  margin-bottom: 2px;
`;

const VoteTag = styled.span`
  border-radius: 5px;
  padding: 1px 3px;
`;

const VoteDate = styled.span`
  font-size: 12px;
  color: #6b7280;
  font-weight: 100;
`;

const AdminRSVPVote = ({ vote }: Props) => {
  const styles = vote.val ? VOTE_STYLES[vote.val] : SKIP_STYLE;

  return (
    <AdminVoteItem>
      <VoteTag css={css(styles)}>{vote.val || vote.voteType}</VoteTag>
      <strong> {vote.voterName}</strong>
      {!!vote.comments && <span>: {vote.comments}</span>}
      <VoteDate>
        {" "}
        {formatDistanceStrict(new Date(vote.updatedAt), new Date(), { addSuffix: true })}
      </VoteDate>
    </AdminVoteItem>
  );
};

export default AdminRSVPVote;
