import React from "react";
import styled from "@emotion/styled";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import { css } from "@emotion/core";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { DASHBOARD_DATA } from "./__generated__/DASHBOARD_DATA";
import Button from "../../components/statelessForms/Button";
import { cofounderMatchingPath, libraryPath } from "../../__generated__/routes";

type Props = {
  data: DASHBOARD_DATA;
};

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h4 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0;
  }
`;

// @ts-ignore
export default ({ data }: Props) => (
  <Pane>
    <PaneHeader>
      <h3
        css={css({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          svg: { marginRight: 5 },
        })}
      >
        <BookmarksIcon />
        Other Resources
      </h3>
    </PaneHeader>
    <PaneItem>
      <HeaderRow>
        <h4>YC Library</h4>
        {/* @ts-ignore */}
        <Button color="blue" target="_blank" size="small" href={libraryPath()}>
          View
        </Button>
      </HeaderRow>
      <p>
        The YC library contains hundreds of videos and articles from YC partners and founders with
        advice on a wide range of topics.
      </p>
      <p>
        If you're looking to answer a specific question, searching the library is a good place to
        start!
      </p>
    </PaneItem>
    <PaneItem>
      <HeaderRow>
        <h4>Co-Founder Matching</h4>
        <Button color="blue" size="small" href={cofounderMatchingPath()}>
          View
        </Button>
      </HeaderRow>
      {data?.cofounderMatching?.profile ? (
        <p>
          You've already signed up for co-founder matching! Head over to your{" "}
          <a href={cofounderMatchingPath()}>dashboard</a> to see any updates you might have missed.
        </p>
      ) : (
        <p>
          Want to find a co-founder? Try co-founder matching, YC's free online platform for finding
          a high-quality co-founder!
        </p>
      )}
    </PaneItem>
  </Pane>
);
