import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox, Dialog, FormControlLabel, Radio, withStyles } from "@material-ui/core";
import { isBefore } from "date-fns";
import { MarkdownComponent } from "@yc/shared/renderMarkdown";
import { toast, ToastContainer } from "react-toastify";
import {
  getSlugFromWindowLocation,
  validateLinkedinUrl,
  YC_ORANGE,
} from "../../components/forms/util";
import Button from "../../components/statelessForms/Button";
import { MEETUP, MEETUPVariables } from "./__generated__/MEETUP";
import {
  UPDATE_MEETUP_RSVP,
  UPDATE_MEETUP_RSVPVariables,
} from "./__generated__/UPDATE_MEETUP_RSVP";
import {
  cofounderMatchingPath,
  dashboardCofounderMatchingPath,
  meetupCofounderMatchingPath,
  signInUsersPath,
} from "../../__generated__/routes";
import { reqPrefs } from "../cofounderMatching/profileFields";
import Input from "../../components/statelessForms/Input";
import WithCharCount from "../../components/statelessForms/WithCharCount";
import { useForm } from "../../components/forms";
import MeetupQuestion, {
  MeetupQuestionHeader,
  MeetupQuestionSubtitle,
  RequiredAsterisk,
} from "./MeetupQuestion";
import { QuestionResponseInput } from "../../types/graphqlTypes";
import SignOutLink from "../../components/navigation/SignOutLink";
import { DesktopOnly, MobileOnly, mobileStyleCss, paneBorder } from "../../styles";
import EmailSupportLink from "../cofounderMatching/EmailSupportLink";
import {
  UPDATE_SSO_USER_EMAIL,
  UPDATE_SSO_USER_EMAILVariables,
} from "./__generated__/UPDATE_SSO_USER_EMAIL";
import { formatTime } from "../admin/meetups/meetupUtils";
import { useSmallStyles } from "../../components/forms/MultiSelect";

const HEADER_HEIGHT = 180;

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: #f5f5f5;
`;

const Header = styled.div`
  background-color: #eeeee5;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  position: relative;
`;

const HeaderContent = styled.div`
  z-index: 3;
  position: absolute;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  padding: 60px 30px;

  ${mobileStyleCss(`
    padding: 40px 30px;
  `)}
`;

const YCLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 15px 20px;
  z-index: 4;
  background-color: white;
  height: 40px;
  width: 40px;
  img {
    height: 40px;
    width: 40px;
  }

  ${mobileStyleCss(`
    margin: 10px;
    height: 30px;
    width: 30px;
    img {
      height: 30px;
      width: 30px;
    }
  `)}
`;

const WelcomeOrSignIn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 20px;
  z-index: 4;

  ${mobileStyleCss(`
    font-size: 12px;
    padding: 10px;
  `)}
`;

const Centered = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Centered)`
  h1 {
    color: black;
    font-weight: bold;
    font-size: 28px;

    ${mobileStyleCss(`
      font-size: 26px;
      text-align: center;
    `)}
  }
`;

const DateLine = styled(Centered)`
  padding: 16px;
  span {
    font-size: 16px;

    ${mobileStyleCss(`
      font-size: 16px;
    `)}
  }
`;

const Dot = styled.div`
  margin: 0 15px;
  background-color: black;
  height: 5px;
  width: 5px;
  border-radius: 50%;
`;

const Subtitle = styled(Centered)`
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;

  ${mobileStyleCss(`
    font-size: 18px;
    text-align: center;
  `)}
`;

const Body = styled.div`
  width: 100%;
  padding: 30px 100px;
  max-width: 1300px;
  margin: 0 auto;

  ${mobileStyleCss(`
    padding: 30px 20px;
  `)}
`;

const Description = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
`;

const BodyHeader = styled.h2`
  font-size: 20px;
  font-weight: 500;
`;

const Section = styled.div`
  background-color: white;
  border: ${paneBorder};
  border-radius: 4px;
  padding: 30px;
  overflow: hidden;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`;

const SectionHeader = styled.div`
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: -30px;
  margin-bottom: 30px;
  padding: 20px 30px;
  background-color: #666;

  h2 {
    color: white;
    font-size: 18px;
  }
`;

const SpacedSection = styled(Section)`
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const ExtraLargeButton = withStyles({
  root: {
    width: "100%",
    height: 60,
    fontSize: 18,
    letterSpacing: 1,
    marginTop: 15,
  },
})(Button);

const ActionSection = styled(Section)`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    max-width: 600px;
    text-align: center;
  }
`;

type ButtonRowProps = { extraWide?: boolean };
const ButtonRow = styled.div<ButtonRowProps>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  ${mobileStyleCss("flex-direction: column")}
  button {
    margin: 0 10px;
    width: ${({ extraWide }) => (extraWide ? 300 : 200)}px;
  }
`;

const LogisticsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  b {
    margin: 10px 0 5px 0;
  }
`;

const RequirementsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  b {
    margin-right: 15px;
  }
`;

const NameSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameInputWrapper = styled.div`
  flex-grow: 1;
  &:first-of-type {
    margin-right: 5px;
  }
  &:last-of-type {
    margin-left: 5px;
  }
`;

const CancelledText = styled.h3`
  margin-top: 0;
  width: 100%;
  text-align: center;
  color: darkred;
`;

const Speaker = styled.div`
  margin: 10px;
  text-align: center;
  max-width: 200px;

  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  h2 {
    margin: 5px 0;
  }

  span {
  }
`;

const MeetupTimeSlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const MeetupTimeSlot = styled.div<{ selected: boolean }>`
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: ${({ selected }) => (selected ? "white" : "#ebebeb")};
  color: ${({ selected }) => (selected ? "black" : "gray")};
  padding: 10px 15px;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  signedIn: boolean;
  userCreated: boolean;
  cfmProfileCreated: boolean;
};

const YC_Y_LOGO_IMG = "/images/yc_y.svg";

const SIGN_UP_PATH = "https://account.ycombinator.com/";

const YC_LI_URL = "https://www.linkedin.com/school/y-combinator/";
const YC_TWITTER_URL = "https://twitter.com/ycombinator";

const COLORS = {
  WARNING: "#ffdf80",
  DANGER: "rgba(255, 0, 0, 0.2)",
  GRAY: "#e0e0e0",
  GREEN: "rgba(0, 255, 0, 0.3)",
};

type SpeakerType = {
  isHost?: boolean;
  name: string;
  description: string;
  url: string;
  oneLiner: string;
  imgSrc: string;
};

export default ({ signedIn, userCreated, cfmProfileCreated }: Props) => {
  const slug = getSlugFromWindowLocation();
  const susBaseUrl = window.location.origin.includes("yclocal.com")
    ? "http://startupschool.yclocal.com:3004"
    : "https://www.startupschool.org";
  const joinToken = new URLSearchParams(window.location.search).get("join_token");

  if (!slug) {
    return <div />;
  }

  const [email, setEmail] = useState<string | undefined>();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [linkedin, setLinkedin] = useState<string | undefined>();
  const [noLinkedin, setNoLinkedin] = useState(false);
  const [hasIdea, setHasIdea] = useState<string | undefined>();
  const [ideaOneLiner, setIdeaOneLiner] = useState<string>("");
  const [personalOneLiner, setPersonalOneLiner] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmingDecline, setIsConfirmingDecline] = useState(false);
  const [selectedSlotStart, setSelectedSlotStart] = useState<string>();

  const isLinkedinValid = useMemo(() => validateLinkedinUrl(linkedin || ""), [linkedin]);

  const smallStyles = useSmallStyles();

  const { data, refetch } = useQuery<MEETUP, MEETUPVariables>(
    gql`
      query MEETUP($slug: ID!) {
        ssoUser {
          firstName
          lastName
          email
          linkedin
        }
        meetup(slug: $slug) {
          slug
          title
          description
          cfm
          host
          hostYcUrl
          publicLocation
          timeZone
          usCitizenOnly
          startsAt
          endsAt
          registrationClosesAt
          formattedStartDatetime
          formattedStartDate
          formattedStartTime
          cancelled
          address
          instructions
          onWaitlist
          invited
          confirmed
          declined
          denied
          inviteExpired
          qrCodeUrl
          eventType
          speakers
          anotherAttendeeText
          waitlistInstructionTitleOverride
          waitlistInstructionSubtitleOverride
          linkedinRequired
          questions {
            id
            questionText
            questionSubtitle
            questionType
            required
            otherRequirements
            fieldName
          }
          ohSlot {
            startsAt
            endsAt
            ownerNames
            description
            location
          }
          ohSlotOptions {
            startsAt
            endsAt
            ownerNames
          }
        }
        cofounderMatching {
          profile {
            slug
            hasIdea
          }
        }
      }
    `,
    { variables: { slug } }
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const { ssoUser, meetup } = data;

    const first = ssoUser?.firstName;
    const last = ssoUser?.lastName;
    const li = ssoUser?.linkedin;

    if (first && !firstName) {
      setFirstName(first);
    }

    if (last && !lastName) {
      setLastName(last);
    }

    if (li && !linkedin) {
      setLinkedin(li);
      setNoLinkedin(false);
    }

    if (
      meetup.eventType === "office_hours" &&
      meetup.invited &&
      meetup.ohSlotOptions.length === 1
    ) {
      setSelectedSlotStart(data.meetup.ohSlotOptions[0].startsAt);
    }
  }, [data]);

  const [updateRsvp] = useMutation<UPDATE_MEETUP_RSVP, UPDATE_MEETUP_RSVPVariables>(gql`
    mutation UPDATE_MEETUP_RSVP($input: UpdateMeetupRsvpInput!) {
      updateMeetupRsvp(input: $input) {
        slug
        onWaitlist
        invited
        confirmed
        declined
      }
    }
  `);

  const [updateSsoFields] = useMutation<UPDATE_SSO_USER_EMAIL, UPDATE_SSO_USER_EMAILVariables>(gql`
    mutation UPDATE_SSO_USER_EMAIL($input: UpdateSsoUserInput!) {
      updateSsoUser(input: $input) {
        errors {
          field
          error
        }
      }
    }
  `);

  const ageQuestionId = useMemo(
    () => data?.meetup?.questions.find((q) => q.fieldName === "age")?.id,
    [data?.meetup?.questions]
  );

  const speakers: SpeakerType[] = useMemo(() => {
    if (!data?.meetup.speakers) {
      return [];
    }

    return JSON.parse(data.meetup.speakers);
  }, [data?.meetup.speakers]);

  useEffect(() => {
    if (hasIdea) {
      return;
    }
    setHasIdea(data?.cofounderMatching?.profile?.hasIdea);
  }, [data?.cofounderMatching?.profile?.hasIdea]);

  const { formMethods, ConnectedForm, connectedFormProps } = useForm({}, () => {});

  const joinWaitlist = async () => {
    setIsSubmitting(true);
    const questionResponses: QuestionResponseInput[] = [];
    Object.entries(formMethods.getValues()).forEach(([questionId, value]) => {
      if (value) {
        questionResponses.push({ questionId, response: JSON.stringify({ value }) });
      }
    });

    if (firstName?.trim() || lastName?.trim() || linkedin?.trim()) {
      const ssoInput = {
        ...(firstName?.trim() ? { firstName: firstName.trim() } : {}),
        ...(lastName?.trim() ? { lastName: lastName.trim() } : {}),
        ...(linkedin?.trim() ? { linkedin: linkedin.trim() } : {}),
      };
      await updateSsoFields({ variables: { input: ssoInput } });
    }

    if (!data?.ssoUser?.email && !!email?.trim()) {
      const resp = await updateSsoFields({ variables: { input: { email } } });
      if (resp?.data?.updateSsoUser.errors?.length) {
        setIsSubmitting(false);
        toast.error(
          "Unable to join waitlist. Please double-check to make sure you've entered a valid email."
        );
        return;
      }
    }

    await updateRsvp({
      variables: {
        input: {
          slug,
          hasIdea,
          ideaOneLiner,
          personalOneLiner,
          joinWaitlist: true,
          referrerSlug: new URLSearchParams(window.location.search).get("referrer"),
          questionResponses,
          joinToken,
        },
      },
    });
    await refetch();
    setIsSubmitting(false);
  };

  const leaveWaitlist = async () => {
    await updateRsvp({ variables: { input: { slug, leaveWaitlist: true } } });
    refetch();
  };

  const confirmAttendance = async () => {
    await updateRsvp({
      variables: { input: { slug, confirm: true, ohSlotStart: selectedSlotStart } },
    });
    refetch();
  };

  const declineAttendance = async () => {
    await updateRsvp({ variables: { input: { slug, decline: true } } });
    await refetch();
    setIsConfirmingDecline(false);
  };

  if (!data || !data.meetup) {
    return <div />;
  }

  const { meetup } = data;

  const isOhEvent = meetup.eventType === "office_hours";

  const hasStarted = isBefore(new Date(meetup.startsAt), new Date());
  const registrationClosed =
    (!!meetup.registrationClosesAt &&
      isBefore(new Date(meetup.registrationClosesAt), new Date())) ||
    hasStarted;
  const hasEnded = isBefore(new Date(meetup.endsAt), new Date());

  const bannerSection = () => {
    if (!signedIn) {
      return;
    }

    if (hasStarted) {
      return meetupHasStartedBanner();
    }

    if (!userCreated && meetup.eventType === "cfm") {
      return createUserBanner;
    }

    if (meetup.invited || meetup.inviteExpired) {
      return invitedBanner();
    }

    return null;
  };

  const nameSection = (!data?.ssoUser?.firstName || !data?.ssoUser?.lastName) && (
    <div css={css({ marginTop: 30 })}>
      <RequirementsSection>
        <b>Your name</b>
        <NameSection>
          <NameInputWrapper>
            <Input
              value={firstName}
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
              size="small"
              outlined
            />
          </NameInputWrapper>
          <NameInputWrapper>
            <Input
              value={lastName}
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
              size="small"
              outlined
            />
          </NameInputWrapper>
        </NameSection>
      </RequirementsSection>
    </div>
  );

  const emailSection = (
    <div css={css({ marginTop: 30 })}>
      <RequirementsSection>
        <b>Your email</b>
        {data?.ssoUser?.email ? (
          <div>
            {data.ssoUser.email}{" "}
            <a href="https://account.ycombinator.com/?editing=true" target="_blank">
              (update)
            </a>
          </div>
        ) : (
          <Input
            value={email}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            outlined
          />
        )}
      </RequirementsSection>
    </div>
  );

  const linkedinQuestionSection = (
    <div css={css({ marginTop: 30 })}>
      <RequirementsSection css={css({ alignItems: "flex-start" })}>
        <MeetupQuestionHeader>LinkedIn URL{!noLinkedin && RequiredAsterisk}</MeetupQuestionHeader>
        <MeetupQuestionSubtitle css={css({ marginBottom: 0 })}>
          URL should start with https://
        </MeetupQuestionSubtitle>
        <Input
          value={linkedin}
          onChange={(e) => setLinkedin(e.target.value)}
          placeholder="https://www.linkedin.com/in/..."
          size="small"
          outlined
        />
        {!!linkedin && linkedin.length > 5 && !isLinkedinValid && (
          <div css={css({ color: "#ef4444", fontSize: 14, fontWeight: "bold" })}>
            Please enter a valid LinkedIn URL
          </div>
        )}
        <div css={css({ marginTop: 5 })}>
          <FormControlLabel
            control={<Checkbox color="default" />}
            checked={noLinkedin}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNoLinkedin(e.target.checked)}
            label="I don't have a LinkedIn profile"
            classes={{ label: smallStyles.label }}
          />
        </div>
      </RequirementsSection>
    </div>
  );

  const cfmQuestionsSection = (
    <div css={css({ marginTop: 30 })}>
      <RequirementsSection>
        <b>Give your personal one-liner in 50 characters or fewer.</b>
        <div css={css({ fontStyle: "italic", marginBottom: 10 })}>
          (This could include your current role, skills, or interests)
        </div>
        <WithCharCount max={50} currentLength={personalOneLiner.length}>
          <Input
            value={personalOneLiner}
            placeholder="e.g. Software Engineer at OpenAI"
            onChange={(e) => setPersonalOneLiner(e.target.value)}
            size="small"
            outlined
          />
        </WithCharCount>
      </RequirementsSection>
      <RequirementsSection>
        <b>Are you set on an idea?</b>
        <div css={css({ display: "flex", flexDirection: "column" })}>
          {Array.from(reqPrefs.hasIdea).map(([key, label]) => (
            <FormControlLabel
              key={key}
              control={<Radio size="small" color="default" />}
              label={label}
              value={key.toString()}
              onChange={() => setHasIdea(key)}
              checked={hasIdea === key}
            />
          ))}
        </div>
      </RequirementsSection>
      {(hasIdea === "committed" || hasIdea === "open") && (
        <RequirementsSection>
          <b>Describe your idea in 50 characters or fewer.</b>
          <WithCharCount max={50} currentLength={ideaOneLiner.length}>
            <Input
              value={ideaOneLiner}
              placeholder="e.g. Backup and share files in the cloud"
              onChange={(e) => setIdeaOneLiner(e.target.value)}
              size="small"
              outlined
            />
          </WithCharCount>
        </RequirementsSection>
      )}
      <div css={css({ textAlign: "center", marginBottom: 10 })}>
        <i>Your responses will be shown to potential co-founders if you attend.</i>
      </div>
    </div>
  );

  const questionsSection = (
    <div css={css({ minWidth: "50%", marginTop: meetup.questions.length ? 30 : 0 })}>
      <ConnectedForm {...connectedFormProps}>
        {meetup.questions.map((question) => (
          <MeetupQuestion question={question} key={question.id} ageQuestionId={ageQuestionId} />
        ))}
      </ConnectedForm>
    </div>
  );

  const isSubmitDisabled = () => {
    if (isSubmitting) {
      return true;
    }

    if (!data?.ssoUser?.email && !email?.trim()) {
      return true;
    }

    if (!data?.ssoUser?.firstName && !firstName?.trim()) {
      return true;
    }

    if (!data?.ssoUser?.lastName && !lastName?.trim()) {
      return true;
    }

    if (meetup.linkedinRequired) {
      const trimmedLi = !!linkedin?.trim();
      // If they've entered anything for LinkedIn and it's not valid, don't allow submission
      if (trimmedLi && !isLinkedinValid) {
        return true;
      }
      // Don't allow a blank LinkedIn unless they explicitly claim not to have one
      if (!data?.ssoUser?.linkedin && !trimmedLi && !noLinkedin) {
        return true;
      }
    }

    if (
      meetup.cfm &&
      (!hasIdea ||
        !personalOneLiner ||
        personalOneLiner.length > 50 ||
        (!!hasIdea &&
          ["committed", "open"].includes(hasIdea) &&
          (!ideaOneLiner || ideaOneLiner.length > 50)))
    ) {
      return true;
    }

    if (meetup.questions.length && !formMethods.formState.isValid) {
      return true;
    }

    return false;
  };

  const needsCfmProfileNote = meetup.cfm && !cfmProfileCreated && (
    <div css={css({ marginTop: 30, fontWeight: "bold", fontSize: 18 })}>
      Please note, you will need to create a co-founder matching profile to be accepted off the
      waitlist.
    </div>
  );

  const onWaitlistSection = (
    <ActionSection>
      <>
        <BodyHeader>You're on the waitlist!</BodyHeader>
        <p>We'll email you with details if we're able to give you a spot.</p>
        <ButtonRow>
          {meetup.cfm && !cfmProfileCreated ? (
            <a href={`${susBaseUrl}${cofounderMatchingPath()}`}>
              <Button size="large" color="orange">
                Create your profile
              </Button>
            </a>
          ) : (
            <Button onClick={leaveWaitlist}>Leave Waitlist</Button>
          )}
        </ButtonRow>
      </>
      {needsCfmProfileNote}
    </ActionSection>
  );

  const eventLogisticsSection = () => {
    if (registrationClosed && !meetup.confirmed) {
      return meetupRegistrationClosedSection();
    }

    if (meetup.declined) {
      return hasDeclinedSection;
    }

    if (meetup.confirmed || meetup.invited) {
      return (
        <SpacedSection>
          <SectionHeader css={css({ backgroundColor: YC_ORANGE })}>
            <h2>Important logistics</h2>
          </SectionHeader>
          {logisticsSection}
          {meetup.confirmed && !hasEnded && (
            <>
              <div css={css({ margin: "20px 0", borderTop: "1px solid #e0e0e0" })} />
              <Button size="small" onClick={() => setIsConfirmingDecline(true)}>
                I need to cancel.
              </Button>
            </>
          )}
        </SpacedSection>
      );
    }

    if (meetup.inviteExpired) {
      return inviteExpiredSection;
    }

    if (meetup.denied) {
      return (
        <SpacedSection css={css({ backgroundColor: COLORS.GRAY })}>
          <h2>Unfortunately, we weren't able to find you a spot.</h2>
          <div>
            Instead, we hope you'll check out these YC resources:
            <ul css={css({ textAlign: "left", li: { marginBottom: 10 } })}>
              <li>
                If you're interested in a starting a company, check out{" "}
                <a href="https://startupschool.org?utm_source=meetup-page&utm_campaign=meetup">
                  Startup School
                </a>{" "}
                for guidance from YC partners on everything from choosing an idea to fundraising.
              </li>
              {meetup.eventType !== "cfm" && (
                <li>
                  If you want to find a co-founder, check out{" "}
                  <a href="https://www.ycombinator.com/cofounder-matching?utm_source=meetup-page&utm_campaign=meetup">
                    Co-Founder Matching
                  </a>{" "}
                  to access a pool of high-quality candidates.
                </li>
              )}
              <li>
                If you're interested getting a job at a startup, check out{" "}
                <a href="https://workatastartup.com?utm_source=meetup-page&utm_campaign=meetup">
                  Work at a Startup
                </a>{" "}
                to see open positions at YC companies.
              </li>
              <li>
                And if you have a startup, we encourage you to{" "}
                <a href="https://ycombinator.com/">apply to YC</a> to give your startup a
                disproportionate advantage (plus $500k).
              </li>
            </ul>
          </div>
        </SpacedSection>
      );
    }

    if (meetup.onWaitlist) {
      return onWaitlistSection;
    }

    const canAutoJoinText = (
      <>
        <h2 css={css({ textAlign: "center" })}>
          {meetup.questions.length
            ? `We can confirm your spot as soon as you answer ${
                meetup.questions.length === 1 ? "this question" : "these questions"
              }!`
            : "Let us know if you'll be there!"}
        </h2>
        <p css={css({ textAlign: "center", color: "gray" })}>
          <i>
            We'll send you a confirmation email with a calendar invite after you confirm your
            registration.
          </i>
        </p>
      </>
    );

    const waitlistDescriptionText = (
      <>
        <h2>
          {meetup.waitlistInstructionTitleOverride ||
            "To request a spot at this event, join the waitlist."}
        </h2>
        <p css={css({ color: "gray", marginTop: -10 })}>
          <i>
            {meetup.waitlistInstructionSubtitleOverride ||
              "Joining the waitlist does not guarantee you a spot. We'll reach out with details if we're able to confirm your spot."}
          </i>
        </p>
      </>
    );

    return (
      <Section>
        {joinToken && !meetup.cfm ? canAutoJoinText : waitlistDescriptionText}
        {signedIn && (
          <div css={css({ marginTop: 5 })}>
            {emailSection}
            {nameSection}
            {meetup.linkedinRequired && linkedinQuestionSection}
            {meetup.cfm && cfmQuestionsSection}
            {questionsSection}
            {needsCfmProfileNote}
          </div>
        )}
        <ButtonWrapper>
          {signedIn ? (
            <ExtraLargeButton
              color="orange"
              size="large"
              disabled={isSubmitDisabled()}
              onClick={joinWaitlist}
            >
              {joinToken && !meetup.cfm ? "Confirm your registration" : "Join the waitlist"}
            </ExtraLargeButton>
          ) : (
            <a href={getSignInPath(false)}>
              <ExtraLargeButton color="orange" size="large">
                Sign in to RSVP
              </ExtraLargeButton>
            </a>
          )}
        </ButtonWrapper>
      </Section>
    );
  };

  const renderMeetupStartDateTime = () => {
    const startTime =
      isOhEvent && meetup.ohSlot ? formatTime(meetup.ohSlot.startsAt) : meetup.formattedStartTime;
    return `${meetup.formattedStartDate} at ${startTime}`;
  };

  const logisticsSection = (
    <LogisticsSection>
      {isOhEvent && meetup.ohSlot && (
        <>
          <b>Office Hours With</b>
          <p>{meetup.ohSlot.ownerNames.join(" and ")}</p>
        </>
      )}
      <b>Date & Time</b>
      <p>{renderMeetupStartDateTime()}</p>
      <b>Address</b>
      <p css={css({ whiteSpace: "pre-wrap" })}>
        {isOhEvent && meetup.ohSlot ? meetup.ohSlot.location : meetup.address}
      </p>
      {meetup.instructions && (
        <>
          <b>Instructions</b>
          <p>
            <MarkdownComponent content={meetup.instructions} />
          </p>
        </>
      )}
      {meetup.confirmed && meetup.qrCodeUrl && (
        <>
          <b>QR Code</b>
          <p>
            Show other founders this QR code if you want to follow up with them. If they scan your
            code, you'll be instantly matched on the platform.
          </p>
          <img src={meetup.qrCodeUrl} alt="QR code" />
        </>
      )}
    </LogisticsSection>
  );

  const hasDeclinedSection = (
    <ActionSection css={css({ backgroundColor: COLORS.GRAY })}>
      <BodyHeader>You've declined your invite.</BodyHeader>
      <h2 css={css({ marginTop: 0 })}>Thanks for letting us know you can't make it.</h2>
      <p>
        <i>
          If you didn't mean to decline, email us at{" "}
          {meetup.cfm ? (
            <EmailSupportLink />
          ) : (
            <a href="mailto:events@ycombinator.com">events@ycombinator.com</a>
          )}{" "}
          ASAP.
        </i>
      </p>
    </ActionSection>
  );

  const confirmedBanner = (
    <ActionSection css={css({ backgroundColor: COLORS.GREEN })}>
      <BodyHeader>Your attendance is confirmed!</BodyHeader>
    </ActionSection>
  );

  const rsvpBanner = (
    <ActionSection css={css({ backgroundColor: COLORS.WARNING })}>
      <BodyHeader>
        Congratulations - you're invited
        {isOhEvent
          ? ` to office hours with ${meetup.ohSlotOptions[0]?.ownerNames.join(" and ")}`
          : ""}
        !
      </BodyHeader>
      {isOhEvent ? (
        <div>
          <p>
            <b>Please select a time slot when you can attend.</b>
          </p>

          <MeetupTimeSlotsContainer>
            {meetup.ohSlotOptions.map((slot) => (
              <MeetupTimeSlot
                key={slot.startsAt}
                selected={!selectedSlotStart || selectedSlotStart === slot.startsAt}
                onClick={() => setSelectedSlotStart(slot.startsAt)}
              >
                {formatTime(slot.startsAt)} - {formatTime(slot.endsAt)}
              </MeetupTimeSlot>
            ))}
          </MeetupTimeSlotsContainer>
        </div>
      ) : (
        <p>
          <b>Please confirm whether you'll be able to attend!</b>
        </p>
      )}
      <p>
        <i>
          Capacity is very limited for this event. Please let us know if you can't make it so that
          we can invite {meetup.anotherAttendeeText} instead.
        </i>
      </p>
      {isOhEvent && <div />}
      <ButtonRow>
        <Button
          onClick={confirmAttendance}
          color="orange"
          disabled={isOhEvent && !selectedSlotStart}
        >
          I'll be there!
        </Button>
        <Button onClick={() => setIsConfirmingDecline(true)} color="gray">
          I can't make it.
        </Button>
      </ButtonRow>
    </ActionSection>
  );

  const inviteExpiredSection = (
    <ActionSection css={css({ backgroundColor: COLORS.DANGER })}>
      <h2>
        Your invitation has expired, so we've freed up your spot for {meetup.anotherAttendeeText}.
      </h2>
      <p>
        <i>If you still want to attend, you can rejoin the waitlist.</i>
      </p>
      <Button onClick={joinWaitlist} color="orange">
        Rejoin Waitlist
      </Button>
    </ActionSection>
  );

  const currentPath = `${window.location.pathname}${window.location.search}`;

  const getSignInPath = (signUp: Boolean) => {
    const baseUrl = signUp ? SIGN_UP_PATH : signInUsersPath();
    const continueUrlParam = signUp
      ? `continue=${window.location.href}`
      : `continue_url=${currentPath}`;
    const signUpPath = signUp ? "&signUpActive=true" : "";
    const cfmParam = meetup.cfm ? "&cofounder-matching=true" : "";
    return `${baseUrl}?${continueUrlParam}${signUpPath}${cfmParam}`;
  };

  const createUserBanner = (
    <SpacedSection css={css({ backgroundColor: COLORS.WARNING })}>
      <b>We'll need to collect a few more details before you can join the waitlist!</b>
      <ButtonRow css={css({ marginTop: 20 })}>
        <a
          href={`${susBaseUrl}${dashboardCofounderMatchingPath()}?continue_url=${currentPath}&continueUrl=${currentPath}`}
        >
          <Button color="orange">Add user details</Button>
        </a>
      </ButtonRow>
    </SpacedSection>
  );

  const meetupRegistrationClosedSection = () => (
    <SpacedSection css={css({ backgroundColor: COLORS.GRAY })}>
      <BodyHeader>
        This event {hasEnded ? "has ended" : "is no longer accepting new registrations"}.
      </BodyHeader>
      <p>
        <i>
          Stay tuned for news about future events by following Y Combinator's{" "}
          <a href={YC_LI_URL} target="_blank">
            LinkedIn
          </a>{" "}
          and{" "}
          <a href={YC_TWITTER_URL} target="_blank">
            Twitter
          </a>{" "}
          accounts, plus by keeping an eye on your inbox!
        </i>
      </p>
    </SpacedSection>
  );

  const meetupHasStartedBanner = () => {
    if (!meetup.confirmed) {
      return;
    }

    if (hasEnded) {
      return (
        <SpacedSection css={css({ backgroundColor: COLORS.GREEN })}>
          <BodyHeader>Thank you for attending!</BodyHeader>
          {meetup.cfm && (
            <>
              <p>
                Meet anyone you'd like to follow up with? We've put together a{" "}
                <a href={`${susBaseUrl}${meetupCofounderMatchingPath(meetup.slug)}`}>list</a> of the
                attendees at the event in case you didn't get a chance to swap contact info.
              </p>
              <p>
                <a href={`${susBaseUrl}${meetupCofounderMatchingPath(meetup.slug)}`}>
                  <Button color="orange">See attendee list</Button>
                </a>
              </p>
            </>
          )}
          <p css={css({ textAlign: "center" })}>
            <i>
              We'd love to hear how it went! If you have feedback on the event, you can send it to{" "}
              <a href="mailto:startupschool@ycombinator.com" target="_blank">
                startupschool@ycombinator.com
              </a>
            </i>
          </p>
        </SpacedSection>
      );
    }

    if (hasStarted) {
      return confirmedBanner;
    }
  };

  const invitedBanner = () => {
    if (meetup.declined) {
      return;
    }
    if (meetup.confirmed) {
      return confirmedBanner;
    }
    if (meetup.invited) {
      return rsvpBanner;
    }

    return null;
  };

  const headerSection = (
    <Header>
      <YCLogo>
        <img src={YC_Y_LOGO_IMG} alt="YC Logo" />
      </YCLogo>
      <WelcomeOrSignIn>
        {signedIn ? (
          <div css={css({ display: "flex", alignItems: "center" })}>
            <div>Welcome{data?.ssoUser?.firstName ? `, ${data?.ssoUser?.firstName}` : ""}!</div>
            <Dot />
            <SignOutLink noRedirect />
          </div>
        ) : (
          <a href={getSignInPath(false)}>
            <Button color="orange">Sign in</Button>
          </a>
        )}
      </WelcomeOrSignIn>
      <HeaderContent>
        <Title>
          <h1>{meetup.title}</h1>
        </Title>
        <DateLine>
          <span>{renderMeetupStartDateTime()}</span>
          <Dot />
          <span>{meetup.publicLocation}</span>
        </DateLine>
      </HeaderContent>
    </Header>
  );

  if (meetup.cancelled) {
    return (
      <Container>
        {headerSection}
        <div css={css({ display: "flex", justifyContent: "center" })}>
          <Subtitle>{meetup.description}</Subtitle>
        </div>
        <CancelledText>This meetup has been cancelled.</CancelledText>
      </Container>
    );
  }

  const aboutSection = (
    <Description>
      <Section>
        <MarkdownComponent content={meetup.description} />
      </Section>
    </Description>
  );

  const renderHostsOrSpeakers = (label: string, hostsOrSpeakers: SpeakerType[]) => (
    <>
      {!!hostsOrSpeakers?.length && (
        <Section css={css({ marginBottom: 30 })}>
          <SectionTitle>{label}:</SectionTitle>
          <div
            css={css({
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              justifyItems: "center",
            })}
          >
            {hostsOrSpeakers.map((speaker) => (
              <a href={speaker.url} target="_blank" key={speaker.name}>
                <div
                  css={css({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  })}
                >
                  <Speaker>
                    <img src={speaker.imgSrc} alt={speaker.name} />
                    <h2>{speaker.name}</h2>
                    <span>{speaker.oneLiner}</span>
                    {!!speaker.description && (
                      <div css={css({ color: "black" })}>{speaker.description}</div>
                    )}
                  </Speaker>
                </div>
              </a>
            ))}
          </div>
        </Section>
      )}
    </>
  );

  const speakerSection = (
    <>
      {renderHostsOrSpeakers(
        "Speakers",
        speakers.filter(({ isHost }) => !isHost)
      )}
      {renderHostsOrSpeakers(
        "Hosts",
        speakers.filter(({ isHost }) => isHost)
      )}
    </>
  );

  return (
    <Container>
      <Dialog
        open={isConfirmingDecline}
        onClose={() => setIsConfirmingDecline(false)}
        scroll="body"
        maxWidth="md"
      >
        <div css={css({ padding: 30, textAlign: "center" })}>
          <h2>Are you sure you can't make it? You won't be able to change your RSVP later.</h2>
          <ButtonRow extraWide>
            <Button color="orange" onClick={declineAttendance}>
              Yes, I decline my invite.
            </Button>
            <Button color="gray" onClick={() => setIsConfirmingDecline(false)}>
              No, keep my spot.
            </Button>
          </ButtonRow>
        </div>
      </Dialog>
      {headerSection}

      <Body>
        {bannerSection()}

        <DesktopOnly>
          {aboutSection}
          {speakerSection}
          {eventLogisticsSection()}
        </DesktopOnly>

        <MobileOnly>
          {aboutSection}
          {eventLogisticsSection()}
          {speakerSection}
        </MobileOnly>
      </Body>
      <ToastContainer position="top-right" />
    </Container>
  );
};
