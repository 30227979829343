import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "../../../components/statelessForms/Button";

export default () => {
  const [hasConfirmed, setHasConfirmed] = useState(false);

  return (
    <Dialog
      open={!hasConfirmed}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Warning: you're in override mode!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Setting an attendee as "going" or "not going" will override their own response.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <a href={window.location.pathname}>
          <Button color="blue">Go back</Button>
        </a>
        <Button color="orange" onClick={() => setHasConfirmed(true)}>
          I understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};
