import React from "react";
import Papa from "papaparse";
import { css } from "@emotion/core";
import {
  MEETUP_RSVPS_admin_adminMeetup,
  MEETUP_RSVPS_admin_meetupRsvps,
} from "../__generated__/MEETUP_RSVPS";
import {
  formatRsvpWithQuestionResponses,
  formatTime,
  getCfmUrl,
  getSusUrl,
  RsvpType,
} from "./meetupUtils";
import MEETUP_COLUMNS, { getQuestionColumnsForEvent } from "./MeetupColumns";

type Props = {
  meetup: MEETUP_RSVPS_admin_adminMeetup | undefined;
  rsvps: MEETUP_RSVPS_admin_meetupRsvps[];
};

export default ({ meetup, rsvps }: Props) => {
  if (!meetup) {
    return null;
  }

  const formatOhSlot = (rsvp: RsvpType) => {
    const { ohSlot } = rsvp;
    if (!ohSlot) {
      return rsvp.ohOwner ? `No slot assigned (invited for OH with ${rsvp.ohOwner.name})` : "";
    }

    const names = [ohSlot.owner.name, ohSlot.owner2?.name].filter((n) => n).join(" and ");
    return `${formatTime(ohSlot.startsAt)} with ${names}`;
  };

  const exportAllAttendees = () => {
    const confirmedAttendees = rsvps.map((rawRsvp) => {
      const rsvp = formatRsvpWithQuestionResponses(rawRsvp);
      const columnValues: { [title: string]: string | undefined | null } = {};
      const allColumns = [...Object.values(MEETUP_COLUMNS), ...getQuestionColumnsForEvent(meetup)];
      allColumns.forEach((column) => {
        const { title } = column;
        const extractorFn = column.renderAsText || column.render;
        let value = `${extractorFn(rsvp as RsvpType)}`;
        if ([null, undefined, "null", "undefined"].includes(value)) {
          value = "";
        }
        columnValues[title] = value || "";
      });

      return {
        "First name": rsvp.ssoUser.firstName,
        "Last name": rsvp.ssoUser.lastName,
        "Internal URL": rsvp.ssoUser.internalUrl,
        "SUS User URL": getSusUrl(rsvp),
        "CFM Profile URL": getCfmUrl(rsvp),
        "User UUID": rsvp.ssoUser.uuid,
        ...(meetup.eventType === "office_hours" ? { "Office Hours Slot": formatOhSlot(rsvp) } : {}),
        ...columnValues,
        ...(meetup.eventType === "cfm" ? { "QR Code URL": rsvp.qrCodeUrl } : {}),
        "Checked in?": rsvp.checkedIn ? "Yes" : "No",
        "No show?": rsvp.noShow ? "Yes" : "No",
      };
    });
    const csvData = Papa.unparse(confirmedAttendees);
    const csv = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    return window.URL.createObjectURL(csv);
  };

  const csvTitle = `${meetup.title.replaceAll(" ", "_")}_Attendees`;

  return (
    <div css={css({ textAlign: "center", marginBottom: 10 })}>
      <a href={exportAllAttendees()} download={csvTitle}>
        Export as CSV
      </a>
    </div>
  );
};
