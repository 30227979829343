import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { omit } from "lodash";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { RadioGroup, Submit, useForm } from "../../components/forms";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import {
  cofounderMatchingPath,
  curriculumPath,
  submitRedirectTrackPath,
} from "../../__generated__/routes";
import {
  CHOOSE_TRACK_PAGE,
  CHOOSE_TRACK_PAGE_currentUser,
} from "./__generated__/CHOOSE_TRACK_PAGE";
import { UPDATE_USER_TRACK, UPDATE_USER_TRACKVariables } from "./__generated__/UPDATE_USER_TRACK";

const Feature: React.FC<{ title: string; link?: string; description: string }> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <strong
        css={css({ cursor: "pointer", display: "flex", alignItems: "center" })}
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        {props.title}
      </strong>
      {open && (
        <div css={css({ marginLeft: 23, marginBottom: 14 })}>
          {props.description}{" "}
          {props.link && (
            <a href={props.link} target="_blank">
              See more.
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default () => {
  const { data } = useQuery<CHOOSE_TRACK_PAGE>(gql`
    query CHOOSE_TRACK_PAGE {
      currentUser {
        slug
        track
      }
      courseModules {
        name
      }
    }
  `);

  const [updateUser] = useMutation<UPDATE_USER_TRACK, UPDATE_USER_TRACKVariables>(gql`
    mutation UPDATE_USER_TRACK($input: UpdateUserInput!) {
      updateUser(input: $input) {
        slug
        track
        errors {
          field
          error
        }
      }
    }
  `);

  const onSubmit = async (fields: CHOOSE_TRACK_PAGE_currentUser) => {
    const resp = await updateUser({
      variables: {
        input: omit(fields, ["__typename", "errors", "persisted", "slug"]),
      },
    });
    return resp.data?.updateUser;
  };

  // @ts-ignore
  const { ConnectedForm, connectedFormProps } = useForm(data?.currentUser, onSubmit, {
    fallbackContinueUrl: submitRedirectTrackPath(),
  });

  return (
    <Page title="Have a Startup?">
      <Container css={css({ maxWidth: 900 })}>
        <ConnectedForm {...connectedFormProps}>
          <Pane css={css({ li: { marginBottom: 7 } })}>
            <PaneItem>
              <p>All Startup School participants get access to the following programs:</p>
              <div css={css({ marginBottom: "1em" })}>
                <Feature
                  title="Startup School curriculum"
                  link={curriculumPath()}
                  description="The Startup School curriculum is the best resource on the internet for learning how to build an early-stage startup."
                />
                <Feature
                  title="Co-founder matching"
                  link={cofounderMatchingPath()}
                  description="Looking for a co-founder? Connect with potential matches based on your
                  preferences for interests, skills, location, and more."
                />
              </div>

              <p>
                For founders actively working on a startup, we offer additional features to make you
                more productive:
              </p>
              <div css={css({ marginBottom: "1em" })}>
                <Feature
                  title="Company updates"
                  description="Submit weekly company updates using our
                  structured form to help you stay focused on the right metrics."
                />
              </div>
            </PaneItem>
            <PaneItem>
              <RadioGroup
                required
                fieldName="track"
                label="Are you actively working on a startup?"
                subLabel='"Actively" means 10+ hours a week (and 20+ is better). You can come back and change this at any time.'
                options={[
                  ["active_founder", "Yes"],
                  ["aspiring_founder", "No"],
                ]}
              />
            </PaneItem>
          </Pane>
          <Submit />
        </ConnectedForm>
      </Container>
    </Page>
  );
};
