import { css } from "@emotion/core";
import React from "react";
import { colors } from "../../styles";
import Checkbox from "./Checkbox";

export const cappedFraction = (num: number | undefined, denom: number | undefined): string =>
  `${Math.min(num || 0, denom || 0)}/${denom || 0}`;

const Requirement: React.FC<{
  done: boolean | undefined;
  title: string;
  link: string;
  id?: string;
  futureRequirement?: boolean;
}> = (props) => (
  <a
    href={props.link}
    css={css({
      display: "flex",
      alignItems: "center",
      color: props.futureRequirement ? colors.lightText : "inherit",
      padding: "14px 28px",
      ":hover": {
        color: props.futureRequirement ? colors.lightText : "inherit",
        backgroundColor: colors.clickablePaneHover,
      },
    })}
    id={props.id}
  >
    <Checkbox checked={props.done || false} css={css({ marginRight: 10 })} />
    <div
      css={css({
        fontWeight: "bold",
        textDecoration: props.done ? "line-through" : "initial",
        flex: 1,
        marginRight: 10,
      })}
    >
      {props.title}
    </div>
  </a>
);
export default Requirement;
