import { css } from "@emotion/core";
import { MarkdownComponent } from "@yc/shared/renderMarkdown";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Button from "../../components/Button";
import { Pane, PaneItem } from "../../components/Pane";
import { colors } from "../../styles";
import useCompleteAction from "./useCompleteAction";
import { DASHBOARD_DATA, DASHBOARD_DATA_dashboard_todos } from "./__generated__/DASHBOARD_DATA";

const Todo = ({ todo }: { todo: DASHBOARD_DATA_dashboard_todos }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);

  const completeAction = useCompleteAction();

  if (dismissed) return null;

  const onClick =
    !todo.actionUrl && todo.actionComplete
      ? (e: React.MouseEvent<HTMLAnchorElement>) => {
          if (todo.actionUrl || !todo.actionComplete) return;
          e.preventDefault();
          setDismissed(true);
          completeAction(todo.actionComplete);
        }
      : undefined;

  return (
    <PaneItem
      css={css({
        display: "flex",
        cursor: "pointer",
        ":hover": { backgroundColor: colors.clickablePaneHover },
      })}
      onClick={() => setOpen(!open)}
    >
      <div css={css({ flex: 1 })}>
        <h2 css={css({ marginBottom: 16 })}>{todo.title}</h2>
        {open && (
          <>
            <MarkdownComponent
              css={css({ color: colors.lightText, marginBottom: 16 })}
              content={todo.description}
            />

            <div css={css({ display: "flex" })}>
              <Button
                type="cta"
                href={todo.actionUrl ?? undefined}
                onClick={onClick}
                text={todo.actionText}
              />
            </div>
          </>
        )}
      </div>
      <div css={css({ color: "#c7c7c7", fontSize: 20 })}>
        {open ? (
          <KeyboardArrowUpIcon fontSize="large" />
        ) : (
          <KeyboardArrowDownIcon fontSize="large" />
        )}
      </div>
    </PaneItem>
  );
};

const Actions: React.FC<{ data: DASHBOARD_DATA }> = ({ data }) => {
  if (data.dashboard.todos.length === 0) return null;

  return (
    <Pane id="other_todos_pane" title="✓ Other To-Dos">
      {data.dashboard.todos.map((todo) => (
        <Todo key={todo.title + todo.actionUrl} todo={todo} />
      ))}
    </Pane>
  );
};

export default Actions;
