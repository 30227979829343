import React, { useState, useCallback } from "react";
import { css } from "@emotion/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import authorizedFetch from "@yc/shared/authorizedFetch";
import { TextField, withStyles } from "@material-ui/core";
import styled from "@emotion/styled";
import SearchIcon from "@material-ui/icons/Search";

const ResultCard = styled.a`
  display: flex;
  flex-direction row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(34,36,38,.1);
  }
  h1 {
    font-size: 1em;
    font-weight: 700;
  }
  span {
    color: rgba(0,0,0,.4);
    font-size: 0.9em;
  }
  img {
    height: 48px;
    width: 48px;
  }
`;

const StyledAutocomplete = withStyles({
  popupIndicatorOpen: {
    transform: "rotate(0)",
  },
  listbox: {
    maxHeight: "fit-content",
  },
  paper: {
    width: 500,
    marginLeft: -250,
  },
  option: {
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    },
  },
})(Autocomplete);

type OptionType = {
  resultCategory: string;
  title: string;
  description: string;
  href: string;
  image?: string;
};

export default () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(undefined);

  const runSearch = useCallback<(query: string) => void>(
    debounce(async (query) => {
      const resp = await authorizedFetch(`/admin/search/?query=${encodeURIComponent(query)}`).then(
        (r) => r.json()
      );
      setResults(resp);
      setLoading(false);
    }, 300),
    []
  );

  const queueSearch = useCallback(
    (e, { value }) => {
      setLoading(true);
      runSearch(e.target.value);
    },
    [runSearch]
  );

  const formattedOptions: any[] = [];
  if (results) {
    Object.values((results || {}) as object).forEach((categoryObject) => {
      const { name: resultCategory, results: optionList } = categoryObject;
      (optionList || []).forEach((option: object) => {
        formattedOptions.push({ ...option, resultCategory });
      });
    });
  }

  const renderGroup = (optionGroup: any) => (
    <div key={optionGroup.group}>
      <div
        css={css({
          fontWeight: "bold",
          padding: 10,
          textAlign: "center",
          textTransform: "uppercase",
          backgroundColor: "#f3f4f5",
        })}
      >
        {optionGroup.group}
      </div>
      {optionGroup.children}
    </div>
  );

  const renderOption = (option: OptionType) => (
    <ResultCard href={option.href} key={option.href}>
      <div>
        <h1>{option.title}</h1>
        <span>{option.description}</span>
      </div>

      {option.image && <img src={option.image} alt="Avatar" />}
    </ResultCard>
  );

  return (
    <div css={css({ float: "right", width: 250 })}>
      <StyledAutocomplete
        disableClearable
        size="small"
        loading={loading}
        options={formattedOptions}
        filterOptions={(options) => options}
        getOptionLabel={(option: OptionType) => option.title}
        groupBy={(option: OptionType) => option.resultCategory}
        renderOption={renderOption}
        renderGroup={renderGroup}
        onInputChange={queueSearch}
        renderInput={(params) => <TextField {...params} label="Search..." variant="outlined" />}
        popupIcon={<SearchIcon />}
      />
    </div>
  );
};
