import React, { useContext } from "react";
import { css } from "@emotion/core";
import { MenuContext, measurements, animateMenu } from "./shared";
import { mobileStyles, fontSize } from "../../styles";

const MenuContainer: React.FC = ({ children }) => {
  const open = useContext(MenuContext);
  return (
    <div
      css={css({
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 3,
        width: measurements.menuOpen,
        ...mobileStyles({
          width: open ? measurements.menuOpen : 0,
          borderWidth: open ? 1 : 0,
        }),
        maxWidth: "100%",
        "*:focus": { outline: "none" },
        borderRight: "1px solid #e0e0e0",
        backgroundColor: "white",
        boxShadow: open ? "0px 0px 14px 0px rgba(0, 0, 0, 0.1)" : "none",
        fontSize: fontSize.small,
        ...animateMenu,
      })}
    >
      <div
        css={css({
          height: "100%",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          "::-webkit-scrollbar": {
            width: 0,
            background: "transparent",
          },
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default MenuContainer;
