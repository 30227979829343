import { useMutation, gql } from "@apollo/client";
import {
  ADMIN_REJECT_MEETUP_RSVP,
  ADMIN_REJECT_MEETUP_RSVPVariables,
} from "../__generated__/ADMIN_REJECT_MEETUP_RSVP";

export default function useRejectRsvps() {
  const [rejectRsvps] = useMutation<
    ADMIN_REJECT_MEETUP_RSVP,
    ADMIN_REJECT_MEETUP_RSVPVariables
  >(gql`
    mutation ADMIN_REJECT_MEETUP_RSVP($ids: [ID!]!, $rejected: Boolean!) {
      adminRejectMeetupRsvp(ids: $ids, rejected: $rejected) {
        id
        status
      }
    }
  `);

  return (variables: ADMIN_REJECT_MEETUP_RSVPVariables) => rejectRsvps({ variables });
}
