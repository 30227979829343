import { gql, useQuery } from "@apollo/client";
import React from "react";
import EmailPushForm, { parseEmailPush } from "./EmailPushForm";
import { EDIT_EMAIL_PUSH } from "./__generated__/EDIT_EMAIL_PUSH";

type Props = {
  campaignId: string;
  pushId: string;
};

export default ({ campaignId, pushId }: Props) => {
  const { data } = useQuery<EDIT_EMAIL_PUSH>(
    gql`
      query EDIT_EMAIL_PUSH($id: ID!, $pushId: ID!) {
        admin {
          emailCampaign(id: $id) {
            id
            name
            slug
            fromEmailOptions
            replyToEmailOptions
            meetupId
            meetupTitle
            meetupStartsAt
            templateValues(pushId: $pushId)
            emailPushes {
              id
              name
              slug
              lastSentAt
            }
            emailPush(id: $pushId) {
              id
              campaignId
              name
              slug
              subject
              emailBody
              fromEmail
              replyToEmail
              filters
              disabled
              lifecycleOnly
              autoLoginLinks
              sendsAt
              externalEmailRecipients {
                id
                firstName
                email
              }
            }
          }
        }
      }
    `,
    { variables: { id: campaignId, pushId } }
  );

  if (!data?.admin.emailCampaign.emailPush) {
    return <div />;
  }

  const { emailCampaign } = data.admin;

  return (
    <EmailPushForm
      title={`Edit email push for campaign ${emailCampaign.name}`}
      emailPush={parseEmailPush(emailCampaign.emailPush)}
      campaignId={campaignId}
      emailCampaign={emailCampaign}
    />
  );
};
