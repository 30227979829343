import React from "react";

import ReactChartkick, { LineChart } from "react-chartkick";
import Chart from "chart.js";

ReactChartkick.addAdapter(Chart);

type Data = {
  date: string;
  entries: { series: string; val: number | null }[] | false;
};

type Props = {
  data: Data[];
};

export default function EngagementChart({ data }: Props) {
  const allSeries: { [series: string]: { [date: string]: number | null } } = {};
  if (data[0].entries)
    data[0].entries.forEach(({ series }) => {
      allSeries[series] = {};
    });

  data.forEach(({ date, entries }) => {
    if (entries) {
      entries.forEach(({ series, val }) => {
        if (val && val > 0) allSeries[series][date] = val;
      });
    } else {
      Object.values(allSeries).forEach((v) => {
        v[date] = null;
      });
    }
  });

  return (
    <LineChart
      data={Object.entries(allSeries).map(([name, seriesData]) => ({ name, data: seriesData }))}
    />
  );
}
