import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import {
  MEETUP_RSVPS_admin_meetupRsvps_ycAppHistory,
  MEETUP_RSVPS_admin_meetupRsvps_ycAppHistory_comments,
} from "../__generated__/MEETUP_RSVPS";
import {
  MEETUP_NAMETAGS_admin_meetupRsvps_ycAppHistory,
  MEETUP_NAMETAGS_admin_meetupRsvps_ycAppHistory_comments,
} from "../__generated__/MEETUP_NAMETAGS";
import { Pane, PaneItem } from "../../../components/Pane";

type Props = {
  app: MEETUP_RSVPS_admin_meetupRsvps_ycAppHistory | MEETUP_NAMETAGS_admin_meetupRsvps_ycAppHistory;
};

type AppContainerProps = { hasComments: boolean };
const AppContainer = styled.p<AppContainerProps>`
  ${({ hasComments }) => {
    if (hasComments) {
      return css`
        &:hover {
          font-weight: bold;
          article {
            visibility: visible;
          }
        }
      `;
    }
  }}
`;

const TooltipContainer = styled.article`
  width: 500px;
  left: -505px;
  position: absolute;
  z-index: 2;
  transform: translate(0, -50%);
  visibility: hidden;
  font-weight: normal;
`;

const Comment = styled.div`
  margin-bottom: 5px;
`;

const CommentSectionHeader = styled.h2`
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 15px;
`;

const Rating = styled.strong`
  padding: 3px 5px;
  border-radius: 4px;
  display: inline-block;
  min-width: 16px;
  text-align: center;
`;

const VoterName = styled.strong`
  margin: 0 3px;
`;

const RATING_STYLES = {
  green: { color: "white", backgroundColor: "#54BC4E" },
  yellow: { color: "#222", backgroundColor: "#f6d122" },
  gray: { color: "#555", backgroundColor: "#ecedde" },
  red: { color: "white", backgroundColor: "#EC4F1B" },
};

export default ({ app }: Props) => {
  const hasComments = "comments" in app && !!app.comments?.length;

  const ratingCss = (voteType: string, rating: string) => {
    if (voteType === "vote" && rating === "n") {
      return RATING_STYLES.gray;
    }

    if (voteType === "interview_note") {
      if (rating.startsWith("YES")) {
        return RATING_STYLES.green;
      }
      if (rating.startsWith("yes")) {
        return RATING_STYLES.yellow;
      }
      if (rating.startsWith("no")) {
        return RATING_STYLES.gray;
      }
      if (rating.startsWith("NO")) {
        return RATING_STYLES.red;
      }
    }

    switch (rating) {
      case "inf":
      case "Y":
      case "M":
        return RATING_STYLES.green;
      case "y":
      case "m":
        return RATING_STYLES.yellow;
      case "n":
      case "N":
      case "NN":
        return RATING_STYLES.red;
      default:
        return { border: "1px solid #e0e0e0" };
    }
  };

  const interviewComments = app.comments?.filter((c) => c.voteType === "interview_note");
  const voteComments = app.comments?.filter((c) => c.voteType === "2");
  const saveSquadComments = app.comments?.filter((c) => c.voteType === "6");
  const reviewComments = app.comments?.filter((c) => c.voteType === "1");
  // comment, rescue_review
  const otherComments = app.comments?.filter((c) => ["0", "4"].includes(c.voteType));

  const renderComments = (
    header: string,
    comments:
      | MEETUP_RSVPS_admin_meetupRsvps_ycAppHistory_comments[]
      | MEETUP_NAMETAGS_admin_meetupRsvps_ycAppHistory_comments[]
      | undefined
  ) => {
    if (!comments?.length) {
      return null;
    }

    return (
      <>
        <CommentSectionHeader>{header}</CommentSectionHeader>
        {comments.map((comment) => (
          <Comment>
            {!!comment.rating && (
              <Rating css={css(ratingCss(comment.voteType, comment.rating))}>
                {comment.rating}
              </Rating>
            )}
            <VoterName>{comment.voterName}</VoterName>
            {comment.notes || ""}
          </Comment>
        ))}
      </>
    );
  };

  return (
    <AppContainer key={app.batch} hasComments={hasComments}>
      <span css={css({ textDecoration: hasComments ? "underline" : "none" })}>{app.batch}</span>{" "}
      <a href={app.url} target="_blank">
        {app.result}
      </a>
      {hasComments && (
        <TooltipContainer>
          <Pane>
            <PaneItem>
              <h2>
                {app.batch}: {app.result}
              </h2>
              {renderComments("Interview notes", interviewComments)}
              {renderComments("Partner votes", voteComments)}
              {renderComments("Save squad", saveSquadComments)}
              {renderComments("First review", reviewComments)}
              {renderComments("Other comments", otherComments)}
            </PaneItem>
          </Pane>
        </TooltipContainer>
      )}
    </AppContainer>
  );
};
