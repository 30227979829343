import { useMutation, gql } from "@apollo/client";
import {
  UPDATE_MEETUP_QUESTION,
  UPDATE_MEETUP_QUESTIONVariables,
} from "./__generated__/UPDATE_MEETUP_QUESTION";

export default function useUpdateQuestion() {
  const [updateQuestion] = useMutation<UPDATE_MEETUP_QUESTION, UPDATE_MEETUP_QUESTIONVariables>(gql`
    mutation UPDATE_MEETUP_QUESTION($input: UpdateMeetupQuestionInput!) {
      updateMeetupQuestion(input: $input) {
        id
        questionText
        questionSubtitle
        questionType
        required
        otherRequirements
        sortOrder
      }
    }
  `);

  return (variables: UPDATE_MEETUP_QUESTIONVariables) => updateQuestion({ variables });
}
