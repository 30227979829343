import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../styles";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import Tooltip from "../../components/statelessForms/Tooltip";
import Button from "../../components/Button";
import Icon from "../../components/icon";
import Checkbox from "./Checkbox";
import {
  DASHBOARD_DATA,
  DASHBOARD_DATA_dashboard_updatesByWeek,
} from "./__generated__/DASHBOARD_DATA";

const PWMeasurements = {
  size: 15,
  padding: 3,
};

const PreviousWeek: React.FC<{
  week: DASHBOARD_DATA_dashboard_updatesByWeek;
  popupDisabled?: boolean;
}> = ({ week, popupDisabled = false }) => (
  <Tooltip
    title={
      popupDisabled ? (
        ""
      ) : (
        <div css={css({ textAlign: "center" })}>
          <strong>{week.weekLabel}</strong>
          <br />
          {week.url === null && <em>No Update</em>}
        </div>
      )
    }
  >
    <a
      href={week.url || undefined}
      css={css({
        display: "block",
        padding: PWMeasurements.padding,
        textDecoration: "none",
        ":hover": { textDecoration: "none" },
      })}
      target="_blank"
    >
      <Checkbox size={PWMeasurements.size} checked={week.url !== null} />
    </a>
  </Tooltip>
);

const PreviousWeeks = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  maxWidth: (PWMeasurements.size + PWMeasurements.padding * 2) * 26 + PWMeasurements.padding * 2,
  margin: "0 auto",
});

const UpdatesModule: React.FC<{ data: DASHBOARD_DATA }> = ({ data }) => {
  const thisWeek = data.dashboard.updatesByWeek[0];
  const thisWeekSubmitted = thisWeek?.url != null;
  const anyUpdates = data.dashboard.updatesByWeek.some((wk) => wk.url);

  return (
    <Pane>
      <PaneHeader>
        <h3>
          <Icon icon="updates" /> Weekly Updates
        </h3>
        {(data.dashboard.currentStreak || 0) > 0 && (
          <div css={css({ color: colors.lightText, paddingLeft: 14 })}>
            You're on a {data.dashboard.currentStreak}-week streak! 🔥
          </div>
        )}
        <div css={css({ flex: 1 })} />
        <a href="/updates" target="_blank">
          See All
        </a>
      </PaneHeader>
      {anyUpdates && (
        <PaneItem css={css({ padding: "20px" })}>
          <PreviousWeeks>
            {data.dashboard.updatesByWeek
              .slice()
              .reverse()
              .map((wk) => (
                <PreviousWeek key={wk.weekLabel} week={wk} />
              ))}
          </PreviousWeeks>
        </PaneItem>
      )}
      <PaneItem css={css({ display: "flex" })}>
        <Checkbox size={20} checked={thisWeekSubmitted} />
        <div css={css({ flex: 1, marginLeft: 14 })}>
          {thisWeekSubmitted ? (
            <>
              <h2 css={css({ marginBottom: 16 })}>This week's update is in. Nice job!</h2>
              <a href={thisWeek?.url || undefined}>Edit Update</a>
            </>
          ) : (
            <>
              <h2 css={css({ marginBottom: 16 })}>You haven't submitted this week's update yet.</h2>
              <div css={css({ color: colors.lightText, marginBottom: 16 })}>
                Updates help you concentrate on what's important and keep your company on track.
              </div>
              <div css={css({ marginLeft: -34 })}>
                <div css={css({ margin: "0 auto", height: "fit-content", width: "fit-content" })}>
                  <Button href="/updates/new" type="cta" text="Submit Update" />
                </div>
              </div>
            </>
          )}
        </div>
      </PaneItem>
    </Pane>
  );
};

export default UpdatesModule;
