import { useMutation, gql } from "@apollo/client";
import {
  ADMIN_ACCEPT_MEETUP_RSVP,
  ADMIN_ACCEPT_MEETUP_RSVPVariables,
} from "./__generated__/ADMIN_ACCEPT_MEETUP_RSVP";

export default function useAcceptRsvps() {
  const [acceptRsvps] = useMutation<
    ADMIN_ACCEPT_MEETUP_RSVP,
    ADMIN_ACCEPT_MEETUP_RSVPVariables
  >(gql`
    mutation ADMIN_ACCEPT_MEETUP_RSVP($ids: [ID!]!, $accepted: Boolean!, $ohOwnerId: ID) {
      adminAcceptMeetupRsvp(ids: $ids, accepted: $accepted, ohOwnerId: $ohOwnerId) {
        id
        accepted
        status
        ohOwner {
          id
          name
        }
      }
    }
  `);

  return (variables: ADMIN_ACCEPT_MEETUP_RSVPVariables) => acceptRsvps({ variables });
}
