import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import Icon from "../icon";
import { measurements, MenuContext, TabType } from "./shared";
import MenuContainer from "./MenuContainer";
import MenuTab from "./MenuTab";

type Props = {
  tabs: TabType[];
  cfm: boolean;
};

let setNavOpenFunction: React.Dispatch<React.SetStateAction<boolean>> | null = null;

export default function Navigation(props: Props) {
  const [navOpen, setNavOpen] = useState<boolean>(false);

  useEffect(() => {
    setNavOpenFunction = setNavOpen;

    return function cleanup() {
      setNavOpenFunction = null;
    };
  }, [setNavOpen]);

  return (
    <MenuContext.Provider value={navOpen}>
      <div onClick={() => setNavOpen(true)} css={css({ width: measurements.menuOpen })}>
        <Icon aria-label="Open Navigation" icon="menu" />
      </div>
      <MenuContainer>
        <MenuTab
          tab={{
            name: props.cfm ? "Co-Founder Matching" : "Startup School",
            path: "/",
            icon: <Icon icon="ycY" keepColor />,
            active: true,
          }}
        />

        {props.tabs.map((tab) => (
          <MenuTab key={tab.name + tab.path} tab={tab} />
        ))}
      </MenuContainer>
      {navOpen && (
        <div
          css={css({
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.1)",
            zIndex: 2,
          })}
          onClick={() => setNavOpen(false)}
        />
      )}
    </MenuContext.Provider>
  );
}

export const setNavOpen = (open: boolean) => {
  if (setNavOpenFunction !== null) setNavOpenFunction(open);
};
