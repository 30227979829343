import * as Sentry from "@sentry/react";

if (window.RAILS_ENV !== "development") {
  Sentry.init({
    dsn: "https://608d840eb9408d7a10e1fa2f40465cf3@o4506690008121344.ingest.us.sentry.io/4507018163126273",
    release: import.meta.env.VITE_REVISION,
    environment: window.RAILS_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.05,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/www\.startupschool\.com$/],
    // Session Replay
    replaysSessionSampleRate: 0.0, // Don't use Sentry for session replay (we use posthog for that currently)
    replaysOnErrorSampleRate: 0.0,
  });
}
