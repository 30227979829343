import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { getSlugFromWindowLocation } from "../../../components/forms/util";
import { MEETUP_YC_APP_STATS } from "./__generated__/MEETUP_YC_APP_STATS";
import LoadingDots from "../../../components/statelessForms/LoadingDots";
import {
  MEETUP_YC_APP_MENTIONS,
  MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions,
} from "./__generated__/MEETUP_YC_APP_MENTIONS";
import { AppsStat, IncrementalNote, INITIAL_APPS_STAT, updateStat } from "./meetupStatUtils";
import AppStatTable from "./AppStatTable";
import AcceptedYCApplicantTable from "./AcceptedYCApplicantTable";

type BatchStat = { [batch: string]: AppsStat };

export default () => {
  const id = getSlugFromWindowLocation();

  const { data, loading } = useQuery<MEETUP_YC_APP_STATS>(
    gql`
      query MEETUP_YC_APP_STATS($id: ID!) {
        admin {
          meetupRsvps(id: $id) {
            id
            status
          }
          meetupYcApps(id: $id) {
            internalAppId
            appUrl
            batch
            result
            name
            uuid
          }
        }
      }
    `,
    { variables: { id } }
  );

  const { data: mentionData, refetch } = useQuery<MEETUP_YC_APP_MENTIONS>(
    gql`
      query MEETUP_YC_APP_MENTIONS($id: ID!) {
        admin {
          meetupYcAppMentions(id: $id) {
            id
            internalAppId
            mentioned
          }
        }
      }
    `,
    { variables: { id } }
  );

  const [userCountsByBatch, companyCountsByBatch, mentionsByAppId] = useMemo(() => {
    const countsByUser: BatchStat = { all: { ...INITIAL_APPS_STAT } };
    const countsByCompany: BatchStat = { all: { ...INITIAL_APPS_STAT } };
    const totalRsvps = data?.admin.meetupRsvps.filter(
      ({ status }) => status === "confirmed"
    ).length;
    const meetupYcApps = data?.admin.meetupYcApps;
    const mentionsById: { [appId: number]: MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions } = {};
    if (!totalRsvps || !meetupYcApps || !mentionData) {
      return [countsByUser, countsByCompany, mentionsById];
    }

    mentionData?.admin.meetupYcAppMentions.forEach((mention) => {
      mentionsById[mention.internalAppId] = mention;
    });

    const appsSeen = new Set<string>([]);
    meetupYcApps.forEach(({ batch, result, appUrl, internalAppId }) => {
      if (!countsByUser[batch]) {
        countsByUser[batch] = { ...INITIAL_APPS_STAT };
      }
      if (!countsByCompany[batch]) {
        countsByCompany[batch] = { ...INITIAL_APPS_STAT };
      }

      const mentioned = !!mentionsById[internalAppId]?.mentioned;
      updateStat(countsByUser.all, result, totalRsvps, mentioned);
      updateStat(countsByUser[batch], result, totalRsvps, mentioned);

      if (!appsSeen.has(appUrl)) {
        updateStat(countsByCompany.all, result, totalRsvps, mentioned);
        updateStat(countsByCompany[batch], result, totalRsvps, mentioned);
      }

      appsSeen.add(appUrl);
    });

    return [countsByUser, countsByCompany, mentionsById];
  }, [data, mentionData]);

  return (
    <div>
      {loading ? (
        <div css={css({ display: "flex", justifyContent: "center", marginTop: 50, width: "100%" })}>
          <LoadingDots />
        </div>
      ) : (
        <>
          <AppStatTable
            title="Counts by attendee"
            countMap={userCountsByBatch}
            rowTitle="Batch"
            defaultSort="batch"
          />
          <AppStatTable
            title="Counts by company"
            countMap={companyCountsByBatch}
            rowTitle="Batch"
            defaultSort="batch"
          />
          <IncrementalNote>
            *incremental means the company was accepted <i>and</i> they mentioned this event in
            their app.
          </IncrementalNote>

          <AcceptedYCApplicantTable
            meetupId={id!}
            apps={data?.admin.meetupYcApps || []}
            mentionsByAppId={mentionsByAppId}
            refetch={() => refetch()}
          />
        </>
      )}
    </div>
  );
};
