import React from "react";
import { css } from "@emotion/core";
import { addWeeks, subWeeks } from "date-fns/esm";
import { isBefore, isToday, isTomorrow } from "date-fns";
import styled from "@emotion/styled";
import Icon from "../../components/icon";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { DASHBOARD_DATA } from "./__generated__/DASHBOARD_DATA";
import Button from "../../components/statelessForms/Button";
import { YC_ORANGE } from "../../components/forms/util";

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  a {
    width: 150px;
  }
`;

const ABOUT_URL = "https://www.ycombinator.com/about/";
const APPLY_URL = "https://apply.ycombinator.com/";
const WHY_YC_URL = "https://www.ycombinator.com/why/";

const YcAppIntention: React.FC<{ data: DASHBOARD_DATA }> = ({ data }) => {
  const { ycAppIntention } = data;

  const appDeadline = new Date(ycAppIntention.appsBatchDeadline);
  const now = new Date();

  if (now < subWeeks(appDeadline, 5) || now > addWeeks(appDeadline, 4)) {
    // If it's too late to apply or too early to apply, don't nag you to apply.
    return null;
  }

  let formattedAppDeadline = appDeadline.toLocaleDateString(undefined, {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  if (isToday(appDeadline)) {
    formattedAppDeadline = `today (${formattedAppDeadline})`;
  } else if (isTomorrow(appDeadline)) {
    formattedAppDeadline = `tomorrow (${formattedAppDeadline})`;
  }

  const deadlineHasPassed = isBefore(appDeadline, now);

  const header = (
    <PaneHeader>
      <Icon
        icon="ycY"
        css={css({ height: "1.4em", width: "1.4em", marginRight: 10, color: YC_ORANGE })}
      />
      <h3 css={css({ flex: 1 })}>YC Application</h3>
      <a href={APPLY_URL} target="_blank">
        Apply
      </a>
    </PaneHeader>
  );

  if (ycAppIntention.appliedThisBatch) {
    return (
      <Pane>
        {header}
        <PaneItem>
          <p>🎉 Your application for the upcoming batch has been submitted! 🎉</p>
          <p>{`If you applied before the deadline, you should hear back by ${ycAppIntention.invitesSentBy} at the latest.`}</p>
        </PaneItem>
      </Pane>
    );
  }

  return (
    <Pane>
      {header}
      <PaneItem>
        <p>
          We're now accepting applications for the{" "}
          <a href={ABOUT_URL} target="_blank">
            YC batch program
          </a>
          !
        </p>
        <p>
          We would love to have you apply! Over 50% of the founders in each batch are Startup School
          alumni.
        </p>
        {deadlineHasPassed ? (
          <p>
            You can still apply, even though the deadline has passed. We read and seriously consider
            every application, including late ones.
          </p>
        ) : (
          <p>
            The deadline to apply is <b>{formattedAppDeadline}</b>.
          </p>
        )}
        <ButtonRow>
          {/* @ts-ignore */}
          <Button color="yellow" href={WHY_YC_URL} target="_blank">
            Why do YC?
          </Button>
          {/* @ts-ignore */}
          <Button color="orange" href={APPLY_URL} target="_blank">
            Apply!
          </Button>
        </ButtonRow>
      </PaneItem>
    </Pane>
  );
};

export default YcAppIntention;
