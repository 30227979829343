import React, { useEffect, useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useFormContext } from "react-hook-form";
import { Radio } from "@material-ui/core";
import { EDIT_EMAIL_PUSH_admin_emailCampaign_emailPush } from "./__generated__/EDIT_EMAIL_PUSH";
import LifecycleEmailNotice from "./LifecycleEmailNotice";
import ExpandableSection from "../../../components/navigation/ExpandableSection";
import { Pane, PaneItem } from "../../../components/Pane";
import { NEW_EMAIL_PUSH_admin_emailCampaign } from "./__generated__/NEW_EMAIL_PUSH";

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  div.field {
    flex-grow: 1;
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const Row = styled(FilterGroup)`
  flex-direction: row;
`;

const RadioItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const INITIAL_FILTER_DATA = {
  ycStaffOnly: undefined,
  omitYcStaff: undefined,
  omitMeetupWaitlistUsers: undefined,
  onlyConfirmedAttendees: undefined,
  onlyPendingInvites: undefined,
  excludeYcAlumni: undefined,
  onlyYcAlumni: undefined,
};

type FilterField = {
  fieldName: string;
  label: string;
};

export const parseFiltersFromEmailPush = (push: EDIT_EMAIL_PUSH_admin_emailCampaign_emailPush) => {
  const fields: any = {};
  const filters = JSON.parse(push.filters);

  Object.entries(ALL_FILTERS).forEach(([filter, field]) => {
    if (filter in filters && filters[filter]) {
      fields[field.fieldName] = true;
    }
  });

  return fields;
};

export const ALL_FILTERS: { [key: string]: FilterField } = {
  OMIT_MEETUP_WAITLIST_USERS: {
    fieldName: "omitMeetupWaitlistUsers",
    label: "Don't email users on the waitlist for the event",
  },
  ONLY_CONFIRMED_ATTENDEES: {
    fieldName: "onlyConfirmedAttendees",
    label: "Only email confirmed attendees of the event",
  },
  ONLY_PENDING_INVITES: {
    fieldName: "onlyPendingInvites",
    label: "Only email people with pending invitations to the event",
  },
  YC_STAFF_ONLY: {
    fieldName: "ycStaffOnly",
    label: "Only email YC staff",
  },
  OMIT_YC_STAFF: {
    fieldName: "omitYcStaff",
    label: "Don't email any YC staff",
  },
  ONLY_YC_ALUMNI: {
    fieldName: "onlyYcAlumni",
    label: "Only email YC alumni",
  },
  EXCLUDE_YC_ALUMNI: {
    fieldName: "excludeYcAlumni",
    label: "Don't email any YC alumni",
  },
};

const ALL_FILTER_FIELDS = Object.values(ALL_FILTERS).map(({ fieldName }) => fieldName);

export const formatFilters = (fields: any) => {
  const result = {};

  Object.entries(ALL_FILTERS).forEach(([filter, field]) => {
    if (fields[field.fieldName]) {
      Object.assign(result, { [filter]: true });
    }
  });

  return JSON.stringify(result);
};

type Props = {
  emailPush?: any;
  emailCampaign: NEW_EMAIL_PUSH_admin_emailCampaign;
};

export default ({ emailPush, emailCampaign }: Props) => {
  const formMethods = useFormContext();
  const [counts, setCounts] = useState(0);

  const formFieldIsPresent = (value: any) => {
    if (!value) {
      return false;
    }
    if (Array.isArray(value)) {
      return !!value.length;
    }

    return true;
  };

  const filterData = formMethods.watch(ALL_FILTER_FIELDS);

  useEffect(() => {
    setCounts(filterData.filter(formFieldIsPresent).length);
  }, [...filterData]);

  if (emailPush?.lifecycleOnly) {
    return <LifecycleEmailNotice />;
  }

  const renderRadioGroup = (fields: FilterField[]) => {
    const valuesByFieldName: { [name: string]: boolean } = {};
    fields.forEach(({ fieldName }) => {
      valuesByFieldName[fieldName] = !!filterData[ALL_FILTER_FIELDS.indexOf(fieldName)];
    });
    const noFilter = Object.values(valuesByFieldName).every((value) => !value);

    return (
      <div>
        <RadioItem>
          <Radio
            color="default"
            checked={noFilter}
            onChange={() => {
              fields.forEach((field) => {
                formMethods.setValue(field.fieldName, false, { shouldTouch: true });
              });
            }}
          />
          <div>No filter</div>
        </RadioItem>

        {fields.map((field) => (
          <RadioItem key={field.fieldName}>
            <Radio
              checked={valuesByFieldName[field.fieldName]}
              onChange={() => {
                fields.forEach((f) => {
                  const isChecked = f.fieldName === field.fieldName;
                  formMethods.setValue(f.fieldName, isChecked, { shouldTouch: true });
                });
              }}
            />
            <div>{field.label}</div>
          </RadioItem>
        ))}
      </div>
    );
  };

  return (
    <>
      <FilterGroup css={css({ marginTop: 50, marginBottom: 20 })}>
        <h2>Who should receive the email?</h2>
      </FilterGroup>

      <ExpandableSection title="Filters" count={counts}>
        <Pane>
          <PaneItem>
            {emailCampaign.meetupId && (
              <FilterGroup>
                <b>Event attendees</b>
                {renderRadioGroup([
                  ALL_FILTERS.ONLY_CONFIRMED_ATTENDEES,
                  ALL_FILTERS.ONLY_PENDING_INVITES,
                  ALL_FILTERS.OMIT_MEETUP_WAITLIST_USERS,
                ])}
              </FilterGroup>
            )}
            <FilterGroup>
              <b>YC alumni</b>
              {renderRadioGroup([ALL_FILTERS.ONLY_YC_ALUMNI, ALL_FILTERS.EXCLUDE_YC_ALUMNI])}
            </FilterGroup>
            <FilterGroup>
              <b>YC staff</b>
              {renderRadioGroup([ALL_FILTERS.YC_STAFF_ONLY, ALL_FILTERS.OMIT_YC_STAFF])}
            </FilterGroup>
          </PaneItem>
        </Pane>
      </ExpandableSection>
    </>
  );
};
