import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Checkbox, Dialog, DialogTitle, FormControlLabel } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import MEETUP_COLUMNS, {
  CHECK_IN_COLUMNS,
  getDefaultCheckInColumnsForEvent,
  getDefaultColumnsForEvent,
  getQuestionColumnsForEvent,
  MeetupColumnType,
} from "./MeetupColumns";
import Button from "../../../components/statelessForms/Button";
import { MEETUP_RSVPS_admin_adminMeetup } from "../__generated__/MEETUP_RSVPS";
import {
  SET_MEETUP_DEFAULT_COLUMNS,
  SET_MEETUP_DEFAULT_COLUMNSVariables,
} from "./__generated__/SET_MEETUP_DEFAULT_COLUMNS";

const LinkWrapper = styled.div`
  margin-bottom: 16px;
  margin-left: 15px;
  font-size: 14px;
  a:hover {
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  padding: 30px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 10px;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  id: string;
  columns: MeetupColumnType[];
  meetup: MEETUP_RSVPS_admin_adminMeetup | undefined;
  onChange: (meetup: MeetupColumnType[]) => void;
  checkInView?: boolean;
};

export default ({ id, columns, meetup, onChange, checkInView }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<MeetupColumnType[]>(columns);

  const [setDefaultColumns] = useMutation<
    SET_MEETUP_DEFAULT_COLUMNS,
    SET_MEETUP_DEFAULT_COLUMNSVariables
  >(gql`
    mutation SET_MEETUP_DEFAULT_COLUMNS($id: ID!, $defaultColumns: [String!]!) {
      setMeetupDefaultColumns(id: $id, defaultColumns: $defaultColumns) {
        slug
        defaultColumns
      }
    }
  `);

  useEffect(() => {
    if (checkInView) {
      onChange(getDefaultCheckInColumnsForEvent(meetup));
    } else {
      onChange(getDefaultColumnsForEvent(meetup));
    }
  }, [meetup]);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns]);

  if (!meetup) {
    return <div />;
  }

  const onSetColumns = () => {
    onChange(selectedColumns);
    setIsModalOpen(false);
  };

  const onSaveColumns = async () => {
    await setDefaultColumns({
      variables: {
        id,
        defaultColumns: selectedColumns.map((col) => col.title),
      },
    });
    onSetColumns();
  };

  const renderColumnCheckbox = (column: MeetupColumnType) => {
    const titles = selectedColumns.map(({ title }) => title);
    const colIdx = titles.indexOf(column.title);
    const isSelected = colIdx >= 0;

    const label = isSelected ? `${column.title} (col #${colIdx + 1})` : column.title;
    const onColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isSelected && !e.target.checked) {
        setSelectedColumns(selectedColumns.filter(({ title }) => title !== column.title));
      } else if (!isSelected && e.target.checked) {
        setSelectedColumns([...selectedColumns, column]);
      }
    };

    return (
      <FormControlLabel
        key={column.title}
        control={<Checkbox />}
        label={label}
        checked={isSelected}
        onChange={onColumnChange}
      />
    );
  };

  const availableColumns = checkInView ? CHECK_IN_COLUMNS : Object.values(MEETUP_COLUMNS);
  return (
    <LinkWrapper>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} scroll="body" maxWidth="md">
        <ModalBody>
          <DialogTitle>Which columns would you like to see?</DialogTitle>
          <CheckboxWrapper>
            {[...availableColumns, ...getQuestionColumnsForEvent(meetup)].map(renderColumnCheckbox)}
          </CheckboxWrapper>
          <ButtonRow>
            {!checkInView && (
              <Button color="orange" onClick={onSaveColumns}>
                Set and save as default columns
              </Button>
            )}
            <Button color="gray" onClick={onSetColumns}>
              Set for now
            </Button>
          </ButtonRow>
        </ModalBody>
      </Dialog>
      <a onClick={() => setIsModalOpen(true)}>Change columns shown</a>
    </LinkWrapper>
  );
};
