import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { isBefore } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import styled from "@emotion/styled";
import { getSlugFromWindowLocation } from "../../../components/forms/util";
import Button, { BasicButton } from "../../../components/statelessForms/Button";
import {
  DEFAULT_HARMONIC_EVENT_PROSPECT_COLUMNS,
  HarmonicEventProspectSortType,
} from "./MeetupColumns";
import Input from "../../../components/statelessForms/Input";
import AdminMeetupHarmonicEventProspectTable from "./AdminMeetupHarmonicEventProspectTable";
import Select from "../../../components/statelessForms/Select";
import {
  MEETUP_SAVED_SEARCH,
  MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
} from "./__generated__/MEETUP_SAVED_SEARCH";
import {
  SEND_HARMONIC_EVENT_PROSPECT_EMAIL_PUSH,
  SEND_HARMONIC_EVENT_PROSPECT_EMAIL_PUSHVariables,
} from "./__generated__/SEND_HARMONIC_EVENT_PROSPECT_EMAIL_PUSH";
import {
  ADMIN_FILTER_HARMONIC_PROSPECT,
  ADMIN_FILTER_HARMONIC_PROSPECTVariables,
} from "./__generated__/ADMIN_FILTER_HARMONIC_PROSPECT";
import HarmonicProspectStats from "./HarmonicProspectStats";
import AddHarmonicSavedSearchModal from "./AddHarmonicSavedSearchModal";
import useKickOffHarmonicProspectSearch from "./hooks/useKickOffHarmonicProspectSearch";
import {
  CHECK_HARMONIC_SEARCH_STATUS,
  CHECK_HARMONIC_SEARCH_STATUSVariables,
} from "./__generated__/CHECK_HARMONIC_SEARCH_STATUS";

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const SavedSearchSelection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SavedSearchSelectionButton = styled.button<{ selected?: boolean }>`
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  font-weight: bold;
  font-size: 18px;
  background-color: ${({ selected }) => (selected ? "#dbeafe" : "auto")};
  text-decoration: ${({ selected }) => (selected ? "underline" : "none")};
  &:hover {
    cursor: pointer;
  }
`;

const SearchActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
`;

export default function MeetupHarmonicProspects() {
  const id = getSlugFromWindowLocation() || "";
  const kickOffHarmonicProspectSearch = useKickOffHarmonicProspectSearch(id);
  const [harmonicSortType, setHarmonicSortType] = useState<HarmonicEventProspectSortType | null>(
    null
  );

  const [prospectColumnToFilter, setProspectColumnToFilter] = useState<
    | undefined
    | keyof MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects
  >(undefined);
  const [commaSeparatedFilterKeywords, setCommaSeparatedFilterKeywords] = useState("");

  const [shownSearchId, setShownSearchId] = useState<string | undefined>();
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);

  const { data, refetch } = useQuery<MEETUP_SAVED_SEARCH>(
    gql`
      query MEETUP_SAVED_SEARCH($id: ID!) {
        admin {
          adminMeetup(id: $id) {
            slug
            title
            eventType
            startsAt
            harmonicSavedSearchId

            harmonicProspectSearches {
              id
              startedAt
              finishedAt
              harmonicSavedSearchId
              harmonicProspects {
                id
                entityUrn
                fullName
                firstName
                lastName
                emailsFound
                linkedinHeadline
                linkedin
                ycApps
                userLinks
                harmonicSavedSearchId
                toBeInvited
                inviteEmailSent
                automatedRating
                age
                topSchoolEducations
                topCompanyExperiences
                lastEmailedAt
              }
            }
          }
        }
      }
    `,
    { variables: { id } }
  );

  useEffect(() => {
    if (shownSearchId) {
      return;
    }
    const searchId = data?.admin.adminMeetup.harmonicProspectSearches[0]?.id;
    setShownSearchId(searchId);
  }, [data]);

  const [sendHarmonicProspectEmailPush] = useMutation<
    SEND_HARMONIC_EVENT_PROSPECT_EMAIL_PUSH,
    SEND_HARMONIC_EVENT_PROSPECT_EMAIL_PUSHVariables
  >(gql`
    mutation SEND_HARMONIC_EVENT_PROSPECT_EMAIL_PUSH($harmonicProspectSearchId: ID!) {
      sendHarmonicEventProspectEmails(harmonicProspectSearchId: $harmonicProspectSearchId) {
        success
        errors
      }
    }
  `);

  const [checkHarmonicSearchStatus] = useMutation<
    CHECK_HARMONIC_SEARCH_STATUS,
    CHECK_HARMONIC_SEARCH_STATUSVariables
  >(gql`
    mutation CHECK_HARMONIC_SEARCH_STATUS($searchId: ID!) {
      checkHarmonicSearchStatus(searchId: $searchId) {
        incomplete
        search {
          id
          startedAt
          finishedAt
          harmonicSavedSearchId
          harmonicProspects {
            id
            entityUrn
            fullName
            firstName
            lastName
            emailsFound
            linkedinHeadline
            linkedin
            ycApps
            userLinks
            harmonicSavedSearchId
            toBeInvited
            inviteEmailSent
            automatedRating
            age
            topSchoolEducations
            topCompanyExperiences
            lastEmailedAt
          }
        }
      }
    }
  `);

  const [filterProspects] = useMutation<
    ADMIN_FILTER_HARMONIC_PROSPECT,
    ADMIN_FILTER_HARMONIC_PROSPECTVariables
  >(gql`
    mutation ADMIN_FILTER_HARMONIC_PROSPECT(
      $searchId: ID!
      $filterColumn: String!
      $filterKeywords: [String!]!
      $toBeInvited: Boolean!
      $shouldMatchFilterKeywords: Boolean!
    ) {
      adminFilterHarmonicProspect(
        searchId: $searchId
        filterColumn: $filterColumn
        filterKeywords: $filterKeywords
        toBeInvited: $toBeInvited
        shouldMatchFilterKeywords: $shouldMatchFilterKeywords
      ) {
        id
        toBeInvited
      }
    }
  `);

  const sortHarmonicEventProspects = (
    rawHarmonicEventProspects:
      | MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects[]
      | undefined,
    currentSortType: HarmonicEventProspectSortType | null
  ) => {
    if (currentSortType && rawHarmonicEventProspects) {
      const harmonicEventProspectsCopy = [...rawHarmonicEventProspects];
      return harmonicEventProspectsCopy.sort(currentSortType.sortFn);
    }
    return rawHarmonicEventProspects;
  };

  const filterShowHarmonicEventProspects = (
    rawHarmonicEventProspects:
      | MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects[]
      | undefined
  ) => {
    if (!prospectColumnToFilter || !commaSeparatedFilterKeywords) {
      return rawHarmonicEventProspects;
    }

    const keywords = commaSeparatedFilterKeywords
      .split(",")
      .map((keyword) => keyword.trim().toLowerCase());
    return rawHarmonicEventProspects?.filter((prospect) => {
      const val = prospect[prospectColumnToFilter];
      if (typeof val === "string") {
        return keywords.some((keyword) => val.toLowerCase().includes(keyword));
      }
      return false;
    });
  };

  const sortProspects = useCallback(
    (
      prospects: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects[]
    ) => sortHarmonicEventProspects(filterShowHarmonicEventProspects(prospects), harmonicSortType),
    [harmonicSortType]
  );

  const startsAt = data?.admin.adminMeetup.startsAt;
  const isOver = !!startsAt && isBefore(new Date(startsAt), new Date());

  const onHarmonicProspectFilter = useCallback(
    async (matching: boolean, toBeInvited: boolean) => {
      if (!shownSearchId) {
        return;
      }
      await filterProspects({
        variables: {
          searchId: shownSearchId,
          filterColumn:
            prospectColumnToFilter as keyof MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
          filterKeywords: commaSeparatedFilterKeywords.split(",").map((keyword) => keyword.trim()),
          shouldMatchFilterKeywords: matching,
          toBeInvited,
        },
      });
    },
    [shownSearchId, prospectColumnToFilter, commaSeparatedFilterKeywords]
  );

  const onSendEmails = useCallback(async () => {
    if (
      !!shownSearchId &&
      confirm(
        "Are you SURE you want to send out emails? Make sure you made an email the Harmonic saved search ID field set."
      )
    ) {
      const res = await sendHarmonicProspectEmailPush({
        variables: { harmonicProspectSearchId: shownSearchId },
      });
      console.error("send harmonic emails res", res);
    }
  }, [shownSearchId]);

  const onRerunSearch = useCallback(async () => {
    if (
      !!shownSearch?.finishedAt &&
      confirm(`Are you sure you want to kick off search [${shownSearch.id}] again?`)
    ) {
      await kickOffHarmonicProspectSearch(shownSearch.harmonicSavedSearchId.toString());
      toast.success("Search is running again...");
    }
  }, [shownSearchId]);

  const shownSearch = useMemo(
    () => data?.admin.adminMeetup.harmonicProspectSearches.find((p) => p.id === shownSearchId),
    [data, shownSearchId]
  );

  const onCheckStatus = async () => {
    if (!shownSearchId) {
      return;
    }
    setIsCheckingStatus(true);
    const resp = await checkHarmonicSearchStatus({ variables: { searchId: shownSearchId } });
    setIsCheckingStatus(false);
    if (resp.data?.checkHarmonicSearchStatus.incomplete) {
      toast.info("Search is still running...");
    } else {
      toast.success("Search is complete!");
    }
  };

  const prospectsToShow = shownSearch?.harmonicProspects;
  const sortedFilteredProspects = useMemo(
    () => sortProspects(prospectsToShow || []),
    [prospectsToShow, sortProspects]
  );

  const headerRow = (
    <HeaderRow>
      <SavedSearchSelection>
        <h4 css={css({ margin: 0 })}>Saved searches</h4>
        {data?.admin.adminMeetup.harmonicProspectSearches.map((search) => (
          <SavedSearchSelectionButton
            key={search.id}
            onClick={() => setShownSearchId(search.id)}
            selected={search.id === shownSearchId}
          >
            {search.harmonicSavedSearchId}
          </SavedSearchSelectionButton>
        ))}
      </SavedSearchSelection>

      <AddHarmonicSavedSearchModal
        meetupId={id}
        savedSearchIds={
          data?.admin.adminMeetup.harmonicProspectSearches.map((s) => s.harmonicSavedSearchId) || []
        }
        onSearchAdded={refetch}
      />
    </HeaderRow>
  );

  const existingSavedSearchesSection = !!data?.admin.adminMeetup.harmonicProspectSearches
    .length && (
    <>
      <SearchActions>
        <BasicButton onClick={onRerunSearch} disabled={!shownSearch?.finishedAt}>
          Re-run search
        </BasicButton>
        <BasicButton onClick={onSendEmails} disabled={!prospectsToShow?.length}>
          Send Invite Emails
        </BasicButton>
        <a
          href={`https://console.harmonic.ai/dashboard/people/urn:harmonic:saved_search:${shownSearch?.harmonicSavedSearchId}`}
          target="_blank"
        >
          View on Harmonic
        </a>
      </SearchActions>

      {!!shownSearch && !shownSearch?.finishedAt && (
        <div css={css({ display: "flex", alignItems: "center", gap: 20, margin: "20px 0" })}>
          <div>Results are loading! This can take a while - check back in 15 minutes or so.</div>
          <Button onClick={onCheckStatus} disabled={isCheckingStatus}>
            {isCheckingStatus
              ? "Checking status (and loading results, if complete)"
              : "Check status"}
          </Button>
        </div>
      )}

      {!!prospectsToShow?.length && (
        <>
          <HarmonicProspectStats prospects={prospectsToShow} />

          <div
            css={css({
              display: "flex",
              alignItems: "center",
              gap: 8,
              marginBottom: 8,
              marginTop: 8,
            })}
          >
            <div>
              <Select
                value={prospectColumnToFilter}
                onChange={(r: any) => setProspectColumnToFilter(r)}
                options={DEFAULT_HARMONIC_EVENT_PROSPECT_COLUMNS.map((col) => [
                  col.sort?.key,
                  col.title,
                ])}
              />
            </div>
            <div>
              <Input
                size="small"
                outlined
                value={commaSeparatedFilterKeywords}
                placeholder="filter, keywords, separated"
                onChange={(e) => setCommaSeparatedFilterKeywords(e.target.value)}
              />
            </div>
            <div>
              (Showing {sortedFilteredProspects?.length || 0} out of {prospectsToShow.length} total)
            </div>
            <div>
              <BasicButton
                onClick={() => {
                  onHarmonicProspectFilter(true, true);
                }}
              >
                Accept Prospects Who Match
              </BasicButton>
            </div>
            <div>
              <BasicButton
                onClick={() => {
                  onHarmonicProspectFilter(true, false);
                }}
              >
                Reject Prospects Who Match
              </BasicButton>
            </div>
            <div>
              <BasicButton
                onClick={() => {
                  onHarmonicProspectFilter(false, false);
                }}
              >
                Reject Prospects Who Don't Match
              </BasicButton>
            </div>
          </div>

          {sortedFilteredProspects && (
            <AdminMeetupHarmonicEventProspectTable
              columns={DEFAULT_HARMONIC_EVENT_PROSPECT_COLUMNS}
              harmonicProspects={sortedFilteredProspects}
              isOver={isOver}
              sortType={harmonicSortType}
              setSortType={setHarmonicSortType}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <div css={css({ marginTop: 30 })}>
      {headerRow}

      {existingSavedSearchesSection}

      {/* Add toast container for UI feedback */}
      <ToastContainer position="top-right" />
    </div>
  );
}
