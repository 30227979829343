import styled from "@emotion/styled";
import React, { useMemo } from "react";
import { css } from "@emotion/core";
import { useFormContext } from "react-hook-form";
import { MEETUP_QUESTIONS_admin_meetup_questions } from "../admin/meetups/__generated__/MEETUP_QUESTIONS";
import { Input, MultiSelect, RadioGroup, Textarea } from "../../components/forms";
import { MEETUP_meetup_questions } from "./__generated__/MEETUP";

type Props = {
  question: MEETUP_QUESTIONS_admin_meetup_questions | MEETUP_meetup_questions;
  skipValidation?: boolean;
  ageQuestionId?: string;
  alwaysShow?: boolean;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const MeetupQuestionHeader = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-right: 15px;
  margin-bottom: -5px;
`;

export const MeetupQuestionSubtitle = styled.span`
  font-weight: 100;
  font-size: 14px;
  margin-bottom: -10px;
`;

export const RequiredAsterisk = (
  <span css={css({ color: "#db2828", position: "relative", top: "-0.2em", left: "0.2em" })}>*</span>
);

const STUDENT_QUESTIONS = ["test_scores", "clubs"];

export default ({ question, skipValidation, ageQuestionId, alwaysShow }: Props) => {
  const blah = useFormContext();
  const age = blah.watch(ageQuestionId || "---");

  const isStudent = useMemo(() => {
    if (!age) {
      return false;
    }
    const ageNum = Number.parseInt(age, 10);
    if (isNaN(ageNum)) {
      return false;
    }

    return ageNum > 10 && ageNum <= 25;
  }, [age]);

  const {
    id,
    questionText,
    questionSubtitle,
    questionType,
    required,
    otherRequirements,
    fieldName,
  } = question;

  if (fieldName && STUDENT_QUESTIONS.includes(fieldName) && !isStudent && !alwaysShow) {
    return <div />;
  }

  const defaultProps = {
    fieldName: id,
    ...(skipValidation ? {} : { required }),
  };

  const renderInput = (isNumber: boolean) => {
    const typeProps = isNumber ? { type: "number" } : {};
    return <Input {...defaultProps} {...typeProps} outlined />;
  };

  const renderTextarea = () => <Textarea {...defaultProps} minRows={3} />;

  const getMultipleChoiceOptions = () => {
    const options = (JSON.parse(otherRequirements)?.multipleChoiceOptions || []) as string[];

    if (!options?.length) {
      return [];
    }

    const results: [string, string][] = options.map((option) => [option, option]);
    return results;
  };

  const renderCheckboxes = () => (
    <MultiSelect {...defaultProps} options={getMultipleChoiceOptions()} />
  );

  const renderRadio = () => <RadioGroup {...defaultProps} options={getMultipleChoiceOptions()} />;

  const renderFormComponent = () => {
    switch (questionType) {
      case "short_text":
        return renderInput(false);
      case "long_text":
        return renderTextarea();
      case "number":
        return renderInput(true);
      case "checkboxes":
        return renderCheckboxes();
      case "radio":
        return renderRadio();
      default:
        return null;
    }
  };

  return (
    <Container>
      <MeetupQuestionHeader>
        {questionText}
        {required && RequiredAsterisk}
      </MeetupQuestionHeader>
      {!!questionSubtitle && <MeetupQuestionSubtitle>{questionSubtitle}</MeetupQuestionSubtitle>}
      <div css={css({ marginTop: -10 })}>{renderFormComponent()}</div>
    </Container>
  );
};
