import React, { useState } from "react";
import { css } from "@emotion/core";
import { gql, useMutation } from "@apollo/client";
import Button from "../../components/Button";
import {
  COMPLETE_CURRICULUM_ITEM,
  COMPLETE_CURRICULUM_ITEMVariables,
} from "./__generated__/COMPLETE_CURRICULUM_ITEM";

type Props = {
  curriculumItemId: number;
  status: string | null | undefined;
  hideIrrelevant: boolean;
  row?: boolean;
};

export default function CurriculumItemStatus({
  curriculumItemId,
  status,
  hideIrrelevant,
  row,
}: Props) {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [completeCurriculumItem] = useMutation<
    COMPLETE_CURRICULUM_ITEM,
    COMPLETE_CURRICULUM_ITEMVariables
  >(gql`
    mutation COMPLETE_CURRICULUM_ITEM($curriculumItemId: ID!, $status: String) {
      completeCurriculumItem(curriculumItemId: $curriculumItemId, status: $status) {
        id
        curriculumItemFeedback {
          status
          rating
        }
      }
    }
  `);

  const updateStatus = async (value: string | null) => {
    setSubmitting(true);

    await completeCurriculumItem({
      variables: {
        curriculumItemId: `${curriculumItemId}`,
        status: value,
      },
    });

    setSubmitting(false);
  };

  const content = (
    <>
      {!status && (
        <div css={css({ marginBottom: "20px" })}>
          <Button
            text="I Completed This"
            type="inactiveSuccess"
            onClick={() => updateStatus("complete")}
            disabled={submitting}
            asButton
          />
        </div>
      )}
      {!status && !hideIrrelevant && (
        <Button
          text="Skip - Not Relevant"
          type="inactiveFail"
          onClick={() => updateStatus("irrelevant")}
          disabled={submitting}
          asButton
        />
      )}
      {status === "complete" && (
        <div css={css({ marginBottom: "20px" })}>
          <Button
            text="Lesson Completed"
            type="success"
            onClick={() => updateStatus("")}
            disabled={submitting}
            asButton
          />
        </div>
      )}
      {status === "irrelevant" && (
        <Button
          text="Skip - Not Relevant"
          type="fail"
          onClick={() => updateStatus("")}
          disabled={submitting}
          asButton
        />
      )}
    </>
  );

  if (row) {
    return (
      <div
        css={css({
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: status === "complete" ? 20 : 0,
          div: {
            marginBottom: "0 !important",
          },
          a: {
            margin: "0 10px",
          },
        })}
      >
        {content}
      </div>
    );
  }

  return content;
}
