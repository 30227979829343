import React from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { ADMIN_RATED_USERS_admin_ratedUsers_badActorReports } from "./__generated__/ADMIN_RATED_USERS";

const ReasonWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const UnconfirmedLabel = styled.div`
  color: orange;
  font-style: italic;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

type ReportTypeTagProps = {
  confirmed: boolean;
};
const ReportTypeTag = styled.div<ReportTypeTagProps>`
  background-color: ${({ confirmed }) => (confirmed ? "red" : "orange")};
  color: white;
  border-radius: 5px;
  padding: 2px 6px;
  margin-right: 5px;
`;

type Props = {
  report: ADMIN_RATED_USERS_admin_ratedUsers_badActorReports;
};

export default ({ report }: Props) => {
  const { reason, comment, creatorName, createdAt, confirmed } = report;

  const formattedDate = format(new Date(createdAt), "P");

  return (
    <ReasonWrapper>
      {!confirmed && <UnconfirmedLabel>*unconfirmed</UnconfirmedLabel>}
      <HeaderRow>
        <ReportTypeTag confirmed={!!confirmed}>{reason}</ReportTypeTag>
        <span>{`${creatorName} (${formattedDate})`}</span>
      </HeaderRow>
      <div>{comment}</div>
    </ReasonWrapper>
  );
};
