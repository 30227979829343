import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { omit } from "lodash";
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  Checkbox,
  Input,
  LocationPicker,
  RadioGroup,
  Submit,
  Textarea,
  useForm,
} from "../../components/forms";
import EditableAvatar, { saveAvatar } from "../../components/forms/EditableAvatar";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import UserHowHeard from "../../components/UserHowHeard";
import { mobileStyles } from "../../styles";
import { EDIT_USER_PAGE } from "./__generated__/EDIT_USER_PAGE";
import { UPDATE_USER, UPDATE_USERVariables } from "./__generated__/UPDATE_USER";
import SpacedGrid from "../../components/statelessForms/SpacedGrid";
import {
  validateLinkedinUrl,
  encodeLinkedinUrl,
  decodeLinkedinUrl,
  getUtmFields,
  validateBirthdate,
} from "../../components/forms/util";
import EnrichableEducationExperience from "../../components/forms/EnrichableEducationExperience";
import EmailSettings from "../../components/forms/EmailSettings";
import { UPDATE_SSO_USER, UPDATE_SSO_USERVariables } from "./__generated__/UPDATE_SSO_USER";

type Props = {
  suppression?: { reason: string; type: string };
  ssoUser: { avatarUrl: string; firstName: string; lastName: string; email: string };
  enrichedFields: { education: string | null; employment: string | null };
  signingUp: boolean;
};

const SSO_FIELDS = ["avatarUrl", "firstName", "lastName", "email"];

export default (props: Props) => {
  const { data } = useQuery<EDIT_USER_PAGE>(gql`
    query EDIT_USER_PAGE {
      currentUser {
        slug
        linkedin
        noLinkedin
        education
        employment
        location
        impressiveThing
        isTechnical
        seekingCofounder
        isYcFounder
        showYcFounder
        gender
        birthdate
        expertise
        howHeard
        howHeardFreeText
        emailSettings
        track
        persisted
        relevantEmailSettings
        enrichedUserData {
          education
          employment
        }
        errors {
          field
          error
        }
      }
    }
  `);

  const [updateSsoUser] = useMutation<UPDATE_SSO_USER, UPDATE_SSO_USERVariables>(gql`
    mutation UPDATE_SSO_USER($ssoInput: UpdateSsoUserInput!) {
      updateSsoUser(input: $ssoInput) {
        errors {
          field
          error
        }
      }
    }
  `);

  const [updateUser] = useMutation<UPDATE_USER, UPDATE_USERVariables>(gql`
    mutation UPDATE_USER($userInput: UpdateUserInput!) {
      updateUser(input: $userInput) {
        slug
        errors {
          field
          error
        }
      }
    }
  `);

  const onSubmit = async (fields: NonNullable<typeof formData>) => {
    const linkedin = encodeLinkedinUrl(fields.linkedin);
    const utmFields = getUtmFields();
    const [, ssoResp] = await Promise.all([
      saveAvatar(fields.ssoUser.avatarUrl),
      updateSsoUser({
        variables: {
          ssoInput: {
            ...omit(fields.ssoUser, ["avatarUrl", "linkedin"]),
            ...(linkedin ? { linkedin } : {}),
          },
        },
      }),
    ]);

    const userResp = await updateUser({
      variables: {
        userInput: omit({ ...fields, ...utmFields }, [
          "__typename",
          "errors",
          "persisted",
          "slug",
          "ssoUser",
          "isYcFounder",
          "linkedin",
          "enrichedUserData",
          "relevantEmailSettings",
        ]),
      },
    });

    return { ...userResp.data?.updateUser, ssoUser: ssoResp.data?.updateSsoUser };
  };

  const currentUserData = {
    ...data?.currentUser,
    linkedin: decodeLinkedinUrl(data?.currentUser?.linkedin),
  };
  const formData = data ? { ...currentUserData, ssoUser: props.ssoUser } : null;

  // @ts-ignore
  const { ConnectedForm, connectedFormProps, formMethods } = useForm(formData, onSubmit, {
    formProps: { defaultValues: { emailSettings: ["announcements"] } },
  });

  const noLinkedin = formMethods.watch("noLinkedin");
  const birthdate = formMethods.watch("birthdate");

  useEffect(() => {
    data?.currentUser?.errors?.forEach((error) => {
      SSO_FIELDS.forEach((ssoFieldName) => {
        if (error.field === ssoFieldName) {
          formMethods.setError(`ssoUser.${ssoFieldName}`, { message: error.error });
          formMethods.clearErrors(ssoFieldName);
        }
      });
    });
  }, [data, formMethods]);

  useEffect(() => {
    if (!birthdate) {
      // react-hook-form doesn't like null values to be set, but this is fine in our case
      // @ts-ignore
      formMethods.setValue("birthdate", null);
    }
  }, [birthdate]);

  return (
    <Page title="User Details">
      <Container css={css({ maxWidth: 900 })}>
        <ConnectedForm {...connectedFormProps}>
          <Pane title="YC Account">
            <PaneItem
              css={css({
                display: "flex",
                alignItems: "flexStart",
                ...mobileStyles({ flexDirection: "column" }),
              })}
            >
              <EditableAvatar
                fieldName="ssoUser.avatarUrl"
                style={css({ marginRight: 40, marginTop: 20, ...mobileStyles({ margin: 0 }) })}
              />
              <div css={css({ flex: 1 })}>
                <SpacedGrid container marginBottom="1em" spacing={1} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Input
                      label="First"
                      fieldName="ssoUser.firstName"
                      placeholder="Jessie"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Last" fieldName="ssoUser.lastName" placeholder="Smith" required />
                  </Grid>
                </SpacedGrid>
                <Input
                  label="Email"
                  fieldName="ssoUser.email"
                  placeholder="jessiesmith@gmail.com"
                  required
                />
                <Input
                  label="LinkedIn URL"
                  subLabel="URL should start with https://"
                  fieldName="linkedin"
                  required={!noLinkedin}
                  validate={(value: string) => !value || validateLinkedinUrl(value)}
                  placeholder="https://www.linkedin.com/in/..."
                />
                <Checkbox label="I don't have a LinkedIn profile" fieldName="noLinkedin" />
              </div>
            </PaneItem>
          </Pane>
          <Pane title="More Information">
            <PaneItem>
              <EnrichableEducationExperience
                currentUser={data?.currentUser}
                enrichedFields={props.enrichedFields}
                required={noLinkedin}
              />
              <LocationPicker
                fieldName="location"
                required
                label="Location"
                subLabel="What country and city are you in?"
              />
              <Textarea
                fieldName="impressiveThing"
                required
                label="Tell us about an impressive accomplishment of yours."
                placeholder="For example, an academic or professional achievement, an award you've won, or something impressive you've built."
                minRows={4}
                maxLength={1000}
                showCharCount
              />
              {props.signingUp && (
                <RadioGroup
                  required
                  fieldName="track"
                  label="Are you actively working on a startup?"
                  subLabel='"Actively" means 10+ hours a week. We will show you different content based on whether you are currently working on a startup or whether you are interested in doing so in the future. You can come back and change this at any time.'
                  options={[
                    ["active_founder", "Yes"],
                    ["aspiring_founder", "No"],
                  ]}
                />
              )}
              <RadioGroup
                required
                fieldName="isTechnical"
                label="Are you technical?"
                subLabel="You are a programmer, scientist or engineer who can build the product without outside assistance."
                options={[
                  [true, "Yes"],
                  [false, "No"],
                ]}
              />
              <RadioGroup
                required
                fieldName="seekingCofounder"
                label="Are you actively seeking a co-founder?"
                subLabel="Startup School can help you find others interested in finding a co-founder."
                options={[
                  [true, "Yes"],
                  [false, "No"],
                ]}
              />
              <SpacedGrid container spacing={2} marginBottom="0">
                <Grid item xs>
                  <Input label="Gender" fieldName="gender" />
                </Grid>
                <Grid item xs>
                  <Input
                    required
                    type="date"
                    fieldName="birthdate"
                    label="Birthdate"
                    validate={validateBirthdate}
                  />
                </Grid>
              </SpacedGrid>
              {data && !data?.currentUser?.persisted && <UserHowHeard />}
              <EmailSettings
                fieldName="emailSettings"
                email={props.ssoUser.email}
                suppression={props.suppression}
                relevantOptions={
                  data?.currentUser?.relevantEmailSettings || [
                    "announcements",
                    "weekly_update_reminders",
                  ]
                }
              />
            </PaneItem>
          </Pane>
          <Submit />
        </ConnectedForm>
      </Container>
    </Page>
  );
};
