import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Papa from "papaparse";
import { useFormState } from "react-hook-form";
import Button from "../../components/statelessForms/Button";
import { Container } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import { BROWSE_USERS } from "./__generated__/BROWSE_USERS";
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  LocationPicker,
  Submit,
  useForm,
} from "../../components/forms";
import { GET_VERTICALS } from "./__generated__/GET_VERTICALS";
import { reqPrefs } from "../cofounderMatching/profileFields";
import { candidateCofounderMatchingPath } from "../../__generated__/routes";

const SearchContainer = styled.div`
  width: 100%;
  padding: 20px 100px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  div.field {
    flex-grow: 1;
  }
`;

const HeaderCell = styled.th`
  padding: 10px 20px;
  text-align: left;
  border: 1px solid rgba(34, 36, 38, 0.1);
`;

const Cell = styled.td`
  width: 25%;
  padding: 10px 20px;
  border: 1px solid rgba(34, 36, 38, 0.1);
`;

export default function BrowseUsers() {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [showCfmFilters, setShowCfmFilters] = useState(false);

  const { data: verticalData } = useQuery<GET_VERTICALS>(
    gql`
      query GET_VERTICALS {
        admin {
          verticals {
            id
            name
          }
        }
      }
    `
  );

  const { data, refetch } = useQuery<BROWSE_USERS>(
    gql`
      query BROWSE_USERS($userInput: BrowseUsersInput!, $profileInput: BrowseCfmProfilesInput!) {
        admin {
          browseUsers(userInput: $userInput, profileInput: $profileInput) {
            totalResults
            results {
              slug
              email
              adminPath

              user {
                slug
                name
                firstName
              }
              adminCfmProfile {
                slug
                id
                adminPath
              }
            }
          }
        }
      }
    `,
    { skip: !initialLoadComplete }
  );

  const tryParseInt = (intStr: string) => {
    if (intStr) {
      const parsed = Number.parseInt(intStr, 10);
      if (!Number.isNaN(parsed)) {
        return parsed;
      }
    }
  };

  const executeSearch = async (input: any) => {
    const { userInput, profileInput } = input;
    await refetch({
      userInput: {
        ...userInput,
        minAge: tryParseInt(userInput.minAge),
        maxAge: tryParseInt(userInput.maxAge),
      },
      profileInput: {
        ...profileInput,
        minRequestsSent: tryParseInt(profileInput.minRequestsSent),
        maxRequestsSent: tryParseInt(profileInput.maxRequestsSent),
        minRequestsReceived: tryParseInt(profileInput.minRequestsReceived),
        maxRequestsReceived: tryParseInt(profileInput.maxRequestsReceived),
        minMatches: tryParseInt(profileInput.minMatches),
        maxMatches: tryParseInt(profileInput.maxMatches),
      },
    });
  };

  const { formMethods, ConnectedForm, connectedFormProps } = useForm(
    {
      userInput: {
        ratings: [],
        inclusiveGroups: [],
        technical: undefined,
        inBuildSprint: undefined,
        inCourse: undefined,
        woman: undefined,
        companyCategory: [],
        track: undefined,
        companyStage: [],
        location: undefined,
        locationRadius: undefined,
        primaryMetric: [],
        minAge: undefined,
        maxAge: undefined,
        superRegion: [],
        region: [],
        minCreated: undefined,
        maxCreated: undefined,
        minLastActive: undefined,
        maxLastActive: undefined,
        appliedToLatestBatch: undefined,
        approvedForDeals: undefined,
      },
      profileInput: {
        active: undefined,
        hasIdea: [],
        interests: [],
        responsibilities: [],
        timing: [],
        minRequestsSent: undefined,
        maxRequestsSent: undefined,
        minRequestsReceived: undefined,
        maxRequestsReceived: undefined,
        minMatches: undefined,
        maxMatches: undefined,
        minCreated: undefined,
        maxCreated: undefined,
        minLastActive: undefined,
        maxLastActive: undefined,
      },
    },
    executeSearch
  );

  useEffect(() => setInitialLoadComplete(true), []);

  const location = formMethods.watch("userInput.location");
  const locationRadius = formMethods.watch("userInput.locationRadius");
  useEffect(() => {
    if (location && !locationRadius) {
      formMethods.setValue("userInput.locationRadius", "country");
    } else if (!location && locationRadius) {
      formMethods.setValue("userInput.locationRadius", undefined);
    }
  }, [location]);

  const adminUsers = data?.admin.browseUsers.results;
  const totalResults = data?.admin.browseUsers.totalResults;

  const numberInput = (fieldName: string, label: string) => (
    <Input type="number" fieldName={fieldName} label={label} />
  );

  const dateInput = (fieldName: string, label: string) => (
    <DatePicker allowEmpty fieldName={fieldName} label={label} />
  );

  const getCSVBlob = () => {
    const { origin } = window.location;
    const rows = adminUsers?.map((adminUser) => ({
      email: adminUser.email,
      name: adminUser.user.name,
      first_name: adminUser.user.firstName,
      admin_path: origin + adminUser.adminPath,
      profile_admin_path: adminUser.adminCfmProfile?.adminPath
        ? origin + adminUser.adminCfmProfile.adminPath
        : "",
      profile_url: adminUser.adminCfmProfile?.slug
        ? origin + candidateCofounderMatchingPath(adminUser.adminCfmProfile.slug)
        : "",
    }));

    const csv = new Blob([Papa.unparse(rows)], { type: "text/csv;charset=utf-8;" });
    return window.URL.createObjectURL(csv);
  };

  const { isSubmitting } = useFormState({ control: formMethods.control });

  return (
    <div css={css({ marginTop: 30 })}>
      <div>
        <SearchContainer>
          <Pane title="User search filters">
            <ConnectedForm {...connectedFormProps}>
              <PaneItem>
                <Row>
                  <Dropdown
                    fieldName="userInput.ratings"
                    label="Admin ratings"
                    multiple
                    options={[
                      ["Y", "Y"],
                      ["y", "y"],
                      ["m", "m"],
                      ["n", "n"],
                      ["N", "N"],
                      ["suspended", "suspended"],
                    ]}
                  />
                  <Dropdown
                    clearable
                    fieldName="userInput.technical"
                    label="Is technical"
                    options={[
                      [true, "true"],
                      [false, "false"],
                    ]}
                  />
                  <Dropdown
                    clearable
                    fieldName="userInput.inclusiveGroups"
                    label="Inclusive Groups"
                    options={[
                      ["woman_founder", "Women founders"],
                      ["black_founder", "Black founders"],
                      ["latinx_founder", "Latinx founders"],
                      ["lgbtq_founder", "LGBTQ founders"],
                    ]}
                  />
                  <Dropdown
                    clearable
                    fieldName="userInput.woman"
                    label="Is woman"
                    options={[[true, "true"]]}
                  />
                </Row>
                <Row>
                  <Dropdown
                    clearable
                    fieldName="userInput.inBuildSprint"
                    label="In Latest Build Sprint"
                    options={[
                      [true, "true"],
                      [false, "false"],
                    ]}
                  />
                  <Dropdown
                    clearable
                    fieldName="userInput.inCourse"
                    label="In Current Course"
                    options={[
                      [true, "true"],
                      [false, "false"],
                    ]}
                  />
                  <Dropdown
                    fieldName="userInput.track"
                    label="Track"
                    options={[
                      ["active_founder", "Active"],
                      ["aspiring_founder", "Aspiring"],
                    ]}
                  />
                  <Dropdown
                    fieldName="userInput.companyStage"
                    label="Company Stage"
                    multiple
                    options={[
                      ["stage_1", "Stage 1"],
                      ["stage_2", "Stage 2"],
                      ["stage_3", "Stage 3"],
                    ]}
                  />
                </Row>
                <Row>
                  <Dropdown
                    fieldName="userInput.companyCategory"
                    label="Company Vertical"
                    multiple
                    options={verticalData?.admin?.verticals?.map((v) => [v.id, v.name]) || []}
                  />
                </Row>
                <Row>
                  <Dropdown
                    fieldName="userInput.primaryMetric"
                    label="Primary Metric (if active founder)"
                    multiple
                    options={[
                      ["arr", "Annual Recurring Revenue"],
                      ["mrr", "Monthly Recurring Revenue"],
                      ["software_sales", "Software Sales"],
                      ["hardware_sales", "Hardware Sales"],
                      ["preorder_sales", "Preorder Sales"],
                      ["loi", "Letters of Intent"],
                      ["trials", "Paid Trials"],
                      ["contracts", "Paid Contracts"],
                      ["ecommerce", "Ecommerce Sales"],
                      ["marketplace", "Marketplace Transaction Volume"],
                      ["transaction_volume", "Transaction Volume (other)"],
                      ["aum", "Assets Under Management"],
                      ["dau", "Active users in the last day (DAU)"],
                      ["wau", "Active users in the last week (WAU)"],
                      ["mau", "Active users in the last month (MAU)"],
                      ["weeks", "Weeks to launch"],
                      ["revenue", "Revenue"],
                    ]}
                  />
                </Row>
                <Row>
                  <LocationPicker fieldName="userInput.location" label="Location" />
                  <Dropdown
                    fieldName="userInput.locationRadius"
                    label="Location radius"
                    disabled={!location}
                    options={[
                      ["50km", "Within 50 km"],
                      ["timezone", "Within 3 timezones"],
                      ["country", "Same country"],
                    ]}
                  />
                </Row>
                <Row>
                  {numberInput("userInput.minAge", "Minimum age")}
                  {numberInput("userInput.maxAge", "Maximum age")}
                </Row>
                <Row>
                  <Dropdown
                    fieldName="userInput.superRegion"
                    label="Super Region"
                    multiple
                    options={[
                      ["us", "US / US-like"],
                      ["row", "Rest of World"],
                    ]}
                  />
                  <Dropdown
                    fieldName="userInput.region"
                    label="Region"
                    multiple
                    options={[
                      ["North America", "North America"],
                      ["Oceania", "Oceania"],
                      ["Western Europe", "Western Europe"],
                      ["Eastern Europe", "Eastern Europe"],
                      ["Middle East and North Africa", "Middle East and North Africa"],
                      ["South Asia", "South Asia"],
                      ["Africa", "Africa"],
                      ["Latin America", "Latin America"],
                      ["East & Southeast Asia", "East & Southeast Asia"],
                    ]}
                  />
                </Row>
                <Row>
                  {dateInput("userInput.minCreated", "Min user created date")}
                  {dateInput("userInput.maxCreated", "Max user created date")}
                  {dateInput("userInput.minLastActive", "Min last active date")}
                  {dateInput("userInput.maxLastActive", "Max last active date")}
                </Row>
                <Row>
                  <Dropdown
                    fieldName="userInput.appliedToLatestBatch"
                    label="Applied to latest YC Batch?"
                    clearable
                    options={[
                      [true, "Has applied to latest batch"],
                      [false, "Hasn't applied to latest batch"],
                    ]}
                  />
                  <Dropdown
                    fieldName="userInput.approvedForDeals"
                    label="Approved for deals?"
                    clearable
                    options={[
                      [true, "Was approved"],
                      [false, "Was denied"],
                    ]}
                  />
                </Row>
                <Row>
                  <Checkbox
                    fieldName="userInput.omitRejectedFromTwoEvents"
                    label="Omit users rejected from >=2 CFM events?"
                  />
                </Row>
              </PaneItem>
              <PaneItem>
                <div
                  css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  })}
                >
                  <h3>Co-founder matching filters</h3>
                  <Button onClick={() => setShowCfmFilters(!showCfmFilters)}>
                    {showCfmFilters ? "Hide" : "Show"}
                  </Button>
                </div>
              </PaneItem>
              {showCfmFilters && (
                <PaneItem>
                  <Row>
                    <Dropdown
                      clearable
                      fieldName="profileInput.active"
                      label="Is active"
                      options={[
                        [true, "true"],
                        [false, "false"],
                      ]}
                    />
                    <Dropdown
                      clearable
                      fieldName="profileInput.hasIdea"
                      label="Has idea"
                      options={reqPrefs.hasIdea}
                    />
                  </Row>
                  <Row>
                    <Dropdown
                      fieldName="profileInput.interests"
                      label="Industry interests"
                      multiple
                      options={reqPrefs.interests}
                    />
                  </Row>
                  <Row>
                    <Dropdown
                      fieldName="profileInput.responsibilities"
                      label="Responsibilities"
                      multiple
                      options={reqPrefs.responsibilities}
                    />
                  </Row>
                  <Row>
                    <Dropdown
                      fieldName="profileInput.timing"
                      label="Timing"
                      multiple
                      options={[
                        ["active", "Working full-time on startup now"],
                        ["ready", "Ready within 3 months"],
                        ["year", "Ready within a year"],
                        ["passive", "Passively looking"],
                      ]}
                    />
                  </Row>
                  <Row>
                    {dateInput("profileInput.minCreated", "Min profile created date")}
                    {dateInput("profileInput.maxCreated", "Max profile created date")}
                    {dateInput("profileInput.minLastActive", "Min last active date")}
                    {dateInput("profileInput.maxLastActive", "Max last active date")}
                  </Row>
                  <Row>
                    {numberInput("profileInput.minRequestsSent", "Min # requests sent")}
                    {numberInput("profileInput.maxRequestsSent", "Max # requests sent")}
                    {numberInput("profileInput.minRequestsReceived", "Min # requests received")}
                    {numberInput("profileInput.maxRequestsReceived", "Max # requests received")}
                    {numberInput("profileInput.minMatches", "Min # matches")}
                    {numberInput("profileInput.maxMatches", "Max # matches")}
                  </Row>
                  <Row>
                    <Input
                      fieldName="profileInput.keyword"
                      label="Keyword (intro, education, employment, other, cf free text)"
                    />
                  </Row>
                </PaneItem>
              )}
              <PaneItem>
                <Submit hideStatusText centered>
                  <div css={css({ display: "flex", alignItems: "center" })}>
                    <Button type="submit" size="large" color="orange">
                      Search users & profiles
                    </Button>
                    {isSubmitting && <div css={css({ paddingLeft: 14 })}>Searching...</div>}
                  </div>
                </Submit>
              </PaneItem>
            </ConnectedForm>
          </Pane>
        </SearchContainer>
      </div>
      {adminUsers?.length && (
        <Container css={css({ marginTop: 25 })}>
          <a href={getCSVBlob()} download="users.csv">
            <Button size="large" css={css({ marginBottom: "25px !important" })}>
              Export
            </Button>
          </a>
          {adminUsers && (
            <span css={css({ marginLeft: 15 })}>
              {totalResults} results{(totalResults || 0) > 10_000 ? " (showing first 10,000)" : ""}
            </span>
          )}
          <Pane>
            <table cellSpacing={0}>
              <tbody>
                <tr>
                  <HeaderCell>Name</HeaderCell>
                  <HeaderCell>Email</HeaderCell>
                  <HeaderCell>Admin Profile</HeaderCell>
                  <HeaderCell>CFM Profile</HeaderCell>
                </tr>
                {adminUsers?.slice(0, 1_000).map((adminUser) => (
                  <tr key={adminUser.slug}>
                    <Cell>{adminUser.user.name}</Cell>
                    <Cell>{adminUser.email}</Cell>
                    <Cell>
                      {adminUser.adminPath && (
                        <a href={adminUser.adminPath} target="_blank">
                          {adminUser.adminPath}
                        </a>
                      )}
                    </Cell>
                    <Cell>
                      {adminUser.adminCfmProfile?.adminPath && (
                        <a href={adminUser.adminCfmProfile.adminPath} target="_blank">
                          {`.../${adminUser.adminCfmProfile.id}`}
                        </a>
                      )}
                    </Cell>
                  </tr>
                ))}
              </tbody>
            </table>
          </Pane>
        </Container>
      )}
    </div>
  );
}
