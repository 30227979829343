import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import CloseIcon from "@material-ui/icons/Close";

export enum TagType {
  bookmarked = "bookmarked",
  category = "category",
  curriculum = "curriculum",
  stage = "stage",
}

type Props = {
  label: string;
  tagType: TagType;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ignoreMargin?: boolean;
  showClose?: boolean;
};

const mapTypeToBackgroundColor = {
  bookmarked: "#FACFCF",
  category: "#EEEEEE",
  curriculum: "#FF8F4C",
  stage: "#CAF6FA",
};

const Tag = styled.div((props: { tagType: TagType; ignoreMargin: boolean }) => ({
  backgroundColor: mapTypeToBackgroundColor[props.tagType] || "#EEEEEE",
  borderRadius: "4px",
  color: props.tagType == TagType.curriculum ? "white" : "#616161",
  fontFamily: "Avenir",
  fontSize: "10px",
  margin: props.ignoreMargin ? 0 : "0 10px 10px 0",
  padding: "0 10px",
  textTransform: "uppercase",
  height: 20,
}));

export default function CurriculumItemTag({
  label,
  tagType,
  onClick,
  ignoreMargin = false,
  showClose = false,
}: Props) {
  return (
    <Tag tagType={tagType} ignoreMargin={ignoreMargin} onClick={onClick}>
      <div css={css({ display: "flex" })}>
        {label}
        {showClose && (
          <div css={css({ marginLeft: ".25rem", marginRight: "-.25rem" })}>
            <CloseIcon fontSize="small" />
          </div>
        )}
      </div>
    </Tag>
  );
}
