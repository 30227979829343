import React from "react";
import styled from "@emotion/styled";
import { REVIEW_EMAIL_PUSH_admin_emailCampaign_emailPush } from "./__generated__/REVIEW_EMAIL_PUSH";
import { REVIEW_ALL_EMAIL_PUSHES_admin_emailCampaign_emailPushes } from "./__generated__/REVIEW_ALL_EMAIL_PUSHES";

export const PreviewWrapper = styled.div`
  width: 100%;

  h4 {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const EmailField = styled.div`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
`;

const AutoLoginLinkAlert = styled.div`
  width: 100%;
  text-align: center;
  color: red;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  font-style: italic;
`;

type Props = {
  emailPush:
    | REVIEW_EMAIL_PUSH_admin_emailCampaign_emailPush
    | REVIEW_ALL_EMAIL_PUSHES_admin_emailCampaign_emailPushes;
};

export default ({ emailPush }: Props) => (
  <PreviewWrapper>
    <h4>From:</h4>
    <EmailField>{emailPush.fromEmail}</EmailField>
    {!!emailPush.replyToEmail && (
      <>
        <h4>Reply-To:</h4>
        <EmailField>{emailPush.replyToEmail}</EmailField>
      </>
    )}
    <h4>Subject:</h4>
    <EmailField>{emailPush.subjectPreview}</EmailField>
    <h4>Email body (formatted for a user):</h4>
    <EmailField>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: emailPush.emailBodyUserPreview }} />
    </EmailField>
    {emailPush.autoLoginLinks && (
      <AutoLoginLinkAlert>Note: this email contains auto-login links</AutoLoginLinkAlert>
    )}
  </PreviewWrapper>
);
