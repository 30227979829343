import React from "react";
import YouTube from "react-youtube";
import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { MarkdownComponent } from "@yc/shared/renderMarkdown";
import { mobileStyles } from "../../styles";
import { Container, Page } from "../../components/Page";
import { CURRICULUM_ITEM } from "./__generated__/CURRICULUM_ITEM";
import { getSlugFromWindowLocation } from "../../components/forms/util";
import Button from "../../components/statelessForms/Button";
import CurriculumItemFeedback from "./CurriculumItemFeedback";
import { CurriculumPreviewImage } from "./CurriculumItem";
import { Pane, PaneItem } from "../../components/Pane";

type ProgressBarProps = {
  numCompleted: number;
  numTotal: number;
  fullWidth?: boolean;
};

const CenteredContent = styled.div`
  width: 100%;
  text-align: center;
`;

const IframeContainer = styled.div`
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ArticleIframe = styled.iframe`
  border: 1px solid #c0c0c0;
`;

const DescriptionBody = styled.div`
  padding: 0 10px;
  font-size: 18px;

  h3 {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: gray;
    margin-bottom: 15px;
    font-weight: bold;
    margin-top: 0;
  }
`;

const Author = styled.h4`
  font-size: 14px;
  color: gray;
  font-style: italic;
  font-weight: 300;
  margin-top: 0;
`;

const FeedbackContainer = styled.div`
  text-align: center;
`;

const NextModuleName = styled.div`
  width: 100%;
  text-align: center;
  background-color: darkgray;
  color: white;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ExtraLargeButton = withStyles({
  root: {
    width: "100%",
    height: 70,
    fontSize: 20,
    letterSpacing: 1,
    margin: "20px 0",
  },
})(Button);

export const ProgressBar = ({ numCompleted, numTotal, fullWidth = false }: ProgressBarProps) => (
  <div
    css={css({
      backgroundColor: "#b1e0ae",
      height: "12px",
      width: fullWidth ? "100%" : "75%",
      ...mobileStyles({ width: "100%" }),
    })}
  >
    <div
      css={css({
        backgroundColor: "#7bcf56",
        height: "100%",
        width: `${(100 * numCompleted) / numTotal}%`,
      })}
    />
  </div>
);

export default function CurriculumItemPage(): React.ReactElement {
  const slug = getSlugFromWindowLocation();
  const { data } = useQuery<CURRICULUM_ITEM>(
    gql`
      query CURRICULUM_ITEM($slug: ID!) {
        curriculumItem(slug: $slug) {
          id
          title
          author
          description
          youtubeVideoId
          link
          markdownText
          moduleName
          orderInModule
          moduleLength
          curriculumItemFeedback {
            status
            rating
          }

          previousLesson {
            id
            title
            url
          }

          nextLesson {
            id
            title
            author
            url
            previewLink
            moduleName
          }
        }
      }
    `,
    { variables: { slug } }
  );

  if (!data?.curriculumItem?.id) {
    return <div />;
  }

  const youtubeVideoOpts = {
    playerVars: {
      autoplay: 1,
      rel: 0,
    },
  };

  const isLibraryArticle =
    !data?.curriculumItem?.youtubeVideoId &&
    data?.curriculumItem?.link?.includes("ycombinator.com/library");

  const isPGEssay =
    !data?.curriculumItem?.youtubeVideoId && data?.curriculumItem?.link?.includes("paulgraham.com");

  const externalLinkText = `Read this article ${
    isPGEssay ? "on Paul Graham's blog" : "in the YC Library"
  }`;

  return (
    <Page
      title={data.curriculumItem.moduleName || data.curriculumItem.title}
      subtitle={
        data.curriculumItem.moduleName
          ? `Lesson ${data.curriculumItem.orderInModule} / ${data.curriculumItem.moduleLength}`
          : ""
      }
    >
      <Container>
        <h2 css={css({ marginBottom: 0, fontSize: 24, fontWeight: 500 })}>
          {data.curriculumItem.title}
        </h2>
        <Author>by {data.curriculumItem.author}</Author>

        <CenteredContent>
          {data.curriculumItem.markdownText && (
            <Pane>
              <PaneItem>
                <MarkdownComponent
                  css={css({ textAlign: "left" })}
                  content={data.curriculumItem.markdownText}
                />
              </PaneItem>
            </Pane>
          )}
          {isLibraryArticle || isPGEssay ? (
            <a href={data.curriculumItem.link || undefined} target="_blank">
              {data.curriculumItem.markdownText ? (
                <div css={css({ display: "flex", alignItems: "center", justifyContent: "center" })}>
                  <OpenInNewIcon />
                  <span css={css({ marginLeft: 10 })}>{externalLinkText}</span>
                </div>
              ) : (
                <ExtraLargeButton color="orange" fullWidth>
                  <OpenInNewIcon />
                  <span css={css({ marginLeft: 10 })}>{externalLinkText}</span>
                </ExtraLargeButton>
              )}
            </a>
          ) : (
            <IframeContainer>
              {data.curriculumItem.youtubeVideoId ? (
                <YouTube videoId={data.curriculumItem.youtubeVideoId} opts={youtubeVideoOpts} />
              ) : (
                <ArticleIframe src={data.curriculumItem.link || undefined} />
              )}
            </IframeContainer>
          )}
        </CenteredContent>

        <div css={css({ marginTop: 20 })}>
          <Pane>
            <PaneItem>
              <DescriptionBody>
                <h3>About this content</h3>
                {data.curriculumItem.description}
              </DescriptionBody>
            </PaneItem>
            <PaneItem>
              <FeedbackContainer>
                <CurriculumItemFeedback
                  centered
                  row
                  curriculumItemId={Number.parseInt(data.curriculumItem.id, 10)}
                  feedback={data.curriculumItem.curriculumItemFeedback}
                />
              </FeedbackContainer>
            </PaneItem>
          </Pane>
        </div>

        <Pane>
          {data.curriculumItem.nextLesson?.moduleName && (
            <NextModuleName>{data.curriculumItem.nextLesson.moduleName}</NextModuleName>
          )}
          <PaneItem>
            <div css={css({ textAlign: "center", marginBottom: 20 })}>
              <h3>
                {data.curriculumItem.nextLesson
                  ? `Next up: ${data.curriculumItem.nextLesson.title}`
                  : "You've reached the end of the Startup School curriculum!"}
              </h3>
              {data.curriculumItem.nextLesson?.author && (
                <Author>by {data.curriculumItem.nextLesson.author}</Author>
              )}
            </div>
            {data.curriculumItem.nextLesson?.previewLink && (
              <CurriculumPreviewImage centered link={data.curriculumItem.nextLesson.previewLink} />
            )}
            <ButtonRow>
              {data.curriculumItem.previousLesson ? (
                <a href={data.curriculumItem.previousLesson.url}>
                  <Button color="gray">
                    <ChevronLeft />
                    Previous lesson
                  </Button>
                </a>
              ) : (
                <div />
              )}
              {data.curriculumItem.nextLesson ? (
                <a href={data.curriculumItem.nextLesson.url}>
                  <Button color="orange">
                    Next lesson
                    <ChevronRight />
                  </Button>
                </a>
              ) : (
                <div />
              )}
            </ButtonRow>
          </PaneItem>
        </Pane>
      </Container>
    </Page>
  );
}
