import React from "react";
import { useFormContext, useController } from "react-hook-form";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";
import S3FileUpload, { S3FileUploadProps } from "../statelessForms/S3FileUpload";

type Props = CustomFieldProps<S3FileUploadProps>;

export default (props: Props) => {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { required: fieldProps.required },
  });

  return (
    <Field {...fieldProps}>
      <S3FileUpload {...otherProps} value={field.value} onChange={(url) => field.onChange(url)} />
    </Field>
  );
};
