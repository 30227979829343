import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import Button from "../../components/Button";
import { Container, Page } from "../../components/Page";
import { signUpUsersPath, sprintRegisterPath } from "../../__generated__/routes";
import Requirements from "./Requirements";
import {
  REGISTER_FOR_SPRINT,
  REGISTER_FOR_SPRINTVariables,
} from "./__generated__/REGISTER_FOR_SPRINT";
import { SPRINT_REGISTER } from "./__generated__/SPRINT_REGISTER";

export default function SprintRegister() {
  const { data } = useQuery<SPRINT_REGISTER>(
    gql`
      query SPRINT_REGISTER {
        currentUser {
          slug
          track
        }

        latestSprint {
          id
          name
          startDate
          endDate
          totalWeeks
          registrationOpen
        }
      }
    `
  );

  const [registerMutation] = useMutation<REGISTER_FOR_SPRINT, REGISTER_FOR_SPRINTVariables>(gql`
    mutation REGISTER_FOR_SPRINT($sprintId: ID!) {
      registerForSprint(sprintId: $sprintId) {
        continue
      }
    }
  `);

  const register = async () => {
    const resp = await registerMutation({ variables: { sprintId: data!.latestSprint.id } });
    if (resp.data) {
      window.location.href = resp.data.registerForSprint.continue;
    }
  };

  const sprintDescription = (
    <>
      <p>
        Registration is now open for the {data?.latestSprint.name} YC Build Sprint. The Build Sprint
        is an opportunity for intense focus on your startup or side-project for a specific period of
        time. For existing startups, this may involve a push towards launching a specific feature or
        reaching a certain milestone. For new founders, this is an opportunity to take the plunge
        and begin work on a project that excites you.
      </p>
      <p>
        At the start of the sprint, you’ll register your {data?.latestSprint.totalWeeks}-week goal.
        As the sprint progresses, you’ll be able to track your progress, get feedback from YC group
        partners and team members, and have weekly meetings with other sprint participants to
        provide accountability. The sprint culminates with the{" "}
        <a href="https://www.ycombinator.com/apply" target="_blank">
          YC application deadline
        </a>
        .
      </p>
      <h2>Schedule</h2>
      <p>
        <strong>Start date:</strong> {data?.latestSprint.startDate}
        <br />
        <strong>End date:</strong> {data?.latestSprint.endDate}
      </p>
      <Requirements />
    </>
  );

  let content = null;

  if (data?.latestSprint.registrationOpen === false) {
    content = (
      <>
        <p>
          Sorry, sprint registration is currently closed. You can still sign up for Startup School
          though to enjoy many of the benefits associated with the sprint.
        </p>
        <Button
          css={css({ marginTop: 15 })}
          type="cta"
          href={signUpUsersPath()}
          text="Sign up for Startup School"
        />
      </>
    );
  } else if (data?.currentUser === null) {
    content = (
      <>
        {sprintDescription}
        <p>Before registering for the sprint, you'll need to create a Startup School profile.</p>
        <Button
          css={css({ marginTop: 15 })}
          type="cta"
          href={signUpUsersPath({ continue_url: sprintRegisterPath() })}
          text="Sign up for Startup School"
        />
      </>
    );
  } else if (data?.currentUser.track === "aspiring_founder") {
    content = (
      <>
        {sprintDescription}
        <p>
          To sign up for the sprint, you'll need to switch to the Active Founder track (you're
          currently registered up as a Aspiring Founder).
        </p>
        <Button
          css={css({ marginTop: 15 })}
          type="cta"
          href="/track"
          target="_blank"
          text="Switch Track"
        />
      </>
    );
  } else if (data?.currentUser.track === "active_founder") {
    content = (
      <>
        {sprintDescription}
        <Button
          css={css({ marginTop: 15 })}
          onClick={register}
          type="cta"
          text="I'm ready! Register now"
        />
      </>
    );
  } else {
    content = (
      <p>
        Sorry, there has been an error rendering the sprint registration page. Please{" "}
        <a href="mailto:startupschool@ycombinator.com">let us know ASAP!</a>
      </p>
    );
  }

  return (
    <Page title={`YC Build Sprint ${data?.latestSprint.name}`}>
      <Container>
        <div
          css={css({
            padding: "50px 0",
            maxWidth: 600,
          })}
        >
          {data && content}
        </div>
      </Container>
    </Page>
  );
}
