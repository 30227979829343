import { css } from "@emotion/core";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { groupSessionsPath, updatesPath } from "../../__generated__/routes";

const Requirement: React.FC<{ title: string }> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div css={css({ marginLeft: 14, marginBottom: 14 })}>
      <span
        css={css({ cursor: "pointer", display: "flex", alignItems: "center" })}
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        {props.title}
      </span>
      {open && (
        <div css={css({ marginLeft: 23, marginBottom: 14, fontStyle: "italic" })}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export default () => (
  <>
    <h2>Make the most of the Build Sprint</h2>
    <Requirement title="Choose a goal">
      Your goal should be specific, measurable, and hard but not impossible. It should also be
      related to your{" "}
      <a
        href="https://www.ycombinator.com/library/6j-how-to-set-kpis-and-goals-sus-2019"
        target="_blank"
      >
        primary metric
      </a>
      .
    </Requirement>
    <Requirement title="Submit four weekly updates">
      <a href={updatesPath()} target="_blank">
        Updates
      </a>{" "}
      help you track your progress over time and focus on what matters.
    </Requirement>
    <Requirement title="Add a weekly update follower">
      <p>
        <em>Followers</em> will get an email every time you submit a new update. Having followers
        will motivate you to get more done each week.
      </p>
      <p>You can add followers from the update edit form.</p>
    </Requirement>
    <Requirement title="Attend a group session">
      <a href={groupSessionsPath()} target="_blank">
        Group sessions
      </a>{" "}
      are 45-minute structured calls with 2-3 other founders. They're an opportunity to build
      community, get advice and practice your pitch. You can sign up for a group session each week
      when you submit your weekly update.
    </Requirement>
  </>
);
