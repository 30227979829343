import React, { useEffect, useState } from "react";
import { differenceInSeconds, isBefore } from "date-fns";
import styled from "@emotion/styled";

const CountdownWrapper = styled.div`
  text-align: center;

  div {
    color: #fb651e;
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

const Countdown = styled.div`
  font-weight: bold;
  text-align: center;
  color: #fb651e;
  font-size: 36px;
`;

type Props = {
  targetTime: Date;
};
export default ({ targetTime }: Props) => {
  const getSecsToTime = () => {
    const now = new Date();
    if (isBefore(targetTime, now)) {
      return 0;
    }

    return differenceInSeconds(targetTime, now);
  };

  const [secondsRemaining, setSecondsRemaining] = useState(getSecsToTime());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSecondsRemaining(getSecsToTime());
    }, 1000);

    return () => clearInterval(timerInterval);
  });

  if (!secondsRemaining) {
    return null;
  }

  const minutesRemaining = Math.floor(secondsRemaining / 60);
  const remainderSeconds = secondsRemaining % 60;
  const formattedSecondsRemaining =
    remainderSeconds < 10 ? `0${remainderSeconds}` : remainderSeconds;

  return (
    <CountdownWrapper>
      <Countdown>{`${minutesRemaining}:${formattedSecondsRemaining}`}</Countdown>
    </CountdownWrapper>
  );
};
