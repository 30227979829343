import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "@emotion/styled";

type Props = {
  loading?: boolean;
  onSubmit?: () => {};
};

const CenteredContent = styled.div`
  width: 100%;
  text-align: center;
`;

const Form: React.FC<Props> = ({ loading, onSubmit, children }) => {
  if (loading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    );
  }
  return <form onSubmit={onSubmit}>{children}</form>;
};

export default Form;
