import React from "react";

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { css } from "@emotion/core";
import { CSSObject } from "@emotion/styled";
import { colors } from "../../styles";
import "pure-react-carousel/dist/react-carousel.es.css";

/* eslint-disable global-require */

const arrowStyle: CSSObject = {
  backgroundColor: "transparent",
  color: colors.mediumText,
  height: "100%",
  display: "flex",
  position: "absolute",
  top: 0,
  padding: "0 40px",
  border: "none",
  zIndex: 1,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  "&:focus": {
    outline: "none",
  },
};

const photoStyle: CSSObject = {
  boxShadow: "0 0 54px 0 rgba(0, 0, 0, 0.3)",
  objectFit: "fill",
  position: "absolute",
};

type Founder = {
  name: string;
  image: string;
};

type TestimonialProps = {
  title: string;
  founders: Founder[];
  quote: string;
};

function TestimonialStory({ founders, title, quote }: TestimonialProps): React.ReactElement {
  const imagePaths = founders.map((founder) => `${window.location.origin}/images/${founder.image}`);

  const multipleFounders = founders.length > 1;

  return (
    <div
      css={css({
        display: "flex",
        minHeight: "650px",
        width: "100%",
      })}
    >
      <div
        css={css({
          padding: "120px 100px 120px 143px",
          textAlign: "left",
          width: multipleFounders ? "60%" : "67%",
        })}
      >
        <div
          css={css({
            color: "#1a1a1a",
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "1.5",
            marginBottom: "20px",
          })}
        >
          {title}
        </div>
        <div
          css={css({
            color: colors.mediumText,
            fontSize: "18px",
            lineHeight: "2",
            marginBottom: "1.5em",
          })}
        >
          "{quote}"
        </div>
        <div css={css({ marginBottom: "20px" })}>
          <div
            css={css({
              color: colors.mediumText,
              fontSize: "24px",
              fontWeight: "bold",
              letterSpacing: "-0.24",
              lineHeight: "1.5",
              marginLeft: "auto",
              width: "fit-content",
              whiteSpace: "nowrap",
            })}
          >
            - {multipleFounders ? `${founders[0].name} and ${founders[1].name}` : founders[0].name}
          </div>
        </div>
      </div>
      <div css={css({ position: "relative", width: multipleFounders ? "40%" : "33%" })}>
        {multipleFounders ? (
          <div css={css({ display: "flex", flexDirection: "column" })}>
            <div
              css={css({
                height: 200,
                width: 200,
                top: 130,
                zIndex: 2,
                ...photoStyle,
              })}
            >
              <img src={imagePaths[0]} height={200} width={200} alt="cofounder 1 headshot" />
            </div>
            <div
              css={css({
                height: 200,
                width: 200,
                top: 250,
                left: 170,
                ...photoStyle,
              })}
            >
              <img src={imagePaths[1]} height={200} width={200} alt="cofounder 2 headshot" />
            </div>
          </div>
        ) : (
          <div
            css={css({
              height: 230,
              width: 230,
              top: 160,
              ...photoStyle,
            })}
          >
            <img src={imagePaths[0]} height={230} width={230} alt="founder headshot" />
          </div>
        )}
      </div>
    </div>
  );
}

type Props = {
  stories: {
    founders: Founder[];
    quote: string;
  }[];
};

export default function TestimonialCarousel({ stories }: Props): React.ReactElement {
  const cfm = stories[0].founders.length > 1;

  return (
    <CarouselProvider
      naturalSlideWidth={1000}
      naturalSlideHeight={600}
      totalSlides={stories.length}
      infinite
    >
      <Slider>
        {stories.map((story, i) => (
          <Slide index={i} key={story.founders[0].name}>
            <TestimonialStory
              title={cfm ? "Co-Founder Testimonials" : "Alumni Stories"}
              founders={story.founders}
              quote={story.quote}
            />
          </Slide>
        ))}
      </Slider>
      <ButtonBack css={css({ left: 0, ...arrowStyle })}>
        <div css={css({ margin: "auto" })}>
          <KeyboardArrowLeftIcon fontSize="large" />
        </div>
      </ButtonBack>
      <ButtonNext css={css({ right: 0, ...arrowStyle })}>
        <div css={css({ margin: "auto" })}>
          <KeyboardArrowRightIcon fontSize="large" />
        </div>
      </ButtonNext>
    </CarouselProvider>
  );
}
