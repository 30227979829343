import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import {
  AdminBaseProfileFragment,
  AdminCFMProfileFragment,
  RecentMessagesSentFragment,
  AdminBaseUserFragment,
  UserAdminActionFragment,
  BadActorFragment,
  BaseAdminUserFragment,
  AdminUserStubFragment,
} from "../cofounderMatching/fragments";
import RateUser from "./RateUser";
import SingleAdminProfile from "./SingleAdminCofounderProfile";
import { ADMIN_USERS_TO_REVIEW } from "./__generated__/ADMIN_USERS_TO_REVIEW";

export default function ReviewUsers() {
  const { data, loading } = useQuery<ADMIN_USERS_TO_REVIEW>(
    gql`
      query ADMIN_USERS_TO_REVIEW {
        currentUser {
          slug
          superAdmin
        }

        admin {
          usersToReview {
            ...BaseAdminUserFragment

            user {
              ...AdminBaseUserFragment
            }

            badActorReports {
              ...BadActorFragment
            }

            adminUserStub {
              ...AdminUserStubFragment
            }

            votes {
              ...UserAdminActionFragment
            }

            ycApps {
              id
              batch
              result
              url
            }

            adminCfmProfile {
              ...AdminCFMProfileFragment

              recentMessagesSent {
                ...RecentMessagesSentFragment
              }

              profile {
                ...AdminBaseProfileFragment
              }
            }
          }
        }
      }
      ${BaseAdminUserFragment}
      ${AdminCFMProfileFragment}
      ${RecentMessagesSentFragment}
      ${AdminBaseProfileFragment}
      ${AdminBaseUserFragment}
      ${UserAdminActionFragment}
      ${BadActorFragment}
      ${AdminUserStubFragment}
    `
  );

  const superAdmin = data?.currentUser?.superAdmin;
  const adminUsers = data?.admin.usersToReview;
  if (!adminUsers) {
    return null;
  }

  let remainingCount: string | number =
    adminUsers?.filter((u) => u.needsAdminReview).length ?? "error!";
  if (loading) remainingCount = "...";

  return (
    <div css={css({ margin: -100 })}>
      <Page title={`${remainingCount} Users to Review`}>
        <Container>
          <Pane>
            {adminUsers.map((adminUser) => (
              <PaneItem key={adminUser.slug}>
                {adminUser.adminCfmProfile ? (
                  <SingleAdminProfile
                    candidate={adminUser.adminCfmProfile}
                    allVotes={adminUser.votes}
                    superAdmin={superAdmin}
                    toastOnVote
                  />
                ) : (
                  <RateUser adminUser={adminUser} toastOnVote />
                )}
              </PaneItem>
            ))}
          </Pane>
        </Container>
      </Page>
    </div>
  );
}
