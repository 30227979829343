import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { omit } from "lodash";
import MarkdownEditor, { EditorPropTypes } from "@yc/shared/editor/MarkdownEditor";
import styled from "@emotion/styled";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";
import WithCharCount from "../statelessForms/WithCharCount";

type CustomProps = {
  showCharCount?: boolean;
};

const MdComponentWrapper = styled.div`
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  width: 100%;
`;

const MarkdownTextarea = (
  props: CustomFieldProps<Omit<EditorPropTypes, "value"> & CustomProps>
) => {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);

  return (
    <Field
      {...fieldProps}
      input={
        <Controller
          name={props.fieldName}
          control={control}
          render={({ field: formProps }) =>
            props.showCharCount ? (
              <WithCharCount
                min={fieldProps.minLength}
                max={fieldProps.maxLength}
                currentLength={formProps.value?.length || 0}
              >
                <MdComponentWrapper>
                  <MarkdownEditor
                    className="form-control body"
                    {...otherProps}
                    {...omit(formProps, "ref")}
                    isControlled
                  />
                </MdComponentWrapper>
              </WithCharCount>
            ) : (
              <MdComponentWrapper>
                <MarkdownEditor
                  className="form-control body"
                  {...otherProps}
                  {...omit(formProps, "ref")}
                  isControlled
                />
              </MdComponentWrapper>
            )
          }
        />
      }
    />
  );
};

export default MarkdownTextarea;
