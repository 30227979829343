import React, { useState } from "react";
import styled from "@emotion/styled";
import { gql, useMutation } from "@apollo/client";
import Button from "../../../components/statelessForms/Button";
import { BULK_SET_NO_SHOW, BULK_SET_NO_SHOWVariables } from "./__generated__/BULK_SET_NO_SHOW";

type Props = {
  meetupId: string;
};

const LinkButton = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export default ({ meetupId }: Props) => {
  const [isShown, setIsShown] = useState(false);

  const [bulkSetNoShow] = useMutation<BULK_SET_NO_SHOW, BULK_SET_NO_SHOWVariables>(gql`
    mutation BULK_SET_NO_SHOW($meetupId: ID!, $inverted: Boolean) {
      bulkSetNoShow(meetupId: $meetupId, inverted: $inverted) {
        id
        noShow
        checkedIn
      }
    }
  `);

  const onSetNoShow = async (inverted: boolean) => {
    await bulkSetNoShow({ variables: { meetupId, inverted } });
  };

  return isShown ? (
    <ButtonRow>
      <Button onClick={() => onSetNoShow(false)}>
        <div>
          <div>Mark all as no-show</div>
          <div>
            <i>(excluding all checked-in)</i>
          </div>
        </div>
      </Button>
      <Button onClick={() => onSetNoShow(true)}>
        <div>
          <div>Mark all as checked in</div>
          <div>
            <i>(excluding all no-shows)</i>
          </div>
        </div>
      </Button>
      <LinkButton onClick={() => setIsShown(false)}>Cancel</LinkButton>
    </ButtonRow>
  ) : (
    <LinkButton onClick={() => setIsShown(true)}>Bulk actions</LinkButton>
  );
};
