import React, { ChangeEvent, useState } from "react";
import uploadToS3 from "@yc/shared/editor/tiptap/uploads/upload";
import styled, { CSSObject } from "@emotion/styled";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "@emotion/core";

const MAX_WIDTH = 600;

export type S3FileUploadProps = {
  value?: string | undefined;
  onChange?: (fileUrl: string | null) => void;
  accept?: string;
  hideImagePreview?: boolean;
  maxSizeMB?: number;
  setUploading?: (isUploading: boolean) => void;
  previewDimensions?: [number, number];
  rounded?: boolean;
};

const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  padding-left: 0;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const PreviewImgWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const PreviewImg = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  text-align: center;
`;

const S3FileUpload = ({
  value,
  onChange,
  accept,
  hideImagePreview,
  maxSizeMB,
  setUploading,
  previewDimensions,
  rounded,
}: S3FileUploadProps) => {
  const [isUploading, setIsUploading] = useState(false);

  const updateUploading = (uploading: boolean) => {
    setIsUploading(uploading);
    if (setUploading) {
      setUploading(uploading);
    }
  };

  const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || !files.length) {
      return;
    }

    const file = files[0];

    if (!!maxSizeMB && file.size > maxSizeMB * 1024 * 1024) {
      toast.error(`File size must be smaller than ${maxSizeMB}MB`);
      return;
    }

    updateUploading(true);
    const fileUpload = await uploadToS3(file, { upload_type: "forum" });
    if (onChange) {
      onChange(fileUpload.src);
    }
    updateUploading(false);
  };

  const multiplier =
    previewDimensions && MAX_WIDTH < previewDimensions[0]
      ? (1.0 * MAX_WIDTH) / previewDimensions[0]
      : 1;
  const previewStyle: CSSObject = previewDimensions
    ? {
        objectFit: "cover",
        width: previewDimensions[0] * multiplier,
        height: previewDimensions[1] * multiplier,
      }
    : {};

  if (rounded) {
    previewStyle.borderRadius = "50%";
  }

  return (
    <div>
      {isUploading && (
        <div css={css({ margin: "20px 0" })}>
          <CircularProgress />
        </div>
      )}
      {!hideImagePreview && !!value && !isUploading && (
        <PreviewImgWrapper>
          <PreviewImg src={value} css={css(previewStyle)} alt="photo" />
        </PreviewImgWrapper>
      )}
      <div>
        <input type="file" accept={accept} onChange={onUpload} />
      </div>
      {!!value && (
        <DeleteButton
          onClick={() => {
            if (onChange) onChange(null);
          }}
        >
          Delete image
        </DeleteButton>
      )}
    </div>
  );
};

export default S3FileUpload;
