import { css } from "@emotion/core";
import React, { useCallback, useState } from "react";
import Joyride from "react-joyride";
import { colors, mobileStyles } from "../../styles";
import { Container, Header as PageHeader } from "../../components/Page";
import { CompletableAction } from "../../types/graphqlTypes";
import Button from "../../components/Button";
import { setNavOpen } from "../../components/navigation";
import useCompleteAction from "./useCompleteAction";
import { DASHBOARD_DATA } from "./__generated__/DASHBOARD_DATA";

const Tour: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <Joyride
    steps={[
      {
        target: "#dashboard_tab_anchor",
        title: "Dashboard",
        content: (
          <span>
            Welcome to Startup School! You're on the dashboard, where you can get an overview of
            your progress.
          </span>
        ),
        disableBeacon: true,
      },
      {
        target: "#updates_tab_anchor",
        title: "Weekly Updates",
        content: (
          <div>
            <p>
              The weekly update is the heartbeat of your Startup School experience and keeps you
              focused on what matters most.
            </p>
          </div>
        ),
      },
      {
        target: "#curriculum_tab_anchor",
        title: "Curriculum",
        content:
          "You'll be assigned 15-30 pieces of curriculum based on your company profile. The YC partners who created the curriculum have cumulatively advised thousands of startups and condensed their learnings here.",
      },
      {
        target: "#forum_tab_anchor",
        title: "Forum",
        content: "The community home is on the forum. Get and give advice and meet other founders.",
      },
      {
        target: "#aspiring_founders_pane_header",
        title: "Track Progress",
        content:
          "Curriculum is organized into modules. You can use this pane to track your progress through the modules.",
      },
      {
        target: "#other_todos_pane",
        content:
          "Finally, if there's a big announcement or something else we think you'll get value out of, we'll show it here!",
      },
    ]}
    callback={(state) => {
      if (state.action === "reset") onClose();

      const targetIsNav =
        state.action !== "reset" &&
        typeof state.step.target === "string" &&
        state.step.target.endsWith("_tab_anchor");
      setNavOpen(targetIsNav);
    }}
    run
    continuous
    locale={{ last: "Finish" }}
    disableScrollParentFix
    styles={{
      options: {
        primaryColor: colors.brandPrimary,
      },
      tooltipTitle: {
        fontSize: "1.25rem",
        fontWeight: "bold",
      },
      tooltipContent: {
        textAlign: "left",
        fontSize: "1rem",
      },
      buttonNext: { padding: "10px 15px", borderRadius: 6 },
    }}
  />
);

const Header: React.FC<{ data: DASHBOARD_DATA | undefined }> = ({ data }) => {
  const [tourComplete, setTourComplete] = useState<boolean>(false);
  const showTour = data && !data.completedActions.includes(CompletableAction.dashboard_tour);
  const [tourActive, setTourActive] = useState<boolean>(false);
  const completeAction = useCompleteAction();

  const finishTour = useCallback(async () => {
    setTourComplete(true);
    await completeAction(CompletableAction.dashboard_tour);
  }, [setTourComplete]);

  return (
    <PageHeader>
      <Container
        css={css({
          display: "flex",
          alignItems: "center",
          ...mobileStyles({
            flexDirection: "column",
            justifyContent: "center",
          }),
        })}
      >
        <h1 css={css({ flex: 1 })}>
          {data &&
            `Welcome ${data.currentUser?.returningUser ? " back, " : ""}${
              data.currentUser?.firstName
            }!`}
        </h1>
        {showTour && (
          <div
            css={css({
              opacity: tourComplete ? 0 : 1,
              transition: "all 0.5s ease",
              textAlign: "center",
              ...mobileStyles({ marginTop: 20 }),
            })}
          >
            New to SUS?
            <Button
              css={css({ marginLeft: 14 })}
              type="cta"
              text="Take the Tour!"
              onClick={() => setTourActive(true)}
            />
            {tourActive && <Tour onClose={finishTour} />}
          </div>
        )}
      </Container>
    </PageHeader>
  );
};

export default Header;
