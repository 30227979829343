import React, { useContext } from "react";
import { FormControl } from "@material-ui/core";
import { Section, Update, UpdateContext } from "./shared";
import Select from "../../components/statelessForms/Select";

const ExperimentalQuestions: React.FC<{
  setPendingUpdate: (update: Update) => void;
}> = ({ setPendingUpdate }) => {
  const { experimental_questions, pendingUpdate } = useContext(UpdateContext);

  const experimentalQuestions = Object.entries(experimental_questions)
    .filter(([, { active }]) => active)
    .map(([key, options]) => ({
      key,
      ...options,
      value: pendingUpdate.experimental_questions[key],
    }));

  if (experimentalQuestions.length === 0) return null;

  return (
    <Section title="Experimental Questions">
      <p>We use this section to experiment with grouping companies along different dimensions.</p>

      {experimentalQuestions.map((q) => (
        <FormControl fullWidth>
          <label>{q.name}</label>
          <Select
            value={pendingUpdate.experimental_questions[q.key]}
            options={q.options.map((o) => [o, o])}
            onChange={(newValue: any) =>
              setPendingUpdate({
                ...pendingUpdate,
                experimental_questions: {
                  ...pendingUpdate.experimental_questions,
                  [q.key]: newValue as string,
                },
              })
            }
          />
        </FormControl>
      ))}
    </Section>
  );
};

export default ExperimentalQuestions;
