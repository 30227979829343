import { css } from "@emotion/core";
import styled from "@emotion/styled";
import authorizedFetch from "@yc/shared/authorizedFetch";
import { pick } from "lodash";
import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Grid,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  Switch,
  withStyles,
} from "@material-ui/core";
import Button from "../../components/Button";
import InfoCard from "../../components/InfoCard";
import { Page } from "../../components/Page";
import Input, { TextArea } from "../../components/statelessForms/Input";
import { Provider } from "../../graphql";
import { updatesPath } from "../../__generated__/routes";
import ExperimentalQuestions from "./ExperimentalQuestions";
import FollowersSection from "./FollowersSection";
import Goals from "./Goals";
import { AvailableMetric, ContextProps, Section, Update, UpdateContext } from "./shared";
import SprintSection from "./SprintSection";
import SpacedGrid from "../../components/statelessForms/SpacedGrid";
import { StyledFormLabel } from "../../components/forms/Field";
import Select, { ExtendedSelectValueType } from "../../components/statelessForms/Select";

type Props = ContextProps & {
  update: Update;
  can_edit: boolean;
  stage: string;
};

const MORALE_OPTIONS = [
  "1 – We're totally burned out",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10 – We couldn't be more excited and optimistic!",
].map((text) => [parseInt((text.match(/\d+/) || ["0"])[0], 10), text]);

const UnderField = styled.div({
  marginTop: "0.5em",
  fontSize: "0.8em",
});

const Content = styled.div({
  width: "100%",
  maxWidth: 900,
  margin: "0 auto",
  padding: 25,
});

const FormControlRow = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > label": {
      marginRight: "1em !important",
    },
  },
})(FormControl);

export default function UpdateEdit(props: Props) {
  const [pendingUpdate, setPendingUpdate] = useState<Update>(props.update);
  const [errors, setErrors] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  // If you're not launched, the only valid metric is "weeks to launch".
  // If you're launched, you can choose anything else.
  const availableMetrics = Object.entries(props.metrics).filter(([key]) =>
    pendingUpdate.launched ? key !== "weeks" : key === "weeks"
  );

  useEffect(() => {
    // Make sure the metric type is always valid for the `launched` status of the update.

    const metricTypes = availableMetrics.map(([metric]) => metric);
    if (!pendingUpdate.metric_type || !metricTypes.includes(pendingUpdate.metric_type)) {
      setPendingUpdate({ ...pendingUpdate, metric_type: metricTypes[0] });
    }
  }, [pendingUpdate]);

  const selectProps = (field: keyof Update) => ({
    name: field,
    value: pendingUpdate[field] == null ? "" : (pendingUpdate[field] as any),
    onChange: (newValue: any) => setPendingUpdate({ ...pendingUpdate, [field]: newValue }),
    error: errors.includes(field),
  });

  const textFieldInputProps = (field: keyof Update) => ({
    name: field,
    value: pendingUpdate[field] == null ? "" : (pendingUpdate[field] as any),
    onChange: (entry: any) => setPendingUpdate({ ...pendingUpdate, [field]: entry.target.value }),
    error: errors.includes(field),
  });

  const numberInputProps = (field: keyof Update) => ({
    ...textFieldInputProps(field),
    type: "number",
    InputProps: {
      disableUnderline: true,
      inputProps: { min: 0 },
    },
  });

  const metricInfo: AvailableMetric | undefined =
    pendingUpdate.metric_type && Object.keys(props.metrics).includes(pendingUpdate.metric_type)
      ? props.metrics[pendingUpdate.metric_type || ""]
      : undefined;

  // Metric context field
  const metricContext = metricInfo && metricInfo.context;

  let metricContextInput: React.ReactNode = null;
  if (metricContext === "target_market") {
    metricContextInput = (
      <FormControl fullWidth required>
        <StyledFormLabel>What is your target market?</StyledFormLabel>
        <Select
          options={[
            ["Consumer", "Consumer"],
            ["SMB", "SMB (Small/Medium Business)"],
            ["Enterprise", "Enterprise"],
          ]}
          {...selectProps("metric_context")}
        />
      </FormControl>
    );
  } else if (metricContext != undefined) {
    metricContextInput = (
      <FormControl fullWidth required hiddenLabel={false}>
        <StyledFormLabel>{metricContext}</StyledFormLabel>
        <Input {...textFieldInputProps("metric_context")} />
      </FormControl>
    );
  }

  let metricNag: null | string = null;
  if (
    props.update.metric_type &&
    pendingUpdate.launched === props.update.launched &&
    pendingUpdate.metric_type !== props.update.metric_type &&
    Object.keys(props.metrics).includes(props.update.metric_type || "")
  ) {
    metricNag = `Your primary metric is the north star you use to measure your progress. You should only change your primary metric if the way you think about your company has substantially changed (previous primary metric: ${
      props.metrics[props.update.metric_type].short
    }).`;
  }

  let weeksNag: null | string = null;
  if (pendingUpdate.metric_type === "weeks" && pendingUpdate.metric_value) {
    weeksNag =
      pendingUpdate.metric_value < 5
        ? "Almost there, looking forward to your launch!"
        : "That's a long way out! Think hard: is there an MVP you could launch earlier?";
  }

  let usersNag: null | string = null;
  if (pendingUpdate.talked_to) {
    usersNag =
      pendingUpdate.talked_to < 5
        ? "Talking to users is the best way to ensure you're building the right thing. Don't neglect it!"
        : "Great job! Keep talking to your users.";
  }

  const metricField = (
    <Grid item xs>
      <FormControl fullWidth required>
        <StyledFormLabel>{(metricInfo || { long: null }).long}</StyledFormLabel>
        <Input {...numberInputProps("metric_value")} />
        {weeksNag && <UnderField>{weeksNag}</UnderField>}
      </FormControl>
    </Grid>
  );

  return (
    <UpdateContext.Provider
      value={{
        pendingUpdate,
        ...pick(
          props,
          "missed_sessions",
          "matching_factors",
          "group_session_available",
          "group_session_slots",
          "group_session_topic",
          "default_gs_slot",
          "metrics",
          "experimental_questions"
        ),
      }}
    >
      <Page title={pendingUpdate.formatted_date} nav={{ text: "Updates", href: updatesPath() }}>
        <Content>
          <form className="ui form">
            <SprintSection setPendingUpdate={setPendingUpdate} />
            <Section title="Launch">
              <Grid container alignItems="flex-start">
                <Grid item xs>
                  <FormControlRow fullWidth>
                    <StyledFormLabel>Are you launched?</StyledFormLabel>
                    <Switch
                      checked={pendingUpdate.launched}
                      onChange={(e) =>
                        setPendingUpdate({ ...pendingUpdate, launched: e.target.checked || false })
                      }
                    />
                    {!props.update.launched && pendingUpdate.launched && (
                      <FormLabel>🎉 Woohoo! Huge congrats on launching!</FormLabel>
                    )}
                  </FormControlRow>
                </Grid>
                {!pendingUpdate.launched && metricField}
              </Grid>
            </Section>
            <Section title="Users">
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  <FormControl fullWidth required>
                    <StyledFormLabel>
                      Users/prospective users talked to in the last week?
                    </StyledFormLabel>
                    <Input placeholder="0" {...numberInputProps("talked_to")} />
                    {usersNag && <UnderField>{usersNag}</UnderField>}
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <StyledFormLabel>What have you learned from them?</StyledFormLabel>
                    <Input {...textFieldInputProps("learned_from_users")} />
                  </FormControl>
                </Grid>
              </Grid>
            </Section>
            {pendingUpdate.launched && (
              <Section title="Primary Metric">
                <SpacedGrid container alignItems="flex-start" spacing={1}>
                  <Grid item xs>
                    <FormControl fullWidth required variant="outlined" size="small">
                      <StyledFormLabel>Choose your primary metric</StyledFormLabel>
                      <MuiSelect
                        renderValue={() =>
                          metricInfo ? metricInfo.long : pendingUpdate.metric_type || ""
                        }
                        value={pendingUpdate.metric_type}
                        css={css({ ".menu .header": { borderBottom: "1px solid #ccc" } })}
                        onChange={(e) =>
                          setPendingUpdate({
                            ...pendingUpdate,
                            metric_type: e.target.value as string,
                          })
                        }
                      >
                        {[
                          ["recurring_revenue", "Recurring revenue (in USD)"],
                          ["revenue", "Revenue (in USD)"],
                          ["engagement", "Engagement"],
                        ].map(([cat, description]) => {
                          const metrics = availableMetrics.filter(
                            ([, { category }]) => category === cat
                          );
                          return (
                            metrics && [
                              <ListSubheader>{description}</ListSubheader>,
                              ...metrics.map((m) => (
                                <MenuItem key={m[0]} value={m[0]}>
                                  {m[1].long}
                                </MenuItem>
                              )),
                            ]
                          );
                        })}
                      </MuiSelect>
                      {metricNag && <UnderField>{metricNag}</UnderField>}
                    </FormControl>
                  </Grid>
                  {metricField}
                </SpacedGrid>
                {metricContextInput}
              </Section>
            )}
            <Section title="Goals and Morale">
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <StyledFormLabel>On a scale of 1-10, what is your morale?</StyledFormLabel>
                    <Select
                      options={MORALE_OPTIONS as [ExtendedSelectValueType, string][]}
                      {...selectProps("morale")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <StyledFormLabel>What most improved your primary metric?</StyledFormLabel>
                    <TextArea {...textFieldInputProps("biggest_change")} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    required={pendingUpdate.group_session_slot !== null}
                    error={errors.includes("biggest_blocker")}
                  >
                    <StyledFormLabel>Biggest obstacle?</StyledFormLabel>
                    <TextArea {...textFieldInputProps("biggest_blocker")} />
                  </FormControl>
                </Grid>
              </Grid>
              <Goals setPendingUpdate={setPendingUpdate} />
            </Section>
            <ExperimentalQuestions setPendingUpdate={setPendingUpdate} />
            {props.can_edit && (
              <Provider>
                <FollowersSection />
              </Provider>
            )}
            {props.can_edit && (
              <Button
                type="cta"
                disabled={submitting}
                onClick={async () => {
                  setSubmitting(true);
                  const resp = await authorizedFetch(`/updates/${pendingUpdate.id || ""}`, {
                    method: pendingUpdate.id ? "PUT" : "POST",
                    body: JSON.stringify({ update: pendingUpdate }),
                  });
                  const body = await resp.json();

                  if (body.errors) {
                    setErrors(body.errors);
                    setSubmitting(false);
                  } else {
                    window.location = body.next;
                  }
                }}
                text="Submit"
              />
            )}
            {errors.length > 0 && !submitting && (
              <InfoCard type="negative">Please review the errors above and submit again.</InfoCard>
            )}
          </form>
        </Content>
      </Page>
    </UpdateContext.Provider>
  );
}
