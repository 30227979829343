import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import { css } from "@emotion/core";
import Button from "../../components/statelessForms/Button";
import { Textarea } from "../../components/forms";
import { GROUP_SESSION_groupSession_feedback } from "./__generated__/GROUP_SESSION";
import Rating from "../../components/forms/Rating";

type Props = {
  feedbackOpen: boolean;
  feedback?: GROUP_SESSION_groupSession_feedback | null | undefined;
  submitting?: boolean;
};

export default function Feedback({ feedbackOpen, feedback, submitting }: Props) {
  return (
    <div>
      <Rating fieldName="usefulness" label="How useful was today's discussion?" />
      <Rating fieldName="callQuality" label="How clear was the audio/video?" />
      <Textarea
        label="Any other feedback for us?"
        fieldName="textFeedback"
        placeholder="What went well or didn't go well? What could've gone better?"
      />
      <div css={css({ marginTop: 20, display: "flex", alignItems: "center" })}>
        <Button disabled={!feedbackOpen || submitting} type="submit" color="orange">
          <div>{feedback?.feedbackSubmitted ? "Update" : "Submit"}</div>
        </Button>
        <div css={css({ marginLeft: "10px" })} />
        {submitting && (
          <div>
            <CircularProgress style={{ padding: "8px" }} />
          </div>
        )}
        {feedback?.feedbackSubmitted && !submitting && (
          <div css={css({ display: "flex", color: "#21BA45" })}>
            <CheckIcon fontSize="large" />
          </div>
        )}
      </div>

      {!feedbackOpen && (
        <div css={css({ fontSize: "0.8em" })}>
          You can submit feedback starting 20 minutes after the call begins.
        </div>
      )}
    </div>
  );
}
