import { useQuery, gql } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { addWeeks } from "date-fns";
import { mobileStyles } from "../../styles";
import { Container, Page, LeftColumn, RightColumn } from "../../components/Page";
import CourseProgress from "./CourseProgress";
import EventsModule from "./EventsModule";
import Header from "./Header";
import OtherTodos from "./OtherTodos";
import UpdatesModule from "./UpdatesModule";
import { DASHBOARD_DATA } from "./__generated__/DASHBOARD_DATA";
import Sprint from "./Sprint";
import YcAppIntention from "./YcAppIntention";
import OtherResourcesModule from "./OtherResourcesModule";

const Dashboard: React.FC = () => {
  const { data } = useQuery<DASHBOARD_DATA>(gql`
    query DASHBOARD_DATA {
      currentUser {
        slug
        firstName
        track
        returningUser
      }

      completedActions

      dashboard {
        currentStreak

        curriculum {
          completed
          required
          nextItem {
            id
            title
            previewLink
          }
        }

        updatesByWeek {
          url
          weekLabel
        }

        todos {
          title
          description
          actionText
          actionUrl
          actionComplete
        }

        events {
          title
          description
          displayTime
          actionText
          actionUrl
        }

        timeZone

        dealsEligibility
      }

      latestSprint {
        name
        showOnDashboard
        enrolled
        registrationOpen
        active
        started
        finished
        endDate
        goalCompleted
        goal
        currentWeek
        totalWeeks
        daysRemaining
        daysUntilStart
        daysUntilRegCloses
        gsAttended
        updatesSubmitted
      }

      ycAppIntention {
        id
        planningToApply
        sendReminders
        appliedThisBatch
        appsBatchName
        appsBatchDeadline
        invitesSentBy
      }

      courseModules {
        name
        order

        curriculumItems {
          title
          contentType
          id
          url

          curriculumItemFeedback {
            status
          }
        }
      }

      cofounderMatching {
        profile {
          slug
          approvalStatus
          active
        }
      }

      updates {
        followers {
          secureSlug
        }
      }
    }
  `);

  const activeFounder = data?.currentUser?.track === "active_founder";

  const { active, endDate, goalCompleted, showOnDashboard } = data?.latestSprint || {};
  const sprintIsActive =
    active || (endDate && addWeeks(new Date(endDate), 2) > new Date() && goalCompleted == null);

  return (
    <Page>
      <Header data={data} />
      <Container>
        {data && (
          <>
            <div
              css={css({
                display: "flex",
                flexDirection: "row",
                ...mobileStyles({ flexDirection: "column", paddingTop: 20 }),
              })}
            >
              <LeftColumn>
                {showOnDashboard && sprintIsActive && <Sprint data={data} />}
                <CourseProgress data={data} />
                {activeFounder && <UpdatesModule data={data} />}
                {showOnDashboard && !sprintIsActive && <Sprint data={data} />}
              </LeftColumn>
              <RightColumn>
                <YcAppIntention data={data} />
                <EventsModule data={data} />
                <OtherResourcesModule data={data} />
                <OtherTodos data={data} />
              </RightColumn>
            </div>
          </>
        )}
      </Container>
    </Page>
  );
};

export default Dashboard;
