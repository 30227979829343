import React from "react";
import styled from "@emotion/styled";
import { HarmonicEventProspectColumnType, HarmonicEventProspectSortType } from "./MeetupColumns";
import { Row } from "./AdminMeetupRsvp";

type ClickableThProps = {
  selected: boolean;
};
const ClickableTh = styled.th<ClickableThProps>`
  box-shadow: ${({ selected }) => (selected ? "0px 0px 5px 3px #D4F1F4 inset" : "none")};
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 10px 5px rgb(34 36 38 / 5%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    background-color: #fbfbfb;
  }
`;

const HeaderRow = styled(Row)`
  position: sticky;
  top: 0;
  background-color: whitesmoke;
  z-index: 1;
`;

type Props = {
  sortType: HarmonicEventProspectSortType | null;
  setSortType: (sortType: HarmonicEventProspectSortType) => void;
  columns: HarmonicEventProspectColumnType[];
  hideActions?: boolean;
};

export default ({ sortType, setSortType, columns, hideActions }: Props) => (
  <thead>
    <HeaderRow>
      {!hideActions && (
        <>
          <th>Reject</th>
          <th>Accept</th>
        </>
      )}

      {columns.map(({ title, sort }) =>
        sort ? (
          <ClickableTh
            key={sort.key}
            onClick={() => {
              setSortType(sort);
            }}
            selected={sortType?.key === sort.key}
          >
            {title}
          </ClickableTh>
        ) : (
          <th key={title}>{title}</th>
        )
      )}

      <th>View</th>
    </HeaderRow>
  </thead>
);
