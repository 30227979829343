import { css } from "@emotion/core";
import React, { ReactElement, ReactNode } from "react";
import NewBadge from "../../components/NewBadge";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";

export type Update = {
  id: number | null;
  launched: boolean;
  metric_type: string | null;
  metric_display_name: string;
  metric_value: number;
  metric_context: string | null;
  talked_to: number;
  morale: number;
  biggest_change: string;
  biggest_blocker: string;
  learned_from_users: string;
  created_at: string;
  group_session_slot: number | null;
  gs_closing_time: string;
  goals: string;
  experimental_questions: { [key: string]: string };
  formatted_date: string;
  completable_goals: null | { goal: string; key: string }[];
  sprint_on_track: null | boolean;
  company_url: string | null;
};

export type AvailableMetric = {
  short: string;
  long: string;
  context?: string;
  category: "recurring_revenue" | "revenue" | "engagement";
};

export type ContextProps = {
  default_gs_slot: number | null;
  experimental_questions: { [key: string]: { name: string; options: string[]; active: boolean } };
  group_session_available: boolean;
  group_session_slots: [string, number][];
  group_session_topic: string | null;
  matching_factors: {
    commitment: string;
    stage: string;
    vertical: string;
    seeking_cofounder: boolean;
  };
  metrics: { [key: string]: AvailableMetric };
  missed_sessions: number;
};

type UpdateContextType = ContextProps & {
  pendingUpdate: Update;
};

// @ts-ignore
export const UpdateContext = React.createContext<UpdateContextType>();

export const Section = (props: {
  title: string;
  isNew?: boolean;
  titleLink?: ReactElement;
  children: ReactNode;
}) => (
  <Pane>
    <PaneHeader>
      <h3 css={css({ flex: 1 })}>
        {props.title} {props.isNew && <NewBadge />}
      </h3>
      {props.titleLink}
    </PaneHeader>
    <PaneItem>{props.children}</PaneItem>
  </Pane>
);
