import React, { useEffect, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { pick } from "lodash";
import {
  MEETUP_ADMINS,
  MEETUP_ADMINS_admin_adminMeetup_admins,
} from "./__generated__/MEETUP_ADMINS";
import { Pane, PaneItem } from "../../../components/Pane";
import Autocomplete from "../../../components/statelessForms/Autocomplete";
import {
  UPDATE_MEETUP_ADMINS,
  UPDATE_MEETUP_ADMINSVariables,
} from "./__generated__/UPDATE_MEETUP_ADMINS";
import { EDIT_MEETUP_PAGE } from "./__generated__/EDIT_MEETUP_PAGE";
import { Checkbox, Input, Submit, useForm } from "../../../components/forms";
import useUpdateMeetup from "./hooks/useUpdateMeetup";
import useYcStaff from "./hooks/useYcStaff";

type Props = {
  data: EDIT_MEETUP_PAGE | null;
  meetupId?: string | undefined;
  afterUpdate: (id: string) => void;
};

export default ({ data, meetupId, afterUpdate }: Props) => {
  const updateMeetup = useUpdateMeetup();
  const options = useYcStaff();

  const { data: adminData, refetch } = useQuery<MEETUP_ADMINS>(
    gql`
      query MEETUP_ADMINS($id: ID!) {
        admin {
          adminMeetup(id: $id) {
            id
            admins {
              id
              name
            }
          }
        }
      }
    `,
    { variables: { id: meetupId }, skip: !meetupId }
  );

  const [updateMeetupAdmins] = useMutation<UPDATE_MEETUP_ADMINS, UPDATE_MEETUP_ADMINSVariables>(gql`
    mutation UPDATE_MEETUP_ADMINS($meetupId: ID!, $adminIds: [ID!]!) {
      updateMeetupAdmins(meetupId: $meetupId, adminIds: $adminIds) {
        id
        admins {
          id
          name
        }
      }
    }
  `);

  const adminIds = useMemo(
    () => adminData?.admin.adminMeetup.admins.map(({ id }) => id) || [],
    [adminData]
  );

  const onChange = async (admins: MEETUP_ADMINS_admin_adminMeetup_admins[]) => {
    await updateMeetupAdmins({
      variables: {
        meetupId: meetupId!,
        adminIds: admins.map(({ id }) => id),
      },
    });
    refetch();
  };

  const onSubmit = async (fields: any) => {
    const resp = await updateMeetup({
      meetupId,
      input: pick({ ...fields }, ["cost", "remindToApply"]),
    });

    const id = resp.data?.adminUpdateEvent?.id;
    if (id && !meetupId) {
      afterUpdate(id);
    }
  };

  // @ts-ignore
  const { ConnectedForm, connectedFormProps, formMethods } = useForm(
    data?.admin?.adminMeetup,
    onSubmit
  );

  const cost = formMethods.watch("cost");
  useEffect(() => {
    const costNum = parseFloat(`${cost}`);
    if (!isNaN(costNum)) {
      formMethods.setValue("cost", costNum);
    }
  }, [cost]);

  return (
    <>
      <Pane title="Who from YC is attending? (Optional)">
        <PaneItem>
          <p>
            If you add YC partners here, they may be prompted to add/update notes on any YC founders
            in attendance after the event.
          </p>
          <Autocomplete
            multiple
            options={options}
            getOptionLabel={(option) => option.name}
            value={options.filter(({ id }) => adminIds.includes(id))}
            onChange={(_, admins) => onChange(admins)}
            getOptionSelected={(option, value) => option.id === value.id}
          />
        </PaneItem>
      </Pane>

      <ConnectedForm {...connectedFormProps}>
        <Pane title="Finance">
          <PaneItem>
            <Input label="What is the cost of this event? (USD)" fieldName="cost" type="number" />
          </PaneItem>
        </Pane>

        <Pane title="App Ops">
          <PaneItem>
            <Checkbox label="Send apply reminders to attendees?" fieldName="remindToApply" />
          </PaneItem>
        </Pane>

        <Submit />
      </ConnectedForm>
    </>
  );
};
