import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Dialog, DialogTitle } from "@material-ui/core";
import { getSlugFromWindowLocation } from "../../../components/forms/util";
import { Pane, PaneItem } from "../../../components/Pane";
import { MEETUP_WAITLIST_BYPASS_TOKENS } from "./__generated__/MEETUP_WAITLIST_BYPASS_TOKENS";
import Button from "../../../components/statelessForms/Button";
import Input from "../../../components/statelessForms/Input";
import {
  CREATE_MEETUP_INVITE_TOKEN,
  CREATE_MEETUP_INVITE_TOKENVariables,
} from "./__generated__/CREATE_MEETUP_INVITE_TOKEN";

const UsageStats = styled.div`
  color: gray;
  font-weight: 300;
  font-style: italic;
`;

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`;

export default () => {
  const id = getSlugFromWindowLocation();

  const { data, refetch } = useQuery<MEETUP_WAITLIST_BYPASS_TOKENS>(
    gql`
      query MEETUP_WAITLIST_BYPASS_TOKENS($id: ID!) {
        admin {
          meetup(id: $id) {
            slug
            waitlistBypassTokens {
              id
              token
              notes
              usageLimit
              numTimesUsed
              signUpUrl
            }
          }
        }
      }
    `,
    { variables: { id } }
  );

  const [createToken] = useMutation<
    CREATE_MEETUP_INVITE_TOKEN,
    CREATE_MEETUP_INVITE_TOKENVariables
  >(gql`
    mutation CREATE_MEETUP_INVITE_TOKEN($meetupId: ID!, $usageLimit: Int!, $notes: String) {
      createMeetupInviteToken(meetupId: $meetupId, usageLimit: $usageLimit, notes: $notes) {
        id
        token
        notes
        usageLimit
        numTimesUsed
        signUpUrl
      }
    }
  `);

  const [modalOpen, setModalOpen] = useState(false);
  const [usageLimit, setUsageLimit] = useState(0);
  const [notes, setNotes] = useState("");

  const onCreate = async () => {
    if (!id) {
      return;
    }

    await createToken({
      variables: {
        meetupId: id,
        usageLimit,
        notes,
      },
    });
    await refetch();
    setModalOpen(false);
    setUsageLimit(0);
    setNotes("");
  };

  return (
    <div css={css({ marginTop: 30 })}>
      <Pane>
        <PaneItem>
          <h2>Waitlist bypass URLs</h2>
        </PaneItem>
        {data?.admin.meetup.waitlistBypassTokens?.map((token) => (
          <PaneItem key={token.id}>
            <UsageStats>
              Used {token.numTimesUsed} / {token.usageLimit} times
            </UsageStats>
            <a href={token.signUpUrl} target="_blank">
              {token.signUpUrl}
            </a>
            <p css={css({ marginTop: 10 })}>{token.notes}</p>
          </PaneItem>
        ))}
      </Pane>
      <div css={css({ marginTop: 20 })}>
        <Button color="orange" onClick={() => setModalOpen(true)}>
          Create a new waitlist bypass URL
        </Button>
      </div>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Create a waitlist bypass URL</DialogTitle>
        <div css={css({ padding: 30, paddingTop: 0 })}>
          <Label>How many times can this token be used?</Label>
          <Input
            type="number"
            value={usageLimit}
            onChange={(e) => setUsageLimit(Number.parseInt(e.target.value, 10))}
            outlined
            size="small"
          />
          <Label>Any notes about what this token will be used for?</Label>
          <Input value={notes} onChange={(e) => setNotes(e.target.value)} />
          <div
            css={css({
              display: "flex",
              alignItems: "center",
              marginTop: 15,
              button: { flexGrow: 1 },
            })}
          >
            <Button color="orange" onClick={onCreate} disabled={!usageLimit}>
              Create
            </Button>
            <Button color="gray" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
