import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Button from "../../components/Button";
import Input from "../../components/statelessForms/Input";
import { Container, LeftColumn, Page } from "../../components/Page";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { mobileStyles } from "../../styles";
import { SPRINT_INDEX } from "./__generated__/SPRINT_INDEX";
import {
  UPDATE_SPRINT_COMPANY,
  UPDATE_SPRINT_COMPANYVariables,
} from "./__generated__/UPDATE_SPRINT_COMPANY";
import Requirements from "./Requirements";

export default function Sprint() {
  const [localGoal, setLocalGoal] = useState<string>("");

  const { data, refetch } = useQuery<SPRINT_INDEX>(
    gql`
      query SPRINT_INDEX {
        latestSprint {
          finished
          id
          goal
          goalCompleted
          startDate
          endDate
        }
      }
    `,
    { onCompleted: (resp) => setLocalGoal(resp.latestSprint.goal || "") }
  );

  const [updateSprintCompany] = useMutation<
    UPDATE_SPRINT_COMPANY,
    UPDATE_SPRINT_COMPANYVariables
  >(gql`
    mutation UPDATE_SPRINT_COMPANY($sprintId: ID!, $goal: String, $goalCompleted: Boolean) {
      updateSprintCompany(sprintId: $sprintId, goal: $goal, goalCompleted: $goalCompleted) {
        success
      }
    }
  `);

  const saveGoal = async () => {
    await updateSprintCompany({
      variables: { goal: localGoal, sprintId: data!.latestSprint.id },
    });
    refetch();
  };

  const saveGoalComplete = async (complete: boolean) => {
    await updateSprintCompany({
      variables: { goalCompleted: complete, sprintId: data!.latestSprint.id },
    });
    refetch();
  };

  const goalSaved = localGoal === data?.latestSprint.goal;

  return (
    <Page title="YC Build Sprint">
      <Container>
        <LeftColumn>
          <Pane>
            <PaneHeader>
              <h3>Your Goal</h3>
            </PaneHeader>
            <PaneItem>
              {data?.latestSprint.finished ? (
                <>
                  <p>
                    The sprint is now over! Your goal was <em>{data?.latestSprint.goal}</em>. Were
                    you able to achieve your goal by the end of the sprint?
                  </p>
                  <RadioGroup>
                    <FormControlLabel
                      label="Yes"
                      checked={(data && data.latestSprint.goalCompleted) ?? false}
                      onChange={() => saveGoalComplete(true)}
                      control={<Radio color="default" />}
                    />
                    <FormControlLabel
                      label="No"
                      checked={(data && data.latestSprint.goalCompleted == false) ?? false}
                      onChange={() => saveGoalComplete(false)}
                      control={<Radio color="default" />}
                    />
                  </RadioGroup>
                  <p />
                  {data && data.latestSprint.goalCompleted && (
                    <p>🎉 Right on! Congrats, and wishing you more success on your journey!</p>
                  )}
                  {data && data.latestSprint.goalCompleted == false && (
                    <p>
                      Sorry to hear that. 😞 But the future is still a blank canvas. Keep setting
                      ambitious goals and trying to hit them!
                    </p>
                  )}
                </>
              ) : (
                <>
                  <p>
                    Set a goal you’d like to hit by {data?.latestSprint.endDate}. This should be
                    specific, measurable, and hard but not impossible.
                  </p>
                  <p>
                    Your goal should be related to your{" "}
                    <a
                      href="https://www.ycombinator.com/library/6j-how-to-set-kpis-and-goals-sus-2019"
                      target="_blank"
                    >
                      primary metric
                    </a>{" "}
                    (launching, revenue, etc). You may want to set a goal that you think you have
                    about a 50% chance of hitting if you work really hard.
                  </p>
                  Good goals:
                  <ul>
                    <li>
                      Unlaunched:{" "}
                      <strong>
                        Launch our{" "}
                        <a
                          href="https://www.ycombinator.com/library/6f-how-to-plan-an-mvp"
                          target="_blank"
                        >
                          MVP
                        </a>
                      </strong>
                    </li>
                    <li>
                      Consumer app: <strong>Grow Daily Active Users to 5000</strong>
                    </li>
                    <li>
                      Enterprise SaaS: <strong>Close first pilot deal</strong>
                    </li>
                  </ul>
                  Bad goals:
                  <ul>
                    <li>“get to know my users better” (not measurable)</li>
                    <li>“send 20 emails to prospective customers” (too easy)</li>
                  </ul>
                  <div css={css({ display: "flex", ...mobileStyles({ flexDirection: "column" }) })}>
                    <Input
                      outlined
                      size="small"
                      placeholder="Enter your goal here"
                      value={localGoal}
                      onChange={(e) => setLocalGoal(e.target.value)}
                      onKeyDown={(e: React.KeyboardEvent) => e.keyCode === 13 && saveGoal()}
                    />
                    <Button
                      css={css({ marginLeft: 5, padding: "8px 20px" })}
                      type={goalSaved || localGoal.length === 0 ? "inactiveSuccess" : "success"}
                      text={goalSaved ? "Saved" : "Save"}
                      onClick={saveGoal}
                    />
                  </div>
                  <p>{}</p>
                </>
              )}
            </PaneItem>
          </Pane>

          <Pane>
            <PaneHeader>
              <h3>Sprint Information</h3>
            </PaneHeader>
            <PaneItem>
              <p>
                <strong>Start date:</strong> {data?.latestSprint.startDate}
                <br />
                <strong>End date:</strong> {data?.latestSprint.endDate}
              </p>
              <Requirements />
            </PaneItem>
          </Pane>
        </LeftColumn>
      </Container>
    </Page>
  );
}
