import React from "react";
import { css } from "@emotion/core";
import { colors } from "../styles";

export default function NewBadge() {
  return (
    <span
      css={css({
        color: "white",
        background: colors.brandSecondary,
        borderRadius: 2,
        fontSize: "0.8em",
        padding: "0.2em 0.3em",
      })}
    >
      NEW
    </span>
  );
}
