import React, { useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import ScrollableAnchor, { goToAnchor } from "react-scrollable-anchor";
import styled from "@emotion/styled";
import { isBefore } from "date-fns";
import { EDIT_MEETUP_PAGE } from "./__generated__/EDIT_MEETUP_PAGE";
import { ColumnContainer, Page } from "../../../components/Page";
import EditMeetupDetails from "./EditMeetupDetails";
import NavPanel, { FormPage } from "../../../components/navigation/NavPanel";
import { mobileStyles } from "../../../styles";
import EditMeetupSpeakers from "./EditMeetupSpeakers";
import EditCustomMeetupQuestions from "./EditCustomMeetupQuestions";
import ReviewAllEmailPushes from "../emailCampaigns/ReviewAllEmailPushes";
import EditMeetupInternalSettings from "./EditMeetupInternalSettings";
import Button from "../../../components/statelessForms/Button";
import EditMeetupOfficeHourSlots from "./EditMeetupOfficeHourSlots";
import MeetupLinksDropdown from "./MeetupLinksDropdown";
import { adminMeetupsPath } from "../../../__generated__/routes";

type Props = {
  meetupId?: string;
  eventTypeOptions: [string, string][];
  canBeDeleted?: boolean;
};

const TwoColumnContainer = styled(ColumnContainer)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: 20,
  padding: 0,
  ...mobileStyles({
    flexDirection: "column",
    alignItems: "center",
  }),
});

type ComponentPropsType = {
  meetupId?: string | undefined;
  eventTypeOptions: [string, string][];
  canBeDeleted?: boolean;
  afterUpdate: (id?: string | undefined) => void;
  campaignId: string | null;
  continueButton?: React.ReactNode | undefined;
};
const FORM_PAGES: FormPage<EDIT_MEETUP_PAGE, ComponentPropsType>[] = [
  {
    Component: EditMeetupDetails,
    title: "Event details",
    fields: [],
    disabled: (_) => false,
  },
  {
    Component: EditMeetupOfficeHourSlots,
    title: "Office hour slots",
    fields: [],
    disabled: (_) => false,
    renderIf: (data) => data?.admin.adminMeetup?.eventType === "office_hours",
  },
  {
    Component: EditMeetupSpeakers,
    title: "Speakers",
    fields: [],
    disabled: (data) => !data?.admin.adminMeetup,
  },
  {
    Component: EditCustomMeetupQuestions,
    title: "RSVP questions",
    fields: [],
    disabled: (data) => !data?.admin.adminMeetup,
  },
  {
    Component: EditMeetupInternalSettings,
    title: "Internal settings",
    fields: [],
    disabled: (data) => !data?.admin.adminMeetup,
  },
  {
    Component: ReviewAllEmailPushes,
    title: "Preview email copy",
    fields: [],
    disabled: (data) => !data?.admin.adminMeetup,
    isPreviewPage: true,
  },
];

const PAGE_PARAM = "page";

export default (props: Props) => {
  const { eventTypeOptions, canBeDeleted } = props;
  const [meetupId, setMeetupId] = useState<string | undefined>(props.meetupId);
  const isNewEvent = !props.meetupId;
  const [currentPage, setCurrentPage] = useState<number>(
    Number.parseInt(
      new URLSearchParams(window.location.search).get(PAGE_PARAM) || "1",
      10
    ) as number
  );
  const [pageValidity, setPageValidity] = useState<(boolean | null)[]>(FORM_PAGES.map((_) => null));
  const copiedMeetupId = new URLSearchParams(window.location.search).get("copiedMeetupId");
  const meetupIdToLoad = meetupId || copiedMeetupId;
  const { data, refetch } = useQuery<EDIT_MEETUP_PAGE>(
    gql`
      query EDIT_MEETUP_PAGE($id: ID!) {
        admin {
          adminMeetup(id: $id) {
            slug
            eventType
            attendeeType
            title
            friendlyName
            description
            publicLocation
            address
            locationForGeocoding
            startsAt
            endsAt
            registrationClosesAt
            instructions
            capacity
            campaignId
            url
            socialImageUrl
            cost
            remindToApply
            linkedinRequired
            landingPageDisabled
            inviteExpirationDisabled
            ohSlots {
              id
              description
              location
              startsAt
              endsAt
              ownerId
              owner2Id
              owner {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      skip: !meetupIdToLoad,
      variables: { id: meetupIdToLoad },
    }
  );

  const formPages = useMemo(
    () => FORM_PAGES.filter((page) => !page.renderIf || page.renderIf(data)),
    [data]
  );

  useEffect(() => {
    const meetup = data?.admin.adminMeetup;
    if (meetup) {
      setPageValidity(formPages.map((_) => true));
    }
  }, [data, formPages]);

  useEffect(() => {
    const url = new URL(window.location.toString());
    url.searchParams.set(PAGE_PARAM, currentPage.toString());
    window.history.pushState({}, "", url.toString());
  }, [currentPage]);

  const goToPage = (page: number) => {
    setCurrentPage(page);
    goToAnchor(`topOfForm_${page}`);
  };

  const afterUpdate = (id: string | undefined = undefined) => {
    if (id && id !== meetupId) {
      setMeetupId(id);
    }
    if (isNewEvent) {
      goToPage(currentPage + 1);
    }
    refetch();
  };

  const title = meetupId ? "Edit event" : "New event";

  const options =
    meetupId && data ? (
      <MeetupLinksDropdown
        id={meetupId}
        emailCampaignId={data.admin.adminMeetup.campaignId}
        publicUrl={data.admin.adminMeetup.url}
        isOver={isBefore(new Date(data.admin.adminMeetup.startsAt), new Date())}
        ycCompaniesUrl={`https://internal.ycinside.com/meetups/company_attendees?sus_id=${meetupId}`}
      />
    ) : undefined;

  return (
    <Page title={title} nav={{ href: adminMeetupsPath(), text: "Meetups" }} menuOptions={options}>
      <TwoColumnContainer>
        <NavPanel
          title={title}
          data={data}
          formPages={formPages}
          currentPage={currentPage}
          goToPage={goToPage}
          pageValidity={pageValidity}
        />
        <div css={css({ maxWidth: 900 })}>
          {formPages.map((formPage, idx) => (
            <div
              key={formPage.title}
              css={css({ display: currentPage === idx + 1 ? "block" : "none", minWidth: 700 })}
            >
              <ScrollableAnchor id={`topOfForm_${idx + 1}`}>
                <div />
              </ScrollableAnchor>
              <formPage.Component
                data={data || null}
                meetupId={meetupId}
                eventTypeOptions={eventTypeOptions}
                canBeDeleted={canBeDeleted}
                afterUpdate={afterUpdate}
                campaignId={data?.admin.adminMeetup?.campaignId || null}
                continueButton={
                  isNewEvent &&
                  meetupId && (
                    <Button color="orange" onClick={() => afterUpdate(meetupId)}>
                      Continue
                    </Button>
                  )
                }
              />
            </div>
          ))}
        </div>
      </TwoColumnContainer>
    </Page>
  );
};
