import React, { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Checkbox, Dialog, FormControlLabel } from "@material-ui/core";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { AddCircle } from "@material-ui/icons";
import Button from "../../../components/statelessForms/Button";
import Input from "../../../components/statelessForms/Input";
import { MEETUP_SPEAKERS } from "./__generated__/MEETUP_SPEAKERS";
import useUpdateMeetupSpeakers from "./useUpdateMeetupSpeakers";
import SortableSpeaker from "./SortableSpeaker";
import { Pane, PaneItem } from "../../../components/Pane";
import { EDIT_MEETUP_PAGE } from "./__generated__/EDIT_MEETUP_PAGE";
import S3FileUpload from "../../../components/statelessForms/S3FileUpload";

const ModalBody = styled.div`
  padding: 30px;
  width: 700px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;
`;

type Speaker = {
  url: string;
  name: string;
  imgSrc: string;
  oneLiner: string;
  description?: string;
  isHost?: boolean;
};

type Props = {
  data: EDIT_MEETUP_PAGE | null;
  meetupId?: string | undefined;
  continueButton?: React.ReactNode | undefined;
};

export default ({ data: meetupData, meetupId, continueButton }: Props) => {
  const updateMeetupSpeakers = useUpdateMeetupSpeakers();
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [speakerToEdit, setSpeakerToEdit] = useState<string | null>(null);
  const [speakerKeys, setSpeakerKeys] = useState<string[]>([]);
  const [speakerDict, setSpeakerDict] = useState<{ [key: string]: Speaker }>({});
  const [hasReorderedSpeakers, setHasReorderedSpeakers] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [name, setName] = useState("");
  const [oneLiner, setOneLiner] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [isHost, setIsHost] = useState(false);

  const id = meetupId;

  const { data, refetch } = useQuery<MEETUP_SPEAKERS>(
    gql`
      query MEETUP_SPEAKERS($id: ID!) {
        admin {
          meetup(id: $id) {
            slug
            speakers
          }
        }
      }
    `,
    { variables: { id }, skip: !id }
  );

  useMemo(() => {
    if (!data?.admin.meetup.speakers) {
      return [];
    }

    const parsedSpeakers: Speaker[] = JSON.parse(data.admin.meetup.speakers);
    setSpeakerKeys(parsedSpeakers.map((speaker, idx) => `${speaker.imgSrc}${idx}`));

    setSpeakerDict(
      parsedSpeakers.reduce((dict: { [key: string]: Speaker }, speaker, idx) => {
        dict[`${speaker.imgSrc}${idx}`] = speaker;
        return dict;
      }, {})
    );
  }, [data?.admin.meetup.speakers]);

  if (!id || !data) {
    return <div />;
  }

  const clearFormAndClose = () => {
    setName("");
    setDescription("");
    setOneLiner("");
    setUrl("");
    setImgSrc("");
    setIsHost(false);
    setModalOpen(false);
  };

  const onAddSpeaker = async () => {
    setIsSubmitting(true);
    const speakers = speakerKeys.map((speakerKey) => speakerDict[speakerKey]);
    const newSpeakers = [...speakers, { name, oneLiner, url, imgSrc, description, isHost }];

    await updateMeetupSpeakers({ id, speakers: JSON.stringify(newSpeakers) });

    await refetch();

    clearFormAndClose();

    setIsSubmitting(false);
  };

  const onDeleteSpeaker = async (idxToDelete: number) => {
    setIsSubmitting(true);
    const speakers = speakerKeys.map((speakerKey) => speakerDict[speakerKey]);

    const newSpeakers = [...speakers.slice(0, idxToDelete), ...speakers.slice(idxToDelete + 1)];

    await updateMeetupSpeakers({ id, speakers: JSON.stringify(newSpeakers) });
    await refetch();

    setIsSubmitting(false);
  };

  const onEditSpeaker = async (keyToEdit: string) => {
    setIsSubmitting(true);

    const idxToEdit = speakerKeys.indexOf(keyToEdit);
    const speakers = speakerKeys.map((speakerKey) => speakerDict[speakerKey]);

    speakers.splice(idxToEdit, 1, { name, oneLiner, url, imgSrc, description, isHost });

    await updateMeetupSpeakers({ id, speakers: JSON.stringify(speakers) });
    await refetch();

    clearFormAndClose();

    setIsSubmitting(false);
  };

  const saveSpeakerOrder = async () => {
    setIsSubmitting(true);

    const newSpeakersOrder = speakerKeys.map((speakerKey) => speakerDict[speakerKey]);

    await updateMeetupSpeakers({ id, speakers: JSON.stringify(newSpeakersOrder) });
    await refetch();

    setIsSubmitting(false);
    setHasReorderedSpeakers(false);
  };

  const disabled = isSubmitting || isUploadingImg || !name || !url || !oneLiner || !imgSrc;
  const existingSpeakerInfo = speakerToEdit !== null && speakerDict[speakerToEdit];

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSpeakerKeys((key) => {
        const oldIndex = key.indexOf(active.id);
        const newIndex = key.indexOf(over.id);

        return arrayMove(key, oldIndex, newIndex);
      });
      setHasReorderedSpeakers(true);
    }
  };

  const numSpeakers = speakerKeys.length;
  return (
    <div>
      <Pane
        title="Add speakers (optional)"
        headerRight={
          <Button color="blue" onClick={() => setModalOpen(true)}>
            <div css={css({ display: "flex", alignItems: "center", gap: 10 })}>
              <AddCircle />
              <span>Add speaker</span>
            </div>
          </Button>
        }
      >
        <PaneItem>
          <p>
            Speakers you add here will show up on the{" "}
            <a href={meetupData?.admin.adminMeetup.url} target="_blank">
              public event page.
            </a>
          </p>
          <Dialog
            open={modalOpen}
            onClose={() => {
              clearFormAndClose();
            }}
            scroll="body"
            maxWidth="md"
          >
            <ModalBody>
              <h2>{existingSpeakerInfo ? "Edit speaker" : "Add a speaker"}</h2>

              <Label>First & last name</Label>
              <Input
                outlined
                disabled={isSubmitting}
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                placeholder="e.g. Emmett Shear"
              />

              <Label>One-liner</Label>
              <Input
                outlined
                disabled={isSubmitting}
                value={oneLiner}
                onChange={(e) => setOneLiner(e.target.value)}
                size="small"
                placeholder="e.g. Founder at Twitch (W07)"
              />

              <Label>Description (optional)</Label>
              <Input
                outlined
                disabled={isSubmitting}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size="small"
                placeholder="e.g. Twitch is a streaming company."
              />

              <Label>URL (we'll link to this - probably a YC company/founder page)</Label>
              <Input
                outlined
                disabled={isSubmitting}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                size="small"
                placeholder="e.g. https://www.ycombinator.com/companies/twitch"
              />

              <Label>Show as "host" instead of "speaker" (optional)</Label>
              <FormControlLabel
                control={<Checkbox color="default" />}
                checked={isHost}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsHost(e.target.checked)}
                label={'Show as "host"'}
              />

              <Label>Add a photo</Label>
              <S3FileUpload
                value={imgSrc}
                onChange={(src) => setImgSrc(src || "")}
                accept="image/*"
                setUploading={setIsUploadingImg}
                rounded
              />

              <br />
              {existingSpeakerInfo ? (
                <Button
                  color="orange"
                  onClick={() => onEditSpeaker(speakerToEdit)}
                  disabled={disabled}
                >
                  Save Changes
                </Button>
              ) : (
                <Button color="orange" onClick={onAddSpeaker} disabled={disabled}>
                  Add
                </Button>
              )}
            </ModalBody>
          </Dialog>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={speakerKeys} strategy={verticalListSortingStrategy}>
              {speakerKeys.map((speakerKey, idx) => (
                <SortableSpeaker
                  id={speakerKey}
                  speaker={speakerDict[speakerKey]}
                  idx={idx}
                  onDeleteSpeaker={onDeleteSpeaker}
                  setModalOpen={setModalOpen}
                  setSpeakerToEdit={setSpeakerToEdit}
                  setName={setName}
                  setOneLiner={setOneLiner}
                  setUrl={setUrl}
                  setImgSrc={setImgSrc}
                  setIsHost={setIsHost}
                  setDescription={setDescription}
                />
              ))}
            </SortableContext>
          </DndContext>

          {numSpeakers > 1 && (
            <div
              css={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              })}
            >
              <div css={css({ color: "gray", fontSize: 14 })}>
                (Hint: you can drag speakers to reorder them.)
              </div>
              <Button
                color="blue"
                onClick={() => saveSpeakerOrder()}
                disabled={!hasReorderedSpeakers}
                size="small"
              >
                Save current speaker ordering
              </Button>
            </div>
          )}
        </PaneItem>
      </Pane>

      {continueButton}
    </div>
  );
};
