import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React, { useState } from "react";
import { FormControlLabel, Radio } from "@material-ui/core";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import {
  UPCOMING_KICKOFF_CALLS,
  UPCOMING_KICKOFF_CALLS_upcomingKickoffCalls,
} from "./__generated__/UPCOMING_KICKOFF_CALLS";
import Button from "../../components/statelessForms/Button";
import {
  CHOOSE_KICKOFF_CALL,
  CHOOSE_KICKOFF_CALLVariables,
} from "./__generated__/CHOOSE_KICKOFF_CALL";
import Select from "../../components/statelessForms/Select";
import { loadDefaultHome } from "../../components/forms/util";

export default () => {
  const [desiredKickoffCall, setDesiredKickoffCall] = useState<string | null | undefined>();
  const [showOtherDates, setShowOtherDates] = useState(false);

  const { data } = useQuery<UPCOMING_KICKOFF_CALLS>(gql`
    query UPCOMING_KICKOFF_CALLS {
      upcomingKickoffCalls {
        slug
        startsAt
      }
    }
  `);

  const [chooseKickoffCall] = useMutation<CHOOSE_KICKOFF_CALL, CHOOSE_KICKOFF_CALLVariables>(gql`
    mutation CHOOSE_KICKOFF_CALL($slug: String) {
      chooseKickoffCall(slug: $slug) {
        success
      }
    }
  `);

  if (!data || !data.upcomingKickoffCalls.length) {
    return null;
  }

  const { upcomingKickoffCalls } = data;

  const nextCall = upcomingKickoffCalls[0];
  const upcomingCalls = upcomingKickoffCalls.slice(1);

  const selectValue = (value: string | null | undefined, shouldShowOtherDates: boolean) => {
    setDesiredKickoffCall(value);
    setShowOtherDates(shouldShowOtherDates);
  };

  const weekday = (kickoffCall: UPCOMING_KICKOFF_CALLS_upcomingKickoffCalls) =>
    new Date(kickoffCall.startsAt).toLocaleDateString(undefined, { weekday: "long" });

  const formatDate = (kickoffCall: UPCOMING_KICKOFF_CALLS_upcomingKickoffCalls) => {
    const startsAt = new Date(kickoffCall.startsAt);
    const day = startsAt.toLocaleDateString(undefined, { month: "long", day: "numeric" });
    const startTime = startsAt.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });

    return `${weekday(kickoffCall)}, ${day} at ${startTime}`;
  };

  const confirm = async () => {
    if (desiredKickoffCall === undefined) {
      return;
    }
    await chooseKickoffCall({
      variables: {
        slug: desiredKickoffCall,
      },
    });
    loadDefaultHome();
  };

  return (
    <Page title="Attend a kickoff call">
      <Container css={css({ maxWidth: 900 })}>
        <Pane css={css({ li: { marginBottom: 7 } })}>
          <PaneItem>
            <p>
              To get the most out of Startup School, we recommend joining our next kickoff call!
            </p>
            <p>
              This is a one-hour Zoom call with all the founders who joined in the last week to
              learn about Startup School and meet each other. You'll also get to hear from a
              successful founder who participated in Startup School and went on to get funded by Y
              Combinator. We'll split up at the end into small groups so that you can get to meet
              some of the other founders in Startup School.
            </p>
            <p>
              If you're not able to attend a kickoff call, that's okay; you're still welcome to take
              part in Startup School!
            </p>

            <FormControlLabel
              control={<Radio size="small" color="default" />}
              label={`I want to attend this ${formatDate(nextCall)}`}
              value={nextCall.slug}
              onChange={() => selectValue(nextCall.slug, false)}
              checked={desiredKickoffCall === nextCall.slug && !showOtherDates}
            />
            <FormControlLabel
              control={<Radio size="small" color="default" />}
              label={`I can't make it this ${weekday(
                nextCall
              )}, but I want to attend another kickoff call`}
              value={showOtherDates}
              onChange={() => selectValue(undefined, true)}
              checked={showOtherDates}
            />
            {showOtherDates && (
              <Select
                value={desiredKickoffCall}
                options={upcomingCalls.map((option) => [option.slug, formatDate(option)])}
                onChange={(newValue: any) => selectValue(newValue as string, true)}
              />
            )}
            <FormControlLabel
              control={<Radio size="small" color="default" />}
              label="I don't want to attend a kickoff call."
              value={null}
              onChange={() => selectValue(null, false)}
              checked={desiredKickoffCall === null && !showOtherDates}
            />
            <div css={css({ marginTop: 10, textAlign: "center" })}>
              <Button color="orange" disabled={desiredKickoffCall === undefined} onClick={confirm}>
                Confirm
              </Button>
            </div>
          </PaneItem>
        </Pane>
      </Container>
    </Page>
  );
};
