import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects } from "./__generated__/MEETUP_SAVED_SEARCH";

type Props = {
  prospects: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects[];
};

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Stat = styled.div`
  padding: 0 10px;
  border-left: 1px solid #e0e0e0;

  &:first-of-type {
    padding-left: 0;
    border-left: none;
  }
`;

export default ({ prospects }: Props) => {
  const numAccepted = useMemo(
    () => prospects.filter((p) => p.toBeInvited && !p.inviteEmailSent).length,
    [prospects]
  );
  const numRejected = useMemo(
    () => prospects.filter((p) => !p.toBeInvited && !p.inviteEmailSent).length,
    [prospects]
  );
  const numEmailsSent = useMemo(
    () => prospects.filter((p) => p.inviteEmailSent).length,
    [prospects]
  );

  return (
    <StatContainer>
      <Stat>{numAccepted} accepted</Stat>
      <Stat>{numRejected} rejected</Stat>
      <Stat>{numEmailsSent} invited</Stat>
    </StatContainer>
  );
};
