import React from "react";
import { css } from "@emotion/core";
import CurriculumItemStatus from "./CurriculumItemStatus";
import CurriculumItemRating from "./CurriculumItemRating";
import { CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback } from "./__generated__/CURRICULUM_FEEDBACK";

type Props = {
  curriculumItemId: number;
  feedback: CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback | undefined | null;
  hideIrrelevant?: boolean;
  centered?: boolean;
  row?: boolean;
};

export default function CurriculumItemFeedback({
  curriculumItemId,
  feedback,
  hideIrrelevant = false,
  centered = false,
  row = false,
}: Props) {
  return (
    <div
      css={css({
        display: "flex",
        flexDirection: "column",
        ...(centered ? { alignItems: "center" } : {}),
      })}
    >
      <CurriculumItemStatus
        curriculumItemId={curriculumItemId}
        status={feedback?.status}
        hideIrrelevant={hideIrrelevant}
        row={row}
      />
      {feedback?.status == "complete" && (
        <CurriculumItemRating curriculumItemId={curriculumItemId} rating={feedback?.rating} />
      )}
    </div>
  );
}
