import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { MEETUP_RSVPS_admin_meetupRsvps } from "../__generated__/MEETUP_RSVPS";

type Props = {
  rsvps: MEETUP_RSVPS_admin_meetupRsvps[];
  capacity: number;
  eventType: string | undefined;
};

const CapacityRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;

  span {
    padding: 0 10px;
  }
  div {
    color: red;
    font-weight: bold;
    padding: 0 10px;
  }
  button {
    margin: 0 10px;
  }
`;

export default ({ rsvps, capacity, eventType }: Props) => {
  const rsvpsToCount = useMemo(
    () =>
      rsvps.filter(({ status }) => ["accepted", "invite_pending", "confirmed"].includes(status)),
    [rsvps]
  );

  const {
    spotsAssigned,
    numAccepted,
    numInvitePending,
    numConfirmed,
    numTechnical,
    numNontech,
    numIdeaCommitted,
    numIdeaOpen,
    numNoIdea,
    numTechIdeaCommitted,
    numTechNotCommitted,
    numNontechIdeaCommitted,
    numNontechNotCommitted,
  }: any = useMemo(() => {
    const spotsAssignedCount = rsvpsToCount.length;
    const numAcceptedCount = rsvpsToCount.filter(({ status }) => status === "accepted").length;
    const numInvitePendingCount = rsvpsToCount.filter(
      ({ status }) => status === "invite_pending"
    ).length;
    const numConfirmedCount = rsvpsToCount.filter(({ status }) => status === "confirmed").length;

    const numTechnicalCount = rsvpsToCount.filter(
      ({ adminUser }) => adminUser?.user.isTechnical
    ).length;
    const numNontechCount = spotsAssignedCount - numTechnicalCount;
    const numIdeaCommittedCount = rsvpsToCount.filter(
      ({ cfmProfile }) => cfmProfile?.profile.hasIdea === "committed"
    ).length;
    const numIdeaOpenCount = rsvpsToCount.filter(
      ({ cfmProfile }) => cfmProfile?.profile.hasIdea === "open"
    ).length;
    const numNoIdeaCount = spotsAssignedCount - numIdeaCommittedCount - numIdeaOpenCount;

    const numTechIdeaCommittedCount = rsvpsToCount.filter(
      ({ adminUser, cfmProfile }) =>
        adminUser?.user.isTechnical && cfmProfile?.profile.hasIdea === "committed"
    ).length;
    const numTechNotCommittedCount = numTechnicalCount - numTechIdeaCommittedCount;
    const numNontechIdeaCommittedCount = numIdeaCommittedCount - numTechIdeaCommittedCount;
    const numNontechNotCommittedCount = numNontechCount - numNontechIdeaCommittedCount;

    return {
      spotsAssigned: spotsAssignedCount,
      numAccepted: numAcceptedCount,
      numInvitePending: numInvitePendingCount,
      numConfirmed: numConfirmedCount,
      numTechnical: numTechnicalCount,
      numNontech: numNontechCount,
      numIdeaCommitted: numIdeaCommittedCount,
      numIdeaOpen: numIdeaOpenCount,
      numNoIdea: numNoIdeaCount,
      numTechIdeaCommitted: numTechIdeaCommittedCount,
      numTechNotCommitted: numTechNotCommittedCount,
      numNontechIdeaCommitted: numNontechIdeaCommittedCount,
      numNontechNotCommitted: numNontechNotCommittedCount,
    };
  }, [rsvpsToCount]);

  const { totalRsvps, numDeclined, numWaitlist }: any = useMemo(() => {
    const totalRsvpsCount = rsvps.length;
    const numDeclinedCount = rsvps.filter(({ status }) => status === "declined").length;
    const numWaitlistCount = rsvps.filter(({ status }) => status === "").length;

    return {
      totalRsvps: totalRsvpsCount,
      numDeclined: numDeclinedCount,
      numWaitlist: numWaitlistCount,
    };
  }, [rsvps]);

  return (
    <>
      <CapacityRow>
        <span>Capacity: {capacity}</span>
        <span>Spots remaining: {capacity - spotsAssigned}</span>
      </CapacityRow>
      <CapacityRow>
        <span>Spots assigned: {spotsAssigned}</span>
        <span>Accepted: {numAccepted}</span>
        <span>Pending invites: {numInvitePending}</span>
        <span>Confirmed: {numConfirmed}</span>
        <span>Declined: {numDeclined}</span>
        <span>Waitlist: {numWaitlist}</span>
        <span>Total RSVPs: {totalRsvps}</span>
      </CapacityRow>
      {eventType === "cfm" && (
        <>
          <CapacityRow>
            <span>Total tech: {numTechnical}</span>
            <span>Total nontech: {numNontech}</span>
            <span>Total no idea: {numNoIdea}</span>
            <span>Total open to ideas: {numIdeaOpen}</span>
            <span>Total idea committed: {numIdeaCommitted}</span>
          </CapacityRow>
          <CapacityRow>
            <span>Tech/no idea or open: {numTechNotCommitted}</span>
            <span>Nontech/idea (committed): {numNontechIdeaCommitted}</span>
            <span>Tech/idea (committed): {numTechIdeaCommitted}</span>
            <span>Nontech/no idea or open: {numNontechNotCommitted}</span>
          </CapacityRow>
        </>
      )}
      {spotsAssigned > capacity && (
        <CapacityRow>
          <div>{spotsAssigned - capacity} OVER CAPACITY</div>
        </CapacityRow>
      )}
    </>
  );
};
