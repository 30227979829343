import { useQuery, gql } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import Button from "../../components/Button";
import {
  ColumnContainer,
  Container,
  Header,
  LeftColumn,
  Page,
  RightColumn,
} from "../../components/Page";
import { mobileStyles } from "../../styles";
import Update from "./Update";
import UpdateCharts from "./UpdateCharts";
import { UPDATES_INDEX, UPDATES_INDEXVariables } from "./__generated__/UPDATES_INDEX";
import { trackPath } from "../../__generated__/routes";

const NoUpdates: React.FC = () => (
  <div css={css({ maxWidth: 800, paddingTop: 50 })}>
    <p>Welcome to Startup School! Let's get started by submitting your first weekly update.</p>
    <p>
      These updates will help you keep track of your progress over time. We ask you to track what we
      have found to be important to many founders, such as whether you are launched, a primary
      metric, and the number of users you spoke to. If you haven't launched yet, your primary metric
      should be "weeks to launch." If you are having difficulty understanding or selecting a metric
      to track, check out the video lecture{" "}
      <a
        href="https://www.startupschool.org/curriculum/how-to-set-kpis-and-prioritize-your-time"
        target="_blank"
      >
        How to Set KPIs and Goals
      </a>
      . An update should take at most 5-15 minutes to complete.
    </p>
    <p>
      Note that if your company has more than one co-founder, only ONE founder needs to submit the
      weekly update. You won't be able to submit another update for the week if one of your
      co-founders has already submitted one. However, you can edit it anytime during the week.
    </p>
    <br />
    <Button href="/updates/new" type="cta" text="Submit your first update!" />
  </div>
);

const UpdateIndex: React.FC<{
  company_id: string | null;
  follower_view: boolean;
  follower_unsubscribe_url?: string;
}> = (props) => {
  const { data } = useQuery<UPDATES_INDEX, UPDATES_INDEXVariables>(
    gql`
      query UPDATES_INDEX($companyId: ID) {
        currentUser {
          slug
          track
        }
        updates(companyId: $companyId) {
          companyName
          updates {
            biggestBlocker
            biggestChange
            canEdit
            completableGoals {
              key
              goal
              completed
            }
            createdAt
            formattedDate
            goals
            id
            learnedFromUsers
            metricDisplayName
            metricValue
            morale
            path
            talkedTo
          }
          thisWeekSubmitted
        }
      }
    `,
    { variables: { companyId: props.company_id } }
  );

  const updates = data?.updates?.updates ?? [];

  return (
    <Page>
      <Header>
        <Container
          css={css({
            display: "flex",
            alignItems: "center",
            ...mobileStyles({
              flexDirection: "column",
              justifyContent: "center",
            }),
          })}
        >
          <div css={css({ flex: 1 })}>
            {props.follower_view && (
              <div css={css({ fontWeight: "bold" })}>{data?.updates?.companyName}</div>
            )}
            <h1>Weekly Updates</h1>
          </div>
          {data &&
            data?.currentUser?.track === "active_founder" &&
            !data.updates?.thisWeekSubmitted &&
            updates.length > 0 &&
            !props.follower_view && <Button type="cta" text="Add Update" href="/updates/new" />}
          {props.follower_view && (
            <a href={props.follower_unsubscribe_url}>Stop Following Company</a>
          )}
        </Container>
      </Header>
      {data?.currentUser?.track === "active_founder" ? (
        <ColumnContainer>
          {data && updates.length === 0 && !props.follower_view && <NoUpdates />}
          {data && updates.length > 0 && (
            <>
              <LeftColumn>
                {updates.map((u) => (
                  <Update update={u} key={u.id} />
                ))}
              </LeftColumn>
              <RightColumn>
                {props.company_id && <UpdateCharts companyId={props.company_id} />}
              </RightColumn>
            </>
          )}
        </ColumnContainer>
      ) : (
        <ColumnContainer>
          <div>
            Weekly updates are great for keeping track of your progress. To submit a weekly update,
            you need an active company. If you are actively working on an idea, you can switch to
            the "active founder" track and create a company by going <a href={trackPath()}>here</a>.
          </div>
        </ColumnContainer>
      )}
    </Page>
  );
};

export default UpdateIndex;
