import styled from "@emotion/styled";

export type AppsStat = {
  applied: number;
  interviewed: number;
  accepted: number;
  acceptedMentioned: number;
  pctApplied: string;
  pctInterviewed: string;
  pctAccepted: string;
  pctAcceptedMentioned: string;
  pctAcceptance: string;
};

export const INITIAL_APPS_STAT: AppsStat = {
  applied: 0,
  interviewed: 0,
  accepted: 0,
  acceptedMentioned: 0,
  pctApplied: "0%",
  pctInterviewed: "0%",
  pctAccepted: "0%",
  pctAcceptedMentioned: "0%",
  pctAcceptance: "0%",
};

export const INVITED_OR_BETTER_RESULTS = [
  "invited",
  "pending_decision",
  "accepted",
  "deferred",
  "withdrawn",
  "denied",
  "interviewed_rejected",
  "post_interview_rejected",
];

const getPctString = (num: number, denom: number) => {
  if (!denom) {
    return "0%";
  }
  return `${((100.0 * num) / denom).toFixed(2)}%`;
};

export const updateStat = (
  countsMap: AppsStat,
  result: string,
  total: number,
  mentioned: boolean
) => {
  // add to counts
  countsMap.applied += 1;
  countsMap.pctApplied = getPctString(countsMap.applied, total);

  // check if interviewed
  if (INVITED_OR_BETTER_RESULTS.includes(result)) {
    countsMap.interviewed += 1;
    countsMap.pctInterviewed = getPctString(countsMap.interviewed, total);
  }

  // check if accepted
  if (result === "accepted") {
    countsMap.accepted += 1;
    countsMap.pctAccepted = getPctString(countsMap.accepted, total);

    if (mentioned) {
      countsMap.acceptedMentioned += 1;
      countsMap.pctAcceptedMentioned = getPctString(countsMap.acceptedMentioned, total);
    }
  }

  countsMap.pctAcceptance = getPctString(countsMap.accepted, countsMap.applied);
};

export const updateNestedStat = (
  nestedKey: string,
  countsMap: { [key: string]: AppsStat },
  result: string,
  total: number,
  mentioned: boolean
) => {
  if (!countsMap[nestedKey]) {
    countsMap[nestedKey] = { ...INITIAL_APPS_STAT };
  }
  updateStat(countsMap[nestedKey], result, total, mentioned);
};

export const getBatchValue = (batch: string) => {
  if (batch === "all") {
    return 100_000;
  }

  const year = batch.slice(1);
  const season = batch[0].toLowerCase() === "s" ? 1 : 0;
  return year + season;
};

export const TableSection = styled.div`
  margin: 10px 0 30px 0;

  h2 {
    margin-bottom: 5px;
  }
`;

export const IncrementalNote = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: 14px;
`;
