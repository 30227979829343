import { useMutation, gql } from "@apollo/client";
import {
  UPDATE_MEETUP_SPEAKERS,
  UPDATE_MEETUP_SPEAKERSVariables,
} from "./__generated__/UPDATE_MEETUP_SPEAKERS";

export default function useUpdateMeetupSpeakers() {
  const [updateMeetupSpeakers] = useMutation<
    UPDATE_MEETUP_SPEAKERS,
    UPDATE_MEETUP_SPEAKERSVariables
  >(gql`
    mutation UPDATE_MEETUP_SPEAKERS($id: ID!, $speakers: String!) {
      updateMeetupSpeakers(id: $id, speakers: $speakers) {
        slug
        speakers
      }
    }
  `);

  return (variables: UPDATE_MEETUP_SPEAKERSVariables) => updateMeetupSpeakers({ variables });
}
