import React from "react";
import ReactPDF, { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import Styles = ReactPDF.Styles;
import { YC_ORANGE } from "../../../../components/forms/util";
import { BottomBarContent, NametagContent } from "./NametagTypes";

type Props = {
  withBleed: (styles: Styles) => Styles;
  nametag: NametagContent;
  bottomBar: BottomBarContent;
  qrCodeUrl: string | null;
};

export default ({ withBleed, nametag, bottomBar, qrCodeUrl }: Props) => {
  const styles = StyleSheet.create(
    withBleed({
      nametag: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontFamily: "Helvetica",
      },
      mainSection: {
        paddingBottom: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      mainSectionColumn: {
        display: "flex",
        flexDirection: "column",
      },
      leftSide: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
      },
      rightSide: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      nameContainer: {
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
      },
      namePlaceholder: {
        height: 100,
      },
      firstName: {
        fontSize: 32,
        fontWeight: 500,
        marginBottom: 10,
      },
      lastName: {
        fontSize: 32,
        fontWeight: 500,
      },
      subtitle: {
        fontWeight: 300,
        fontSize: 26,
      },
      personalOneLiner: {
        fontSize: 18,
        marginTop: 20,
        width: 250,
      },
      divider: {
        backgroundColor: YC_ORANGE,
        height: 3,
        width: 100,
        margin: "20px 0",
      },
      ycLogo: {
        marginBottom: 20,
        width: 175,
        height: 35,
      },
      qrCode: {
        height: 240,
        width: 240,
      },
      bottomBar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
      },
      bottomBarText: {
        fontSize: 16,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontFamily: "Helvetica",
      },
    })
  );

  return (
    <View style={styles.nametag}>
      <View style={styles.mainSection}>
        <View style={styles.leftSide}>
          <View style={styles.nameContainer}>
            <Text style={styles.firstName}>{nametag.firstName}</Text>
            <Text style={styles.lastName}>{nametag.lastName}</Text>
          </View>
          <View style={styles.divider} />
          <Text style={styles.subtitle}>{nametag.subtitle}</Text>
          <Text style={styles.personalOneLiner}>{nametag.oneLiner}</Text>
        </View>
        <View style={styles.rightSide}>
          <Image src={`${window.location.origin}/ycombinator-logo.png`} style={styles.ycLogo} />
          {qrCodeUrl && <Image src={qrCodeUrl} style={styles.qrCode} />}
        </View>
      </View>
      <View style={[styles.bottomBar, { backgroundColor: bottomBar.backgroundColor }]}>
        <Text style={[styles.bottomBarText, { color: bottomBar.textColor }]}>{bottomBar.text}</Text>
      </View>
    </View>
  );
};
