import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { PDFViewer, Document, Page, Font } from "@react-pdf/renderer";
import { css } from "@emotion/core";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { pick } from "lodash";
import { getSlugFromWindowLocation, YC_ORANGE } from "../../components/forms/util";
import {
  MEETUP_NAMETAGS,
  MEETUP_NAMETAGS_admin_meetupRsvps,
} from "./__generated__/MEETUP_NAMETAGS";
import EditMeetupNametagFieldsModal, {
  NametagFields,
} from "./meetups/EditMeetupNametagFieldsModal";
import { MeetupRsvpFragment } from "../cofounderMatching/fragments";
import MEETUP_COLUMNS, {
  allAvailableColumnsForEvent,
  MeetupColumnType,
} from "./meetups/MeetupColumns";
import { formatRsvpWithQuestionResponses, RsvpType } from "./meetups/meetupUtils";
import useBleedStyles from "./meetups/nametags/useBleedStyles";
import { AttendeeType, BottomBarContent, NametagContent } from "./meetups/nametags/NametagTypes";
import HorizontalNametag from "./meetups/nametags/HorizontalNametag";
import NametagWithQRCode from "./meetups/nametags/NametagWithQRCode";
import CustomNametagUpload from "./meetups/nametags/CustomNametagUpload";
import VerticalNametag from "./meetups/nametags/VerticalNametag";

export default () => {
  const id = getSlugFromWindowLocation();
  const [customAttendees, setCustomAttendees] = useState<AttendeeType[]>([]);
  const [subtitleColumn, setSubtitleColumn] = useState<MeetupColumnType | undefined>();
  const [oneLinerColumn, setOneLinerColumn] = useState<MeetupColumnType | undefined>();
  const [bottomBarColumn, setBottomBarColumn] = useState<MeetupColumnType | undefined>();
  const { hasBleed, setHasBleed, vertical, setVertical, withBleed, heightPx, widthPx } =
    useBleedStyles();

  const { data, refetch } = useQuery<MEETUP_NAMETAGS>(
    gql`
      query MEETUP_NAMETAGS($id: ID!, $attendingOnly: Boolean, $bigEvent: Boolean!) {
        admin {
          adminMeetup(id: $id) {
            slug
            eventType
            title
            additionalNametags
            nametagFields
            defaultColumns
            questions {
              id
              questionText
              questionType
            }
          }
          meetupRsvps(id: $id, attendingOnly: $attendingOnly) {
            ...MeetupRsvpFragment
          }
        }
      }
      ${MeetupRsvpFragment}
    `,
    { variables: { id, attendingOnly: true, bigEvent: false } }
  );

  useEffect(() => {
    if (!data?.admin?.adminMeetup?.nametagFields) {
      return;
    }

    const nametagFields: any = JSON.parse(data?.admin?.adminMeetup?.nametagFields);

    const columns = allAvailableColumnsForEvent(data.admin.adminMeetup);

    if (nametagFields?.subtitle?.field) {
      setSubtitleColumn(columns.find((col) => col.title === nametagFields?.subtitle?.field));
    }
    if (nametagFields?.oneLiner?.field) {
      setOneLinerColumn(columns.find((col) => col.title === nametagFields?.oneLiner?.field));
    }
    if (nametagFields?.bottomBar?.field) {
      setBottomBarColumn(columns.find((col) => col.title === nametagFields?.bottomBar?.field));
    }
  }, [data?.admin?.adminMeetup?.nametagFields]);

  Font.registerHyphenationCallback((word) => [word]);

  const meetup = data?.admin.adminMeetup;
  if (!meetup) {
    return <div />;
  }

  const cfm = () => meetup.eventType === "cfm";

  const getTechnicalText = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps) => {
    if (!rsvp.adminUser) {
      return "";
    }

    return rsvp.adminUser.user.isTechnical ? "technical" : "non-technical";
  };

  const getCfmBottomBarContent = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps): BottomBarContent => {
    let text = "Open to ideas";
    let textColor = "white";
    let backgroundColor = "#21ba45";
    const hasIdea = rsvp.cfmProfile?.profile.hasIdea;
    if (hasIdea === "open") {
      textColor = "black";
      backgroundColor = "#fbdb05";
      text = rsvp.ideaOneLiner || "Idea; open to others";
    } else if (hasIdea === "committed") {
      backgroundColor = "red";
      text = rsvp.ideaOneLiner || "Committed to idea";
    }

    return { text, textColor, backgroundColor };
  };

  const getBottomBarContent = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps): BottomBarContent => ({
    text: getValueForField(bottomBarColumn, nametagFields?.bottomBar?.prefix, rsvp, meetup.title),
    backgroundColor: YC_ORANGE,
    textColor: "white",
  });

  const getAlumniDetails = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps) => {
    if (!rsvp.ssoUser.ycAlumCompanyNames?.length) {
      return;
    }

    const company = MEETUP_COLUMNS.YC_ALUM_COMPANY_NAME?.renderAsText?.(rsvp as RsvpType, true);
    const batch = MEETUP_COLUMNS.YC_ALUM_COMPANY_BATCH?.renderAsText?.(rsvp as RsvpType, true);

    if (!company || !batch) {
      return;
    }

    return `${company} (${batch})`;
  };

  const getCfmNametagContent = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps): NametagContent => {
    const { firstName, lastName } = rsvp.ssoUser;

    return {
      firstName,
      lastName,
      subtitle: getValueForField(
        subtitleColumn,
        nametagFields?.subtitle?.prefix,
        rsvp,
        getTechnicalText(rsvp)
      ),
      oneLiner: getValueForField(
        oneLinerColumn,
        nametagFields?.oneLiner?.prefix,
        rsvp,
        rsvp.personalOneLiner
      ),
    };
  };

  const getNametagContent = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps): NametagContent => {
    const { firstName, lastName } = rsvp.ssoUser;

    return {
      firstName,
      lastName,
      subtitle: getValueForField(subtitleColumn, nametagFields?.subtitle?.prefix, rsvp),
      oneLiner: getValueForField(oneLinerColumn, nametagFields?.oneLiner?.prefix, rsvp),
    };
  };

  const getValueForField = (
    columnForField: MeetupColumnType | undefined,
    prefix: string | undefined,
    rsvp: MEETUP_NAMETAGS_admin_meetupRsvps | undefined,
    defaultValue: string | undefined | null = undefined
  ) => {
    if (!rsvp || (!columnForField && !prefix)) {
      return defaultValue || "";
    }

    let fieldValue = "";
    if (columnForField) {
      const formattedRsvp = formatRsvpWithQuestionResponses(rsvp);
      if (columnForField.renderAsText) {
        fieldValue = columnForField.renderAsText(formattedRsvp, true) || "";
      } else {
        fieldValue = (columnForField.render(formattedRsvp) as string) || "";
      }
    }
    return [prefix?.trim(), fieldValue].filter((val) => !!val).join(" ");
  };

  const nametagFields: NametagFields = JSON.parse(meetup?.nametagFields) || {};

  const renderBlankNametag = (hasIdea: string | null) => {
    const textColor = "white";
    let backgroundColor = YC_ORANGE;
    let text = meetup.title;

    if (hasIdea === "none") {
      backgroundColor = "#21ba45";
      text = "Open to ideas";
    } else if (hasIdea === "open") {
      backgroundColor = "#fbdb05";
      text = "";
    } else if (hasIdea === "committed") {
      backgroundColor = "red";
      text = "";
    }

    const Component = vertical ? VerticalNametag : HorizontalNametag;

    return (
      <Component
        nametag={{ firstName: "", lastName: "", oneLiner: "", subtitle: "" }}
        bottomBar={{ textColor, backgroundColor, text }}
        withBleed={withBleed}
      />
    );
  };

  const renderBlankNametags = (hasIdea: string | null, count: number) => {
    const blankNametags = [];
    for (let i = 0; i < count; i += 1) {
      blankNametags.push(
        <Page key={`${hasIdea}-${count}`} size={[widthPx, heightPx]}>
          {renderBlankNametag(hasIdea)}
        </Page>
      );
    }

    return blankNametags;
  };

  const renderNametagsForAttendees = () => {
    const Component = vertical ? VerticalNametag : HorizontalNametag;
    return sortByName([...data.admin.meetupRsvps]).map((rsvp) => (
      <Page key={rsvp.id} size={[widthPx, heightPx]}>
        {cfm() ? (
          <NametagWithQRCode
            nametag={getCfmNametagContent(rsvp)}
            bottomBar={getCfmBottomBarContent(rsvp)}
            withBleed={withBleed}
            qrCodeUrl={rsvp.qrCodeUrl}
          />
        ) : (
          <Component
            nametag={getNametagContent(rsvp)}
            bottomBar={getBottomBarContent(rsvp)}
            alumniDetails={getAlumniDetails(rsvp)}
            withBleed={withBleed}
          />
        )}
      </Page>
    ));
  };

  const getSortKey = (rsvp: MEETUP_NAMETAGS_admin_meetupRsvps, secondarySort?: boolean) => {
    const firstName = rsvp.ssoUser.firstName.trim().toLowerCase();
    const lastName = rsvp.ssoUser.lastName.trim().toLowerCase();

    // sort by first then last name for CFM events, otherwise sort by last then first
    return cfm() === !!secondarySort ? lastName : firstName;
  };

  const sortByName = (rsvps: MEETUP_NAMETAGS_admin_meetupRsvps[]) =>
    rsvps.sort((r1, r2) => {
      const first1 = getSortKey(r1);
      const last1 = getSortKey(r1, true);
      const first2 = getSortKey(r2);
      const last2 = getSortKey(r2, true);

      if (first1 !== first2) {
        return first1 < first2 ? -1 : 1;
      }

      return last1 < last2 ? -1 : 1;
    });

  const NametagComponent = vertical ? VerticalNametag : HorizontalNametag;
  return (
    <div>
      <div style={{ display: "flex", gap: "2em" }}>
        <CustomNametagUpload
          id={id}
          additionalNametags={meetup.additionalNametags}
          setCustomAttendees={setCustomAttendees}
        />

        <div css={css({ minWidth: "200px", flex: 1 })}>
          <div css={css({ display: "flex", marginBottom: 10 })}>
            <div css={css({ display: "flex", flexDirection: "column", gap: 10 })}>
              <FormControlLabel
                control={<Checkbox color="default" />}
                checked={hasBleed}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHasBleed(e.target.checked)}
                label="Add bleed (check if sending out for printing)"
              />
              <FormControlLabel
                control={<Checkbox color="default" />}
                checked={vertical}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVertical(e.target.checked)}
                label="Vertical?"
              />
            </div>
            <EditMeetupNametagFieldsModal meetup={meetup} onUpdate={refetch} />
          </div>

          <PDFViewer css={css({ width: "100%", height: "100vh" })}>
            <Document>
              {customAttendees.map((attendee) => (
                <Page key={`${attendee.firstName} ${attendee.lastName}`} size={[widthPx, heightPx]}>
                  <NametagComponent
                    nametag={pick(attendee, [
                      "firstName",
                      "lastName",
                      "subtitle",
                      "secondSubtitle",
                    ])}
                    bottomBar={{
                      text: attendee.bottomText,
                      textColor: attendee.bottomTextColor,
                      backgroundColor: attendee.bottomBarColor,
                    }}
                    withBleed={withBleed}
                  />
                </Page>
              ))}

              {renderNametagsForAttendees()}

              {cfm() ? (
                <>
                  {renderBlankNametags("none", 10)}
                  {renderBlankNametags("open", 10)}
                  {renderBlankNametags("committed", 10)}
                </>
              ) : (
                renderBlankNametags(null, 30)
              )}
            </Document>
          </PDFViewer>
        </div>
      </div>
    </div>
  );
};
