import { useMutation, gql } from "@apollo/client";
import { css } from "@emotion/core";
import { MarkdownComponent } from "@yc/shared/renderMarkdown";
import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  withStyles,
} from "@material-ui/core";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { fontSize } from "../../styles";
import DashboardCheckbox from "../dashboard/Checkbox";
import { COMPLETE_GOAL, COMPLETE_GOALVariables } from "./__generated__/COMPLETE_GOAL";
import { UPDATES_INDEX_updates_updates } from "./__generated__/UPDATES_INDEX";

const BaseCell = withStyles({
  root: {
    fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    fontSize: 16,
    width: "33%",
  },
})(TableCell);

const DataCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(BaseCell);

const HeaderCell = withStyles({
  root: {
    fontWeight: 600,
  },
})(BaseCell);

const TextSection: React.FC<{ title: string; body: string | null }> = (props) => {
  if (!props.body) {
    return null;
  }
  return (
    <>
      <h4>{props.title}</h4>
      <MarkdownComponent content={props.body} />
    </>
  );
};

const CompletableGoals: React.FC<{
  update: UPDATES_INDEX_updates_updates;
}> = ({ update }) => {
  const [completeGoal] = useMutation<COMPLETE_GOAL, COMPLETE_GOALVariables>(gql`
    mutation COMPLETE_GOAL($updateId: ID!, $goalKey: ID!, $completed: Boolean!) {
      completeGoal(updateId: $updateId, goalKey: $goalKey, completed: $completed) {
        id
        completableGoals {
          goal
          key
          completed
        }
      }
    }
  `);

  const setCompleted = async (key: string, completed: boolean) =>
    completeGoal({
      variables: { updateId: update.id, goalKey: key, completed },
    });

  const goals = update.completableGoals?.map((goal) => (
    <div key={goal.key}>
      <FormControlLabel
        control={<Checkbox color="default" />}
        label={goal.goal}
        checked={goal.completed != null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCompleted(goal.key, e.target.checked ?? false);
        }}
        disabled={!update.canEdit}
      />
    </div>
  ));

  const numGoals = update.completableGoals?.length ?? 0;
  const numComplete = update.completableGoals?.filter((g) => g.completed != null).length ?? 0;
  const anyGoals = numGoals > 0;
  const goalsComplete = anyGoals && numGoals === numComplete;

  let goalsSubtitle = "";
  if (goalsComplete) {
    goalsSubtitle = "Nice job hitting your goals!";
  } else if (anyGoals) {
    goalsSubtitle = `${numComplete}/${numGoals} goals marked complete`;
  }

  return (
    <>
      <h4>
        {anyGoals && (
          <DashboardCheckbox
            checked={goalsComplete}
            size={20}
            css={css({ marginRight: 7, marginBottom: -2 })}
          />
        )}
        Goals
        <span
          css={css({
            fontStyle: "italic",
            fontSize: fontSize.small,
            paddingLeft: 7,
            lineHeight: "100%",
          })}
        >
          {goalsSubtitle}
        </span>
      </h4>
      {goals}
    </>
  );
};

export default function Update(props: { update: UPDATES_INDEX_updates_updates }) {
  const u = props.update;
  return (
    <Pane key={u.id}>
      <PaneHeader>
        <h3 css={css({ flex: 1 })}>{u.formattedDate}</h3>
        {u.canEdit && <a href={u.path}>Edit</a>}
      </PaneHeader>
      <PaneItem>
        <TableContainer>
          <Table aria-label="updates-table">
            <TableHead>
              <TableRow>
                <HeaderCell align="center">{u.metricDisplayName}</HeaderCell>
                <HeaderCell align="center">Morale</HeaderCell>
                <HeaderCell align="center">Users talked to</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <DataCell align="center">{u.metricValue}</DataCell>
                <DataCell align="center">{u.morale}/10</DataCell>
                <DataCell align="center">{u.talkedTo}</DataCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </PaneItem>
      {(u.learnedFromUsers || u.biggestChange || u.biggestBlocker || u.completableGoals) && (
        <PaneItem>
          {u.completableGoals != null ? (
            <CompletableGoals update={u} />
          ) : (
            <TextSection title="Goals" body={u.goals} />
          )}
          <TextSection title="Learned from users?" body={u.learnedFromUsers} />
          <TextSection title="Most improved primary metric?" body={u.biggestChange} />
          <TextSection title="Biggest obstacle?" body={u.biggestBlocker} />
        </PaneItem>
      )}
    </Pane>
  );
}
