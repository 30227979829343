import { useMutation, useQuery, gql } from "@apollo/client";
import { css } from "@emotion/core";
import { pick } from "lodash";
import React, { useState } from "react";
import { Grid, TextFieldProps } from "@material-ui/core";
import Icon from "../../components/icon";
import { Pane, PaneItem } from "../../components/Pane";
import Button from "../../components/statelessForms/Button";
import Input from "../../components/statelessForms/Input";
import { colors } from "../../styles";
import { Section } from "./shared";
import {
  ADD_FOLLOWER,
  ADD_FOLLOWERVariables,
  ADD_FOLLOWER_addFollower,
  ADD_FOLLOWER_addFollower_errors,
} from "./__generated__/ADD_FOLLOWER";
import { REMOVE_FOLLOWER, REMOVE_FOLLOWERVariables } from "./__generated__/REMOVE_FOLLOWER";
import { UPDATE_FOLLOWERS } from "./__generated__/UPDATE_FOLLOWERS";

type ActiveRecordModel = {
  errors?: ADD_FOLLOWER_addFollower_errors[] | null;
};

function errorsFor<T extends ActiveRecordModel, U extends keyof T>(
  model: T | null,
  field: U
): undefined | { content: string } {
  const fieldErrors = model?.errors?.find((e) => e.fieldName === field);
  if (fieldErrors == null) return undefined;

  return { content: fieldErrors.errors.join(" | ") };
}

const FollowersSection: React.FC = () => {
  const [newFollower, setNewFollower] = useState<Partial<ADD_FOLLOWER_addFollower>>({});

  const bindField = (field: keyof typeof newFollower): TextFieldProps => ({
    value: newFollower[field] || "",
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setNewFollower({ ...newFollower, [field]: e.target.value }),
    error: !!errorsFor(newFollower, field),
    onKeyDown: (e: React.KeyboardEvent) => e.keyCode === 13 && addFollower(e),
  });

  const [addFollowerMutation] = useMutation<ADD_FOLLOWER, ADD_FOLLOWERVariables>(gql`
    mutation ADD_FOLLOWER($follower: FollowerAttributes!) {
      addFollower(follower: $follower) {
        id
        name
        email
        secureSlug
        errors {
          fieldName
          errors
        }
      }
    }
  `);

  const [removeFollowerMutation] = useMutation<REMOVE_FOLLOWER, REMOVE_FOLLOWERVariables>(gql`
    mutation REMOVE_FOLLOWER($secureSlug: ID!) {
      removeFollower(secureSlug: $secureSlug) {
        id
      }
    }
  `);

  const { data, refetch: refetchFollowers } = useQuery<UPDATE_FOLLOWERS>(gql`
    query UPDATE_FOLLOWERS {
      updates {
        followers {
          id
          name
          email
          secureSlug
        }
      }
    }
  `);

  const followers = data?.updates?.followers ?? [];

  const addFollower = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();

    const mutationResponse = await addFollowerMutation({
      variables: { follower: pick(newFollower, "name", "email") },
    });
    if (mutationResponse.data?.addFollower.id) {
      setNewFollower({});
      refetchFollowers();
    } else if (mutationResponse?.data?.addFollower) {
      setNewFollower(mutationResponse?.data?.addFollower);
    }
  };

  const removeFollower = async (secureSlug: string) => {
    await removeFollowerMutation({ variables: { secureSlug } });
    refetchFollowers();
  };

  const submitEnabled =
    (newFollower.name?.length ?? 0) > 0 && (newFollower?.email ?? "").match(/.+@.+/);

  return (
    <Section title="My Followers">
      <p>
        <em>Followers</em> will get an email every time you submit a new update. They'll also be
        able to see your past updates and your progress over time. Followers could be investors,
        advisors, other peer founders or even family members.
      </p>
      {data && data.updates?.followers.length === 0 && (
        <p>
          Having followers will motivate you to get more done each week.{" "}
          <strong>We recommend you add at least one follower!</strong>
        </p>
      )}
      <h3>Add Follower</h3>

      <Grid container spacing={0} alignItems="center">
        <Grid item xs>
          <Input placeholder="Name" {...bindField("name")} />
        </Grid>
        <Grid item xs>
          <Input placeholder="Email" {...bindField("email")} />
        </Grid>
        <Grid item>
          <Button disabled={!submitEnabled} onClick={addFollower} size="large">
            Add
          </Button>
        </Grid>
      </Grid>
      {(followers?.length ?? 0) > 0 && (
        <>
          <h3>Followers</h3>
          <Pane>
            {followers?.map((f) => (
              <PaneItem
                css={css({ display: "flex", padding: 0, alignItems: "center" })}
                key={f.secureSlug || undefined}
              >
                <div css={css({ flex: 1, paddingLeft: 20 })}>
                  {f.name}{" "}
                  <span css={css({ color: colors.lightText, paddingLeft: 14 })}>{f.email}</span>
                </div>
                <Icon
                  icon="times"
                  css={css({
                    width: 50,
                    height: 50,
                    padding: 15,
                    cursor: "pointer",
                    "&:hover": { color: "#666" },
                  })}
                  onClick={() => removeFollower(f.secureSlug || "")}
                />
              </PaneItem>
            ))}
          </Pane>
        </>
      )}
    </Section>
  );
};

export default FollowersSection;
