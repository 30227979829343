import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Dialog } from "@material-ui/core";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { AddCircle } from "@material-ui/icons";
import {
  DELETE_MEETUP_QUESTION,
  DELETE_MEETUP_QUESTIONVariables,
} from "./__generated__/DELETE_MEETUP_QUESTION";
import { MEETUP_QUESTIONS_admin_meetup_questions } from "./__generated__/MEETUP_QUESTIONS";
import Button, { BasicButton } from "../../../components/statelessForms/Button";
import LoadingDots from "../../../components/statelessForms/LoadingDots";
import { Dropdown, Input, Submit, Textarea, useForm } from "../../../components/forms";
import useUpdateQuestion from "./useUpdateQuestion";

type Props = {
  meetupId: string;
  question?: MEETUP_QUESTIONS_admin_meetup_questions | null;
  onUpdate: () => void;
};

const QUESTION_TYPES = new Map<string, string>([
  ["short_text", "Text input (one line)"],
  ["long_text", "Text input (multiline)"],
  ["number", "Number"],
  ["checkboxes", "Checkboxes (choose multiple from list)"],
  ["radio", "Radio buttons (choose one from list)"],
]);

const MULTIPLE_CHOICE_TYPES = ["checkboxes", "radio"];

const FormWrapper = styled.div`
  padding: 30px;
`;

const DeleteButtonWrapper = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

export default ({ meetupId, question, onUpdate }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateQuestion = useUpdateQuestion();

  const [deleteQuestion] = useMutation<DELETE_MEETUP_QUESTION, DELETE_MEETUP_QUESTIONVariables>(gql`
    mutation DELETE_MEETUP_QUESTION($id: ID!) {
      deleteMeetupQuestion(id: $id) {
        success
      }
    }
  `);

  const splitAndTrim = (text: string) =>
    text
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => !!line);

  const onSubmitUpdate = async (fields: any) => {
    setIsSubmitting(true);

    const otherRequirements: any = {};
    if (MULTIPLE_CHOICE_TYPES.includes(fields.questionType)) {
      otherRequirements.multipleChoiceOptions = splitAndTrim(fields.multipleChoiceOptions);
    }

    await updateQuestion({
      input: {
        meetupId: parseInt(meetupId, 10),
        questionId: question?.id,
        questionText: fields.questionText,
        questionSubtitle: fields.questionSubtitle,
        questionType: fields.questionType,
        required: fields.required === "yes",
        otherRequirements: JSON.stringify(otherRequirements),
      },
    });

    setIsSubmitting(false);

    onUpdate();
    setIsOpen(false);
    if (!question) {
      formMethods.reset({});
    }
  };

  const onDelete = async () => {
    if (!question) {
      return;
    }
    if (
      confirm(
        "Are you sure you want to delete this question? This will also delete responses for anyone who's already answered it."
      )
    ) {
      await deleteQuestion({ variables: { id: question.id } });
      onUpdate();
      setIsOpen(false);
    }
  };

  const getValuesFromQuestion = (
    newQuestion: MEETUP_QUESTIONS_admin_meetup_questions | null | undefined
  ) => {
    if (!newQuestion) {
      return {
        questionText: "",
        questionSubtitle: "",
        questionType: null,
        required: null,
        multipleChoiceOptions: [],
      };
    }

    const {
      otherRequirements: otherRequirementsStr,
      questionText,
      questionSubtitle,
      questionType,
      required,
    } = newQuestion;
    const otherRequirements = JSON.parse(otherRequirementsStr) || {};
    return {
      questionText,
      questionSubtitle,
      questionType,
      required: required ? "yes" : "no",
      multipleChoiceOptions: otherRequirements?.multipleChoiceOptions?.join("\n"),
    };
  };

  const { formMethods, ConnectedForm, connectedFormProps } = useForm(
    getValuesFromQuestion(question),
    onSubmitUpdate
  );

  useEffect(() => {
    formMethods.reset(getValuesFromQuestion(question));
  }, [question]);

  const questionType = formMethods.watch("questionType");

  const loadingSection = (
    <div css={css({ width: "100%", display: "flex", justifyContent: "center", padding: 20 })}>
      <LoadingDots />
    </div>
  );

  const formFields = (
    <FormWrapper>
      <Input fieldName="questionText" label="Question text" required />
      <Input fieldName="questionSubtitle" label="Question subtitle/explanation (optional)" />

      <Dropdown fieldName="questionType" label="Question type" options={QUESTION_TYPES} required />

      <Dropdown
        fieldName="required"
        label="Is this required?"
        required
        options={[
          ["yes", "Yes"],
          ["no", "No"],
        ]}
      />

      {questionType && MULTIPLE_CHOICE_TYPES.includes(questionType) && (
        <Textarea
          fieldName="multipleChoiceOptions"
          label="Multiple choice options to choose from"
          subLabel="Enter one option per line"
          minRows={5}
          required
        />
      )}

      <Submit />
    </FormWrapper>
  );

  return (
    <div>
      <Button color="blue" onClick={() => setIsOpen(true)}>
        <div css={css({ display: "flex", alignItems: "center", gap: 10 })}>
          {!question && <AddCircle />}
          <span>{question ? "Edit" : "Add question"}</span>
        </div>
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} scroll="body" maxWidth="md">
        <ConnectedForm {...connectedFormProps}>
          {isSubmitting ? loadingSection : formFields}
        </ConnectedForm>
        {!!question && (
          <DeleteButtonWrapper>
            <BasicButton onClick={onDelete}>Delete question</BasicButton>
          </DeleteButtonWrapper>
        )}
      </Dialog>
    </div>
  );
};
