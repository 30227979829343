import styled from "@emotion/styled";
import { css } from "@emotion/core";
import React, { useMemo } from "react";
import { Checkbox } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { MeetupColumnType, PinnedNote } from "./MeetupColumns";
import {
  ADMIN_CONFIRM_MEETUP_RSVP,
  ADMIN_CONFIRM_MEETUP_RSVPVariables,
} from "../__generated__/ADMIN_CONFIRM_MEETUP_RSVP";
import {
  UPDATE_MEETUP_NO_SHOW,
  UPDATE_MEETUP_NO_SHOWVariables,
} from "../__generated__/UPDATE_MEETUP_NO_SHOW";
import { formatTime, getAdminUrl, RsvpType } from "./meetupUtils";
import useAcceptRsvps from "./useAcceptRsvps";
import useRejectRsvps from "./useRejectRsvps";
import { YC_ORANGE } from "../../../components/forms/util";
import {
  UPDATE_MEETUP_CHECKED_IN,
  UPDATE_MEETUP_CHECKED_INVariables,
} from "./__generated__/UPDATE_MEETUP_CHECKED_IN";
import useSetRsvpStatus from "./useSetRsvpStatus";
import { MEETUP_RSVPS_admin_adminMeetup } from "../__generated__/MEETUP_RSVPS";
import Select from "../../../components/statelessForms/Select";

export type RowProps = {
  status?: string;
};
export const Row = styled.tr<RowProps>`
  padding: 5px 0;
  border-top: 1px solid black;
  ${({ status }) => {
    switch (status) {
      case "accepted":
        return css`
          background-color: #ffffba;
        `;
      case "invite_pending":
        return css`
          background-color: #bae1ff;
        `;
      case "confirmed":
        return css`
          background-color: #baffc9;
        `;
      case "invite_expired":
      case "rejected":
      case "rejection_sent":
      case "declined":
        return css`
          background-color: #e0e0e0;
          color: gray;
          font-style: italic;
          font-weight: 300;
        `;
      default:
        return css`
          background-color: white;
        `;
    }
  }}
  th {
    padding: 10px 5px;
  }
  td {
    padding: 10px 2px;
    white-space: pre-wrap;
    position: relative;
  }
`;

const CompactConfirmButton = styled.button`
  background-color: #767676;
  color: white;
  padding: 5px 0;
  font-size: 12px;
  width: 50px;
  border: none;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
  }
`;

const ScrollableCellContents = styled.div`
  max-height: 150px;
  overflow-y: auto;
`;

type ViewLinkProps = {
  highlighted: boolean;
};
const ViewLink = styled.a<ViewLinkProps>`
  font-weight: ${({ highlighted }) => (highlighted ? "bold" : "regular")};
  color: ${({ highlighted }) => (highlighted ? YC_ORANGE : "default")};
`;

type Props = {
  rsvp: RsvpType;
  meetup: MEETUP_RSVPS_admin_adminMeetup;
  columns: MeetupColumnType[];
  isOver: boolean;
  hideActions?: boolean;
  checkInView: boolean;
  setAttendeesView: boolean;
  ohOwnerOptions?: [string, string][];
};

export default ({
  rsvp,
  meetup,
  columns,
  isOver,
  hideActions,
  checkInView,
  setAttendeesView,
  ohOwnerOptions,
}: Props) => {
  const acceptRsvps = useAcceptRsvps();
  const rejectRsvps = useRejectRsvps();
  const setRsvpStatus = useSetRsvpStatus();

  const [confirmRsvp] = useMutation<
    ADMIN_CONFIRM_MEETUP_RSVP,
    ADMIN_CONFIRM_MEETUP_RSVPVariables
  >(gql`
    mutation ADMIN_CONFIRM_MEETUP_RSVP($id: ID!) {
      adminConfirmMeetupRsvp(id: $id) {
        id
        status
      }
    }
  `);

  const [setNoShow] = useMutation<UPDATE_MEETUP_NO_SHOW, UPDATE_MEETUP_NO_SHOWVariables>(gql`
    mutation UPDATE_MEETUP_NO_SHOW($id: ID!, $noShow: Boolean!) {
      updateMeetupNoShow(id: $id, noShow: $noShow) {
        id
        noShow
        checkedIn
      }
    }
  `);

  const [setCheckedIn] = useMutation<
    UPDATE_MEETUP_CHECKED_IN,
    UPDATE_MEETUP_CHECKED_INVariables
  >(gql`
    mutation UPDATE_MEETUP_CHECKED_IN($id: ID!, $checkedIn: Boolean!) {
      updateMeetupCheckedIn(id: $id, checkedIn: $checkedIn) {
        id
        checkedIn
        noShow
      }
    }
  `);

  const onNoShowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoShow({
      variables: {
        id: rsvp.id,
        noShow: e.target.checked,
      },
    });
  };
  const noShowCheckbox = <Checkbox checked={rsvp.noShow} onChange={onNoShowChange} />;

  const onCheckInChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedIn({
      variables: {
        id: rsvp.id,
        checkedIn: e.target.checked,
      },
    });
  };
  const checkInCheckbox = <Checkbox checked={rsvp.checkedIn} onChange={onCheckInChange} />;

  const manuallyConfirmRsvp = async () => {
    if (
      confirm(
        `Just double checking - are you trying to manually confirm (and email) ${rsvp.ssoUser.name}?`
      )
    ) {
      await confirmRsvp({ variables: { id: rsvp.id } });
    }
  };

  const onRejectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    rejectRsvps({
      ids: [rsvp.id],
      rejected: e.target.checked,
    });
  };

  const rejectCheckbox =
    rsvp.status && !["rejected", "rejoined_waitlist"].includes(rsvp.status) ? (
      ""
    ) : (
      <Checkbox checked={rsvp.status === "rejected"} onChange={onRejectChange} />
    );

  const onInviteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    acceptRsvps({
      ids: [rsvp.id],
      accepted: e.target.checked,
    });
  };

  const onAcceptForOwner = (ohOwnerId: string | undefined) => {
    if (!ohOwnerId) {
      return;
    }
    acceptRsvps({
      ids: [rsvp.id],
      accepted: true,
      ohOwnerId,
    });
  };

  const isOhEvent = useMemo(
    () => meetup.eventType === "office_hours" && !!ohOwnerOptions,
    [meetup, ohOwnerOptions]
  );
  const inviteCheckbox = (
    <div>
      {rsvp.status &&
      !["accepted", "rejected", "rejection_sent", "rejoined_waitlist"].includes(rsvp.status) ? (
        ""
      ) : (
        <div>
          {isOhEvent && rsvp.status !== "accepted" ? (
            <Select options={ohOwnerOptions!} onChange={(ownerId) => onAcceptForOwner(ownerId)} />
          ) : (
            <div>
              <Checkbox checked={rsvp.status === "accepted"} onChange={onInviteChange} />
            </div>
          )}
        </div>
      )}
      {isOhEvent && rsvp.ohOwner && <div>for {rsvp.ohOwner?.name}</div>}
    </div>
  );

  const onSetStatus = (status: "confirmed" | "declined", active: boolean) => {
    setRsvpStatus({ ids: [rsvp.id], status, active });
    if (status == "confirmed" && active && meetup.confirmationEmailEnabled) {
      toast.success(`${rsvp.ssoUser.firstName} will be emailed a calendar invite in 5 minutes.`);
    }
  };
  const renderSetStatusCheckbox = (status: "confirmed" | "declined") => (
    <Checkbox
      checked={rsvp.status === status}
      onChange={(e) => onSetStatus(status, e.target.checked)}
    />
  );

  const upcomingMeetupActions =
    rsvp.status === "declined" ? (
      <>
        <td>
          <CompactConfirmButton onClick={() => manuallyConfirmRsvp()}>
            Admin confirm
          </CompactConfirmButton>
        </td>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td />
      </>
    ) : (
      <>
        <td>{rejectCheckbox}</td>
        <td>{inviteCheckbox}</td>
      </>
    );

  const setAttendeeStatusActions = (
    <>
      <td>{renderSetStatusCheckbox("confirmed")}</td>
      <td>{renderSetStatusCheckbox("declined")}</td>
    </>
  );

  const url = getAdminUrl(meetup.eventType, rsvp);
  const viewLink = url ? (
    <ViewLink highlighted={rsvp.userHasNotes} href={url} target="_blank">
      View
    </ViewLink>
  ) : null;

  const renderActions = () => {
    if (hideActions) {
      return null;
    }

    if (checkInView) {
      return (
        <>
          <td>{checkInCheckbox}</td>
          <td>{noShowCheckbox}</td>
        </>
      );
    }

    if (isOver) {
      return <td>{noShowCheckbox}</td>;
    }

    if (setAttendeesView) {
      return setAttendeeStatusActions;
    }

    return upcomingMeetupActions;
  };

  const renderOhSlotDetails = () => {
    const slot = rsvp.ohSlot;
    if (!slot) {
      return <td />;
    }

    const time = formatTime(slot.startsAt);
    const names = [slot.owner.name, slot.owner2?.name].filter((n) => !!n).join(" and ");

    return (
      <td>
        {time} with {names}
      </td>
    );
  };

  return useMemo(
    () => (
      <Row status={rsvp.status}>
        {renderActions()}
        {isOhEvent && renderOhSlotDetails()}

        {columns.map((column) => (
          <td key={column.title}>
            <ScrollableCellContents>{column.render(rsvp)}</ScrollableCellContents>
          </td>
        ))}

        <td>
          <div css={css({ display: "flex", alignItems: "center" })}>
            {viewLink}
            {rsvp.ssoUser.pinnedNote && <PinnedNote {...rsvp.ssoUser.pinnedNote} />}
          </div>
        </td>
      </Row>
    ),
    [
      rsvp.status,
      rsvp.noShow,
      rsvp.checkedIn,
      rsvp.ohOwner?.id,
      columns.map((col) => col.title).join(", "),
    ]
  );
};
