import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { format, isBefore } from "date-fns";
import { ExpandMore } from "@material-ui/icons";
import { AppsStat, getBatchValue, TableSection } from "./meetupStatUtils";

type CountMap = { [key: string]: AppsStat };

type Props = {
  title?: string;
  countMap: CountMap;
  rowTitle: string;
  titleMapper?: (val: string) => string | React.ReactNode;
  dateMapper?: (val: string) => Date;
  defaultSort: string;
};

const Table = styled.table`
  width: 100%;
  border: 1px solid #e0e0e0;

  td {
    padding: 5px 10px;

    &:first-of-type {
      max-width: 200px;
    }
  }
  tr:not(:last-of-type) {
    td {
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

const HeaderRow = styled.tr`
  background-color: lightgrey;

  th {
    padding: 5px 10px;
    text-align: left;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export default ({ title, countMap, rowTitle, titleMapper, dateMapper, defaultSort }: Props) => {
  const [sort, setSort] = useState(defaultSort);

  const sortCountsByBatch = (map: CountMap) =>
    Object.entries(map).sort((e1, e2) => (getBatchValue(e1[0]) > getBatchValue(e2[0]) ? -1 : 1));

  const sortCountsByEventTitle = (map: CountMap) =>
    Object.entries(map).sort((e1, e2) => (e1[0].toLowerCase() < e2[0].toLowerCase() ? -1 : 1));

  const sortCountsByEventDate = (map: CountMap) =>
    dateMapper
      ? Object.entries(map).sort((e1, e2) =>
          isBefore(dateMapper(e2[0]), dateMapper(e1[0])) ? -1 : 1
        )
      : Object.entries(map);

  const sortCountsNumerically = (map: CountMap, attr: keyof AppsStat) =>
    Object.entries(map).sort((e1, e2) => (e2[1][attr] as number) - (e1[1][attr] as number));

  const parsePctAsNum = (pct: string) => Number.parseFloat(pct.slice(0, pct.length - 1));
  const sortCountsByPct = (map: CountMap, attr: keyof AppsStat) =>
    Object.entries(map).sort(
      (e1, e2) => parsePctAsNum(e2[1][attr] as string) - parsePctAsNum(e1[1][attr] as string)
    );

  const sortedCounts = useMemo(() => {
    if (sort === "batch") {
      return sortCountsByBatch(countMap);
    }

    if (sort === "event") {
      return sortCountsByEventTitle(countMap);
    }

    if (sort === "date" && dateMapper) {
      return sortCountsByEventDate(countMap);
    }

    if (sort.startsWith("pct")) {
      return sortCountsByPct(countMap, sort as keyof AppsStat);
    }

    return sortCountsNumerically(countMap, sort as keyof AppsStat);
  }, [sort]);

  const renderHeader = (headerTitle: string, sortKey: string) => (
    <th onClick={() => setSort(sortKey)}>
      <div>
        {headerTitle}
        {sort === sortKey && <ExpandMore />}
      </div>
    </th>
  );

  return (
    <TableSection>
      {!!title && <h2>{title}</h2>}
      <Table cellSpacing="0">
        <thead>
          <HeaderRow>
            {renderHeader(rowTitle, rowTitle.toLowerCase())}
            {dateMapper && renderHeader("Date", "date")}
            {renderHeader("Applied to YC", "applied")}
            {renderHeader("Interviewed", "interviewed")}
            {renderHeader("Accepted", "accepted")}
            {renderHeader("Incremental", "acceptedMentioned")}
            {renderHeader("% applied", "pctApplied")}
            {renderHeader("% into YC", "pctAccepted")}
            {renderHeader("% incremental", "pctAcceptedMentioned")}
            {renderHeader("acceptance", "pctAcceptance")}
          </HeaderRow>
        </thead>
        <tbody>
          {sortedCounts.map(([key, counts]) => (
            <tr key={key}>
              <td>{titleMapper ? titleMapper(key) : key}</td>
              {dateMapper && <td>{format(dateMapper(key), "MMM d, yyyy")}</td>}
              <td>{counts.applied}</td>
              <td>{counts.interviewed}</td>
              <td>{counts.accepted}</td>
              <td>{counts.acceptedMentioned}</td>
              <td>{counts.pctApplied}</td>
              <td>{counts.pctAccepted}</td>
              <td>{counts.pctAcceptedMentioned}</td>
              <td>{counts.pctAcceptance}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableSection>
  );
};
