import { css } from "@emotion/core";
import React, { ReactElement } from "react";
import pluralize from "pluralize";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import Button from "../../components/Button";
import Icon from "../../components/icon";
import { DASHBOARD_DATA } from "./__generated__/DASHBOARD_DATA";
import Requirement, { cappedFraction } from "./Requirement";
import { updatesPath } from "../../__generated__/routes";

const Sprint: React.FC<{ data: DASHBOARD_DATA }> = ({ data }) => {
  const { latestSprint } = data;

  let content: ReactElement<typeof PaneItem> | null = null;

  if (!latestSprint.enrolled && latestSprint.registrationOpen) {
    const timeRemaining = latestSprint.active ? (
      <p>
        Registration closes in{" "}
        <strong>{pluralize("day", latestSprint.daysUntilRegCloses, true)}</strong>!
      </p>
    ) : (
      <p>
        The sprint will kick off in{" "}
        <strong>{pluralize("day", latestSprint.daysUntilStart, true)}</strong>!
      </p>
    );
    content = (
      <PaneItem>
        <p>
          Registration is now open for the {latestSprint.name} YC Build Sprint. This is a{" "}
          {latestSprint.totalWeeks}-week period to work intensively towards a defined goal alongside
          a community of thousands of other founders.{" "}
        </p>
        {timeRemaining}
        <Button href="/sprint/register" type="cta" text="Register for Sprint" />
      </PaneItem>
    );
  } else if (latestSprint.enrolled) {
    const goal = (
      <p>
        Your sprint goal: <em>{latestSprint.goal ?? "please set a sprint goal"}</em>.{" "}
        {(latestSprint.registrationOpen || latestSprint.active) && (
          <a href="/sprint" css={css({ fontSize: "0.8em" })}>
            Edit goal
          </a>
        )}
      </p>
    );

    if (latestSprint.started) {
      const thisWeek = data.dashboard.updatesByWeek[0];
      const thisWeekSubmitted = thisWeek?.url != null;
      const followers = data.updates?.followers.length ?? 0;

      content = (
        <>
          <PaneItem>
            {latestSprint.finished && (
              <p>
                The sprint is now over; thanks for participating!{" "}
                {latestSprint.goalCompleted === null && (
                  <>
                    Be sure to <a href="/sprint">mark whether you completed your goal</a>.
                  </>
                )}
              </p>
            )}
            {latestSprint.active && (
              <p>
                This is week {latestSprint.currentWeek}/{latestSprint.totalWeeks}, and there are{" "}
                <strong>{pluralize("day", latestSprint.daysRemaining, true)}</strong> remaining in
                the YC Build Sprint!
              </p>
            )}
            {goal}
            {!thisWeekSubmitted && latestSprint.active && (
              <Button href="/updates/new" type="cta" text="Submit this week's update" />
            )}
          </PaneItem>
          <PaneItem css={css({ padding: "14px 0" })}>
            <Requirement
              done={latestSprint.updatesSubmitted >= 4}
              title={`${cappedFraction(latestSprint.updatesSubmitted, 4)} Weekly Updates Submitted`}
              link="/updates"
            />
            <Requirement
              done={latestSprint.gsAttended >= 1}
              title={`${cappedFraction(latestSprint.gsAttended, 1)} Group Sessions Attended`}
              link="/group_sessions"
            />
            <Requirement
              done={followers >= 1}
              title={`${cappedFraction(followers, 1)} Update Followers Added`}
              link={updatesPath()}
            />
            {latestSprint.finished && (
              <Requirement
                done={latestSprint.goalCompleted != null}
                title="Report Sprint Goal Completion"
                link="/sprint"
              />
            )}
          </PaneItem>
        </>
      );
    } else if (latestSprint.registrationOpen) {
      content = (
        <PaneItem>
          <p>
            <strong>You're in!</strong> The sprint will kick off in{" "}
            <strong>{pluralize("day", latestSprint.daysUntilStart, true)}</strong>.
          </p>
          {goal}
        </PaneItem>
      );
    }
  }

  if (content === null) return null;

  return (
    <Pane>
      <PaneHeader>
        <Icon icon="sprint" css={css({ height: "1.4em", marginRight: 7 })} />
        <h3 css={css({ flex: 1 })}>YC Build Sprint</h3>
        <a href="/sprint" target="_blank">
          More
        </a>
      </PaneHeader>
      {content}
    </Pane>
  );
};

export default Sprint;
