import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { loadDefaultHome } from "../components/forms/util";
import Invites from "../components/Invites";
import { Container, Page } from "../components/Page";
import Button from "../components/statelessForms/Button";
import { CompletableAction } from "../types/graphqlTypes";
import useCompleteAction from "./dashboard/useCompleteAction";
import { INVITES_PAGE } from "./__generated__/INVITES_PAGE";

// @ts-ignore
export default (props) => {
  const { data } = useQuery<INVITES_PAGE>(gql`
    query INVITES_PAGE {
      company {
        name
        slug
      }
    }
  `);

  const completeAction = useCompleteAction();

  const onFinish = async () => {
    await completeAction(CompletableAction.add_co_founders);
    await loadDefaultHome();
  };

  return (
    <Page title="Manage Co-Founders">
      <Container css={css({ maxWidth: 900 })}>
        <p>
          If you have any co-founders currently working with you on {data?.company?.name}, you can
          add them here.
        </p>
        <Invites {...props} />
        <div css={css({ "&&": { marginTop: 25 } })}>
          <Button color="orange" onClick={onFinish}>
            {!window.ONBOARDING_COMPLETE ? "Finish" : "Continue"}
          </Button>
        </div>
      </Container>
    </Page>
  );
};
