import React from "react";
import { useFormContext, useController } from "react-hook-form";
import { Rating, RatingProps } from "@material-ui/lab";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";

type Props = CustomFieldProps<RatingProps>;

export default (props: Props) => {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { required: fieldProps.required },
  });

  return (
    <Field {...fieldProps}>
      <Rating
        {...otherProps}
        name={props.fieldName}
        max={5}
        value={field.value || null}
        onChange={(_, newValue) => field.onChange(newValue)}
      />
    </Field>
  );
};
