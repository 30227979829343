import React, { useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Button from "./Button";
import LoadingDots from "./LoadingDots";

type Props = {
  title?: string;
  confirmationText?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<any>;
};

const ModalBody = styled.div`
  padding: 30px;
  h2 {
    text-align: center;
  }
`;

const ModalText = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 10px;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

export default ({ title, confirmationText, isOpen, onClose, onConfirm }: Props) => {
  const [updating, setUpdating] = useState(false);

  const handleConfirm = async () => {
    setUpdating(true);
    await onConfirm();
    setUpdating(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalBody>
        {updating ? (
          <div css={css({ display: "flex", justifyContent: "center" })}>
            <LoadingDots />
          </div>
        ) : (
          <>
            {!!title && <DialogTitle>{title}</DialogTitle>}
            <ModalText>{confirmationText}</ModalText>
            <ButtonRow>
              <Button color="orange" onClick={handleConfirm}>
                Yes, confirm
              </Button>
              <Button color="gray" onClick={onClose}>
                No, cancel
              </Button>
            </ButtonRow>
          </>
        )}
      </ModalBody>
    </Dialog>
  );
};
