import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { REVIEW_ALL_EMAIL_PUSHES } from "./__generated__/REVIEW_ALL_EMAIL_PUSHES";
import {
  adminEmailCampaignPath,
  adminMeetupPath,
  adminMeetupsPath,
} from "../../../__generated__/routes";
import Button from "../../../components/statelessForms/Button";
import LoadingDots from "../../../components/statelessForms/LoadingDots";
import { Pane, PaneItem } from "../../../components/Pane";
import InlineEmailPush from "./InlineEmailPush";

type Props = {
  campaignId: string | null;
  showNavOptions?: boolean;
};

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin-right: 10px;
  }
`;

const EmailPushSection = styled.div`
  padding: 50px;

  &:nth-of-type(2n) {
    background-color: #f0f0f0;
  }

  &:nth-of-type(2n + 1) {
    background-color: #fafafa;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 30px;
  }
`;

export default ({ campaignId, showNavOptions }: Props) => {
  const { data, loading } = useQuery<REVIEW_ALL_EMAIL_PUSHES>(
    gql`
      query REVIEW_ALL_EMAIL_PUSHES($id: ID!) {
        admin {
          emailCampaign(id: $id) {
            id
            name
            slug
            meetupId
            meetupStartsAt
            meetupTitle
            templateValues
            fromEmailOptions
            replyToEmailOptions
            emailPushes {
              id
              campaignId
              name
              slug
              subject
              subjectPreview
              emailBody
              emailBodyUserPreview
              fromEmail
              replyToEmail
              filters
              numUsersForFilters
              disabled
              lifecycleOnly
              autoLoginLinks
              sendsAt
            }
          }
        }
      }
    `,
    { variables: { id: campaignId }, skip: !campaignId }
  );

  const meetupTitle = data?.admin.emailCampaign.meetupTitle;
  const meetupId = data?.admin.emailCampaign.meetupId;

  if (!campaignId) {
    return <div />;
  }

  const titleSuffix = meetupTitle ? ` for event: ${meetupTitle}` : "";
  return (
    <Pane title={`All emails ${titleSuffix}`}>
      <PaneItem>
        {showNavOptions && (
          <ButtonRow>
            <a href={adminEmailCampaignPath(campaignId)}>
              <Button color="orange">Back to email campaign overview</Button>
              {meetupId && (
                <a href={adminMeetupPath(meetupId)}>
                  <Button color="blue">View event RSVPs</Button>
                </a>
              )}
            </a>
          </ButtonRow>
        )}
        {loading ? (
          <LoadingWrapper>
            <h2>Loading emails (this may take a minute)...</h2>
            <LoadingDots />
          </LoadingWrapper>
        ) : (
          <div>
            {data?.admin.emailCampaign.emailPushes.map((emailPush, idx) => (
              <EmailPushSection>
                <InlineEmailPush
                  number={idx + 1}
                  emailCampaign={data.admin.emailCampaign}
                  emailPush={emailPush}
                  key={emailPush.id}
                />
              </EmailPushSection>
            ))}
            {!!meetupId && (
              <div css={css({ marginTop: 30, textAlign: "center" })}>
                <a href={adminMeetupsPath()}>
                  <Button color="orange">Looks good - take me back to meetups</Button>
                </a>
              </div>
            )}
          </div>
        )}
      </PaneItem>
    </Pane>
  );
};
