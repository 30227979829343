import { css } from "@emotion/core";
import { MarkdownComponent } from "@yc/shared/renderMarkdown";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { colors } from "../../styles";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import Button from "../../components/Button";
import { DASHBOARD_DATA, DASHBOARD_DATA_dashboard_events } from "./__generated__/DASHBOARD_DATA";

const Event = ({ event }: { event: DASHBOARD_DATA_dashboard_events }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <PaneItem
      css={css({
        display: "flex",
        cursor: "pointer",
        ":hover": { backgroundColor: colors.clickablePaneHover },
      })}
      onClick={() => setOpen(!open)}
    >
      <div css={css({ flex: 1 })}>
        <div
          css={css({
            color: "#777",
            fontSize: 15,
            fontWeight: "bold",
            letterSpacing: "0.01em",
            marginBottom: 10,
            textTransform: "uppercase",
          })}
        >
          {event.displayTime}
        </div>
        <h2 css={css({ margin: "0 0 16px" })}>{event.title}</h2>
        {open && (
          <>
            <MarkdownComponent
              css={css({ color: colors.lightText, marginBottom: 16 })}
              content={event.description}
            />

            {event.actionUrl && event.actionText && (
              <div css={css({ display: "flex" })}>
                <Button type="cta" href={event.actionUrl ?? undefined} text={event.actionText} />
              </div>
            )}
          </>
        )}
      </div>
      <div css={css({ color: "#c7c7c7", fontSize: 20 })}>
        {open ? (
          <KeyboardArrowUpIcon fontSize="large" />
        ) : (
          <KeyboardArrowDownIcon fontSize="large" />
        )}
      </div>
    </PaneItem>
  );
};

const EventsModule: React.FC<{ data: DASHBOARD_DATA }> = ({ data }) => {
  if (data.dashboard.events.length === 0) return null;

  return (
    <Pane id="events_pane">
      <PaneHeader>
        <h3>📅 Upcoming Events ({data.dashboard.timeZone})</h3>
      </PaneHeader>
      {data.dashboard.events.map((event) => (
        <Event key={event.title} event={event} />
      ))}
    </Pane>
  );
};

export default EventsModule;
