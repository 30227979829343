import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "@emotion/styled";
import Button from "../../../components/statelessForms/Button";
import useAcceptRsvps from "./useAcceptRsvps";
import useRejectRsvps from "./useRejectRsvps";
import { RsvpType } from "./meetupUtils";

type BulkActionFilters = {
  rating?: string;
  status?: string;
  isWoman?: boolean;
  invitedOrBetter?: boolean;
};

type Props = {
  rsvps: RsvpType[];
};

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;

  button {
    margin: 0 10px;
  }
`;

const BulkActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    width: 200px;
  }
`;

export default ({ rsvps }: Props) => {
  const acceptRsvps = useAcceptRsvps();
  const rejectRsvps = useRejectRsvps();

  const getRsvpsMatchingFilters = ({
    rating,
    status,
    isWoman,
    invitedOrBetter,
  }: BulkActionFilters) => {
    const ids = rsvps
      .filter((rsvp) => {
        const voteVals = rsvp.adminVotes.map(({ val }) => val).filter(Boolean);
        if (rating && !voteVals.includes(rating)) {
          return false;
        }
        if (status !== undefined && status !== rsvp.status) {
          return false;
        }
        if (isWoman && !rsvp.adminUser?.user.isWoman) {
          return false;
        }

        if (invitedOrBetter && rsvp.ycAppBestResult !== "invited_or_better") {
          return false;
        }
        return true;
      })
      .map((rsvp) => rsvp.id);
    return ids || [];
  };

  const filterButtonTitle = (filters: BulkActionFilters) => {
    if (filters.isWoman) {
      return "women";
    }
    if (filters.invitedOrBetter) {
      return "invited+";
    }
    if (filters.rating) {
      return filters.rating;
    }
    return "";
  };

  const onAcceptRating = (filters: BulkActionFilters, accepted: boolean) => {
    const status = accepted ? "" : "accepted";
    const ids = getRsvpsMatchingFilters({ ...filters, status });

    if (!ids || !ids.length) {
      return;
    }

    acceptRsvps({ ids, accepted });
  };

  const acceptRatingButton = (filters: BulkActionFilters, accepted: boolean) => {
    const label = accepted ? "Accept" : "Un-accept";
    const onClick = () => onAcceptRating(filters, accepted);

    return (
      <Button size="small" onClick={onClick}>{`${label} all ${filterButtonTitle(filters)}`}</Button>
    );
  };

  const onRejectRating = (filters: BulkActionFilters, rejected: boolean) => {
    const status = rejected ? "" : "rejected";
    const ids = getRsvpsMatchingFilters({ ...filters, status });

    if (!ids || !ids.length) {
      return;
    }

    rejectRsvps({ ids, rejected });
  };

  const rejectRatingButton = (filters: BulkActionFilters, rejected: boolean) => {
    const label = rejected ? "Reject" : "Un-reject";
    const onClick = () => onRejectRating(filters, rejected);

    return (
      <Button size="small" onClick={onClick}>{`${label} all ${filterButtonTitle(filters)}`}</Button>
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Bulk actions</AccordionSummary>
      <AccordionDetails>
        <BulkActions>
          <h4>Accept actions</h4>
          <ActionRow>
            {acceptRatingButton({ rating: "Y" }, true)}
            {acceptRatingButton({ rating: "Y" }, false)}
            {acceptRatingButton({ rating: "y" }, true)}
            {acceptRatingButton({ rating: "y" }, false)}
          </ActionRow>
          <ActionRow>
            {acceptRatingButton({ rating: "m" }, true)}
            {acceptRatingButton({ rating: "m" }, false)}
            {acceptRatingButton({ rating: "n" }, true)}
            {acceptRatingButton({ rating: "n" }, false)}
          </ActionRow>
          <ActionRow>
            {acceptRatingButton({ isWoman: true }, true)}
            {acceptRatingButton({ isWoman: true }, false)}
            {acceptRatingButton({ invitedOrBetter: true }, true)}
            {acceptRatingButton({ invitedOrBetter: true }, false)}
          </ActionRow>
          <ActionRow>
            {acceptRatingButton({}, true)}
            {acceptRatingButton({}, false)}
          </ActionRow>
          <h4>Reject actions</h4>
          <ActionRow>
            {rejectRatingButton({ rating: "Y" }, true)}
            {rejectRatingButton({ rating: "Y" }, false)}
            {rejectRatingButton({ rating: "y" }, true)}
            {rejectRatingButton({ rating: "y" }, false)}
          </ActionRow>
          <ActionRow>
            {rejectRatingButton({ rating: "m" }, true)}
            {rejectRatingButton({ rating: "m" }, false)}
            {rejectRatingButton({ rating: "n" }, true)}
            {rejectRatingButton({ rating: "n" }, false)}
          </ActionRow>
          <ActionRow>
            {rejectRatingButton({}, true)}
            {rejectRatingButton({}, false)}
          </ActionRow>
        </BulkActions>
      </AccordionDetails>
    </Accordion>
  );
};
