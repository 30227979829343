import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { toast } from "react-toastify";
import Input from "../../../components/statelessForms/Input";
import Button, { BasicButton } from "../../../components/statelessForms/Button";
import useKickOffHarmonicProspectSearch from "./hooks/useKickOffHarmonicProspectSearch";

type Props = {
  meetupId: string;
  savedSearchIds: number[];
  onSearchAdded: () => void;
};

const ModalBody = styled.div`
  padding: 20px;
`;

export default ({ meetupId, savedSearchIds, onSearchAdded }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const kickOffHarmonicProspectSearch = useKickOffHarmonicProspectSearch(meetupId);
  const [harmonicSavedSearchId, setHarmonicSavedSearchId] = useState<string>("");

  const onKickOffSearch = async () => {
    const jobExists = savedSearchIds.find(
      (existingId) => existingId.toString() === harmonicSavedSearchId.toString()
    );

    if (jobExists) {
      toast.error('Search already exists. Click "Re-run" to trigger the search to run again.');
    } else {
      toast.success(
        `Kicking off job to load prospects for Harmonic saved search [${harmonicSavedSearchId}]`
      );
      await kickOffHarmonicProspectSearch(harmonicSavedSearchId);
      setModalOpen(false);
      onSearchAdded();
    }
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalBody>
          <div css={css({ marginTop: 20, display: "flex", alignItems: "center" })}>
            <h4>Add prospects from Harmonic saved search</h4>
          </div>
          <div
            css={css({
              display: "flex",
              alignItems: "center",
              gap: 8,
              marginBottom: 8,
              marginTop: 8,
            })}
          >
            <div>
              <Input
                size="small"
                outlined
                value={harmonicSavedSearchId}
                placeholder="Saved Search ID"
                onChange={(e) => setHarmonicSavedSearchId(e.target.value)}
              />
            </div>
            <div>
              <BasicButton onClick={onKickOffSearch}>Kick off Harmonic import job</BasicButton>
            </div>
          </div>
        </ModalBody>
      </Dialog>
      <Button color="blue" onClick={() => setModalOpen(true)}>
        Add saved search
      </Button>
    </>
  );
};
