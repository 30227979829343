import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { omit } from "lodash";
import React, { useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import styled from "@emotion/styled";
import { Dropdown, Input, RadioGroup, Submit, Textarea, useForm } from "../../components/forms";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import { EDIT_COMPANY_PAGE, EDIT_COMPANY_PAGE_company } from "./__generated__/EDIT_COMPANY_PAGE";
import { UPDATE_COMPANY, UPDATE_COMPANYVariables } from "./__generated__/UPDATE_COMPANY";
import { LEAVE_COMPANY } from "./__generated__/LEAVE_COMPANY";
import Button from "../../components/statelessForms/Button";
import { loadDefaultHome } from "../../components/forms/util";

type MODAL_STATE = "closed" | "confirming" | "track";

const ModalBody = styled.div`
  padding: 30px;
  h2 {
    text-align: center;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 10px;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

export default (props: { verticals: [number, string][]; redirected: boolean }) => {
  const [modalState, setModalState] = useState<MODAL_STATE>("closed");
  const { data, refetch } = useQuery<EDIT_COMPANY_PAGE>(gql`
    query EDIT_COMPANY_PAGE {
      company {
        name
        slug
        url
        description
        verticalId
        why
        commitment
        stage
        incorporated
        progress
        fundingStatus
        showInDirectory
        persisted
        errors {
          field
          error
        }
      }
    }
  `);

  const [updateCompany] = useMutation<UPDATE_COMPANY, UPDATE_COMPANYVariables>(gql`
    mutation UPDATE_COMPANY($input: UpdateCompanyInput!) {
      updateCompany(input: $input) {
        slug
        errors {
          field
          error
        }
      }
    }
  `);

  const [leaveCompany] = useMutation<LEAVE_COMPANY>(gql`
    mutation LEAVE_COMPANY($switchToAspiring: Boolean!) {
      leaveCompany(switchToAspiring: $switchToAspiring) {
        success
      }
    }
  `);

  const onSubmit = async (fields: EDIT_COMPANY_PAGE_company) => {
    const resp = await updateCompany({
      variables: { input: omit(fields, ["__typename", "avatarUrl", "errors", "persisted"]) },
    });
    return resp?.data?.updateCompany;
  };

  // @ts-ignore
  const { ConnectedForm, connectedFormProps, formMethods } = useForm(data?.company, onSubmit);

  const hasACompany = data?.company?.slug;

  const onLeaveCompany = async (switchToAspiring: boolean) => {
    setModalState("closed");

    await leaveCompany({ variables: { switchToAspiring } });
    await refetch();
    // this is a workaround to clear description, which should be cleared by reset({}) but isn't
    // react-hook-form doesn't like setting blank values, but this is what we want
    // @ts-ignore
    formMethods.setValue("description", "");
    formMethods.reset({});

    if (switchToAspiring) {
      loadDefaultHome();
    }
  };

  return (
    <Page title="Company Information">
      <Dialog
        open={modalState !== "closed"}
        onClose={() => setModalState("closed")}
        scroll="body"
        maxWidth="md"
      >
        {modalState === "confirming" && (
          <ModalBody>
            <DialogTitle>Do you really want to leave this company?</DialogTitle>
            <div css={css({ textAlign: "center", marginBottom: 10 })}>
              You will not be able to rejoin after leaving.
            </div>
            <ButtonRow>
              <Button color="orange" onClick={() => setModalState("track")}>
                Yes, leave company
              </Button>
              <Button color="gray" onClick={() => setModalState("closed")}>
                No, cancel
              </Button>
            </ButtonRow>
          </ModalBody>
        )}
        {modalState === "track" && (
          <ModalBody>
            <DialogTitle>What are you working on now?</DialogTitle>
            <ButtonRow>
              <Button onClick={() => onLeaveCompany(false)}>
                I'm actively working on a new company
              </Button>
              <Button onClick={() => onLeaveCompany(true)}>
                I haven't started anything new (yet)
              </Button>
            </ButtonRow>
          </ModalBody>
        )}
      </Dialog>
      <Container css={css({ maxWidth: 900 })}>
        {props.redirected && (
          <div css={css({ display: "flex", justifyContent: "center", marginBottom: 20 })}>
            <Button onClick={() => onLeaveCompany(true)}>I don't have a company</Button>
          </div>
        )}
        <ConnectedForm {...connectedFormProps}>
          <Pane title="Public Information">
            <PaneItem>
              <Input label="Company Name" fieldName="name" required />
              <Input label="Company URL" fieldName="url" placeholder="https://..." />
              <Textarea
                label="Describe your company in a few sentences"
                fieldName="description"
                required
                minLength={10}
                maxLength={500}
                minRows={3}
                showCharCount
              />
            </PaneItem>
          </Pane>
          <Pane title="Other Fields">
            <PaneItem>
              <Dropdown
                anchor="vertical"
                label="Company Category"
                required
                fieldName="verticalId"
                subLabel="It's ok if your company fits in more than one category—just choose the one that fits best."
                options={props.verticals}
              />
              <Dropdown
                required
                anchor="commitment"
                fieldName="commitment"
                label="Are you currently part-time or full-time on this startup?"
                options={[
                  ["full_time", "Full Time (40+ hours a week)"],
                  ["part_time", "Part Time (20+ hours a week)"],
                  ["other_commitment", "< 20 hours a week"],
                ]}
              />
              <RadioGroup
                required
                fieldName="stage"
                label="Company Stage"
                options={[
                  [
                    "stage_1",
                    <p>
                      <strong>Stage 1</strong>
                      <br />
                      You are in the process of validating your idea and building an initial
                      product. You are talking to potential users about your idea.
                    </p>,
                  ],
                  [
                    "stage_2",
                    <p>
                      <strong>Stage 2</strong>
                      <br />
                      You have built an MVP and are talking to early customers. You may have some
                      users. Getting feedback from customers. Iterating.
                    </p>,
                  ],
                  [
                    "stage_3",
                    <p>
                      <strong>Stage 3</strong>
                      <br />
                      You have a product with many active users. You are working on growing
                      revenue/users/sales.
                    </p>,
                  ],
                ]}
                anchor="stage"
              />
              <RadioGroup
                required
                fieldName="incorporated"
                label="Have you incorporated your company?"
                subLabel="Incorporating means forming an LLC, C Corporation, or other similar legal structure for your business. If you're not sure, choose 'No'."
                options={[
                  [true, "Yes"],
                  [false, "No"],
                ]}
              />
              <Textarea
                label="How long have you been working on this and what progress have you made on your company?"
                fieldName="progress"
                minRows={3}
                maxLength={500}
                showCharCount
              />
              <Textarea
                label="If you've already raised funding for this startup, who invested and how much have you raised?"
                fieldName="fundingStatus"
                minRows={3}
                maxLength={500}
                showCharCount
              />
            </PaneItem>
          </Pane>
          <div css={css({ display: "flex", justifyContent: "space-between" })}>
            <Submit />
            {hasACompany && (
              <Button onClick={() => setModalState("confirming")}>Leave company</Button>
            )}
          </div>
        </ConnectedForm>
      </Container>
    </Page>
  );
};
