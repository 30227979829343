import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { groupBy, values } from "lodash";
import { css } from "@emotion/core";
import Button from "../../components/statelessForms/Button";
import BigButton from "../../components/Button";
import { GROUP_SESSIONS } from "./__generated__/GROUP_SESSIONS";
import {
  SIGN_UP_FOR_GROUP_SESSION,
  SIGN_UP_FOR_GROUP_SESSIONVariables,
} from "./__generated__/SIGN_UP_FOR_GROUP_SESSION";
import {
  CANCEL_GROUP_SESSION,
  CANCEL_GROUP_SESSIONVariables,
} from "./__generated__/CANCEL_GROUP_SESSION";
import { groupSessionPath } from "../../__generated__/routes";

export default () => {
  const { data } = useQuery<GROUP_SESSIONS>(gql`
    query GROUP_SESSIONS {
      currentGroupSession {
        slug
        canJoinNow
      }
      groupSessionSlots {
        id
        startsAt
        topicId
        topic
        signUpOpen
        signedUp
        signUpClosesAt
        eligibleForGs
      }
    }
  `);

  const [signUpForGroupSession] = useMutation<
    SIGN_UP_FOR_GROUP_SESSION,
    SIGN_UP_FOR_GROUP_SESSIONVariables
  >(gql`
    mutation SIGN_UP_FOR_GROUP_SESSION($slotId: ID!) {
      signUpForGroupSession(slotId: $slotId) {
        id
        signedUp
      }
    }
  `);

  const [cancelGroupSession] = useMutation<CANCEL_GROUP_SESSION, CANCEL_GROUP_SESSIONVariables>(gql`
    mutation CANCEL_GROUP_SESSION($slotId: ID!) {
      cancelGroupSession(slotId: $slotId) {
        id
        signedUp
      }
    }
  `);

  const confirmSignUp = (slotId: string) => {
    if (
      confirm(
        "Are you sure you want to sign up for this time slot? If you miss your group session you will not be able to sign up again."
      )
    ) {
      signUpForGroupSession({ variables: { slotId } });
    }
  };

  const confirmCancel = (slotId: string) => {
    if (confirm("Are you sure you want to cancel your signup for this group session?")) {
      cancelGroupSession({ variables: { slotId } });
    }
  };

  const currentGroupSession = data?.currentGroupSession;
  const slots = data?.groupSessionSlots;
  if (!slots) return null;

  const { eligibleForGs } = slots[0];

  const groupedSlots = values(groupBy(slots, (slot) => slot.topicId));
  groupedSlots.sort((a, b) => {
    const aTime = new Date(a[0].startsAt);
    const bTime = new Date(b[0].startsAt);
    if (aTime < bTime) return -1;
    if (bTime > aTime) return 1;
    return 0;
  });

  const formatDateTime = (time: string) =>
    new Date(time).toLocaleDateString(undefined, {
      weekday: "long",
      hour: "numeric",
      minute: "2-digit",
      timeZoneName: "short",
    });

  const formatTime = (time: string) =>
    new Date(time).toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      timeZoneName: "short",
    });

  return (
    <>
      <h2>Group Sessions</h2>
      {currentGroupSession?.canJoinNow && (
        <div css={css({ margin: "20px auto 40px", width: "fit-content" })}>
          <BigButton
            href={groupSessionPath(currentGroupSession?.slug)}
            text="Group session happening now - click to join!"
            type="cta"
          />
        </div>
      )}
      <p>
        Group sessions are 60 minute video calls with roughly 3 other founders. We'll host 5
        optional group sessions during the course. Each session has a specific topic; you should
        only attend a session if the topic is directly relevant to you or your company at that time.
      </p>
      <p>
        Each group session will have three different time slots. You're welcome to sign up for the
        one that best fits your schedule. Group sessions will always be on Thursdays and signups
        open the Monday three days before.
      </p>
      <p>
        {eligibleForGs ? (
          <b>
            Please only sign up for a slot if you are sure you can make it. It is very disruptive to
            the other founders if you miss your group session. If you miss the slot that you sign up
            for, you will not be able to sign up for another one during the course.
          </b>
        ) : (
          <b>
            You have missed a group session that you signed up for. Unfortunately, you will not be
            able to sign up for any more group sessions during this course.
          </b>
        )}
      </p>
      <h2>Group Session Schedule</h2>
      {groupedSlots.map((group) => (
        <div css={css({ marginBottom: 40, display: "flex" })} key={group[0].id}>
          <div css={css({ minWidth: 200 })}>
            <b css={css({ fontSize: 20 })}>
              {new Date(group[0].startsAt).toLocaleDateString(undefined, {
                month: "long",
                day: "numeric",
                weekday: "long",
              })}
            </b>
            <div css={css({ fontSize: 16, marginTop: 10 })}>Topic: {group[0].topic}</div>
          </div>
          {group[0].signUpOpen && eligibleForGs && (
            <>
              {group.some((slot) => slot.signedUp) ? (
                <div css={css({ marginLeft: "auto" })}>
                  <div css={css({ marginBottom: 5 })}>
                    You are signed up for{" "}
                    {formatTime(group.find((slot) => slot.signedUp)?.startsAt)}!
                  </div>
                  <Button
                    onClick={() => confirmCancel(group.find((slot) => slot.signedUp)?.id || "")}
                  >
                    Cancel group session
                  </Button>
                </div>
              ) : (
                <div css={css({ marginLeft: "auto" })}>
                  <div css={css({ marginBottom: 5 })}>
                    Signups are now open until {formatDateTime(group[0].signUpClosesAt)}!
                  </div>
                  <div css={css({ display: "flex" })}>
                    {group.map((slot) => (
                      <div css={css({ flex: 1, marginRight: 10 })} key={slot.id}>
                        <Button color="yellow" onClick={() => confirmSignUp(slot.id)}>
                          <div css={css({ fontSize: 13 })}>
                            {formatDateTime(slot.startsAt)
                              .split(", ")
                              .map((s) => (
                                <div>{s}</div>
                              ))}
                          </div>
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};
