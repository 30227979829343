import { css, CSSObject } from "@emotion/core";
import React, { useContext, useState } from "react";
import styled from "@emotion/styled";

import Icon from "../icon";
import {
  animateMenu,
  measurements,
  menuColors,
  MenuContext,
  SubmenuItemType,
  TabType,
} from "./shared";
import { handleSignOut } from "./SignOutLink";
import { YC_ORANGE } from "../forms/util";

const iconContainerWidth = 60;

const NewBanner = styled.span`
  color: ${YC_ORANGE};
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  left: -15px;
`;

const getSubMenuItems = (tab: TabType | SubmenuItemType) =>
  tab.menu?.map((item) => ({
    ...item,
    active:
      item.active ??
      (location.pathname.startsWith(`/${item.active_prefix}`) || location.pathname === item.path),
  }));

const isPathMatch = (tab: TabType | SubmenuItemType, tabPath: string) => {
  if (!tabPath) {
    return false;
  }

  const currentPath = location.pathname.toLowerCase();
  return tab.exact_path
    ? currentPath === tabPath || currentPath === `${tabPath}/`
    : currentPath.startsWith(tabPath);
};

const isTabActive = (tab: TabType | SubmenuItemType, subMenu: SubmenuItemType[] | undefined) => {
  const isActive = tab.active;
  const matchesActivePrefix = tab.active_prefix && isPathMatch(tab, `/${tab.active_prefix}`);
  const matchesPath = tab.path && isPathMatch(tab, tab.path);
  return isActive || matchesActivePrefix || matchesPath || subMenu?.some((m) => m.active) || false;
};

const targetAttribute = (openInNewTab?: boolean) => (openInNewTab ? { target: "_blank" } : {});

const SubmenuItem: React.FC<{ item: SubmenuItemType }> = ({ item }) => {
  const subMenu = getSubMenuItems(item);
  const atThisTab = isTabActive(item, subMenu);

  return (
    <div css={css({ position: "relative" })}>
      {item.notification_dot && !atThisTab && (
        <div
          css={css`
            width: 10px;
            height: 10px;
            border: 4px solid #f50303;
            border-radius: 50%;
            position: absolute;
            background-color: white;
            left: 5px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
          `}
        />
      )}
      <a
        href={item.path}
        {...targetAttribute(item.open_in_new_tab)}
        css={css({
          display: "block",
          padding: "8px 0 8px 20px",
          color: item.active ? menuColors.active : menuColors.inactive,
          fontWeight: "normal",
          ":hover": { color: menuColors.active },
        })}
        onClick={async (e) => {
          if (item.path === "handle_sign_out") {
            await handleSignOut(e);
          }
        }}
      >
        {item.new && <NewBanner>NEW</NewBanner>}
        {item.name}
        {item.name_suffix && !atThisTab && item.name_suffix}
      </a>
      {subMenu && (
        <div css={css({ paddingLeft: 20 })}>
          {subMenu.map((subitem) => (
            <SubmenuItem item={subitem} key={`${subitem.name}-${subitem.path}`} />
          ))}
        </div>
      )}
    </div>
  );
};

const Submenu: React.FC<{ menu: SubmenuItemType[] }> = ({ menu }) => (
  <div css={css({ width: measurements.menuOpen })}>
    {menu.map((item) => (
      <SubmenuItem item={item} key={`${item.name}-${item.path}`} />
    ))}
  </div>
);

const TabIcon: React.FC<{ notificationDot?: boolean }> = (props) => (
  <div
    css={css`
      width: ${iconContainerWidth}px;
      display: flex;
      justify-content: center;
      margin-top: -3px;
      position: relative;
      img,
      svg {
        width: 26px;
        height: 26px;
      }
    `}
  >
    {props.notificationDot && (
      <div
        css={css`
          width: 15px;
          height: 15px;
          border: 6px solid #f50303;
          border-radius: 7.5px;
          position: absolute;
          background-color: white;
          right: 15px;
          top: -5px;
        `}
      />
    )}
    {props.children}
  </div>
);

const MenuTab: React.FC<{ tab: TabType }> = ({ tab }) => {
  const open = useContext(MenuContext);

  if (
    tab.feature_flag != null &&
    window.posthog &&
    !window.posthog.isFeatureEnabled(tab.feature_flag)
  )
    return null;

  const subMenu = getSubMenuItems(tab);
  const atThisTab = isTabActive(tab, subMenu);
  const shouldExpandSubmenu = atThisTab || tab.always_active;

  const [inlineMenuActive, setInlineMenuActive] = useState<boolean>(
    (subMenu && shouldExpandSubmenu) || false
  );
  const [itemHovered, setItemHovered] = useState<boolean>(false);
  const [submenuVerticalAlignment, setSubmenuVerticalAlignment] = useState<CSSObject>({});

  const openSubmenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const screenTopToElementTop =
      e.currentTarget.offsetTop - (e.currentTarget.parentElement?.scrollTop || 0);

    // If the mouse is in the top half of the screen, menu should open
    // facing downwards, otherwise open facing up.
    if (e.clientY < window.innerHeight / 2) {
      setSubmenuVerticalAlignment({
        top: screenTopToElementTop,
        maxHeight: window.innerHeight - screenTopToElementTop,
      });
    } else {
      const screenBottomToElementBottom =
        window.innerHeight - (screenTopToElementTop + e.currentTarget.offsetHeight);
      setSubmenuVerticalAlignment({
        bottom: screenBottomToElementBottom,
        maxHeight: window.innerHeight - screenBottomToElementBottom,
      });
    }
    setItemHovered(true);
  };

  const flyoutMenuActive = subMenu && !open && !inlineMenuActive && itemHovered;

  let menuIconRotation = open ? 90 : 0;
  if (flyoutMenuActive) menuIconRotation = 180;
  if (inlineMenuActive) menuIconRotation = -90;

  let icon = null;
  if (React.isValidElement(tab.icon)) {
    icon = tab.icon;
  } else if (tab.icon.includes(".png") || tab.icon.includes(".jpg") || tab.icon === "/avatar.svg") {
    icon = <img src={tab.icon} alt="avatar" css={css({ borderRadius: 13 })} />;
  } else {
    icon = <Icon icon={tab.icon as any} />;
  }

  const onClickTab = (e: React.MouseEvent) => {
    if (!subMenu || !open) return;

    e.preventDefault();
    setInlineMenuActive(!inlineMenuActive);
  };

  const hasNotifications = tab.notification_dot || subMenu?.some((m) => m.notification_dot);
  const activeable = tab.path || subMenu;
  const baseColor =
    atThisTab || (inlineMenuActive && !tab.always_active) || flyoutMenuActive
      ? menuColors.active
      : menuColors.inactive;

  return (
    <div
      onMouseEnter={openSubmenu}
      onMouseLeave={() => setItemHovered(false)}
      css={css({ width: measurements.menuOpen })}
    >
      <a
        css={css({
          padding: "14px 0",
          display: "flex",
          alignItems: "top",
          fontWeight: "bolder",
          color: baseColor,
          ":hover": { color: activeable ? menuColors.active : baseColor },
        })}
        href={tab.path}
        {...targetAttribute(tab.open_in_new_tab)}
        onClick={onClickTab}
        role="button"
        tabIndex={-1}
        id={tab.id}
      >
        <TabIcon notificationDot={hasNotifications && !shouldExpandSubmenu}>{icon}</TabIcon>
        <div
          css={css({
            flex: 1,
            display: "flex",
          })}
        >
          <div css={css({ flex: 1, alignItems: "center" })}>{tab.name}</div>
          {subMenu && !tab.always_active && (
            <Icon
              icon="chevron"
              css={css({
                marginRight: 15,
                transform: `rotate(${menuIconRotation}deg)`,
                ...animateMenu,
              })}
            />
          )}
        </div>
      </a>
      {subMenu && inlineMenuActive && (
        <div css={css({ marginLeft: iconContainerWidth, marginTop: -6 })}>
          <Submenu menu={subMenu} />
        </div>
      )}
      {subMenu && flyoutMenuActive && (
        <div
          css={css({
            position: "absolute",
            left: measurements.menuOpen - 1,
            minWidth: 180,
            padding: "8px 16px",
            background: "white",
            border: "1px solid #e0e0e0",
            boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.1)",
            zIndex: 5,
            overflowY: "auto",
            ...submenuVerticalAlignment,
          })}
        >
          <Submenu menu={subMenu} />
        </div>
      )}
    </div>
  );
};

export default MenuTab;
