import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiMenu, { MenuProps } from "@material-ui/core/Menu";
import MuiMenuItem from "@material-ui/core/MenuItem";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import { css } from "@emotion/core";
import { Badge, Checklist, Handyman, SavedSearch, ExpandMore } from "@mui/icons-material";
import { Assignment, Edit, Email, People, Public, Star, VpnKey } from "@material-ui/icons";
import {
  Divider as MuiDivider,
  ListSubheader as MuiListSubheader,
  SvgIconTypeMap,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { BasicButton } from "../../../components/statelessForms/Button";
import {
  adminEmailCampaignPath,
  adminMeetupPath,
  appStatsAdminMeetupsPath,
  editAdminMeetupPath,
  nametagsAdminMeetupsPath,
  prospectsAdminMeetupsPath,
  waitlistBypassUrlsAdminMeetupsPath,
} from "../../../__generated__/routes";

type Props = {
  id: string;
  emailCampaignId: string | null;
  publicUrl: string;
  ycCompaniesUrl: string;
  isOver: boolean;
};

const Menu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "fit-content",
  },
})((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const MenuItem = withStyles(() => ({
  root: {},
}))(MuiMenuItem);

const ListItemIcon = withStyles(() => ({
  root: {
    minWidth: 30,
    color: "rgba(0,0,0,0.7) !important",
  },
}))(MuiListItemIcon);

const ListItemText = withStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
    color: "rgba(0,0,0,0.7) !important",
  },
}))(MuiListItemText);

const ListSubheader = withStyles(() => ({
  root: {
    lineHeight: 1.7,
    color: "#9ca3af",
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 0.5,
  },
}))(MuiListSubheader);

const Divider = withStyles(() => ({
  root: {
    margin: "8px 0",
  },
}))(MuiDivider);

export default ({ id, emailCampaignId, publicUrl, isOver, ycCompaniesUrl }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const renderLink = (
    label: string,
    icon: OverridableComponent<SvgIconTypeMap> | { muiName: string },
    link: string
  ) => {
    const Icon = icon as OverridableComponent<SvgIconTypeMap>;
    return (
      <a href={link}>
        <MenuItem dense>
          <ListItemIcon>
            <Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={label} />
        </MenuItem>
      </a>
    );
  };

  return (
    <div>
      <BasicButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <div css={css({ display: "flex", alignItems: "center", gap: 10 })}>
          <span>Go to...</span>
          <ExpandMore />
        </div>
      </BasicButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <ListSubheader>Edit</ListSubheader>
        {renderLink("Edit meetup", Edit, editAdminMeetupPath(id))}
        {emailCampaignId &&
          renderLink("Email copy", Email, adminEmailCampaignPath(emailCampaignId))}
        <Divider />

        <ListSubheader>Manage attendees</ListSubheader>
        {renderLink("Manage RSVPs", People, adminMeetupPath(id))}
        {renderLink("Name tags", Badge, nametagsAdminMeetupsPath(id))}
        {renderLink(
          "Manually set attendees",
          Handyman,
          adminMeetupPath(id, { set_attendees: true })
        )}
        {renderLink("Check-in", Checklist, adminMeetupPath(id, { check_in: true }))}
        <Divider />

        <ListSubheader>Invite people</ListSubheader>
        {renderLink("Waitlist bypass URLs", VpnKey, waitlistBypassUrlsAdminMeetupsPath(id))}
        {renderLink("Harmonic prospects", SavedSearch, prospectsAdminMeetupsPath(id))}
        {renderLink("Public event page", Public, publicUrl)}
        <Divider />

        <ListSubheader>Analysis</ListSubheader>
        {isOver && renderLink("YC apps", Assignment, appStatsAdminMeetupsPath(id))}
        {renderLink("YC companies attending", Star, ycCompaniesUrl)}
      </Menu>
    </div>
  );
};
