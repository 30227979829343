import React from "react";
import { css } from "@emotion/core";
import ScrollableAnchor from "react-scrollable-anchor";
import CurriculumItemFeedback from "./CurriculumItemFeedback";
import CurriculumItemTag, { TagType } from "./CurriculumItemTag";
import { mobileStyles, colors } from "../../styles";
import { Container } from "../../components/Page";
import { CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback } from "./__generated__/CURRICULUM_FEEDBACK";

export type CurriculumItemType = {
  id: number;
  sort_order: number | null;
  title: string;
  content_type: string;
  author: string;
  description: string | null;
  watch_if: string[];
  link: string;
  url: string;
  stages: number[];
  categories: string[];
  slides: string | null;
  transcript: string | null;
  preview_link: string | null;
  asf_module_id: number | null;
  formatted_duration: string | null;
};

type Props = {
  curriculumItem: CurriculumItemType;
  feedback: CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback | undefined | null;
  isCurriculum: boolean;
  showFeedback?: boolean;
  hideIrrelevantFeedback?: boolean;
  showTags?: boolean;
};

export const CurriculumPreviewImage = (props: { link: string; centered?: boolean }) => (
  <div css={css({ height: "100%", ...(props.centered ? { textAlign: "center" } : {}) })}>
    <img
      src={props.link}
      alt="youtube video thumbnail"
      css={css({
        height: 128,
        width: 228,
        objectFit: "cover",
      })}
    />
  </div>
);

export default function CurriculumItem({
  curriculumItem,
  feedback,
  isCurriculum,
  showFeedback = false,
  hideIrrelevantFeedback = false,
  showTags = false,
}: Props) {
  return (
    <a href={curriculumItem.url}>
      <ScrollableAnchor id={`content-${curriculumItem.id}`}>
        <div />
      </ScrollableAnchor>

      <div
        css={css({
          borderTop: "1px solid #e5e7e9",
          display: "flex",
          "&:hover": {
            backgroundColor: colors.clickablePaneHover,
          },
        })}
      >
        <Container
          css={css({
            display: "flex",
            paddingTop: 38,
            paddingBottom: 38,
            ...mobileStyles({
              flexWrap: "wrap",
              paddingTop: 40,
              paddingBottom: 40,
            }),
          })}
        >
          <div
            css={css({
              height: "128px",
              marginRight: "41px",
              width: "228px",
              minWidth: "228px",
              boxShadow: "0 2px 14px 0 rgba(0, 0, 0, 0.2)",
              position: "relative",
            })}
          >
            {curriculumItem.preview_link ? (
              <CurriculumPreviewImage link={curriculumItem.preview_link} />
            ) : (
              <div
                css={css({
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  padding: "20px 20px",
                  position: "relative",
                  background: "white",
                })}
              >
                <div
                  css={css({
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "bold",
                  })}
                >
                  {curriculumItem.title}
                </div>
                <div
                  css={css({
                    color: "#ff7433",
                    fontSize: "13px",
                    marginTop: "3px",
                    flex: 1,
                  })}
                >
                  {curriculumItem.author.split(",")[0]}
                </div>
                <img
                  src="/logo.svg"
                  css={css({
                    width: 75,
                    height: 75 * (240 / 1440),
                  })}
                  alt="Startup School logo"
                />
              </div>
            )}
            {curriculumItem.formatted_duration && (
              <div
                css={css({
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  padding: "3px 6px",
                  borderRadius: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  color: "white",
                })}
              >
                {curriculumItem.formatted_duration}
              </div>
            )}
          </div>
          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              marginRight: "30px",
              ...mobileStyles({ marginTop: "30px" }),
            })}
          >
            <div
              css={css({
                color: "#5d5d5d",
                fontSize: "24px",
                fontWeight: "bold",
                lineHeight: "140%",
              })}
            >
              {curriculumItem.title}
            </div>
            <div css={css({ color: "#737373" })}>
              <div css={css({ marginTop: "15px" })}>
                {curriculumItem.description || curriculumItem.author}
              </div>
              {curriculumItem.watch_if.length > 0 && (
                <div css={css({ marginTop: "15px" })}>
                  <strong>
                    {curriculumItem.content_type == "video" ? "Watch this if:" : "Read this if:"}
                  </strong>
                  <ul css={css({ marginBottom: 0, marginTop: 0 })}>
                    {curriculumItem.watch_if.map((w: string) => (
                      <li key={w}>{w}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {showTags && (
              <div
                css={css({
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "15px",
                })}
              >
                {isCurriculum && (
                  <CurriculumItemTag
                    label="Official Curriculum"
                    key="curriculum"
                    tagType={TagType.curriculum}
                  />
                )}
                {curriculumItem.stages.map((c) => (
                  <CurriculumItemTag label={`Stage ${c}`} key={c} tagType={TagType.stage} />
                ))}
                {curriculumItem.categories.map((c) => (
                  <CurriculumItemTag label={c} key={c} tagType={TagType.category} />
                ))}
              </div>
            )}
          </div>
          {showFeedback && (
            <div
              css={css({
                marginLeft: "auto",
                ...mobileStyles({ margin: "20px 0 0" }),
              })}
            >
              <CurriculumItemFeedback
                curriculumItemId={curriculumItem.id}
                feedback={feedback}
                hideIrrelevant={hideIrrelevantFeedback}
              />
            </div>
          )}
        </Container>
      </div>
    </a>
  );
}
