import { css } from "@emotion/core";
import React from "react";
import Tooltip from "../../components/statelessForms/Tooltip";
import { PaneItem } from "../../components/Pane";
import { colors, round } from "../../styles";

export type User = {
  name: string;
  slug: string;
  avatar_url: string;
  profile_path: string;
  expertise: string | null;
};

export type Company = {
  slug: string;
  name: string;
  description: string;
  url: string;
  vertical: string;
  stage: string;
  founders: User[];
};

export const UserItem = ({ u, moderator }: { u: User; moderator?: boolean }) => (
  <div key={u.profile_path} css={css({ display: "flex", marginRight: "20px" })}>
    <a
      href={u.profile_path}
      target="_blank"
      css={css({
        display: "flex",
        flex: 1,
        color: "inherit",
        alignItems: "center",
        minWidth: 150,
        marginBottom: 15,
      })}
    >
      <img css={css({ ...round(50), marginRight: "0.5em" })} alt="avatar" src={u.avatar_url} />
      <div>
        <div css={css({ display: "flex" })}>
          <div css={css({ marginRight: "8px" })}>{u.name}</div>
          {moderator && (
            <span css={css({ color: colors.brandSecondary, marginLeft: 5 })}>
              <b>moderator</b>
            </span>
          )}
        </div>
        <div css={css({ color: colors.lightText })}>{u.expertise}</div>
      </div>
    </a>
  </div>
);

export const CompanyItem = ({ c }: { c: Company }) => (
  <PaneItem>
    <div css={css({ display: "flex" })}>
      <h4 css={css({ flex: 1 })}>
        <a href={c.url} target="_blank" css={css({ color: colors.brandPrimary })}>
          {c.name}
        </a>
      </h4>
    </div>
    <div key={c.slug}>
      <p>{c.description}</p>

      <p css={css({ color: colors.lightText, display: "flex" })}>
        <div>{c.vertical}</div>
        {c.stage && (
          <Tooltip
            title={
              <>
                <p>
                  <strong>Stage 1</strong>
                  <br />
                  Founder is in the process of validating their idea and building an initial
                  product. They are talking to potential users about their idea.
                </p>
                <p>
                  <strong>Stage 2</strong>
                  <br />
                  Founder has built an MVP and is talking to early customers. They may have some
                  users. Getting feedback from customers. Iterating.
                </p>
                <p>
                  <strong>Stage 3</strong>
                  <br />
                  Founder has a product with many active users. Founder is working on growing
                  revenue/users/sales.
                </p>
              </>
            }
          >
            <div
              css={css({
                borderLeft: `1px solid ${colors.lightText}`,
                paddingLeft: 5,
                marginLeft: 5,
              })}
            >
              {c.stage}
            </div>
          </Tooltip>
        )}
      </p>
      <div css={css({ display: "flex", flexWrap: "wrap", marginBottom: -15 })}>
        {c.founders.map((f) => (
          <UserItem u={f} />
        ))}
      </div>
    </div>
  </PaneItem>
);
