import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { MeetupColumnType, SortType } from "./MeetupColumns";
import AdminMeetupRsvpTableHeaders from "./AdminMeetupRsvpTableHeaders";
import AdminMeetupRsvp from "./AdminMeetupRsvp";
import { RsvpType } from "./meetupUtils";
import { MEETUP_RSVPS_admin_adminMeetup } from "../__generated__/MEETUP_RSVPS";

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: scroll;
`;

export const Table = styled.table`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  text-align: left;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.87);
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 14px;
`;

type Props = {
  meetup: MEETUP_RSVPS_admin_adminMeetup;
  rsvps: RsvpType[];
  columns: MeetupColumnType[];
  isOver: boolean;
  sortType: SortType | null;
  setSortType: (sortType: SortType) => void;
  hideActions?: boolean;
  checkInView?: boolean;
  setAttendeesView?: boolean;
  ohOwnerOptions?: [string, string][];
};

export default ({
  rsvps,
  meetup,
  columns,
  isOver,
  sortType,
  setSortType,
  hideActions,
  checkInView,
  setAttendeesView,
  ohOwnerOptions,
}: Props) => (
  <TableWrapper>
    <Table>
      <AdminMeetupRsvpTableHeaders
        columns={columns}
        isOver={isOver}
        sortType={sortType}
        setSortType={setSortType}
        checkInView={!!checkInView}
        setAttendeesView={!!setAttendeesView}
        isOhEvent={meetup.eventType === "office_hours"}
      />
      <tbody css={css({ fontSize: 12 })}>
        {rsvps.map((rsvp) => (
          <AdminMeetupRsvp
            key={rsvp.id}
            rsvp={rsvp}
            meetup={meetup}
            columns={columns}
            isOver={isOver}
            hideActions={hideActions}
            checkInView={!!checkInView}
            setAttendeesView={!!setAttendeesView}
            ohOwnerOptions={ohOwnerOptions}
          />
        ))}
      </tbody>
    </Table>
  </TableWrapper>
);
