import React, { useCallback } from "react";
import { MEETUP_YC_APP_STATS_admin_meetupYcApps } from "./__generated__/MEETUP_YC_APP_STATS";
import { getBatchValue, TableSection } from "./meetupStatUtils";
import AcceptedYCApplicantRow from "./AcceptedYCApplicantRow";
import { MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions } from "./__generated__/MEETUP_YC_APP_MENTIONS";

type Props = {
  apps: MEETUP_YC_APP_STATS_admin_meetupYcApps[];
  mentionsByAppId: { [appId: string]: MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions };
  meetupId: string;
  refetch: (appId: number) => Promise<any>;
};

export default ({ apps, mentionsByAppId, meetupId, refetch }: Props) => {
  if (!apps) {
    return null;
  }

  const acceptedApps: { [appUrl: string]: MEETUP_YC_APP_STATS_admin_meetupYcApps[] } = apps
    .filter(({ result }) => result === "accepted")
    .sort((a1, a2) => (getBatchValue(a1.batch) > getBatchValue(a2.batch) ? -1 : 1))
    .reduce((map, app) => {
      if (map[app.appUrl]) {
        map[app.appUrl].push(app);
      } else {
        map[app.appUrl] = [app];
      }
      return map;
    }, {} as { [appUrl: string]: MEETUP_YC_APP_STATS_admin_meetupYcApps[] });

  const renderAcceptedApp = useCallback(
    (appList: MEETUP_YC_APP_STATS_admin_meetupYcApps[]) => {
      const app = appList[0];
      const { internalAppId } = app;
      const names = appList.map(({ name }) => name).join(", ");
      const mention = mentionsByAppId[internalAppId];

      return (
        <AcceptedYCApplicantRow
          key={internalAppId}
          app={appList[0]}
          names={names}
          meetupId={meetupId}
          mention={mention}
          refetch={refetch}
        />
      );
    },
    [mentionsByAppId]
  );

  return (
    <TableSection>
      <h2>Accepted apps:</h2>
      {Object.values(acceptedApps).map(renderAcceptedApp)}
    </TableSection>
  );
};
