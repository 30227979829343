import styled from "@emotion/styled";
import { css } from "@emotion/core";
import React, { useMemo } from "react";
import { Checkbox } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { HarmonicEventProspectColumnType } from "./MeetupColumns";
import {
  ADMIN_TOGGLE_INVITE_HARMONIC_PROSPECT,
  ADMIN_TOGGLE_INVITE_HARMONIC_PROSPECTVariables,
} from "./__generated__/ADMIN_TOGGLE_INVITE_HARMONIC_PROSPECT";
import { MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects } from "./__generated__/MEETUP_SAVED_SEARCH";

export type RowProps = {
  status?: string;
};
export const Row = styled.tr<RowProps>`
  padding: 5px 0;
  border-top: 1px solid black;
  ${({ status }) => {
    switch (status) {
      case "accepted":
        return css`
          background-color: #ffffba;
        `;
      case "invite_pending":
        return css`
          background-color: #bae1ff;
        `;
      case "confirmed":
        return css`
          background-color: #baffc9;
        `;
      case "invite_expired":
      case "rejected":
      case "rejection_sent":
      case "declined":
        return css`
          background-color: #e0e0e0;
          color: gray;
          font-style: italic;
          font-weight: 300;
        `;
      default:
        return css`
          background-color: white;
        `;
    }
  }}
  th {
    padding: 10px 5px;
  }
  td {
    padding: 10px 2px;
    white-space: pre-wrap;
  }
`;

const ScrollableCellContents = styled.div`
  max-height: 250px;
  overflow-y: scroll;
`;

type Props = {
  harmonicProspect: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects;
  columns: HarmonicEventProspectColumnType[];
};

export default ({ harmonicProspect, columns }: Props) => {
  const [toggleInvite] = useMutation<
    ADMIN_TOGGLE_INVITE_HARMONIC_PROSPECT,
    ADMIN_TOGGLE_INVITE_HARMONIC_PROSPECTVariables
  >(gql`
    mutation ADMIN_TOGGLE_INVITE_HARMONIC_PROSPECT($id: ID!, $toBeInvited: Boolean!) {
      adminToggleInviteHarmonicProspect(id: $id, toBeInvited: $toBeInvited) {
        id
        toBeInvited
      }
    }
  `);

  const onInviteClick = async (setInvite: boolean) => {
    await toggleInvite({
      variables: {
        id: harmonicProspect.id,
        toBeInvited: setInvite,
      },
    });
  };
  const inviteCheckbox = harmonicProspect.toBeInvited !== null && (
    <Checkbox checked={harmonicProspect.toBeInvited} onClick={() => onInviteClick(true)} />
  );
  const rejectCheckbox = harmonicProspect.toBeInvited !== null && (
    <Checkbox checked={!harmonicProspect.toBeInvited} onClick={() => onInviteClick(false)} />
  );

  return useMemo(
    () => (
      <Row status={harmonicProspect.toBeInvited ? "accepted" : "rejected"}>
        <>
          <td>{rejectCheckbox}</td>
          <td>{inviteCheckbox}</td>
        </>

        {columns.map((column) => (
          <td key={column.title}>
            <ScrollableCellContents>{column.render(harmonicProspect)}</ScrollableCellContents>
          </td>
        ))}
        <td>
          {harmonicProspect.userLinks
            ? harmonicProspect.userLinks.split(",").map((link, index) => (
                <a key={link} href={link.trim()} target="_blank">
                  View User ({index + 1})
                </a>
              ))
            : "No user links found"}
        </td>
      </Row>
    ),
    [harmonicProspect.toBeInvited, harmonicProspect.inviteEmailSent]
  );
};
