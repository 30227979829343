export default async function maybeHandleMergedSession(resp: Response) {
  try {
    const json = await resp.json();
    if (json.error && json.error === "session-merged") {
      alert(
        "It looks like your group session has been merged into another one. Press OK to continue to your new group session."
      );
      window.location.reload();
    }
  } catch (_) {
    // If it doesn't work there's nothing to do, so just ignore
  }
}
