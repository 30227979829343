import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Switch } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { OpenInNew } from "@material-ui/icons";
import { REVIEW_EMAIL_PUSH_admin_emailCampaign_emailPush } from "./__generated__/REVIEW_EMAIL_PUSH";
import {
  REVIEW_ALL_EMAIL_PUSHES_admin_emailCampaign,
  REVIEW_ALL_EMAIL_PUSHES_admin_emailCampaign_emailPushes,
} from "./__generated__/REVIEW_ALL_EMAIL_PUSHES";
import { editPushAdminEmailCampaignPath } from "../../../__generated__/routes";
import Button from "../../../components/statelessForms/Button";
import ScheduledDate from "./ScheduledDate";
import { Autocomplete, Input, useForm } from "../../../components/forms";
import MarkdownTextarea from "../../../components/forms/MarkdownTextarea";
import EmailTemplateModal from "./EmailTemplateModal";
import {
  UPDATE_EMAIL_PUSH_FROM_PREVIEW,
  UPDATE_EMAIL_PUSH_FROM_PREVIEWVariables,
} from "./__generated__/UPDATE_EMAIL_PUSH_FROM_PREVIEW";
import EmailPushPreview, { PreviewWrapper } from "./EmailPushPreview";

const AutoLoginLinkAlert = styled.div`
  width: 100%;
  text-align: center;
  color: red;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  font-style: italic;
`;

const HeaderSection = styled.div`
  margin: -10px 0 30px -20px;
`;

const Tag = styled.div`
  border-radius: 10px;
  padding: 0 8px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  width: fit-content;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const AdvancedEditLink = styled.div`
  position: absolute;
  right: 0;
  transform: translateY(calc(-100% - 15px));
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #268bd2;
`;

const EmailPushHeader = styled.h2`
  font-size: 30px;
`;

const RecipientsSection = styled.div`
  font-style: italic;
  color: gray;
`;

const CenterAlignedGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const InputFieldWrapper = styled.div`
  margin-top: -16px;
  margin-bottom: -8px;
`;

type Props = {
  number?: number;
  emailPush:
    | REVIEW_EMAIL_PUSH_admin_emailCampaign_emailPush
    | REVIEW_ALL_EMAIL_PUSHES_admin_emailCampaign_emailPushes;
  emailCampaign: REVIEW_ALL_EMAIL_PUSHES_admin_emailCampaign;
};

export default ({ number, emailCampaign, emailPush }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const [updateEmailPush] = useMutation<
    UPDATE_EMAIL_PUSH_FROM_PREVIEW,
    UPDATE_EMAIL_PUSH_FROM_PREVIEWVariables
  >(gql`
    mutation UPDATE_EMAIL_PUSH_FROM_PREVIEW($input: CreateOrUpdateEmailPushInput!) {
      createOrUpdateEmailPush(input: $input) {
        id
        subject
        subjectPreview
        emailBody
        emailBodyUserPreview
        fromEmail
        replyToEmail
        disabled
      }
    }
  `);

  const onSave = async (fields: any) => {
    const input = {
      id: emailPush.id,
      subject: fields.subject,
      emailBody: fields.emailBody,
      fromEmail: fields.fromEmail,
      replyToEmail: fields.replyToEmail === "" ? null : fields.replyToEmail,
    };

    await updateEmailPush({ variables: { input } });

    setIsEditing(false);
  };

  const { ConnectedForm, connectedFormProps } = useForm(emailPush, onSave);

  const onToggleDisabled = (disabled: boolean) => {
    updateEmailPush({
      variables: { input: { id: emailPush.id, disabled: !disabled } },
    });
  };

  const { meetupStartsAt } = emailCampaign;

  const editSection = (
    <PreviewWrapper>
      <h4>From:</h4>
      <InputFieldWrapper>
        <Autocomplete
          required
          fieldName="fromEmail"
          getOptionLabel={(option) => option.value}
          options={emailCampaign.fromEmailOptions.map((option) => ({ value: option }))}
        />
      </InputFieldWrapper>

      <h4>Reply-to (optional):</h4>
      <InputFieldWrapper>
        <Autocomplete
          fieldName="replyToEmail"
          getOptionLabel={(option) => option.value}
          options={["", ...emailCampaign.replyToEmailOptions].map((option) => ({
            value: option,
          }))}
        />
      </InputFieldWrapper>

      <h4>Subject:</h4>
      <InputFieldWrapper>
        <Input required fieldName="subject" />
      </InputFieldWrapper>

      <h4>Email body:</h4>
      <div css={css({ fontStyle: "italic", fontSize: 14 })}>
        <EmailTemplateModal templateValues={JSON.parse(emailCampaign.templateValues)} />
      </div>
      <InputFieldWrapper>
        <MarkdownTextarea fieldName="emailBody" />
      </InputFieldWrapper>
    </PreviewWrapper>
  );

  const viewSection = <EmailPushPreview emailPush={emailPush} />;

  return (
    <div>
      <ConnectedForm {...connectedFormProps}>
        <HeaderSection>
          <HeaderRow>
            <CenterAlignedGroup>
              <Tag css={css({ backgroundColor: emailPush.disabled ? "gray" : "#21ba45" })}>
                {emailPush.disabled ? "DISABLED" : "ENABLED"}
              </Tag>
              <Switch
                checked={!emailPush.disabled}
                onChange={(e) => onToggleDisabled(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </CenterAlignedGroup>

            {isEditing ? (
              <CenterAlignedGroup>
                <Button color="gray" onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
                <Button color="blue" type="submit">
                  Save
                </Button>
              </CenterAlignedGroup>
            ) : (
              <Button color="blue" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            )}

            {isEditing && (
              <AdvancedEditLink>
                <OpenInNew fontSize="small" />
                <a
                  href={editPushAdminEmailCampaignPath(emailPush.campaignId, emailPush.id)}
                  target="_blank"
                >
                  Edit filters, custom recipients, etc
                </a>
              </AdvancedEditLink>
            )}
          </HeaderRow>

          <EmailPushHeader>
            {number ? `${number}. ` : ""}
            {emailPush.name}
          </EmailPushHeader>

          {!emailPush.disabled && (
            <RecipientsSection>
              {emailPush.lifecycleOnly
                ? "This will be sent programmatically based on user actions"
                : `This will be sent to ${emailPush.numUsersForFilters} recipient${
                    emailPush.numUsersForFilters === 1 ? "" : "s"
                  }`}
            </RecipientsSection>
          )}
        </HeaderSection>

        {isEditing ? editSection : viewSection}

        {isEditing && emailPush.autoLoginLinks && (
          <AutoLoginLinkAlert>Note: this email contains auto-login links</AutoLoginLinkAlert>
        )}

        {meetupStartsAt && (
          <ScheduledDate sendsAt={emailPush.sendsAt} meetupStartsAt={meetupStartsAt} />
        )}
      </ConnectedForm>
    </div>
  );
};
