import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import {
  MEETUP_QUESTIONS,
  MEETUP_QUESTIONS_admin_meetup_questions,
} from "./__generated__/MEETUP_QUESTIONS";
import EditQuestionModal from "./EditQuestionModal";
import MeetupQuestion from "../../meetups/MeetupQuestion";
import { useForm } from "../../../components/forms";
import useUpdateQuestion from "./useUpdateQuestion";
import { Pane, PaneItem } from "../../../components/Pane";
import { EDIT_MEETUP_PAGE } from "./__generated__/EDIT_MEETUP_PAGE";

const Question = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 15px;
  margin: 10px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Preview = styled.div`
  flex-grow: 1;
  max-width: 800px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 15px;
`;

const ReorderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const Placeholder = styled.div`
  min-width: 75px;
  min-height: 85px;
`;

const ReorderGroup = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  min-width: 75px;

  p {
    margin: 0;
  }

  &:first-of-type {
    margin-bottom: 15px;
  }
  &:last-of-type {
    margin-top: 15px;
  }

  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  data: EDIT_MEETUP_PAGE | null;
  meetupId?: string | undefined;
  continueButton?: React.ReactNode | undefined;
};

export default ({ data: meetupData, meetupId, continueButton }: Props) => {
  const id = meetupId;
  const updateQuestion = useUpdateQuestion();

  const { data, refetch } = useQuery<MEETUP_QUESTIONS>(
    gql`
      query MEETUP_QUESTIONS($id: ID!) {
        admin {
          meetup(id: $id) {
            slug
            questions {
              id
              questionText
              questionSubtitle
              questionType
              required
              otherRequirements
              sortOrder
              fieldName
            }
          }
        }
      }
    `,
    { variables: { id }, skip: !id }
  );

  const { ConnectedForm, connectedFormProps } = useForm({}, () => {});

  if (!id) {
    return <div />;
  }

  const onMoveQuestion = async (idx: number, moveUp: boolean) => {
    const questions = data?.admin.meetup.questions || [];
    const questionOne = questions[idx];
    const questionTwo = questions[moveUp ? idx - 1 : idx + 1];
    const meetupIdInt = parseInt(id, 10);

    if (!questionOne || !questionTwo) {
      return;
    }

    await Promise.all([
      updateQuestion({
        input: {
          meetupId: meetupIdInt,
          questionId: questionOne.id,
          sortOrder: questionTwo.sortOrder,
        },
      }),
      updateQuestion({
        input: {
          meetupId: meetupIdInt,
          questionId: questionTwo.id,
          sortOrder: questionOne.sortOrder,
        },
      }),
    ]);

    refetch();
  };

  const renderQuestion = (question: MEETUP_QUESTIONS_admin_meetup_questions, idx: number) => (
    <Question key={question.id}>
      <ReorderSection>
        {idx ? (
          <ReorderGroup onClick={() => onMoveQuestion(idx, true)}>
            <ArrowUpward />
            <p>Move up</p>
          </ReorderGroup>
        ) : (
          <Placeholder />
        )}
        {idx === (data?.admin.meetup.questions.length || 0) - 1 ? (
          <Placeholder />
        ) : (
          <ReorderGroup onClick={() => onMoveQuestion(idx, false)}>
            <p>Move down</p>
            <ArrowDownward />
          </ReorderGroup>
        )}
      </ReorderSection>
      <Preview>
        <MeetupQuestion question={question} skipValidation alwaysShow />
      </Preview>
      <EditQuestionModal meetupId={id} question={question} onUpdate={refetch} />
    </Question>
  );

  return (
    <div>
      <Pane
        title="RSVP questions for attendees (optional)"
        headerRight={<EditQuestionModal meetupId={id} onUpdate={refetch} />}
      >
        <PaneItem>
          <p>
            These questions will be asked as part of the RSVP form on the{" "}
            <a target="_blank" href={meetupData?.admin.adminMeetup.url}>
              public event page.
            </a>
          </p>
          <ConnectedForm {...connectedFormProps}>
            {data?.admin.meetup.questions.map(renderQuestion)}
          </ConnectedForm>
        </PaneItem>
      </Pane>

      {continueButton}
    </div>
  );
};
