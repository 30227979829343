import { css } from "@emotion/core";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { paneBorder } from "../../styles";
import Checkbox from "./Checkbox";
import Requirement from "./Requirement";
import { DASHBOARD_DATA, DASHBOARD_DATA_courseModules } from "./__generated__/DASHBOARD_DATA";

const Module: React.FC<{
  mod: DASHBOARD_DATA_courseModules;
  complete: boolean;
  currentModule: number | undefined;
}> = ({ mod, ...props }) => {
  const [open, setOpen] = useState<boolean>(mod.order === props.currentModule);

  return (
    <div
      key={mod.order}
      css={css({
        borderBottom: paneBorder,
        ":last-child": {
          borderBottom: 0,
        },
      })}
    >
      <PaneItem css={css({ padding: "14px 0" })}>
        <div
          css={css({
            fontSize: "17px",
            padding: "10px 28px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          })}
          onClick={() => setOpen(!open)}
        >
          <Checkbox checked={props.complete} css={css({ marginRight: 10 })} />
          Module {mod.order}: {mod.name}
          {mod.order === props.currentModule && (
            <div
              css={css({
                backgroundColor: "#fbbd08",
                color: "white",
                fontSize: 15,
                padding: "5px 10px",
                margin: "-10px 7px",
                borderRadius: 4,
              })}
            >
              Current Module
            </div>
          )}
          <div css={css({ flex: 1 })} />
          <div css={css({ color: "#c7c7c7", fontSize: 20 })}>
            {open ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </div>
        </div>
        {open && (
          <>
            {mod.curriculumItems.map((content) => (
              <Requirement
                futureRequirement={props.currentModule ? props.currentModule < mod.order : false}
                key={content.title}
                title={`${content.contentType === "video" ? "📺" : "📖"} ${content.title}`}
                link={content.url}
                done={content.curriculumItemFeedback?.status === "complete"}
              />
            ))}
          </>
        )}
      </PaneItem>
    </div>
  );
};

const CourseProgress: React.FC<{ data: DASHBOARD_DATA }> = ({ data }) => {
  if (data.courseModules == null) return null;

  const mods = data.courseModules.map((mod) => {
    const complete = !mod.curriculumItems.some(
      (ci) => ci.curriculumItemFeedback?.status != "complete"
    );

    return { mod, complete };
  });
  const currentModule = mods.find((mod) => !mod.complete)?.mod.order;
  const completed = mods.filter((mod) => mod.complete).length;

  const nextSteps =
    data?.currentUser?.track === "active_founder" ? (
      <>
        <a href="https://www.ycombinator.com/about">consider applying to YC</a> for funding and
        guidance.
      </>
    ) : (
      <>
        <a href="/track">switch to the Active Founder track</a> for more tools and resources.
      </>
    );

  return (
    <Pane>
      <PaneHeader
        id="aspiring_founders_pane_header"
        css={css({ flexDirection: "column", alignItems: "stretch" })}
      >
        <div css={css({ display: "flex" })}>
          <h3>📈 Course Progress</h3>
          <div css={css({ marginLeft: 14, flex: 1 })}>
            {completed}/{mods.length} modules complete
          </div>
        </div>
        {completed === mods.length && (
          <div css={css({ marginTop: "1em" })}>
            Congratulations - you have completed the Startup School curriculum! When you're ready to
            take the next step and start working on your company, you should {nextSteps}
          </div>
        )}
      </PaneHeader>
      {mods.map(({ mod, complete }) => (
        <Module key={mod.order} mod={mod} complete={complete} currentModule={currentModule} />
      ))}
    </Pane>
  );
};

export default CourseProgress;
