import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type FontAwesomeIconProps = React.ComponentProps<typeof FontAwesomeIcon>;
type LoadingSpinnerProps = Pick<FontAwesomeIconProps, "size">;
export default function LoadingSpinner({ size }: LoadingSpinnerProps) {
  return <FontAwesomeIcon icon={faSpinner} className="fa-spin" size={size} />;
}
