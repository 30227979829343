import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Pane, PaneItem } from "../../../components/Pane";
import Button from "../../../components/statelessForms/Button";

const PreviewImgWrapper = styled.div`
  text-align: center;
`;

const PreviewImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
`;

export default (props: {
  id: string;
  speaker: any;
  idx: number;
  onDeleteSpeaker: (idxToDelete: number) => void;
  setSpeakerToEdit: (speakerToEdit: string | null) => void;
  setModalOpen: (open: boolean) => void;
  setName: (name: string) => void;
  setOneLiner: (name: string) => void;
  setUrl: (name: string) => void;
  setImgSrc: (name: string) => void;
  setIsHost: (isHost: boolean) => void;
  setDescription: (name: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flexGrow: 1,
  };

  return (
    <div css={css({ display: "flex", alignItems: "center", justifyContent: "start", gap: 10 })}>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div key={props.speaker.name} css={css({ marginTop: "10px", flexGrow: 1 })}>
          <Pane>
            <PaneItem
              css={css({
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "space-around",
                padding: 15,
              })}
            >
              <PreviewImgWrapper>
                <PreviewImg src={props.speaker.imgSrc} alt="photo" />
              </PreviewImgWrapper>
              <h2 css={css({ margin: 0 })}>
                {props.speaker.name}
                {props.speaker.isHost && " (host)"}
              </h2>
              <div>{props.speaker.oneLiner}</div>
              <div>{props.speaker.description}</div>
              <a href={props.speaker.url} target="_blank">
                Read more
              </a>
            </PaneItem>
          </Pane>
        </div>
      </div>
      <div css={css({ display: "flex", flexDirection: "column", gap: 10 })}>
        <Button
          css={css({ maxHeight: 100 })}
          onClick={() => {
            props.setSpeakerToEdit(props.id);

            props.setName(props.speaker.name);
            props.setOneLiner(props.speaker.oneLiner);
            props.setUrl(props.speaker.url);
            props.setImgSrc(props.speaker.imgSrc);
            props.setIsHost(!!props.speaker.isHost);
            props.setDescription(props.speaker.description);

            props.setModalOpen(true);
          }}
        >
          Edit
        </Button>
        <Button
          css={css({ maxHeight: 100 })}
          onClick={() => {
            props.onDeleteSpeaker(props.idx);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
