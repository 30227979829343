import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import { getSlugFromWindowLocation } from "../../components/forms/util";
import Button from "../../components/statelessForms/Button";
import { Input, LocationPicker, Submit, useForm } from "../../components/forms";
import {
  ADD_MEETUP_LOCATION,
  ADD_MEETUP_LOCATIONVariables,
} from "./__generated__/ADD_MEETUP_LOCATION";
import {
  DELETE_MEETUP_LOCATION,
  DELETE_MEETUP_LOCATIONVariables,
} from "./__generated__/DELETE_MEETUP_LOCATION";
import { MEETUP_LOCATIONS } from "./__generated__/MEETUP_LOCATIONS";
import {
  SEND_MEETUP_LOCATION_WAITLIST_EMAILS,
  SEND_MEETUP_LOCATION_WAITLIST_EMAILSVariables,
} from "./__generated__/SEND_MEETUP_LOCATION_WAITLIST_EMAILS";

const Table = styled.table`
  width: 100%;
  background: #ffffff;
  margin: 1em 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  text-align: left;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.87);
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 14px;

  td {
    font-size: 16px;
    padding: 10px;
  }

  th {
    font-size: 16px;
    padding: 10px;
  }
`;

const NewLocationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

type Props = {
  capacity: number;
};

export default function MeetupLocations({ capacity }: Props) {
  const id = getSlugFromWindowLocation() || "";

  const { data, refetch } = useQuery<MEETUP_LOCATIONS>(
    gql`
      query MEETUP_LOCATIONS($id: ID!) {
        admin {
          meetupLocations(id: $id) {
            id
            kmRadius
            location
            invitesSent
          }
        }
      }
    `,
    { variables: { id } }
  );

  const [addLocation] = useMutation<ADD_MEETUP_LOCATION, ADD_MEETUP_LOCATIONVariables>(gql`
    mutation ADD_MEETUP_LOCATION($meetupId: ID!, $location: String!, $kmRadius: Int!) {
      addMeetupLocation(meetupId: $meetupId, location: $location, kmRadius: $kmRadius) {
        id
        location
        kmRadius
      }
    }
  `);

  const [deleteLocation] = useMutation<DELETE_MEETUP_LOCATION, DELETE_MEETUP_LOCATIONVariables>(gql`
    mutation DELETE_MEETUP_LOCATION($id: ID!) {
      deleteMeetupLocation(id: $id) {
        success
      }
    }
  `);

  const [sendEmails] = useMutation<
    SEND_MEETUP_LOCATION_WAITLIST_EMAILS,
    SEND_MEETUP_LOCATION_WAITLIST_EMAILSVariables
  >(gql`
    mutation SEND_MEETUP_LOCATION_WAITLIST_EMAILS($id: ID!) {
      sendMeetupLocationWaitlistInvites(id: $id) {
        id
      }
    }
  `);

  const locations = data?.admin.meetupLocations;

  const onAddLocation = async (fields: any) => {
    await addLocation({
      variables: {
        meetupId: id,
        location: fields.location,
        kmRadius: Number.parseInt(fields.kmRadius, 10),
      },
    });
    refetch();
  };

  const onDeleteLocation = async (locationId: string) => {
    await deleteLocation({
      variables: { id: locationId },
    });

    refetch();
  };

  const onSendInvites = async (locationId: string) => {
    await sendEmails({
      variables: { id: locationId },
    });

    refetch();
  };

  const { ConnectedForm, connectedFormProps } = useForm(
    {
      location: "",
      kmRadius: 100,
    },
    onAddLocation
  );

  return (
    <div css={css({ marginTop: 30 })}>
      <div>LOCATIONS!</div>
      <Table>
        <thead>
          <tr>
            <th>City</th>
            <th>Radius (km)</th>
            <th>Invite status</th>
            <th>Delete?</th>
          </tr>
        </thead>
        <tbody>
          {(locations || []).map((locationObj) => (
            <tr key={locationObj.id}>
              <td>{locationObj.location}</td>
              <td>{locationObj.kmRadius}</td>
              <td>
                {locationObj.invitesSent ? (
                  <i>Invites sent</i>
                ) : (
                  <Button color="orange" onClick={() => onSendInvites(locationObj.id)}>
                    Send invites
                  </Button>
                )}
              </td>
              <td>
                {!locationObj.invitesSent && (
                  <Button onClick={() => onDeleteLocation(locationObj.id)}>Delete</Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ConnectedForm {...connectedFormProps}>
        <NewLocationRow>
          <LocationPicker label="Attendee location" fieldName="location" />
          <Input label="Radius (in km)" fieldName="kmRadius" type="number" />
          <div css={css({ marginTop: 30 })}>
            <Submit hideStatusText>
              <Button type="submit" color="orange">
                Add
              </Button>
            </Submit>
          </div>
        </NewLocationRow>
      </ConnectedForm>
    </div>
  );
}
