import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { gql, useQuery } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import { Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import Button from "../../components/statelessForms/Button";
import { SHOWCASE_GROUP } from "./__generated__/SHOWCASE_GROUP";
import { mobileStyles } from "../../styles";
import { formatDateForShowcase } from "./Showcase";

// Maintain 16:9 dimensions
const SlideDisplay = styled.img({
  height: 450,
  width: 800,
  objectFit: "cover",
  ...mobileStyles({
    height: 108,
    width: 192,
  }),
});

type TimerProps = {
  overtime?: boolean;
};
const Timer = styled.div<TimerProps>`
  background-color: ${({ overtime }) => (overtime ? "red" : "white")};
  color: ${({ overtime }) => (overtime ? "white" : "#fb651e")};
  font-size: 18px;
  font-weight: ${({ overtime }) => (overtime ? "bold" : 300)};
  border: 1px solid #fb651e;
  padding: 6px 10px;
  border-radius: 4px;
  margin-left: 10px;
`;

const QUERY_PARAM = "slide_number";

type Props = {
  group_id: boolean;
};

export default ({ group_id }: Props) => {
  // get starting slide number from url if it exists
  let startingSlideNumber = 0;
  const slideNumberParam = new URLSearchParams(window.location.search).get(QUERY_PARAM);
  if (slideNumberParam) {
    const possibleSlideNumber = parseInt(slideNumberParam, 10);
    if (possibleSlideNumber) startingSlideNumber = possibleSlideNumber - 1;
  }

  const [slideNumber, setSlideNumber] = useState(startingSlideNumber);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  const { data } = useQuery<SHOWCASE_GROUP>(
    gql`
      query SHOWCASE_GROUP($id: ID!) {
        latestCourse {
          showcaseStartsAt
          showcaseGroup(id: $id) {
            zoomUrl
            breakoutRoom
            presentations {
              name
              companyName
              slidePath
            }
          }
        }
      }
    `,
    { variables: { id: group_id } }
  );

  useEffect(() => {
    if (timerActive) {
      const timerInterval = setInterval(() => {
        setTimeElapsed(timeElapsed + 1);
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [slideNumber, timerActive, timeElapsed]);

  if (!data?.latestCourse) {
    return <></>;
  }

  const { showcaseStartsAt, showcaseGroup } = data.latestCourse;

  if (!showcaseGroup) {
    return <></>;
  }

  const numPresentations = showcaseGroup.presentations.length ?? 0;

  const previousSlide = () => {
    const newSlideNumber = slideNumber - 1;
    setSlideNumber(Math.max(0, newSlideNumber));
    setTimeElapsed(0);
    setTimerActive(true);

    // push slide number param to url
    const url = new URL(window.location.toString());
    url.searchParams.set(QUERY_PARAM, (newSlideNumber + 1).toString());
    window.history.pushState({}, "", url.toString());
  };

  const nextSlide = () => {
    const newSlideNumber = slideNumber + 1;
    setSlideNumber(Math.min(numPresentations, newSlideNumber));
    setTimeElapsed(0);
    setTimerActive(true);

    // push slide number param to url
    const url = new URL(window.location.toString());
    url.searchParams.set(QUERY_PARAM, (newSlideNumber + 1).toString());
    window.history.pushState({}, "", url.toString());
  };

  const toggleTimer = () => {
    setTimerActive(!timerActive);
  };

  const presentation = showcaseGroup.presentations[slideNumber];
  const finished = slideNumber === numPresentations;

  const timer = () => {
    const minutes = Math.floor(timeElapsed / 60);
    const seconds = timeElapsed % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return (
      <Timer overtime={timeElapsed > 70}>
        {minutes}:{formattedSeconds}
      </Timer>
    );
  };

  return (
    <Page title="Startup School Showcase">
      <Pane css={css({ margin: 40, maxWidth: 1000, width: "initial" })}>
        <PaneItem css={css({ width: "100%", textAlign: "center" })}>
          <i>{formatDateForShowcase(showcaseStartsAt)}</i>
          <div css={css({ marginTop: 5 })}>
            Your Zoom link: <a href={showcaseGroup.zoomUrl}>{showcaseGroup.zoomUrl}</a>
          </div>
          <div css={css({ marginTop: 5 })}>
            Your breakout room number: <b>{showcaseGroup.breakoutRoom}</b>
          </div>
          <br />
          <hr />
          <h2>{!finished && `Slide (${slideNumber + 1} out of ${numPresentations})`}</h2>
          <div css={css({ display: "flex", margin: "15px 0", position: "relative" })}>
            {slideNumber > 0 && (
              <div css={css({ marginRight: "auto", zIndex: 1 })}>
                <Button color="yellow" onClick={previousSlide}>
                  Previous
                </Button>
              </div>
            )}
            {!finished && (
              <div css={css({ marginLeft: "auto", zIndex: 1 })}>
                <Button color="yellow" onClick={nextSlide}>
                  Next
                </Button>
              </div>
            )}
            {!finished && (
              <div css={css({ position: "absolute", top: 0, left: 0, width: "100%" })}>
                <div css={css({ margin: "auto", width: "fit-content", display: "flex" })}>
                  <Button color="orange" onClick={toggleTimer}>
                    {timerActive ? "Pause" : "Start"}
                  </Button>
                  {timer()}
                </div>
              </div>
            )}
          </div>
          <div css={css({ position: "relative" })}>
            {presentation?.name && (
              <div css={css({ fontSize: 16, marginBottom: 10 })}>
                Presenter: <b>{presentation?.name}</b>
              </div>
            )}
            {presentation?.companyName && (
              <div css={css({ fontSize: 16, marginBottom: 20 })}>
                Company: <b>{presentation.companyName}</b>
              </div>
            )}
            {!finished && (
              <div css={css({ position: "absolute", right: 0, top: 0, fontSize: 14 })}>
                Next up:{" "}
                {slideNumber === numPresentations - 1
                  ? "Ending instructions"
                  : showcaseGroup.presentations[slideNumber + 1]?.name}
              </div>
            )}
          </div>
          <SlideDisplay
            src={
              presentation?.slidePath
                ? presentation.slidePath
                : "https://s3.us-west-2.amazonaws.com/www.startupschool.org/live/showcase_end_slide.png"
            }
          />
        </PaneItem>
      </Pane>
    </Page>
  );
};
