import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withStyles } from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { addMinutes, isBefore } from "date-fns";
import { css } from "@emotion/core";
import { Container, Page } from "../../components/Page";
import Button from "../../components/statelessForms/Button";
import NotificationSound from "../../../../assets/audio/notification-sound.wav";
import { GROUP_SESSION_SLOT } from "./__generated__/GROUP_SESSION_SLOT";
import {
  JOIN_GROUP_SESSION_WAITING_ROOM,
  JOIN_GROUP_SESSION_WAITING_ROOMVariables,
} from "./__generated__/JOIN_GROUP_SESSION_WAITING_ROOM";
import useRealtime from "../../hooks/useRealtime";
import { loadContinueUrl } from "../../components/forms/util";
import { groupSessionPath } from "../../__generated__/routes";
import CountdownTimer from "../../components/CountdownTimer";
import Agenda from "./Agenda";
import LoadingDots from "../../components/statelessForms/LoadingDots";

const Title = styled.h1`
  font-size: 24px;
  margin-top: 5px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InWaitingRoomMessage = styled.div`
  width: 100%;
  text-align: center;
  margin: 15px 0;
  font-size: 20px;
  font-weight: bold;
  color: #fb651e;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExtraLargeButton = withStyles({
  root: {
    width: 300,
    height: 50,
    fontSize: 16,
  },
})(Button);

type Props = {
  slug: string;
};

export default ({ slug }: Props) => {
  const [sessionAssigned, setSessionAssigned] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { data, refetch } = useQuery<GROUP_SESSION_SLOT>(
    gql`
      query GROUP_SESSION_SLOT($slug: ID!) {
        groupSessionSlot(slug: $slug) {
          slug
          startsAt
          inWaitingRoom
          assignedGroupSessionSlug
          topic
          agenda
        }
      }
    `,
    { variables: { slug } }
  );
  const [joinWaitingRoom] = useMutation<
    JOIN_GROUP_SESSION_WAITING_ROOM,
    JOIN_GROUP_SESSION_WAITING_ROOMVariables
  >(gql`
    mutation JOIN_GROUP_SESSION_WAITING_ROOM($slug: ID!, $leave: Boolean) {
      joinGroupSessionWaitingRoom(slug: $slug, leave: $leave) {
        slug
        inWaitingRoom
      }
    }
  `);

  // Watch for prompts to reload from the backend over websocket through Firebase
  const latestAssignmentUpdate = useRealtime(`group_sessions/${slug}`, null);

  useEffect(() => {
    refetch();
  }, [latestAssignmentUpdate]);

  const playSoundAndRedirect = async (gsSlug: string) => {
    await new Audio(NotificationSound).play();
    loadContinueUrl(groupSessionPath(gsSlug));
  };

  useEffect(() => {
    const gsSlug = data?.groupSessionSlot?.assignedGroupSessionSlug;
    if (gsSlug && !sessionAssigned) {
      setSessionAssigned(true);
      playSoundAndRedirect(gsSlug);
    }
  }, [data?.groupSessionSlot?.assignedGroupSessionSlug]);

  useEffect(() => {
    if (!data?.groupSessionSlot?.startsAt) {
      return;
    }

    const timerInterval = setInterval(() => {
      const generationTime = addMinutes(new Date(data.groupSessionSlot.startsAt), 5);
      if (isBefore(generationTime, new Date())) {
        setIsGenerating(true);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [data?.groupSessionSlot?.startsAt]);

  if (!data?.groupSessionSlot) {
    return <div />;
  }

  const joinOrLeaveWaitingRoom = async (leave: boolean) => {
    await joinWaitingRoom({
      variables: { slug, leave },
    });
    refetch();
  };

  const onJoin = () => {
    joinOrLeaveWaitingRoom(false);
  };

  const onLeave = () => {
    joinOrLeaveWaitingRoom(true);
  };

  const stayOnPageMessage = data?.groupSessionSlot?.inWaitingRoom && (
    <InWaitingRoomMessage>
      Stay on this page! We'll redirect you to your group session when you've been assigned to one.
    </InWaitingRoomMessage>
  );

  const countdownSection = (
    <>
      <Title>Group Session assignments will begin in</Title>
      <CountdownTimer targetTime={addMinutes(new Date(data.groupSessionSlot.startsAt), 5)} />
      {stayOnPageMessage}
      <ButtonRow>
        {data?.groupSessionSlot?.inWaitingRoom ? (
          <Button onClick={onLeave}>Cancel my group session</Button>
        ) : (
          <ExtraLargeButton color="orange" onClick={onJoin}>
            Assign me a group session
          </ExtraLargeButton>
        )}
      </ButtonRow>
    </>
  );

  const generatingSection = (
    <CenteredContent>
      <Title>Group Sessions are being generated</Title>
      <LoadingDots />
      {stayOnPageMessage}
    </CenteredContent>
  );

  return (
    <Page title="Group Session">
      <Container>
        {isGenerating ? generatingSection : countdownSection}
        <h2 css={css({ fontSize: 22 })}>Agenda</h2>
        <Agenda
          preview
          mergeVisible={false}
          startedAt={data.groupSessionSlot.startsAt}
          currentTime={new Date()}
          topic={data.groupSessionSlot.topic}
          agenda={data.groupSessionSlot.agenda}
        />
      </Container>
    </Page>
  );
};
