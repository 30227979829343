import { ComponentType } from "react";
import AdminUser from "./pages/admin/AdminUser";
import AdminSearch from "./components/AdminSearch";
import AllMeetupAppStats from "./pages/admin/meetups/AllMeetupAppStats";
import BrowseUsers from "./pages/admin/BrowseUsers";
import Checkbox from "./pages/dashboard/Checkbox";
import ChooseKickoffCall from "./pages/myProfile/ChooseKickoffCall";
import ChooseTrack from "./pages/myProfile/ChooseTrack";
import ConfirmSanctionRequirement from "./pages/dashboard/ConfirmSanctionRequirement";
import EditCustomMeetupQuestions from "./pages/admin/meetups/EditCustomMeetupQuestions";
import EditMeetupFormPage from "./pages/admin/meetups/EditMeetupFormPage";
import EditMeetupSpeakers from "./pages/admin/meetups/EditMeetupSpeakers";
import Meetup from "./pages/meetups/Meetup";
import MeetupAppIncrementalQueue from "./pages/admin/meetups/MeetupAppIncrementalQueue";
import MeetupAppStats from "./pages/admin/meetups/MeetupAppStats";
import MeetupHarmonicProspects from "./pages/admin/meetups/MeetupHarmonicProspects";
import MeetupLinksDropdown from "./pages/admin/meetups/MeetupLinksDropdown";
import MeetupLocations from "./pages/admin/MeetupLocations";
import MeetupRsvps from "./pages/admin/MeetupRsvps";
import MeetupNametags from "./pages/admin/MeetupNametags";
import MeetupWaitlistBypassUrls from "./pages/admin/meetups/MeetupWaitlistBypassUrls";
import NewEmailPush from "./pages/admin/emailCampaigns/NewEmailPush";
import EditEmailPush from "./pages/admin/emailCampaigns/EditEmailPush";
import ReviewEmailPush from "./pages/admin/emailCampaigns/ReviewEmailPush";
import ReviewAllEmailPushes from "./pages/admin/emailCampaigns/ReviewAllEmailPushes";
import CourseRegistration from "./pages/course/Registration";
import CourseSpeedDatingEvent from "./pages/course/CourseSpeedDatingEvent";
import CurriculumItemPage from "./pages/curriculum/CurriculumItemPage";
import CurriculumPageContainer from "./pages/curriculum/CurriculumPage";
import Dashboard from "./pages/dashboard";
import EditASFProfile from "./pages/myProfile/EditASFProfile";
import EditCompany from "./pages/myProfile/EditCompany";
import EditUser from "./pages/myProfile/EditUser";
import EngagementChart from "./components/EngagementChart";
import GroupSessionsIndex from "./pages/groupSession/GroupSessionsIndex";
import GroupSessionPage from "./pages/groupSession/GroupSession";
import GroupSessionWaitingRoom from "./pages/groupSession/GroupSessionWaitingRoom";
import InvitesPage from "./pages/InvitesPage";
import KickoffCallSpeedDatingEvent from "./pages/kickoffCall/KickoffCallSpeedDatingEvent";
import LandingPageFAQSection from "./pages/landingPage/FAQSection";
import LandingPageTestimonialCarousel from "./pages/landingPage/TestimonialCarousel";
import Navigation from "./components/navigation";
import RatedUsersList from "./pages/admin/RatedUsersList";
import registerTopLevelComponents from "./registerTopLevelComponents";
import ReviewUsers from "./pages/admin/ReviewUsers";
import Showcase from "./pages/course/Showcase";
import ShowcaseGroup from "./pages/course/ShowcaseGroup";
import SignOutLink from "./components/navigation/SignOutLink";
import Sprint from "./pages/sprint";
import SprintRegister from "./pages/sprint/SprintRegister";
import UnratedUsers from "./pages/admin/UnratedUsers";
import UpdateCharts from "./pages/updateIndex/UpdateCharts";
import UpdateEdit from "./pages/updateEdit";
import UpdateIndex from "./pages/updateIndex";
import UserHowHeard from "./components/UserHowHeard";
import UserLocation from "./components/UserLocation";

const registeredComponents: {
  [name: string]: ((props: any) => JSX.Element) | ComponentType;
} = {
  AdminUser,
  AdminSearch,
  AllMeetupAppStats,
  BrowseUsers,
  Checkbox,
  ChooseKickoffCall,
  ChooseTrack,
  ConfirmSanctionRequirement,
  CourseRegistration,
  CourseSpeedDatingEvent,
  CurriculumItemPage,
  CurriculumPageContainer,
  Dashboard,
  EditASFProfile,
  EditCompany,
  EditUser,
  EngagementChart,
  GroupSessionsIndex,
  GroupSessionPage,
  GroupSessionWaitingRoom,
  InvitesPage,
  KickoffCallSpeedDatingEvent,
  LandingPageFAQSection,
  LandingPageTestimonialCarousel,
  EditCustomMeetupQuestions,
  EditMeetupFormPage,
  EditMeetupSpeakers,
  Meetup,
  MeetupAppIncrementalQueue,
  MeetupAppStats,
  MeetupHarmonicProspects,
  MeetupLinksDropdown,
  MeetupLocations,
  MeetupRsvps,
  MeetupNametags,
  MeetupWaitlistBypassUrls,
  Navigation,
  NewEmailPush,
  EditEmailPush,
  ReviewEmailPush,
  ReviewAllEmailPushes,
  RatedUsersList,
  ReviewUsers,
  Showcase,
  ShowcaseGroup,
  SignOutLink,
  Sprint,
  SprintRegister,
  UnratedUsers,
  UpdateCharts,
  UpdateEdit,
  UpdateIndex,
  UserHowHeard,
  UserLocation,
};

registerTopLevelComponents(registeredComponents);
