import { gql, useMutation } from "@apollo/client";
import { UPDATE_MEETUP, UPDATE_MEETUPVariables } from "./__generated__/UPDATE_MEETUP";

export default function useUpdateMeetup() {
  const [updateMeetup] = useMutation<UPDATE_MEETUP, UPDATE_MEETUPVariables>(gql`
    mutation UPDATE_MEETUP($input: AdminUpdateEventInput!, $meetupId: ID) {
      adminUpdateEvent(input: $input, meetupId: $meetupId) {
        continueUrl
        id
      }
    }
  `);

  return (variables: UPDATE_MEETUPVariables) => updateMeetup({ variables });
}
