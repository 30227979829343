import { useCallback, useMemo, useState } from "react";
import ReactPDF from "@react-pdf/renderer";
import Styles = ReactPDF.Styles;

const UNIT_PX = 150;
const BLEED_IN = 0.25;
const MARGIN_IN = 0.125;
const MARGIN_PX = UNIT_PX * MARGIN_IN;
const BOTTOM_BAR_PX_HORIZONTAL = 85;
const BOTTOM_BAR_PX_VERTICAL = 150;

export default function useBleedStyles() {
  const [hasBleed, setHasBleed] = useState(false);
  const [vertical, setVertical] = useState(true);

  const bleedIn = useMemo(() => (hasBleed ? BLEED_IN : 0), [hasBleed]);

  const bottomBarPx = useMemo(
    () => (vertical ? BOTTOM_BAR_PX_VERTICAL : BOTTOM_BAR_PX_HORIZONTAL),
    [vertical]
  );

  const heightPx = useMemo(() => {
    const heightIn = (vertical ? 4 : 3) + bleedIn;
    return UNIT_PX * heightIn;
  }, [bleedIn, vertical]);

  const widthPx = useMemo(() => {
    const widthIn = (vertical ? 3 : 4) + bleedIn;
    return UNIT_PX * widthIn;
  }, [bleedIn, vertical]);

  const withBleed = useCallback(
    (styles: Styles) => {
      const bleedPx = UNIT_PX * bleedIn;

      styles.nametag = {
        ...styles.nametag,
        height: heightPx,
        width: widthPx,
      };

      styles.mainSection = {
        ...styles.mainSection,
        height: heightPx - bottomBarPx,
        width: widthPx,
        paddingTop: MARGIN_PX + bleedPx + 10,
        paddingLeft: MARGIN_PX + bleedPx + 10,
        paddingRight: MARGIN_PX + bleedPx + 10,
      };

      styles.bottomBar = {
        ...styles.bottomBar,
        height: bottomBarPx,
        width: widthPx,
        paddingBottom: vertical ? 0 : bleedPx + MARGIN_PX,
        paddingLeft: bleedPx + MARGIN_PX,
        paddingRight: bleedPx + MARGIN_PX,
      };

      styles.ycLogoContainer = {
        ...(styles.ycLogoContainer || {}),
        position: "absolute",
        top: bleedPx + 20,
        right: bleedPx + 20,
      };

      return styles;
    },
    [bleedIn, widthPx, heightPx]
  );

  return { hasBleed, setHasBleed, vertical, setVertical, withBleed, widthPx, heightPx };
}
