import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { HarmonicEventProspectColumnType, HarmonicEventProspectSortType } from "./MeetupColumns";
import AdminMeetupHarmonicEventProspect from "./AdminMeetupHarmonicEventProspect";
import AdminMeetupHarmonicEventProspectTableHeaders from "./AdminMeetupHarmonicEventProspectTableHeaders";
import { MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects } from "./__generated__/MEETUP_SAVED_SEARCH";

const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: scroll;
`;

const Table = styled.table`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  text-align: left;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.87);
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 14px;
`;

type Props = {
  harmonicProspects: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects[];
  columns: HarmonicEventProspectColumnType[];
  isOver: boolean;
  sortType: HarmonicEventProspectSortType | null;
  setSortType: (sortType: HarmonicEventProspectSortType) => void;
};

export default ({ harmonicProspects, columns, isOver, sortType, setSortType }: Props) => (
  <TableWrapper>
    <Table>
      <AdminMeetupHarmonicEventProspectTableHeaders
        columns={columns}
        sortType={sortType}
        setSortType={setSortType}
        hideActions={false}
      />
      <tbody css={css({ fontSize: 12 })}>
        {harmonicProspects.map((harmonicProspect) => (
          <AdminMeetupHarmonicEventProspect
            key={harmonicProspect.entityUrn}
            harmonicProspect={harmonicProspect}
            columns={columns}
          />
        ))}
      </tbody>
    </Table>
  </TableWrapper>
);
