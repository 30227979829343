import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Pane, PaneItem } from "../../../components/Pane";
import { Row } from "./EmailPushFiltersForm";
import { DatePicker, RadioGroup } from "../../../components/forms";
import { NEW_EMAIL_PUSH_admin_emailCampaign } from "./__generated__/NEW_EMAIL_PUSH";
import ScheduledDate from "./ScheduledDate";

type Props = {
  emailPush?: any;
  emailCampaign: NEW_EMAIL_PUSH_admin_emailCampaign;
};

const Header = styled.h4`
  font-size: 16px;
  font-weight: bold;
`;

export default ({ emailPush, emailCampaign }: Props) => {
  const formMethods = useFormContext();
  const [sendTimingTab, setSendTimingTab] = useState(emailPush?.sendsAt ? 1 : 0);

  // clear out "sendsAt" when you click the "send on demand" tab
  useEffect(() => {
    if (sendTimingTab === 0) {
      formMethods.setValue("sendsAt", null);
    }
  }, [sendTimingTab]);

  const onToggle = (event: any, sendTiming: number) => {
    setSendTimingTab(sendTiming);
  };

  const sendsAt = formMethods.watch("sendsAt");

  const timeSelect = (
    <>
      <Header>When should the email be sent?</Header>
      <Row css={css({ alignItems: "end", marginTop: sendTimingTab ? -30 : 0, marginBottom: 20 })}>
        <div css={css({ maxWidth: "fit-content" })}>
          <Paper square>
            <Tabs
              value={sendTimingTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={onToggle}
            >
              <Tab label="On-demand (when you hit the send button)" />
              <Tab label="At a scheduled time" />
            </Tabs>
          </Paper>
        </div>
        {!!sendTimingTab && (
          <div css={css({ paddingLeft: 20, flexGrow: 1 })}>
            <DatePicker
              fieldName="sendsAt"
              label="Select delivery time based on *your* current time zone"
              showTimeSelect
              required
            />
          </div>
        )}
      </Row>
      <ScheduledDate sendsAt={sendsAt} meetupStartsAt={emailCampaign.meetupStartsAt} editing />
    </>
  );

  const autoLoginLinkToggle = (
    <RadioGroup
      fieldName="autoLoginLinks"
      label="Should this email use auto-login links?"
      options={[
        [true, "Yes"],
        [false, "No"],
      ]}
      required
    />
  );

  const disabledToggle = (
    <RadioGroup
      fieldName="disabled"
      label="Disable this email? (It will NOT be sent unless you re-enable it)"
      options={[
        [true, "Yes - disable it"],
        [false, "No - keep it enabled"],
      ]}
      required
    />
  );

  return (
    <>
      <Row css={css({ marginTop: 50, marginBottom: 20 })}>
        <h2>Other settings:</h2>
      </Row>
      <Pane>
        <PaneItem>
          {!emailPush?.lifecycleOnly && timeSelect}
          {!!emailCampaign.meetupId && autoLoginLinkToggle}
          {disabledToggle}
        </PaneItem>
      </Pane>
    </>
  );
};
