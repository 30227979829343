import React, { useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { format, isBefore } from "date-fns";
import styled from "@emotion/styled";
import { MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions } from "./__generated__/MEETUP_YC_APP_MENTIONS";
import LoadingDots from "../../../components/statelessForms/LoadingDots";
import {
  MEETUP_YC_APP_MENTION_QUEUE,
  MEETUP_YC_APP_MENTION_QUEUE_admin_meetupYcAppsWithoutMention,
} from "./__generated__/MEETUP_YC_APP_MENTION_QUEUE";
import AcceptedYCApplicantTable from "./AcceptedYCApplicantTable";
import { MEETUP_YC_APP_MENTIONS_BY_APP_ID } from "./__generated__/MEETUP_YC_APP_MENTIONS_BY_APP_ID";
import { appStatsAdminMeetupsPath } from "../../../__generated__/routes";

type MeetupAppData = {
  startDate: Date;
  meetupId: string;
  title: string;
  apps: MEETUP_YC_APP_MENTION_QUEUE_admin_meetupYcAppsWithoutMention[];
};

const EventSection = styled.div`
  padding: 30px 30px 1px 30px;

  &:nth-of-type(even) {
    background-color: #e0ebf6;
  }

  &:nth-of-type(odd) {
    background-color: #f2f7fb;
  }
`;

const PageTitle = styled.h2`
  font-size: 24px;
`;

export default () => {
  const [appIdsTouched, setAppIdsTouched] = useState<number[]>([]);
  const { data, loading } = useQuery<MEETUP_YC_APP_MENTION_QUEUE>(
    gql`
      query MEETUP_YC_APP_MENTION_QUEUE {
        admin {
          allMeetups {
            id
            startsAt
            title
          }
          meetupYcAppsWithoutMention {
            internalAppId
            meetupId
            appUrl
            batch
            result
            name
            uuid
          }
        }
      }
    `
  );

  const [loadMentions, { data: mentionData }] = useLazyQuery<MEETUP_YC_APP_MENTIONS_BY_APP_ID>(
    gql`
      query MEETUP_YC_APP_MENTIONS_BY_APP_ID($appIds: [Int!]) {
        admin {
          meetupYcAppMentions(appIds: $appIds) {
            id
            internalAppId
            mentioned
          }
        }
      }
    `
  );

  const [groupedData, setGroupedData] = useState<[string, MeetupAppData][]>([]);
  const [mentionsByAppId, setMentionsByAppId] = useState<{
    [appId: number]: MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions;
  }>({});

  useEffect(() => {
    if (groupedData.length) {
      return;
    }

    const meetupsById = (data?.admin.allMeetups || []).reduce((map, meetup) => {
      const startDate = new Date(meetup.startsAt);
      const title = `${meetup.title} (${format(startDate, "MMM d, yyyy")})`;
      map[meetup.id] = { startDate, title, meetupId: meetup.id, apps: [] };
      return map;
    }, {} as { [id: string]: MeetupAppData });

    data?.admin.meetupYcAppsWithoutMention.forEach((app) => {
      meetupsById[app.meetupId.toString()].apps.push(app);
    });

    setGroupedData(
      Object.entries(meetupsById)
        .filter(([_, appData]) => appData.apps.length)
        .sort((e1, e2) => (isBefore(e1[1].startDate, e2[1].startDate) ? -1 : 1))
    );
  }, [data, mentionData]);

  useEffect(() => {
    if (!mentionData?.admin.meetupYcAppMentions) {
      return;
    }

    const map: { [appId: number]: MEETUP_YC_APP_MENTIONS_admin_meetupYcAppMentions } = {};
    mentionData?.admin.meetupYcAppMentions.forEach((mention) => {
      map[mention.internalAppId] = mention;
    });

    setMentionsByAppId(map);
  }, [mentionData]);

  const onAppIdTouched = async (appId: number) => {
    const allAppIdsTouched = [...appIdsTouched.filter((id) => id !== appId), appId];
    setAppIdsTouched(allAppIdsTouched);
    await loadMentions({ variables: { appIds: allAppIdsTouched } });
  };

  return (
    <div>
      <PageTitle>Meetup → YC App Queue</PageTitle>
      {loading ? (
        <div css={css({ display: "flex", justifyContent: "center", marginTop: 50, width: "100%" })}>
          <LoadingDots />
        </div>
      ) : (
        groupedData.map(([_, appData]) => (
          <EventSection key={appData.title}>
            <h2>
              <a href={appStatsAdminMeetupsPath(appData.meetupId)} target="_blank">
                {appData.title}
              </a>
            </h2>
            <AcceptedYCApplicantTable
              meetupId={appData.meetupId}
              apps={appData.apps}
              mentionsByAppId={mentionsByAppId}
              refetch={onAppIdTouched}
            />
          </EventSection>
        ))
      )}
    </div>
  );
};
