import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import CurriculumItem, { CurriculumItemType } from "./CurriculumItem";
import { mobileStyles } from "../../styles";
import { Container, Header } from "../../components/Page";
import { signUpUsersPath } from "../../__generated__/routes";
import {
  CURRICULUM_FEEDBACK,
  CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback,
} from "./__generated__/CURRICULUM_FEEDBACK";

type AsfModule = { id: number; name: string; order: number };

type Props = {
  curriculum_items: CurriculumItemType[];
  signed_in: boolean;
  aspiring_founder: boolean;
  asf_modules: AsfModule[] | null;
};

type ProgressBarProps = {
  numCompleted: number;
  numTotal: number;
  fullWidth?: boolean;
};

export const ProgressBar = ({ numCompleted, numTotal, fullWidth = false }: ProgressBarProps) => (
  <div
    css={css({
      backgroundColor: "#b1e0ae",
      height: "12px",
      width: fullWidth ? "100%" : "75%",
      ...mobileStyles({ width: "100%" }),
    })}
  >
    <div
      css={css({
        backgroundColor: "#7bcf56",
        height: "100%",
        width: `${(100 * numCompleted) / numTotal}%`,
      })}
    />
  </div>
);

export default function CurriculumPage(props: Props): React.ReactElement {
  const [feedbackById, setFeedbackById] = useState(
    new Map<String, CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback>()
  );
  const { data } = useQuery<CURRICULUM_FEEDBACK>(gql`
    query CURRICULUM_FEEDBACK {
      curriculum {
        id
        curriculumItemFeedback {
          status
          rating
        }
      }
    }
  `);

  useEffect(() => {
    if (!data?.curriculum) {
      return;
    }

    const newFeedbackById = new Map<
      string,
      CURRICULUM_FEEDBACK_curriculum_curriculumItemFeedback
    >();
    data.curriculum.forEach((item) => {
      const { id, curriculumItemFeedback } = item;
      if (curriculumItemFeedback) {
        newFeedbackById.set(id, curriculumItemFeedback);
      }
    });

    setFeedbackById(newFeedbackById);
  }, [data?.curriculum]);

  const numCompleted =
    data?.curriculum.filter(
      (i) =>
        i.curriculumItemFeedback?.status == "complete" ||
        i.curriculumItemFeedback?.status == "irrelevant"
    ).length || 0;
  const numCurriculum = props.curriculum_items.length;

  let headerText = "Check out a piece of content to get started!";
  if (numCompleted === numCurriculum) {
    headerText = "Curriculum complete, fantastic job!";
  } else if (numCompleted > 0) {
    headerText = "Keep it up, you're doing great!";
  }

  let pageContent = null;

  if (props.asf_modules) {
    pageContent = props.asf_modules.map((mod) => (
      <div key={mod.order}>
        <div
          css={css({
            borderBottom: "1px solid #ddd",
            borderTop: "2px solid #ddd", // extra px because content pieces don't have a bottom border
            color: "#5d5d5d",
            fontSize: 20,
            padding: "30px 63px",
          })}
        >
          Module {mod.order}: {mod.name}
        </div>
        {props.curriculum_items
          .filter((ci) => ci.asf_module_id === mod.id)
          .map((c: CurriculumItemType) => (
            <CurriculumItem
              curriculumItem={c}
              feedback={feedbackById.get(`${c.id}`)}
              isCurriculum
              showFeedback={props.signed_in}
              hideIrrelevantFeedback={props.aspiring_founder}
              key={c.id}
            />
          ))}
      </div>
    ));
  } else {
    pageContent = props.curriculum_items.map((c: CurriculumItemType) => (
      <CurriculumItem
        curriculumItem={c}
        feedback={feedbackById.get(`${c.id}`)}
        isCurriculum
        showFeedback={props.signed_in}
        hideIrrelevantFeedback={props.aspiring_founder}
        key={c.id}
      />
    ));
  }

  return (
    <div className="library-page">
      <Header>
        <Container>
          <h1
            css={css({
              marginBottom: "7px",
            })}
          >
            Startup School Curriculum
          </h1>
          <div
            css={css({
              display: "flex",
              marginBottom: "15px",
              width: "fit-content",
              ...mobileStyles({ flexDirection: "column" }),
            })}
          >
            {props.signed_in && (
              <div
                css={css({
                  backgroundColor: "#dadac9",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "9px",
                  letterSpacing: "1.13px",
                  marginRight: "11px",
                  padding: "2px 14px",
                  textTransform: "uppercase",
                  width: "fit-content",
                })}
              >
                Course progress: {numCompleted}/{numCurriculum}
              </div>
            )}
            <div
              css={css({
                color: "#1a1a1a",
                fontSize: "14px",
                margin: "auto 0",
                ...mobileStyles({ marginTop: "10px" }),
              })}
            >
              {props.signed_in ? (
                <>🔥 {headerText}</>
              ) : (
                <span>
                  This is a selection of our most popular curriculum. To see content specific to
                  your stage,{" "}
                  <a
                    href={signUpUsersPath()}
                    css={css({
                      color: "#1a1a1a",
                      textDecoration: "underline",
                      "&:hover": { color: "#195d9f", textDecoration: "none" },
                    })}
                  >
                    sign up!
                  </a>
                </span>
              )}
            </div>
          </div>
          {props.signed_in && <ProgressBar numCompleted={numCompleted} numTotal={numCurriculum} />}
        </Container>
      </Header>
      {pageContent}
    </div>
  );
}
