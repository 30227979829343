import authorizedFetch from "@yc/shared/authorizedFetch";

// @ts-ignore
window.handleImpersonateClick = async (userId: string, enforceValidations: boolean) => {
  window.posthog.reset();
  const resp = await authorizedFetch(
    `/admin/users/${userId}/impersonate?validate=${enforceValidations}`,
    {
      method: "POST",
    }
  ).then((r) => r.json());
  window.location.href = resp.continue;
};
