import React, { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingDots from "../../../components/statelessForms/LoadingDots";
import {
  AppsStat,
  getBatchValue,
  IncrementalNote,
  INITIAL_APPS_STAT,
  updateNestedStat,
  updateStat,
} from "./meetupStatUtils";
import { ALL_MEETUP_YC_APP_MENTIONS } from "./__generated__/ALL_MEETUP_YC_APP_MENTIONS";
import {
  ALL_MEETUP_YC_APP_STATS,
  ALL_MEETUP_YC_APP_STATS_admin_allMeetups,
} from "./__generated__/ALL_MEETUP_YC_APP_STATS";
import AppStatTable from "./AppStatTable";
import { appStatsAdminMeetupsPath } from "../../../__generated__/routes";
import Button from "../../../components/statelessForms/Button";

type MeetupStat = { [meetupId: string]: AppsStat };
type BatchStat = { [batch: string]: MeetupStat };

export default () => {
  const [viewByAttendee, setViewByAttendee] = useState(true);
  const { data, loading } = useQuery<ALL_MEETUP_YC_APP_STATS>(
    gql`
      query ALL_MEETUP_YC_APP_STATS {
        admin {
          allMeetups {
            id
            title
            startsAt
            confirmedUuids
          }
          meetupYcApps {
            internalAppId
            meetupId
            appUrl
            batch
            result
            name
            uuid
          }
        }
      }
    `
  );

  const { data: mentionData } = useQuery<ALL_MEETUP_YC_APP_MENTIONS>(
    gql`
      query ALL_MEETUP_YC_APP_MENTIONS {
        admin {
          meetupYcAppMentions {
            id
            internalAppId
            meetupId
            mentioned
          }
        }
      }
    `
  );

  const getUserKey = (uuid: string, appId: string) => `user|${uuid}|${appId}`;
  const getCompanyKey = (appId: string) => `company|${appId}`;

  const [userCountsByMeetupByBatch, companyCountsByMeetupByBatch, meetupsById] = useMemo(() => {
    // Aggregate stats for a specific batch. These will be of the form
    // e.g. { all: <SummaryStatsForAllTime>, w2024: <SummaryStatsForTheBatch> }
    const batchCountsByUser: MeetupStat = { all: { ...INITIAL_APPS_STAT } };
    const batchCountsByCompany: MeetupStat = { all: { ...INITIAL_APPS_STAT } };

    // Breakdown of individual meetup stats filtered to a specific batch. These will be of the form
    // e.g. { w2024: { <meetup_id>: MeetupStat, <meetup_id2>: MeetupStat } }
    const countsByMeetupByUser: BatchStat = { all: {} };
    const countsByMeetupByCompany: BatchStat = { all: {} };

    const meetupsMap = (data?.admin.allMeetups || []).reduce((map, meetup) => {
      map[meetup.id.toString()] = meetup;
      return map;
    }, {} as { [id: string]: ALL_MEETUP_YC_APP_STATS_admin_allMeetups });

    const meetupYcApps = data?.admin.meetupYcApps;
    const mentionsById: { [appId: number]: string[] } = {};
    if (!meetupsMap || !meetupYcApps || !mentionData) {
      return [countsByMeetupByUser, countsByMeetupByCompany, meetupsMap];
    }

    mentionData?.admin.meetupYcAppMentions.forEach((mention) => {
      if (!mentionsById[mention.internalAppId]) {
        mentionsById[mention.internalAppId] = [];
      }

      mentionsById[mention.internalAppId].push(mention.meetupId.toString());
    });

    const meetupIdsByBatch: { [batch: string]: Set<string> } = { all: new Set() };
    meetupYcApps.forEach(({ batch, meetupId }) => {
      if (!meetupIdsByBatch[batch]) {
        meetupIdsByBatch[batch] = new Set();
      }
      const id = meetupId.toString();
      meetupIdsByBatch[batch].add(id);
      meetupIdsByBatch.all.add(id);
    });
    const attendeeUuidsByBatch: { [batch: string]: Set<string> } = { all: new Set() };
    Object.entries(meetupIdsByBatch).forEach(([batch, meetupIds]) => {
      meetupIds.forEach((meetupId) => {
        if (!attendeeUuidsByBatch[batch]) {
          attendeeUuidsByBatch[batch] = new Set();
        }
        meetupsMap[meetupId].confirmedUuids.forEach((uuid) => {
          attendeeUuidsByBatch[batch].add(uuid);
        });
      });
    });
    const attendeeCountsByBatch: { [batch: string]: number } = {};
    Object.entries(attendeeUuidsByBatch).forEach(([batch, attendees]) => {
      attendeeCountsByBatch[batch] = attendees.size;
    });

    const appsSeenByMeetup: { [meetupId: string]: Set<string> } = { all: new Set() };
    const usersSeenByBatch: { [batch: string]: Set<string> } = { all: new Set() };
    const appsSeenByBatch: { [batch: string]: Set<string> } = { all: new Set() };
    meetupYcApps.forEach(
      ({ batch, result, appUrl, internalAppId, uuid, meetupId: meetupIdNum }) => {
        const meetupId = meetupIdNum.toString();
        const meetupAttendees = meetupsMap[meetupId].confirmedUuids;
        const totalRsvps = meetupAttendees.length;

        if (!batchCountsByUser[batch]) {
          batchCountsByUser[batch] = { ...INITIAL_APPS_STAT };
        }
        if (!batchCountsByCompany[batch]) {
          batchCountsByCompany[batch] = { ...INITIAL_APPS_STAT };
        }
        if (!countsByMeetupByUser[batch]) {
          countsByMeetupByUser[batch] = {};
        }
        if (!countsByMeetupByCompany[batch]) {
          countsByMeetupByCompany[batch] = {};
        }

        const mentioned = !!mentionsById[internalAppId]?.includes(meetupId);
        const userKey = getUserKey(uuid, appUrl);
        const companyKey = getCompanyKey(appUrl);

        /* Step 1: update meetup-level batch counts */
        updateNestedStat(meetupId, countsByMeetupByUser[batch], result, totalRsvps, mentioned);
        updateNestedStat(meetupId, countsByMeetupByUser.all, result, totalRsvps, mentioned);

        if (!appsSeenByMeetup[meetupId]) {
          appsSeenByMeetup[meetupId] = new Set();
        }

        if (!appsSeenByMeetup[meetupId].has(companyKey)) {
          updateNestedStat(meetupId, countsByMeetupByCompany.all, result, totalRsvps, mentioned);
          updateNestedStat(meetupId, countsByMeetupByCompany[batch], result, totalRsvps, mentioned);
        }

        /* Step 2: update the aggregate counts */
        if (!usersSeenByBatch[batch]) {
          usersSeenByBatch[batch] = new Set();
        }
        // Don't double-count the user's attendance/apps if they attended multiple events
        if (!usersSeenByBatch[batch].has(userKey)) {
          updateStat(batchCountsByUser[batch], result, attendeeCountsByBatch[batch], mentioned);
        }

        if (!usersSeenByBatch.all.has(userKey)) {
          updateStat(batchCountsByUser.all, result, attendeeCountsByBatch.all, mentioned);
        }

        // by company
        if (!appsSeenByBatch[batch]) {
          appsSeenByBatch[batch] = new Set();
        }
        if (!appsSeenByBatch[batch].has(companyKey)) {
          updateStat(batchCountsByCompany.all, result, attendeeCountsByBatch.all, mentioned);
          updateStat(batchCountsByCompany[batch], result, attendeeCountsByBatch[batch], mentioned);
        }

        appsSeenByBatch[batch].add(companyKey);
        appsSeenByMeetup[meetupId].add(companyKey);
        usersSeenByBatch[batch].add(userKey);
        usersSeenByBatch.all.add(userKey);
      }
    );

    return [countsByMeetupByUser, countsByMeetupByCompany, meetupsMap];
  }, [data, mentionData]);

  const countMapByBatch = viewByAttendee ? userCountsByMeetupByBatch : companyCountsByMeetupByBatch;

  const sortedCountMaps = Object.entries(countMapByBatch)
    .filter(([batch]) => batch !== "all")
    .sort((e1, e2) => (getBatchValue(e1[0]) > getBatchValue(e2[0]) ? -1 : 1));

  const titleMapper = (meetupId: string) =>
    meetupsById ? (
      <a href={appStatsAdminMeetupsPath(meetupId)} target="_blank">
        {meetupsById[meetupId]?.title}
      </a>
    ) : (
      ""
    );

  const dateMapper = (meetupId: string) =>
    meetupsById ? new Date(meetupsById[meetupId]?.startsAt) : new Date();

  const setViewButton = (
    <div css={css({ marginBottom: 20 })}>
      <Button onClick={() => setViewByAttendee(!viewByAttendee)}>
        View counts by {viewByAttendee ? "total companies accepted" : "total attendees accepted"}
      </Button>
    </div>
  );

  return (
    <div>
      {loading ? (
        <div css={css({ display: "flex", justifyContent: "center", marginTop: 50, width: "100%" })}>
          <LoadingDots />
        </div>
      ) : (
        <>
          {setViewButton}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h2>All time: counts by {viewByAttendee ? "attendee" : "company"}</h2>
            </AccordionSummary>
            <AccordionDetails>
              <AppStatTable
                countMap={countMapByBatch.all}
                rowTitle="Event"
                titleMapper={titleMapper}
                dateMapper={dateMapper}
                defaultSort="accepted"
              />
            </AccordionDetails>
          </Accordion>
          {sortedCountMaps.map(([batch, countMap]) => (
            <Accordion key={batch}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2>
                  {batch}: counts by {viewByAttendee ? "attendee" : "company"}
                </h2>
              </AccordionSummary>
              <AccordionDetails>
                <AppStatTable
                  countMap={countMap}
                  rowTitle="Event"
                  titleMapper={titleMapper}
                  dateMapper={dateMapper}
                  defaultSort="accepted"
                />
              </AccordionDetails>
            </Accordion>
          ))}
          <IncrementalNote>
            *incremental means the company was accepted <i>and</i> they mentioned this event in
            their app.
          </IncrementalNote>
        </>
      )}
    </div>
  );
};
