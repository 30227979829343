import { format } from "date-fns";
import {
  MEETUP_RSVPS_admin_meetupRsvps,
  MEETUP_RSVPS_admin_meetupRsvps_adminUser,
  MEETUP_RSVPS_admin_meetupRsvps_cfmProfile_profile,
  MEETUP_RSVPS_admin_meetupRsvps_ssoUser,
  MEETUP_RSVPS_admin_meetupRsvps_adminUser_user,
  MEETUP_RSVPS_admin_meetupRsvps_adminVotes,
} from "../__generated__/MEETUP_RSVPS";
import { adminUserPath, profileAdminCofounderMatchingPath } from "../../../__generated__/routes";
import {
  MEETUP_NAMETAGS_admin_meetupRsvps,
  MEETUP_NAMETAGS_admin_meetupRsvps_adminVotes,
} from "../__generated__/MEETUP_NAMETAGS";
import { MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects } from "./__generated__/MEETUP_SAVED_SEARCH";

type RsvpTypeWithoutQuestions = MEETUP_RSVPS_admin_meetupRsvps | MEETUP_NAMETAGS_admin_meetupRsvps;

export type RsvpType = (MEETUP_RSVPS_admin_meetupRsvps | MEETUP_NAMETAGS_admin_meetupRsvps) & {
  questions: { [questionId: string]: any };
};

export type VoteType =
  | MEETUP_RSVPS_admin_meetupRsvps_adminVotes
  | MEETUP_NAMETAGS_admin_meetupRsvps_adminVotes;

export type MapperType = (val: any) => any;

const internalBaseUrl = window.location.origin.includes("yclocal")
  ? "http://internal.yclocal.com:3000"
  : "https://internal.ycinside.com";

export const getSusUrl = (rsvp: MEETUP_RSVPS_admin_meetupRsvps) =>
  rsvp.adminUser ? window.location.origin + adminUserPath(rsvp.adminUser.id) : "";
export const getCfmUrl = (rsvp: MEETUP_RSVPS_admin_meetupRsvps) =>
  rsvp.cfmProfile
    ? window.location.origin + profileAdminCofounderMatchingPath(rsvp.cfmProfile.id)
    : "";

export const getAdminUrl = (eventType: string, rsvp: MEETUP_RSVPS_admin_meetupRsvps) => {
  if (eventType === "cfm" && rsvp.cfmProfile) {
    return getCfmUrl(rsvp);
  }
  if (["cfm", "course"].includes(eventType) && rsvp.adminUser) {
    return getSusUrl(rsvp);
  }

  return `${internalBaseUrl}/users/by_uuid/${rsvp.ssoUser.uuid}`;
};

const baseCompare = (val1: any, val2: any, rsvp1: RsvpType, rsvp2: RsvpType, reversed: boolean) => {
  if (val1 > val2) return reversed ? -1 : 1;
  if (val1 < val2) return reversed ? 1 : -1;

  // use joinedWaitlistAt as a tie breaker, showing newest first
  return rsvp1.joinedWaitlistAt < rsvp2.joinedWaitlistAt ? 1 : -1;
};

export const profileSortFn =
  (
    field: keyof MEETUP_RSVPS_admin_meetupRsvps_cfmProfile_profile,
    reversed?: boolean,
    mapper: MapperType = (val) => val
  ) =>
  (rsvp1: RsvpType, rsvp2: RsvpType) => {
    if (!rsvp1.cfmProfile?.profile || !rsvp2.cfmProfile?.profile) {
      return 0;
    }
    return baseCompare(
      mapper(rsvp1.cfmProfile.profile[field]),
      mapper(rsvp2.cfmProfile.profile[field]),
      rsvp1,
      rsvp2,
      !!reversed
    );
  };

export const adminUserSortFn =
  (
    field: keyof MEETUP_RSVPS_admin_meetupRsvps_adminUser,
    reversed?: boolean,
    mapper: MapperType = (val) => val
  ) =>
  (rsvp1: RsvpType, rsvp2: RsvpType) =>
    baseCompare(
      mapper((rsvp1.adminUser && rsvp1.adminUser[field]) || ""),
      mapper((rsvp2.adminUser && rsvp2.adminUser[field]) || ""),
      rsvp1,
      rsvp2,
      !!reversed
    );

export const userSortFn =
  (
    field: keyof MEETUP_RSVPS_admin_meetupRsvps_adminUser_user,
    reversed?: boolean,
    mapper: MapperType = (val) => val
  ) =>
  (rsvp1: RsvpType, rsvp2: RsvpType) =>
    baseCompare(
      mapper((rsvp1.adminUser?.user && rsvp1.adminUser.user[field]) || ""),
      mapper((rsvp2.adminUser?.user && rsvp2.adminUser.user[field]) || ""),
      rsvp1,
      rsvp2,
      !!reversed
    );

export const ssoUserSortFn =
  (
    field: keyof MEETUP_RSVPS_admin_meetupRsvps_ssoUser,
    reversed?: boolean,
    mapper: MapperType = (val) => val
  ) =>
  (rsvp1: RsvpType, rsvp2: RsvpType) =>
    baseCompare(
      mapper(rsvp1.ssoUser[field]),
      mapper(rsvp2.ssoUser[field]),
      rsvp1,
      rsvp2,
      !!reversed
    );

const harmonicEventProspectBaseCompare = (val1: any, val2: any, reversed: boolean) => {
  if (val1 > val2) return reversed ? -1 : 1;
  if (val1 < val2) return reversed ? 1 : -1;

  return 1;
};

export const harmonicEventProspectSortFn =
  (
    field: keyof MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
    reversed?: boolean
  ) =>
  (
    prospect1: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
    prospect2: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
    mapper: MapperType = (val) => val
  ) =>
    harmonicEventProspectBaseCompare(
      mapper(prospect1[field]),
      mapper(prospect2[field]),
      !!reversed
    );

export const rsvpSortFn =
  (field: keyof RsvpType, reversed?: boolean, mapper: MapperType = (val) => val) =>
  (rsvp1: RsvpType, rsvp2: RsvpType) =>
    baseCompare(mapper(rsvp1[field]), mapper(rsvp2[field]), rsvp1, rsvp2, !!reversed);

export const rsvpQuestionSortFn =
  (questionId: string, reversed?: boolean) =>
  (rsvp1: RsvpType, rsvp2: RsvpType, mapper: MapperType = (val) => val) =>
    baseCompare(
      mapper(rsvp1.questions[questionId]),
      mapper(rsvp2.questions[questionId]),
      rsvp1,
      rsvp2,
      !!reversed
    );

export const formatRsvpWithQuestionResponses = (rsvp: RsvpTypeWithoutQuestions) => {
  const questions: { [questionId: string]: any } = {};
  rsvp.questionResponses.forEach((response) => {
    questions[`${response.meetupQuestionId}`] = JSON.parse(response.response).value;
  });

  const formattedResult: RsvpType = { ...rsvp, questions };
  return formattedResult;
};

export const renderBoolCol = (value: boolean | null | undefined) => {
  if (value === true) {
    return "Yes";
  }
  if (value === false) {
    return "No";
  }
  return null;
};

export const formatTime = (timeAsStr: string) =>
  format(new Date(timeAsStr), "h:mm a").replace(":00", "").replace(" ", "").toLowerCase();
