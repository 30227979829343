import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { REVIEW_EMAIL_PUSH } from "./__generated__/REVIEW_EMAIL_PUSH";
import Button, { BasicButton } from "../../../components/statelessForms/Button";
import { loadContinueUrl } from "../../../components/forms/util";
import {
  adminEmailCampaignPath,
  editPushAdminEmailCampaignPath,
} from "../../../__generated__/routes";
import { SEND_EMAIL_PUSH, SEND_EMAIL_PUSHVariables } from "./__generated__/SEND_EMAIL_PUSH";
import LifecycleEmailNotice from "./LifecycleEmailNotice";
import ConfirmationModal from "../../../components/statelessForms/ConfirmationModal";
import ScheduledDate from "./ScheduledDate";
import EmailPushPreview from "./EmailPushPreview";
import HarmonicSavedSearchNotice from "./HarmonicSavedSearchNotice";
import { ALL_FILTERS } from "./EmailPushFiltersForm";
import { EmailRecipientsPreview } from "./EmailPushCustomRecipientsForm";

type Props = {
  campaignId: string;
  pushId: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  button {
    margin-top: 20px;
  }
`;

const ConfirmSection = styled.div`
  width: 100%;
  margin-bottom: 50px;

  h4 {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const CenteredContent = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonRow = styled(CenteredContent)`
  a,
  button {
    margin: 0 10px;
    width: 200px;
    height: 40px;
  }
`;

export default ({ campaignId, pushId }: Props) => {
  const [confirmedEmail, setConfirmedEmail] = useState(false);
  const [confirmedRecipients, setConfirmedRecipients] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { data } = useQuery<REVIEW_EMAIL_PUSH>(
    gql`
      query REVIEW_EMAIL_PUSH($id: ID!, $pushId: ID!) {
        admin {
          emailCampaign(id: $id) {
            id
            name
            slug
            meetupStartsAt
            emailPushes {
              id
              name
              slug
            }
            emailPush(id: $pushId) {
              id
              campaignId
              name
              slug
              subjectPreview
              emailBodyUserPreview
              fromEmail
              replyToEmail
              filters
              numUsersForFilters
              disabled
              lifecycleOnly
              harmonicSavedSearchId
              autoLoginLinks
              sendsAt
              externalEmailRecipients(withoutUserStubOnly: true) {
                id
                firstName
                email
              }
              userStubPreviews {
                id
                internalUrl
                name
                email
              }
            }
          }
        }
      }
    `,
    { variables: { id: campaignId, pushId } }
  );

  const [sendEmailPush] = useMutation<SEND_EMAIL_PUSH, SEND_EMAIL_PUSHVariables>(gql`
    mutation SEND_EMAIL_PUSH($id: ID!) {
      sendEmailPush(id: $id) {
        success
      }
    }
  `);

  const totalRecipients = useMemo(() => {
    if (!data) {
      return 0;
    }
    return (
      data.admin.emailCampaign.emailPush.externalEmailRecipients.length +
      data.admin.emailCampaign.emailPush.numUsersForFilters
    );
  }, [data]);

  if (!data) {
    return <div />;
  }

  const { emailCampaign } = data.admin;
  const { emailPush } = emailCampaign;

  const canSend = !emailPush.lifecycleOnly && !emailPush.disabled && !emailPush.sendsAt;

  const onConfirmedEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedEmail(e.target.checked);
  };

  const onConfirmedRecipientsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedRecipients(e.target.checked);
  };

  const onSendEmails = async () => {
    await sendEmailPush({ variables: { id: emailPush.id } });
    loadContinueUrl(adminEmailCampaignPath(campaignId));
  };

  const confirmEmailSection = (
    <ConfirmSection>
      <EmailPushPreview emailPush={emailPush} />
      {canSend && (
        <CenteredContent>
          <FormControlLabel
            control={<Checkbox checked={confirmedEmail} onChange={onConfirmedEmailChange} />}
            label="Confirm email contents"
          />
        </CenteredContent>
      )}
    </ConfirmSection>
  );

  const formatFilter = (filterKey: string, filterValue: any) => {
    const filter = ALL_FILTERS[filterKey];
    if (!filter) {
      return `${filterKey}: ${filterValue}`;
    }

    return filter.label;
  };

  const confirmRecipientsSection = (
    <ConfirmSection>
      <CenteredContent css={css({ fontSize: 24, marginBottom: 10 })}>
        This email will be sent to <b>{totalRecipients}</b> users.
      </CenteredContent>
      <CenteredContent css={css({ marginTop: 40 })}>
        <h2>Review filters</h2>
      </CenteredContent>
      <div css={css({ width: "100%", display: "flex", justifyContent: "center" })}>
        <ul>
          {Object.entries(JSON.parse(emailPush.filters))
            .map(([key, rawValue]) => [key, formatFilter(key, rawValue)])
            .filter(([key, value]) => value !== null)
            .map(([key, value]) => (
              <li key={key}>{value}</li>
            ))}
        </ul>
      </div>
      <CenteredContent css={css({ marginTop: 40 })}>
        <h2>Review recipients</h2>
      </CenteredContent>
      <CenteredContent css={css({ fontStyle: "italic" })}>
        Here are some of the users that will be included in the email push. Please verify that this
        looks correct!
      </CenteredContent>
      <div css={css({ width: "100%", display: "flex", justifyContent: "center" })}>
        <ul>
          {emailPush.userStubPreviews.map(({ name, email, internalUrl }) => (
            <li key={internalUrl}>
              <a href={internalUrl} target="_blank">
                {name} {`<${email}>`}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {!!emailPush.externalEmailRecipients.length && (
        <>
          <CenteredContent css={css({ fontStyle: "italic" })}>
            Manually-added recipients:
          </CenteredContent>
          <EmailRecipientsPreview recipients={emailPush.externalEmailRecipients} />
        </>
      )}
      {canSend && (
        <CenteredContent>
          <FormControlLabel
            control={
              <Checkbox checked={confirmedRecipients} onChange={onConfirmedRecipientsChange} />
            }
            label="Confirm email recipients"
          />
        </CenteredContent>
      )}
    </ConfirmSection>
  );

  const formattedDateTime = () => {
    const { sendsAt } = emailPush;
    if (!sendsAt) {
      return "";
    }

    const sendsAtDate = new Date(sendsAt);

    const day = sendsAtDate.toLocaleDateString(undefined, {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
    const startTime = sendsAtDate.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });

    return `${day} at ${startTime}`;
  };

  const formattedNotice = (content: React.ReactElement, hideRecipients?: boolean) => (
    <>
      {!hideRecipients && confirmRecipientsSection}
      <CenteredContent>{content}</CenteredContent>
    </>
  );

  const noticeSection = () => {
    if (emailPush.disabled) {
      return formattedNotice(
        <CenteredContent css={css({ padding: 30 })}>
          <h2>This email is disabled.</h2>
          <p>It will not be sent unless you re-enable it.</p>
        </CenteredContent>
      );
    }

    if (emailPush.lifecycleOnly) {
      return formattedNotice(<LifecycleEmailNotice />, true);
    }

    if (emailPush.harmonicSavedSearchId) {
      return formattedNotice(
        <HarmonicSavedSearchNotice harmonicSavedSearchId={emailPush.harmonicSavedSearchId} />,
        true
      );
    }

    if (emailPush.sendsAt) {
      return formattedNotice(
        <CenteredContent css={css({ marginBottom: 15, fontSize: 18 })}>
          <b>
            <i>This push is scheduled for delivery on {formattedDateTime()}.</i>
          </b>
          <ScheduledDate
            sendsAt={emailPush.sendsAt}
            meetupStartsAt={emailCampaign.meetupStartsAt}
          />
        </CenteredContent>
      );
    }

    return confirmRecipientsSection;
  };

  const sendSection = (
    <ButtonRow>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        confirmationText={`Are you sure you want to send this email? It will go out to ${totalRecipients} people right now.`}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={onSendEmails}
      />
      <BasicButton href={adminEmailCampaignPath(campaignId)}>Back to campaign</BasicButton>
      <Button color="yellow" href={editPushAdminEmailCampaignPath(campaignId, pushId)}>
        Edit push
      </Button>
      {canSend && (
        <Button
          color="orange"
          disabled={!confirmedEmail || !confirmedRecipients}
          onClick={() => setConfirmationModalOpen(true)}
        >
          Send emails now
        </Button>
      )}
    </ButtonRow>
  );

  return (
    <Wrapper>
      <h1>Review email push: {emailPush.name}</h1>
      {confirmEmailSection}
      {noticeSection()}
      {sendSection}
    </Wrapper>
  );
};
