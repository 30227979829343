import React, { ChangeEvent, useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FormControlLabel, Radio } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import Button from "../../components/statelessForms/Button";
import CtaButton from "../../components/Button";
import { SHOWCASE_SIGNUP_DATA } from "./__generated__/SHOWCASE_SIGNUP_DATA";
import { CHANGE_SHOWCASE_RSVP } from "./__generated__/CHANGE_SHOWCASE_RSVP";
import LoadingDots from "../../components/statelessForms/LoadingDots";
import uploadToS3 from "../../../../../shared/frontend/shared/editor/tiptap/uploads/upload";
import { UPDATE_SHOWCASE_SLIDE } from "./__generated__/UPDATE_SHOWCASE_SLIDE";
import { mobileStyles } from "../../styles";

export const TitleContent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 5px;
  }
`;

const StatusSection = styled.div`
  padding: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  text-align: center;
  p:first-of-type {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
`;

const ActionContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const SignupForm = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  i {
    margin: 15px 0;
    font-size: 14px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  button {
    margin: 10px;
    flex-grow: 1;
  }
`;

const Subtitle = styled.p`
  margin-top: -10px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
`;

// Maintain 16:9 dimensions
const SlidePreview = styled.img({
  height: 342,
  width: 608,
  objectFit: "cover",
  ...mobileStyles({
    height: 108,
    width: 192,
  }),
});

const BreakoutRoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 3px solid #fb651e;

  div {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  div:first-of-type {
    color: white;
    background-color: #fb651e;
    font-size: 18px;
    padding: 10px;
  }

  div:last-child {
    color: #fb651e;
    background-color: white;
    font-size: 30px;
    padding: 30px;
  }
`;

const ModeratorName = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const ALLOWABLE_IMAGE_TYPES = ["image/jpg", "image/jpeg", "image/png"];

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};

export const formatDateForShowcase = (dateString: string) => {
  const datetime = new Date(dateString);
  const day = datetime.toLocaleDateString(undefined, dateOptions);
  const startTime = datetime.toLocaleTimeString(undefined, timeOptions);

  return `${day} at ${startTime}`;
};

export default () => {
  const [showRsvpForm, setShowRsvpForm] = useState(false);
  const [willingToModerate, setWillingToModerate] = useState<boolean | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data, refetch } = useQuery<SHOWCASE_SIGNUP_DATA>(gql`
    query SHOWCASE_SIGNUP_DATA {
      latestCourse {
        id
        signedUpForShowcase
        showcaseStartsAt
        showcaseSignupsOpen
        slideSubmissionDeadline
        cofounderInCourse
        slidePath
        showcaseGuideUrl
        showcaseSlideDenied
        showcaseIsLive
        showcaseGroup {
          zoomUrl
          breakoutRoom
          moderator
          moderatorName
          slideViewerUrl
        }
      }
    }
  `);

  const [changeShowcaseRsvp] = useMutation<CHANGE_SHOWCASE_RSVP>(gql`
    mutation CHANGE_SHOWCASE_RSVP($attending: Boolean!, $willingToModerate: Boolean) {
      changeShowcaseRsvp(attending: $attending, willingToModerate: $willingToModerate) {
        success
      }
    }
  `);

  const [updateShowcaseSlide] = useMutation<UPDATE_SHOWCASE_SLIDE>(gql`
    mutation UPDATE_SHOWCASE_SLIDE($slidePath: String) {
      updateShowcaseSlide(slidePath: $slidePath) {
        success
      }
    }
  `);

  const rsvpYes = async () => {
    await changeShowcaseRsvp({
      variables: {
        attending: true,
        willingToModerate,
      },
    });
    await refetch();
    setShowRsvpForm(false);
  };

  const rsvpNo = async () => {
    await changeShowcaseRsvp({
      variables: {
        attending: false,
        willingToModerate: null,
      },
    });
    await refetch();
    setShowRsvpForm(false);
  };

  const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || !files.length) {
      return;
    }

    const file = files[0];

    // don't allow people to edit the HTML and upload an illegal file type
    if (!ALLOWABLE_IMAGE_TYPES.includes(file.type)) {
      toast.error("Your slide must be a .png, .jpg, or .jpeg file");
      return;
    }

    // don't allow files over 10 MB
    const mb = Math.round((file.size / 1024 / 1024) * 10) / 10;
    if (mb > 10) {
      toast.error("Your slide cannot be larger than 10 MB");
      return;
    }

    setIsUploading(true);

    const slideUpload = await uploadToS3(file, { upload_type: "forum" });
    await updateShowcaseSlide({ variables: { slidePath: slideUpload.src } });
    await refetch();

    setIsUploading(false);
  };

  const deleteSlide = async () => {
    await updateShowcaseSlide({ variables: { slidePath: null } });
    refetch();
  };

  if (!data?.latestCourse) {
    return <div />;
  }

  const {
    signedUpForShowcase: signedUp,
    showcaseSignupsOpen,
    showcaseIsLive: isLive,
    slidePath,
    showcaseSlideDenied: slideDenied,
  } = data.latestCourse;

  const showcaseGuideUrl = data.latestCourse.showcaseGuideUrl || "";
  const zoomUrl = data.latestCourse.showcaseGroup?.zoomUrl;
  const breakoutRoom = data.latestCourse.showcaseGroup?.breakoutRoom;
  const isModerator = data.latestCourse.showcaseGroup?.moderator;
  const moderatorName = data.latestCourse.showcaseGroup?.moderatorName;
  const slideViewerUrl = data.latestCourse.showcaseGroup?.slideViewerUrl;

  const formattedDate = formatDateForShowcase(data.latestCourse.showcaseStartsAt);
  const slideDeadline = formatDateForShowcase(data.latestCourse.slideSubmissionDeadline);

  const signupForm = (
    <SignupForm>
      <h2>Sign Up for the Showcase</h2>
      <div>
        Great! Before you’re registered, please let us know if you’d be willing to be a moderator.
        As the moderator, you’ll share your screen using Zoom and advance the slide to the next
        company after each 60-second presentation.
      </div>
      <i>
        Please only say yes if you'll be able to join from a laptop, you have a reliable internet
        connection, and you’ve used the screen sharing feature in Zoom before and are familiar with
        it.
      </i>
      <div>
        <FormControlLabel
          control={<Radio checked={!!willingToModerate} />}
          label="I can moderate"
          onChange={() => setWillingToModerate(true)}
        />
        <FormControlLabel
          control={<Radio checked={willingToModerate === false} />}
          label="I don't want to moderate"
          onChange={() => setWillingToModerate(false)}
        />
      </div>
      <ButtonRow>
        <Button disabled={willingToModerate === null} onClick={rsvpYes} color="orange">
          Submit
        </Button>
        <Button onClick={() => setShowRsvpForm(false)}>Cancel</Button>
      </ButtonRow>
    </SignupForm>
  );

  const uploadButton = () => (
    <ButtonRow>
      <Button
        color={data.latestCourse?.slidePath ? "gray" : "orange"}
        onClick={() => inputRef.current?.click()}
      >
        {data.latestCourse?.slidePath ? "Replace this slide" : "Upload a slide"}
      </Button>
      {data.latestCourse?.slidePath && showcaseSignupsOpen && (
        <Button color="gray" onClick={deleteSlide}>
          Delete this slide
        </Button>
      )}
      <input
        css={css({ display: "none" })}
        type="file"
        accept={ALLOWABLE_IMAGE_TYPES.join(", ")}
        ref={inputRef}
        onChange={onUpload}
      />
    </ButtonRow>
  );

  const registrationClosedSection = (
    <StatusSection>
      <p>Registration for the showcase has closed.</p>
      {signedUp ? (
        <div>You didn't upload a slide, so you will not be able to participate.</div>
      ) : (
        <div>You didn't sign up, so you will not be able to participate.</div>
      )}
    </StatusSection>
  );

  const uploadingSlideSection = (
    <>
      <p>Uploading your slide...</p>
      <div css={css({ width: "100%", display: "flex", justifyContent: "center" })}>
        <LoadingDots />
      </div>
    </>
  );

  const signedUpSection = () => {
    if (!showcaseSignupsOpen && !slidePath) {
      return registrationClosedSection;
    }

    if (isUploading) {
      return <StatusSection>{uploadingSlideSection}</StatusSection>;
    }

    let headline = (
      <div>
        {slidePath
          ? "You are confirmed to present at the Startup School Showcase! 🎉"
          : "You've signed up for the Startup School Showcase!"}
      </div>
    );

    if (slideDenied) {
      headline = (
        <div>
          Your slide does not meet the requirements of the Showcase. Please{" "}
          <a href={showcaseGuideUrl}>review the Showcase guide</a>
          {` and upload a new slide by ${slideDeadline}.`}
        </div>
      );
    }

    return (
      <StatusSection>
        <p>{headline}</p>
        {!slidePath ? (
          <>
            <Subtitle>
              Remember, you will <b>not</b> be able to participate unless you submit a slide.
            </Subtitle>
            <p>
              <b>{`Your slide is due by ${slideDeadline}.`}</b>
            </p>
            <p>
              You can come back to this page to upload your slide anytime before the due date. In
              the meantime, read <a href={showcaseGuideUrl}>this guide</a> on how to prepare your
              slide and your presentation.
            </p>
          </>
        ) : (
          <p>
            To prepare for your 60-second presentation, check out{" "}
            <a href={showcaseGuideUrl}>this guide</a>.
          </p>
        )}
        {slidePath && <SlidePreview src={slidePath} />}
        {uploadButton()}
        <Subtitle css={css({ marginTop: 0 })}>
          Your slide must be a .png, .jpg, or .jpeg file, and must be under 10 MB.
        </Subtitle>
      </StatusSection>
    );
  };

  const titleSection = (
    <TitleContent>
      <h2>Startup School Showcase</h2>
      <i>{formattedDate}</i>
    </TitleContent>
  );

  const actionButtons = !showRsvpForm && (
    <ActionContainer>
      {!signedUp && (
        <span css={css({ marginRight: 10 })}>
          <Button color="orange" disabled={false} onClick={() => setShowRsvpForm(true)}>
            Sign up to present
          </Button>
        </span>
      )}
      {((showcaseSignupsOpen && signedUp !== false) || slidePath) && (
        <Button onClick={rsvpNo}>I don't want to participate</Button>
      )}
    </ActionContainer>
  );

  const registrationSection = (
    <PaneItem>
      {titleSection}
      <p>
        To conclude the course, you'll have the opportunity to present your progress to your peers
        during the Startup School Showcase!
      </p>
      <p>
        Here's how the showcase will work. You'll prepare one slide and a 60-second presentation
        based on <a href={showcaseGuideUrl}>these instructions</a>, and then present to a group of
        fellow Startup School founders on Zoom during the event.
      </p>
      <br />
      {signedUp && (
        <>
          {signedUpSection()}
          {actionButtons}
        </>
      )}
      {signedUp === null && showcaseSignupsOpen && (
        <StatusSection>
          {showRsvpForm ? (
            signupForm
          ) : (
            <>
              <p>Would you like to participate in the Startup School Showcase?</p>
              <i>{`The showcase will take place on ${formattedDate}.`}</i>
            </>
          )}
          {actionButtons}
        </StatusSection>
      )}
      {signedUp === false && showcaseSignupsOpen && (
        <>
          <StatusSection>
            {showRsvpForm ? signupForm : <p>You have opted out of the showcase.</p>}
          </StatusSection>
          {actionButtons}
        </>
      )}
      {!signedUp && !showcaseSignupsOpen && registrationClosedSection}
    </PaneItem>
  );

  const moderatorInstructions = (
    <div>
      <div css={css({ width: "100%", textAlign: "center" })}>
        <b>Moderator duties:</b>
      </div>
      <ul css={css({ marginTop: 5 })}>
        <li>
          Open <a href={slideViewerUrl}>this slide viewer</a> and share your screen with your Zoom
          breakout room.
        </li>
        <li>
          Once your group has gathered in your breakout room and the first presenter is ready to go,
          press the "start" button.
        </li>
        <li>Advance to the next slide after every presentation.</li>
        <li>
          Each person gets 1 minute to present. The timer will turn red after the minute is up.
          After that please give them a verbal warning to wrap up.
        </li>
      </ul>
    </div>
  );

  const attendanceInfoSection = (
    <>
      <PaneItem>
        {titleSection}
        {isLive && (
          <CenteredContainer>
            <CtaButton href={zoomUrl || ""} type="cta" text="Join the Showcase now!" />
          </CenteredContainer>
        )}
        <p>
          When the Showcase starts, you'll be able to join from the Zoom link below. After a quick
          welcome, we'll open up breakout rooms, and you should join your assigned breakout room.
        </p>
        <p>
          Once your group has gathered in your assigned breakout room, your moderator will share
          their screen and open a slide viewer. When your slide comes up, unmute yourself and give
          your 60-second presentation. Please mute yourself while you are not presenting.
        </p>
      </PaneItem>
      {signedUp ? (
        <>
          <PaneItem>
            <CenteredContainer>
              <BreakoutRoomContainer>
                <div>Your breakout room</div>
                <div>{breakoutRoom}</div>
              </BreakoutRoomContainer>
            </CenteredContainer>
            <CenteredContainer>
              Zoom link:{" "}
              <a href={zoomUrl || ""} css={css({ textAlign: "center" })}>
                {zoomUrl}
              </a>
            </CenteredContainer>
            <CenteredContainer>
              {isModerator ? (
                <ModeratorName css={css({ marginBottom: -10 })}>
                  <b>You are the moderator</b>
                </ModeratorName>
              ) : (
                <div css={css({ textAlign: "center" })}>
                  <ModeratorName>
                    Your moderator is <b>{moderatorName}</b>
                  </ModeratorName>
                  <div>
                    <i>
                      If your moderator doesn't show up, choose somebody else from your group to
                      moderate who has a strong internet connection and who's joining from a laptop
                      or desktop computer
                    </i>
                  </div>
                </div>
              )}
            </CenteredContainer>
          </PaneItem>
          <PaneItem>
            {moderatorInstructions}
            <div css={css({ textAlign: "center" })}>
              <Button color={isModerator ? "orange" : "yellow"} href={slideViewerUrl}>
                Open the Slide Viewer
              </Button>
            </div>
          </PaneItem>
          <PaneItem css={css({ textAlign: "center" })}>
            {isUploading ? (
              <div>{uploadingSlideSection}</div>
            ) : (
              <>
                <p>
                  Make sure you're happy with your slide! This is exactly what it will look like in
                  the slide viewer.
                </p>
                <SlidePreview src={slidePath || ""} />
                {uploadButton()}
                <Subtitle css={css({ marginTop: 0 })}>
                  Your slide must be a .png, .jpg, or .jpeg file, and must be under 10 MB.
                </Subtitle>
              </>
            )}
          </PaneItem>
          <PaneItem>
            <CenteredContainer>
              <div css={css({ marginBottom: 20 })}>
                Can't make it to the Showcase anymore? Please let us know.
              </div>
              <Button
                onClick={async () => {
                  if (confirm("Are you sure you aren't going to attend the Showcase?")) {
                    rsvpNo();
                  }
                }}
              >
                I'm not able to attend the Showcase anymore
              </Button>
            </CenteredContainer>
          </PaneItem>
        </>
      ) : (
        <PaneItem>
          <CenteredContainer>
            <h2>You're not attending the Showcase.</h2>
            <div css={css({ marginBottom: 20, textAlign: "center" })}>
              If you change your mind and want to attend, you still can! Make sure to let us know,
              otherwise your slide won't be included in group's slide deck.
            </div>
            <Button onClick={rsvpYes}>I want to attend the Showcase</Button>
          </CenteredContainer>
        </PaneItem>
      )}
    </>
  );

  return (
    <Page title="Startup School Showcase">
      <Container css={css({ maxWidth: 900, fontSize: 17 })}>
        <Pane css={css({ li: { marginBottom: 7 } })}>
          {zoomUrl && breakoutRoom ? attendanceInfoSection : registrationSection}
        </Pane>
      </Container>
    </Page>
  );
};
