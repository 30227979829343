import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { toast } from "react-toastify";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import Button from "../../components/statelessForms/Button";
import { loadDefaultHome } from "../../components/forms/util";
import { CONFIRM_COURSE_REGISTRATION } from "./__generated__/CONFIRM_COURSE_REGISTRATION";
import { DISMISS_COURSE_REGISTRATION } from "./__generated__/DISMISS_COURSE_REGISTRATION";
import { COURSE_REGISTRATION_DATA } from "./__generated__/COURSE_REGISTRATION_DATA";
import "react-toastify/dist/ReactToastify.css";

export default () => {
  const { data } = useQuery<COURSE_REGISTRATION_DATA>(gql`
    query COURSE_REGISTRATION_DATA {
      latestCourse {
        enrolled
        dismissed
      }

      cofounderMatching {
        profile {
          slug
        }
      }
    }
  `);

  const [confirmCourseRegistration] = useMutation<CONFIRM_COURSE_REGISTRATION>(gql`
    mutation CONFIRM_COURSE_REGISTRATION {
      confirmCourseRegistration {
        success
      }
    }
  `);

  const [dismissCourseRegistration] = useMutation<DISMISS_COURSE_REGISTRATION>(gql`
    mutation DISMISS_COURSE_REGISTRATION {
      dismissCourseRegistration {
        success
      }
    }
  `);

  const confirmRegistration = async () => {
    await confirmCourseRegistration();
    toast.success("Your registration is confirmed! Redirecting to dashboard...");
    setTimeout(() => {
      loadDefaultHome();
    }, 3000);
  };

  const dismissRegistration = async () => {
    await dismissCourseRegistration();
    loadDefaultHome();
  };

  const enrolled = data?.latestCourse?.enrolled;
  const hasCofounderMatchingProfile = !!data?.cofounderMatching?.profile?.slug;

  return (
    <Page title="Startup School live course registration">
      <Container css={css({ maxWidth: 900, fontSize: 17 })}>
        <Pane css={css({ li: { marginBottom: 7 } })}>
          <PaneItem>
            <p>We're super excited to hold a live Startup School course!</p>
            <p>
              This <b>7-week course</b> will run from <b>Monday, June 27th</b> to{" "}
              <b>Wednesday, August 10th</b>.
            </p>
            <p>
              We will have live talks over Zoom from YC partners and successful alumni{" "}
              <b>every Monday and Wednesday at 9-10am Pacific Time</b>, followed by optional founder
              networking.
            </p>
            <br />
            {enrolled ? (
              <>
                <p>
                  <b>You are currently enrolled in the course.</b>
                </p>
                <p>
                  If you can no longer make the weekly live talks, you can switch to the self-paced
                  course at any time before Monday, June 27th.
                </p>
              </>
            ) : (
              <>
                <p>
                  <b>
                    Please register for the course only if you are able to attend these live talks
                    each week.
                  </b>
                </p>
                <p>
                  If you prefer not to attend the live talks, you can do our self-paced curriculum,
                  which covers the same core topics but with pre-recorded videos. You will still
                  have access to other Startup School features, including the forum, weekly updates
                  and co-founder matching.
                </p>
                <p>
                  If you're not sure right now, you can change your mind up until the course
                  registration closes on Sunday, June 26th.
                </p>
              </>
            )}
            <div css={css({ marginTop: 40, textAlign: "center" })}>
              {!enrolled && (
                <span css={css({ marginRight: 10 })}>
                  <Button color="orange" disabled={false} onClick={confirmRegistration}>
                    Register for the live course
                  </Button>
                </span>
              )}
              <Button onClick={dismissRegistration}>
                {enrolled ? "Switch to the self-paced course" : "I will do the self-paced course"}
              </Button>
              {hasCofounderMatchingProfile && (
                <div css={css({ marginTop: 10 })}>
                  <Button onClick={dismissRegistration}>I'm not interested in either course</Button>
                </div>
              )}
            </div>
          </PaneItem>
        </Pane>
      </Container>
    </Page>
  );
};
