import React from "react";
import { useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { Dropdown, Input } from "./forms";
import SpacedGrid from "./statelessForms/SpacedGrid";

const prompts = {
  "a friend": "Which friend/community referred you to us?",
  "Google/search engine": "What were you searching for?",
  "online community": "Which community?",
  "social media": "Which platform?",
  "I've previously applied to YC": null,
  "Hacker News": null,
  "local startup community": "Which community?",
  "something else": "Where did you hear about us?",
};

export default () => {
  const { watch } = useFormContext();
  const howHeard: keyof typeof prompts = watch("howHeard");

  const freeTextPrompt = prompts[howHeard];

  return (
    <SpacedGrid container spacing={1} justifyContent="space-between">
      <Grid item xs={freeTextPrompt ? 6 : 12}>
        <Dropdown
          fieldName="howHeard"
          label="How did you first hear about Startup School?"
          options={Object.keys(prompts).map((p) => [p, p])}
        />
      </Grid>
      {freeTextPrompt && (
        <Grid item xs={6}>
          <Input label={freeTextPrompt} fieldName="howHeardFreeText" />
        </Grid>
      )}
    </SpacedGrid>
  );
};
