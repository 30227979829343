import { gql, useQuery } from "@apollo/client";
import React from "react";
import { NEW_EMAIL_PUSH } from "./__generated__/NEW_EMAIL_PUSH";
import EmailPushForm from "./EmailPushForm";

type Props = {
  campaignId: string;
};

export default ({ campaignId }: Props) => {
  const { data } = useQuery<NEW_EMAIL_PUSH>(
    gql`
      query NEW_EMAIL_PUSH($id: ID!) {
        admin {
          emailCampaign(id: $id) {
            id
            name
            slug
            meetupId
            meetupTitle
            meetupStartsAt
            fromEmailOptions
            replyToEmailOptions
            templateValues
            emailPushes {
              id
              name
              slug
              lastSentAt
            }
          }
        }
      }
    `,
    { variables: { id: campaignId } }
  );

  if (!data) {
    return <div />;
  }

  const { emailCampaign } = data.admin;

  return (
    <EmailPushForm
      campaignId={campaignId}
      emailCampaign={emailCampaign}
      title={`New email push for campaign ${emailCampaign.name}`}
    />
  );
};
