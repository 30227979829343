import React from "react";
import { formatDistance } from "date-fns";
import { css } from "@emotion/core";
import { isArray } from "lodash";
import { Comment } from "@material-ui/icons";
import { MEETUP_RSVPS_admin_adminMeetup } from "../__generated__/MEETUP_RSVPS";
import { MEETUP_NAMETAGS_admin_adminMeetup } from "../__generated__/MEETUP_NAMETAGS";
import { adminUserPath } from "../../../__generated__/routes";
import {
  adminUserSortFn,
  harmonicEventProspectSortFn,
  profileSortFn,
  renderBoolCol,
  rsvpQuestionSortFn,
  rsvpSortFn,
  RsvpType,
  ssoUserSortFn,
  userSortFn,
} from "./meetupUtils";
import YCAppHistoryWithCommentTooltip from "./YCAppHistoryWithCommentTooltip";
import { linkified } from "../../../components/forms/util";
import { MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects } from "./__generated__/MEETUP_SAVED_SEARCH";
import AdminRSVPVote from "./AdminRSVPVote";

export type SortType = {
  key: string;
  sortFn: (rsvp1: RsvpType, rsvp2: RsvpType, valueMapper?: (val: any) => any) => number;
};

export type HarmonicEventProspectSortType = {
  key: string;
  sortFn: (
    rsvp1: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
    rsvp2: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects,
    valueMapper?: (val: any) => any
  ) => number;
};

export type MeetupColumnType = {
  title: string;
  render: (rsvp: RsvpType) => React.ReactNode;
  renderAsText?: (rsvp: RsvpType, forNametags?: boolean) => string | null | undefined;
  sort?: SortType;
};

export type HarmonicEventProspectColumnType = {
  title: string;
  render: (
    prospect: MEETUP_SAVED_SEARCH_admin_adminMeetup_harmonicProspectSearches_harmonicProspects
  ) => React.ReactNode;
  sort?: HarmonicEventProspectSortType;
};

type MeetupType = MEETUP_RSVPS_admin_adminMeetup | MEETUP_NAMETAGS_admin_adminMeetup;

const formatListWithHeaders = (list: string[][]) =>
  list.map(([question, answer], idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={`${question}-${answer}-${idx}`}>
      <p key={question} css={css({ marginBottom: 0 })}>
        <b>{question}: </b>
        <span>{answer}</span>
      </p>
    </React.Fragment>
  ));

const formatListWithHeadersAsText = (list: string[][]) =>
  list.map(([question, answer]) => `${question}: ${answer}`).join("\n");

const RATING_TO_SCORE: { [rating: string]: number } = {
  Y: 1,
  y: 2,
  m: 3,
  n: 4,
  N: 5,
  comment: 6,
  skip: 7,
  "": 8,
};

const ratingToScoreMapper = (rating: string | undefined) => RATING_TO_SCORE[rating || ""];

const voteListToScoreMapper = (votes: { val: string | null }[]) => {
  const voteScores = votes.map((vote) => ratingToScoreMapper(vote.val || ""));
  return Math.min(...voteScores, 10);
};

const batchFormatter = (batch: string | null | undefined) =>
  batch ? `${batch[0]}${batch.slice(-2)}`.toUpperCase() : undefined;

const batchToYearMapper = (maybeBatch: string | string[] | undefined) => {
  const batch = isArray(maybeBatch) ? maybeBatch[0] : maybeBatch;

  if (!batch) {
    return 3000;
  }

  const year = Number.parseInt(batch.slice(1), 10);
  const seasonLetter = batch[0].toLowerCase();
  let season = seasonLetter === "w" ? 0 : 0.5;
  if (seasonLetter === "f") {
    season = 0.75;
  }
  return year + season;
};

export const PinnedNote = ({ url, category }: { url: string; category: string }) => {
  let color = "orange";
  if (category === "highlight") {
    color = "green";
  } else if (category === "critical") {
    color = "red";
  }
  const shouldHide = !!new URLSearchParams(window.location.search).get("check_in");
  return (
    <a href={url} target="_blank" css={css({ display: shouldHide ? "none" : "inline-block" })}>
      <span css={css({ color, marginLeft: 3 })}>
        <Comment css={css({ maxHeight: 20, marginBottom: -5 })} />
      </span>
    </a>
  );
};

const lastIfAny = (arr: any[] | null | undefined) => (arr?.length ? arr[arr.length - 1] : null);

const MEETUP_COLUMNS: { [key: string]: MeetupColumnType } = {
  STATUS: {
    title: "Status",
    render: (rsvp) => rsvp.status,
    sort: {
      key: "status",
      sortFn: rsvpSortFn("status"),
    },
  },
  TIME_ON_WAITLIST: {
    title: "RSVPed",
    render: (rsvp) => formatDistance(new Date(rsvp.joinedWaitlistAt), new Date()),
    sort: {
      key: "timeOnWaitlist",
      sortFn: rsvpSortFn("joinedWaitlistAt", true),
    },
  },
  RATING: {
    title: "Rating",
    render: (rsvp) => rsvp.adminUser?.adminRating,
    sort: {
      key: "rating",
      sortFn: adminUserSortFn("adminRating", false, ratingToScoreMapper),
    },
  },
  NAME: {
    title: "Name",
    render: (rsvp) => rsvp.ssoUser.name,
    sort: {
      key: "name",
      sortFn: ssoUserSortFn("name"),
    },
  },
  EMAIL: {
    title: "Email",
    render: (rsvp) => rsvp.ssoUser.email,
    sort: {
      key: "email",
      sortFn: ssoUserSortFn("email"),
    },
  },
  WOMAN: {
    title: "Woman",
    render: (rsvp) => (rsvp.adminUser?.user?.isWoman ? "Woman" : ""),
    sort: {
      key: "woman",
      sortFn: userSortFn("isWoman", true),
    },
  },
  TECHNICAL: {
    title: "Technical",
    render: (rsvp) =>
      !!rsvp.adminUser?.user && (rsvp.adminUser?.user.isTechnical ? "tech" : "non-tech"),
    sort: {
      key: "technical",
      sortFn: userSortFn("isTechnical", true),
    },
  },
  HAS_IDEA: {
    title: "Has idea",
    render: (rsvp) => `idea: ${rsvp.cfmProfile?.profile.hasIdea}`,
    sort: {
      key: "hasIdea",
      sortFn: profileSortFn("hasIdea"),
    },
  },
  PERSONAL_ONE_LINER: {
    title: "Personal one liner",
    render: (rsvp) => rsvp.personalOneLiner,
  },
  IDEA_ONE_LINER: {
    title: "Idea one liner",
    render: (rsvp) => rsvp.ideaOneLiner,
  },
  AGE: {
    title: "Age",
    render: (rsvp) => rsvp.adminUser?.user?.age || rsvp.ssoUser.estimatedAge,
    sort: {
      key: "age",
      sortFn: userSortFn("age"),
    },
  },
  TIME_ON_CFM: {
    title: "Time on CFM",
    render: (rsvp) => {
      const profile = rsvp.cfmProfile?.profile;
      return profile ? formatDistance(new Date(profile.createdAt), new Date()) : null;
    },
    sort: {
      key: "timeOnCfm",
      sortFn: profileSortFn("createdAt", true),
    },
  },
  OTHER_MEETUPS: {
    title: "Other meetups",
    render: (rsvp) => rsvp.otherRsvpStatuses?.join("\n"),
  },
  YC_APP: {
    title: "YC app?",
    render: (rsvp) =>
      rsvp.ycAppHistory?.map((app) => <YCAppHistoryWithCommentTooltip key={app.url} app={app} />),
    renderAsText: (rsvp) =>
      rsvp.ycAppHistory
        ?.map(({ batch, url, result }) => `${batch} - ${result} (${url})}`)
        .join("\n"),
    sort: {
      key: "ycApp",
      sortFn: rsvpSortFn("ycAppHistory"),
    },
  },
  NO_SHOWS: {
    title: "# no shows",
    render: (rsvp) => rsvp.numPriorNoShows,
    sort: {
      key: "noShows",
      sortFn: rsvpSortFn("numPriorNoShows"),
    },
  },
  CITY: {
    title: "City",
    render: (rsvp) => rsvp.adminUser?.user.location,
    sort: {
      key: "city",
      sortFn: userSortFn("location"),
    },
  },
  NUM_UPDATES: {
    title: "# updates",
    render: (rsvp) =>
      !!rsvp.adminUser &&
      (rsvp.adminUser.user.track === "aspiring_founder"
        ? "asf"
        : rsvp.numWeeklyUpdatesDuringCourse),
  },
  LINKEDIN: {
    title: "LinkedIn",
    render: (rsvp) =>
      rsvp.ssoUser.linkedin && (
        <a href={decodeURIComponent(rsvp.ssoUser.linkedin)} target="_blank">
          LinkedIn
        </a>
      ),
    renderAsText: (rsvp) => decodeURIComponent(rsvp.ssoUser.linkedin || ""),
  },
  EMPLOYMENT: {
    title: "Employment",
    render: (rsvp) => rsvp.ssoUser.enrichedEmployment,
  },
  EDUCATION: {
    title: "Education",
    render: (rsvp) => rsvp.ssoUser.enrichedEducation,
  },
  OTHER_QUESTIONS: {
    title: "Other questions",
    render: (rsvp) => formatListWithHeaders(rsvp.customQuestions),
    renderAsText: (rsvp) => formatListWithHeadersAsText(rsvp.customQuestions),
  },
  TRACK: {
    title: "Track",
    render: (rsvp) =>
      !!rsvp.adminUser &&
      (rsvp.adminUser.user.track === "aspiring_founder" ? "aspiring" : "active"),
  },
  REFERRER: {
    title: "Referrer",
    render: (rsvp) => {
      const { referrer, inviteToken } = rsvp;
      if (referrer) {
        if (referrer.adminUser) {
          let text = referrer.name;
          if (referrer.adminUser.adminRating) {
            text = `${text} (${referrer.adminUser.adminRating})`;
          }
          return (
            <a target="_blank" href={window.location.origin + adminUserPath(referrer.adminUser.id)}>
              {text}
            </a>
          );
        }
        return referrer.name;
      }
      return inviteToken;
    },
    renderAsText: (rsvp) => {
      const { referrer, inviteToken } = rsvp;
      if (referrer) {
        if (referrer.adminUser) {
          let text = referrer.name;
          if (referrer.adminUser.adminRating) {
            text = `${text} (${referrer.adminUser.adminRating})`;
          }
          return `${text} - ${window.location.origin + adminUserPath(referrer.adminUser.id)}`;
        }
        return referrer.name;
      }
      return inviteToken;
    },
    sort: {
      key: "referrer",
      sortFn: rsvpSortFn("referrer", true),
    },
  },
  AUTOMATED_RATING: {
    title: "Automated rating",
    render: (rsvp) => rsvp.ssoUser.automatedRating,
    sort: {
      key: "automatedRating",
      sortFn: ssoUserSortFn("automatedRating", false, ratingToScoreMapper),
    },
  },
  YC_ALUM_COMPANY_NAME: {
    title: "YC company",
    render: (rsvp) => {
      const urls = rsvp.ssoUser.ycAlumCompanyInternalUrls || [];
      const pinnedNotesByUrl: { [url: string]: string } = {};
      rsvp.ssoUser.ycAlumCompanyPinnedNotes?.forEach(({ url, category }) => {
        pinnedNotesByUrl[url] = category;
      });

      return rsvp.ssoUser.ycAlumCompanyNames?.map((name, idx) => {
        const url = urls[idx];
        const noteCategory = pinnedNotesByUrl[url];
        return (
          <p key={url} css={css({ display: "flex", alignItems: "center" })}>
            <a href={url} target="_blank">
              {name}
            </a>
            {noteCategory && <PinnedNote url={url} category={noteCategory} />}
          </p>
        );
      });
    },
    renderAsText: (rsvp, forNametags = false) => {
      const urls = rsvp.ssoUser.ycAlumCompanyInternalUrls || [];
      if (forNametags) {
        return lastIfAny(rsvp.ssoUser.ycAlumCompanyNames);
      }
      return rsvp.ssoUser.ycAlumCompanyNames
        ?.map((name, idx) => `${name} (${urls[idx]})`)
        .join("\n");
    },
    sort: {
      key: "ycAlumCompanyNames",
      sortFn: ssoUserSortFn("ycAlumCompanyNames"),
    },
  },
  YC_ALUM_ACTIVE: {
    title: "Active at YC company?",
    render: (rsvp) => renderBoolCol(rsvp.ssoUser.ycAlumActive),
    sort: {
      key: "ycAlumActive",
      sortFn: ssoUserSortFn("ycAlumActive", true),
    },
  },
  YC_ALUM_COMPANY_FATE: {
    title: "YC company fate",
    render: (rsvp) => rsvp.ssoUser.ycAlumCompanyFates?.join(", "),
    sort: {
      key: "ycAlumCompanyFates",
      sortFn: ssoUserSortFn("ycAlumCompanyFates"),
    },
  },
  YC_ALUM_COMPANY_SCORE: {
    title: "YC company score",
    render: (rsvp) => rsvp.ssoUser.ycAlumCompanyScores?.join(", "),
    sort: {
      key: "ycAlumCompanyScores",
      sortFn: ssoUserSortFn("ycAlumCompanyScores", true),
    },
  },
  YC_ALUM_COMPANY_RAISED: {
    title: "YC company raised",
    render: (rsvp) =>
      rsvp.ssoUser.ycAlumCompanyRaised?.map((num) => `$${num.toLocaleString()}`).join(", "),
    sort: {
      key: "ycAlumCompanyRaised",
      sortFn: ssoUserSortFn("ycAlumCompanyRaised", true, (val) => (val ? val[0] || 0 : 0)),
    },
  },
  YC_ALUM_COMPANY_BATCH: {
    title: "YC company batch",
    render: (rsvp) => rsvp.ssoUser.ycAlumCompanyBatches?.join(", "),
    renderAsText: (rsvp, forNametags = false) =>
      forNametags
        ? batchFormatter(lastIfAny(rsvp.ssoUser.ycAlumCompanyBatches))
        : rsvp.ssoUser.ycAlumCompanyBatches?.map(batchFormatter).join(", "),
    sort: {
      key: "ycAlumCompanyBatches",
      sortFn: ssoUserSortFn("ycAlumCompanyBatches", true, batchToYearMapper),
    },
  },
  YC_ALUM_PRIMARY_PARTNERS: {
    title: "YC PPs",
    render: (rsvp) => rsvp.ssoUser.ycAlumPrimaryPartners?.join(", "),
    sort: {
      key: "ycAlumPrimaryPartners",
      sortFn: ssoUserSortFn("ycAlumPrimaryPartners"),
    },
  },
  YC_ALUM_GROUP_PARTNERS: {
    title: "YC GPs",
    render: (rsvp) => rsvp.ssoUser.ycAlumGroupPartners?.join(", "),
    sort: {
      key: "ycAlumGroupPartners",
      sortFn: ssoUserSortFn("ycAlumGroupPartners"),
    },
  },
  YC_ALUM_LATEST_COMPANY_URL: {
    title: "YC company URL",
    render: (rsvp) => lastIfAny(rsvp.ssoUser.ycAlumCompanyInternalUrls),
  },
  HNID: {
    title: "HNID",
    render: (rsvp) => rsvp.ssoUser.hnid,
  },
  INVESTMENT_FIRMS: {
    title: "Investment firms",
    render: (rsvp) => {
      const urls = rsvp.ssoUser.investorFirmInternalUrls || [];
      return rsvp.ssoUser.investorFirmNames?.map((name, idx) => {
        const url = urls[idx];
        return (
          <p key={url}>
            <a href={url} target="_blank">
              {name}
            </a>
          </p>
        );
      });
    },
    renderAsText: (rsvp) => rsvp.ssoUser.investorFirmNames?.join(", "),
    sort: {
      key: "investorFirmNames",
      sortFn: ssoUserSortFn("investorFirmNames"),
    },
  },
  INVESTOR_TITLES: {
    title: "Investor titles",
    render: (rsvp) => rsvp.ssoUser.investorTitles?.join(", "),
    sort: {
      key: "investorTitles",
      sortFn: ssoUserSortFn("investorTitles"),
    },
  },
  VOTES: {
    title: "Votes",
    render: (rsvp) => (
      <div>
        {rsvp.adminVotes.map((vote) => (
          <AdminRSVPVote vote={vote} key={vote.id} />
        ))}
      </div>
    ),
    renderAsText: (rsvp) =>
      rsvp.adminVotes
        .map((vote) =>
          [vote.val || vote.voteType, vote.voterName, vote.comments].filter(Boolean).join(" - ")
        )
        .join("\n"),
    sort: {
      key: "votes",
      sortFn: rsvpSortFn("adminVotes", false, voteListToScoreMapper),
    },
  },
  MONOID_CATEGORY: {
    title: "Monoid category",
    render: (rsvp) => rsvp.ssoUser.monoidCategory,
    sort: {
      key: "rating",
      sortFn: ssoUserSortFn("monoidCategory", false, ratingToScoreMapper),
    },
  },
  RSVP_PAGE: {
    title: "View RSVP",
    render: (rsvp) => (
      <a href={rsvp.internalUrl} target="_blank">
        View RSVP
      </a>
    ),
    renderAsText: (rsvp) => rsvp.internalUrl,
  },
};

export const HARMONIC_EVENT_PROSPECT_COLUMNS: { [key: string]: HarmonicEventProspectColumnType } = {
  ALL_EMAILS: {
    title: "All Emails",
    render: (harmonicProspect) => harmonicProspect.emailsFound?.join(", "),
    sort: {
      key: "emailsFound",
      sortFn: harmonicEventProspectSortFn("emailsFound"),
    },
  },
  HARMONIC_AUTOMATED_RATING: {
    title: "Automated Rating",
    render: (harmonicProspect) => harmonicProspect.automatedRating,
    sort: {
      key: "automatedRating",
      sortFn: harmonicEventProspectSortFn("automatedRating"),
    },
  },
  FULL_NAME: {
    title: "Full Name",
    render: (harmonicProspect) => harmonicProspect.fullName,
    sort: {
      key: "fullName",
      sortFn: harmonicEventProspectSortFn("fullName"),
    },
  },
  LINKEDIN_HEADLINE: {
    title: "LI Headline",
    render: (harmonicProspect) => harmonicProspect.linkedinHeadline,
    sort: {
      key: "linkedinHeadline",
      sortFn: harmonicEventProspectSortFn("linkedinHeadline"),
    },
  },
  YC_APPS: {
    title: "YC Apps",
    render: (harmonicProspect) => linkified(harmonicProspect.ycApps),
    sort: {
      key: "ycApps",
      sortFn: harmonicEventProspectSortFn("ycApps"),
    },
  },
  HARMONIC_LINKEDIN: {
    title: "LinkedIn",
    render: (harmonicProspect) =>
      harmonicProspect.linkedin && (
        <a href={harmonicProspect.linkedin} target="_blank">
          LinkedIn
        </a>
      ),
    sort: {
      key: "linkedin",
      sortFn: harmonicEventProspectSortFn("linkedin"),
    },
  },
  INVITE_EMAIL_SENT: {
    title: "Invite Email Sent",
    render: (harmonicProspect) => (harmonicProspect.inviteEmailSent ? "Email sent" : "Not sent"),
    sort: {
      key: "inviteEmailSent",
      sortFn: harmonicEventProspectSortFn("inviteEmailSent"),
    },
  },
  TOP_COMPANY_EXPERIENCES: {
    title: "Top Company Experiences",
    render: (harmonicProspect) => harmonicProspect.topCompanyExperiences,
    sort: {
      key: "topCompanyExperiences",
      sortFn: harmonicEventProspectSortFn("topCompanyExperiences"),
    },
  },
  TOP_SCHOOL_EDUCATIONS: {
    title: "Top School Educations",
    render: (harmonicProspect) => harmonicProspect.topSchoolEducations,
    sort: {
      key: "topSchoolEducations",
      sortFn: harmonicEventProspectSortFn("topSchoolEducations"),
    },
  },
  HARMONIC_AGE: {
    title: "Age",
    render: (harmonicProspect) => harmonicProspect.age,
    sort: {
      key: "age",
      sortFn: harmonicEventProspectSortFn("age"),
    },
  },
  LAST_EMAILED_AT: {
    title: "Last emailed",
    render: (harmonicProspect) => {
      const val = harmonicProspect.lastEmailedAt;
      if (!val) {
        return "";
      }
      return formatDistance(new Date(val), new Date(), { addSuffix: true });
    },
    sort: {
      key: "lastEmailedAt",
      sortFn: harmonicEventProspectSortFn("lastEmailedAt"),
    },
  },
};

export default MEETUP_COLUMNS;

const {
  ALL_EMAILS,
  FULL_NAME,
  LINKEDIN_HEADLINE,
  YC_APPS,
  HARMONIC_LINKEDIN,
  INVITE_EMAIL_SENT,
  HARMONIC_AUTOMATED_RATING,
  TOP_COMPANY_EXPERIENCES,
  TOP_SCHOOL_EDUCATIONS,
  HARMONIC_AGE,
  LAST_EMAILED_AT,
} = HARMONIC_EVENT_PROSPECT_COLUMNS;

export const DEFAULT_HARMONIC_EVENT_PROSPECT_COLUMNS = [
  INVITE_EMAIL_SENT,
  HARMONIC_AUTOMATED_RATING,
  HARMONIC_AGE,
  FULL_NAME,
  HARMONIC_LINKEDIN,
  LINKEDIN_HEADLINE,
  TOP_COMPANY_EXPERIENCES,
  TOP_SCHOOL_EDUCATIONS,
  YC_APPS,
  LAST_EMAILED_AT,
  ALL_EMAILS,
];

const {
  STATUS,
  TIME_ON_WAITLIST,
  RATING,
  NAME,
  EMAIL,
  WOMAN,
  TECHNICAL,
  HAS_IDEA,
  PERSONAL_ONE_LINER,
  IDEA_ONE_LINER,
  AGE,
  TIME_ON_CFM,
  OTHER_MEETUPS,
  YC_APP,
  NO_SHOWS,
  CITY,
  NUM_UPDATES,
  LINKEDIN,
  EMPLOYMENT,
  EDUCATION,
  OTHER_QUESTIONS,
  TRACK,
  REFERRER,
  AUTOMATED_RATING,
  YC_ALUM_COMPANY_NAME,
  YC_ALUM_COMPANY_BATCH,
  YC_ALUM_ACTIVE,
  YC_ALUM_COMPANY_FATE,
  YC_ALUM_COMPANY_SCORE,
  YC_ALUM_COMPANY_RAISED,
  VOTES,
  RSVP_PAGE,
} = MEETUP_COLUMNS;

export const DEFAULT_CFM_COLUMNS = [
  STATUS,
  TIME_ON_WAITLIST,
  RATING,
  NAME,
  WOMAN,
  TECHNICAL,
  HAS_IDEA,
  PERSONAL_ONE_LINER,
  IDEA_ONE_LINER,
  AGE,
  TIME_ON_CFM,
  OTHER_MEETUPS,
  YC_APP,
  NO_SHOWS,
  REFERRER,
];

export const DEFAULT_ALUMNI_COLUMNS = [
  STATUS,
  TIME_ON_WAITLIST,
  RATING,
  NAME,
  CITY,
  YC_ALUM_COMPANY_NAME,
  YC_ALUM_COMPANY_BATCH,
  YC_ALUM_ACTIVE,
  YC_ALUM_COMPANY_FATE,
  YC_ALUM_COMPANY_SCORE,
  YC_ALUM_COMPANY_RAISED,
];

export const DEFAULT_COURSE_COLUMNS = [
  STATUS,
  TIME_ON_WAITLIST,
  RATING,
  NAME,
  WOMAN,
  CITY,
  NUM_UPDATES,
  REFERRER,
];

export const DEFAULT_SUMMER_CONF_COLUMNS = [
  STATUS,
  TIME_ON_WAITLIST,
  RATING,
  NAME,
  WOMAN,
  LINKEDIN,
  CITY,
  AGE,
  EMPLOYMENT,
  EDUCATION,
  OTHER_QUESTIONS,
  REFERRER,
  VOTES,
  RSVP_PAGE,
];

export const DEFAULT_COLUMNS = [
  STATUS,
  TIME_ON_WAITLIST,
  RATING,
  AUTOMATED_RATING,
  NAME,
  WOMAN,
  LINKEDIN,
  CITY,
  AGE,
  EDUCATION,
  EMPLOYMENT,
  REFERRER,
  VOTES,
  RSVP_PAGE,
];

export const DEFAULT_INELIGIBLE_USER_COLUMNS = [
  RATING,
  NAME,
  AUTOMATED_RATING,
  WOMAN,
  TECHNICAL,
  TRACK,
  AGE,
  YC_APP,
];

export const CHECK_IN_COLUMNS = [
  STATUS,
  NAME,
  LINKEDIN,
  CITY,
  TIME_ON_WAITLIST,
  YC_ALUM_COMPANY_NAME,
  YC_ALUM_COMPANY_BATCH,
];

export const DEFAULT_CHECK_IN_COLUMNS = [STATUS, NAME, EMAIL];

export const DEFAULT_ALUMNI_CHECK_IN_COLUMNS = [
  STATUS,
  NAME,
  EMAIL,
  YC_ALUM_COMPANY_NAME,
  YC_ALUM_COMPANY_BATCH,
];

const getDefaultColumnsForEventType = (eventType: string) => {
  switch (eventType) {
    case "course":
      return DEFAULT_COURSE_COLUMNS;
    case "summerconf":
      return DEFAULT_SUMMER_CONF_COLUMNS;
    case "cfm":
      return DEFAULT_CFM_COLUMNS;
    case "batch":
    case "alumni":
    case "tracking_only":
      return DEFAULT_ALUMNI_COLUMNS;
    default:
      return DEFAULT_COLUMNS;
  }
};

export const getQuestionColumnsForEvent = (event: MeetupType | undefined) => {
  if (!event) {
    const emptyResult: MeetupColumnType[] = [];
    return emptyResult;
  }

  return event.questions.map((question) => {
    const column: MeetupColumnType = {
      title: question.questionText,
      render: (rsvp) => rsvp.questions[question.id],
      sort: {
        key: `question-${question.id}`,
        sortFn: rsvpQuestionSortFn(question.id),
      },
    };
    return column;
  });
};

export const allAvailableColumnsForEvent = (event: MeetupType | undefined) => [
  ...Object.values(MEETUP_COLUMNS),
  ...getQuestionColumnsForEvent(event),
];

export const getDefaultColumnsForEvent = (event: MeetupType | undefined) => {
  if (!event) {
    return DEFAULT_COLUMNS;
  }

  if (event.defaultColumns.length) {
    const colsByTitle: { [title: string]: MeetupColumnType } = {};
    allAvailableColumnsForEvent(event).forEach((column) => {
      colsByTitle[column.title] = column;
    });

    return event.defaultColumns.map((title) => colsByTitle[title]).filter((col) => !!col);
  }

  return [...getDefaultColumnsForEventType(event.eventType), ...getQuestionColumnsForEvent(event)];
};

export const getDefaultCheckInColumnsForEvent = (event: MeetupType | undefined) =>
  event?.eventType === "alumni" ? DEFAULT_ALUMNI_CHECK_IN_COLUMNS : DEFAULT_CHECK_IN_COLUMNS;
