import { gql, useMutation } from "@apollo/client";
import {
  KICK_OFF_HARMONIC_PROSPECT_SEARCH,
  KICK_OFF_HARMONIC_PROSPECT_SEARCHVariables,
} from "../__generated__/KICK_OFF_HARMONIC_PROSPECT_SEARCH";

export default function useKickOffHarmonicProspectSearch(meetupId: string) {
  const [kickOffSearch] = useMutation<
    KICK_OFF_HARMONIC_PROSPECT_SEARCH,
    KICK_OFF_HARMONIC_PROSPECT_SEARCHVariables
  >(gql`
    mutation KICK_OFF_HARMONIC_PROSPECT_SEARCH($meetupId: ID!, $harmonicSavedSearchId: ID!) {
      kickOffHarmonicProspectSearch(
        meetupId: $meetupId
        harmonicSavedSearchId: $harmonicSavedSearchId
      ) {
        success
      }
    }
  `);

  return (harmonicSavedSearchId: string) =>
    kickOffSearch({ variables: { meetupId, harmonicSavedSearchId } });
}
