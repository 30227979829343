import React, { useEffect, useState } from "react";
import { css } from "@emotion/core";
import { Dialog, DialogTitle } from "@material-ui/core";
import styled from "@emotion/styled";
import { gql, useMutation } from "@apollo/client";
import { MEETUP_NAMETAGS_admin_adminMeetup } from "../__generated__/MEETUP_NAMETAGS";
import MEETUP_COLUMNS, { getQuestionColumnsForEvent } from "./MeetupColumns";
import Button from "../../../components/statelessForms/Button";
import Select from "../../../components/statelessForms/Select";
import Input from "../../../components/statelessForms/Input";
import {
  UPDATE_MEETUP_NAMETAG_FIELDS,
  UPDATE_MEETUP_NAMETAG_FIELDSVariables,
} from "./__generated__/UPDATE_MEETUP_NAMETAG_FIELDS";

type Props = {
  meetup: MEETUP_NAMETAGS_admin_adminMeetup;
  onUpdate: () => void;
};

const FormBody = styled.div`
  padding: 0 30px 30px 30px;
`;

const SectionHeader = styled.div`
  font-weight: bold;
  margin-top: 10px;
`;

const SectionSubtitle = styled.div`
  font-style: italic;
`;

type NametagField = {
  field: string;
  prefix: string;
};

export type NametagFields = {
  subtitle?: NametagField | undefined;
  oneLiner?: NametagField | undefined;
  bottomBar?: NametagField | undefined;
};

export default ({ meetup, onUpdate }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [subtitleField, setSubtitleField] = useState<string>("");
  const [subtitlePrefix, setSubtitlePrefix] = useState<string>("");
  const [oneLinerField, setOneLinerField] = useState<string>("");
  const [oneLinerPrefix, setOneLinerPrefix] = useState<string>("");
  const [bottomBarField, setBottomBarField] = useState<string>("");
  const [bottomBarPrefix, setBottomBarPrefix] = useState<string>("");

  const [updateFields] = useMutation<
    UPDATE_MEETUP_NAMETAG_FIELDS,
    UPDATE_MEETUP_NAMETAG_FIELDSVariables
  >(gql`
    mutation UPDATE_MEETUP_NAMETAG_FIELDS($slug: ID!, $nametagFields: String!) {
      updateMeetupNametagFields(slug: $slug, nametagFields: $nametagFields) {
        slug
        nametagFields
      }
    }
  `);

  useEffect(() => {
    if (!meetup?.nametagFields) {
      return;
    }
    const nametagFields: NametagFields = JSON.parse(meetup.nametagFields);
    if (nametagFields?.subtitle) {
      setSubtitleField(nametagFields?.subtitle?.field || "");
      setSubtitlePrefix(nametagFields?.subtitle?.prefix || "");
    }
    if (nametagFields?.oneLiner) {
      setOneLinerField(nametagFields?.oneLiner?.field || "");
      setOneLinerPrefix(nametagFields?.oneLiner?.prefix || "");
    }
    if (nametagFields?.bottomBar) {
      setBottomBarField(nametagFields?.bottomBar?.field || "");
      setBottomBarPrefix(nametagFields?.bottomBar?.prefix || "");
    }
  }, [meetup?.nametagFields]);

  const allColumns = [...Object.values(MEETUP_COLUMNS), ...getQuestionColumnsForEvent(meetup)];

  const columnOptions: [string, string][] = [
    ["", "(leave blank)"],
    ...allColumns.map((col) => [col.title, col.title] as [string, string]),
  ];

  const onSave = async () => {
    setIsSubmitting(true);
    const nametagFields = {
      subtitle: {
        field: subtitleField,
        prefix: subtitlePrefix,
      },
      oneLiner: {
        field: oneLinerField,
        prefix: oneLinerPrefix,
      },
      bottomBar: {
        field: bottomBarField,
        prefix: bottomBarPrefix,
      },
    };

    await updateFields({
      variables: {
        slug: meetup.slug,
        nametagFields: JSON.stringify(nametagFields),
      },
    });

    await onUpdate();

    setIsSubmitting(false);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} scroll="body" maxWidth="md">
        <DialogTitle>Edit nametag fields</DialogTitle>
        <FormBody>
          <SectionHeader>Subtitle field</SectionHeader>
          <SectionSubtitle>(bigger text right under the orange line)</SectionSubtitle>
          <Select
            options={columnOptions}
            value={subtitleField}
            onChange={(value) => setSubtitleField(value)}
          />
          <Input
            placeholder="Subtitle prefix (optional)"
            value={subtitlePrefix}
            onChange={(e) => setSubtitlePrefix(e.target.value)}
            outlined
            size="small"
          />

          <SectionHeader>Secondary subtitle field</SectionHeader>
          <SectionSubtitle>(smaller text below the subtitle)</SectionSubtitle>
          <Select
            options={columnOptions}
            value={oneLinerField}
            onChange={(value) => setOneLinerField(value)}
          />
          <Input
            placeholder="Secondary subtitle prefix (optional)"
            value={oneLinerPrefix}
            onChange={(e) => setOneLinerPrefix(e.target.value)}
            outlined
            size="small"
          />

          <SectionHeader>Bottom bar field</SectionHeader>
          <SectionSubtitle>(by default, this is the event title)</SectionSubtitle>
          <Select
            options={columnOptions}
            value={bottomBarField}
            onChange={(value) => setBottomBarField(value)}
          />
          <Input
            placeholder="Bottom bar prefix (optional)"
            value={bottomBarPrefix}
            onChange={(e) => setBottomBarPrefix(e.target.value)}
            outlined
            size="small"
          />

          <div css={css({ textAlign: "center", width: "100%", marginTop: 20 })}>
            <Button color="orange" onClick={onSave}>
              Save
            </Button>
          </div>
        </FormBody>
      </Dialog>
      <Button color="yellow" size="small" onClick={() => setIsOpen(true)} disabled={isSubmitting}>
        Edit nametag fields
      </Button>
    </>
  );
};
