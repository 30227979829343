import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import { omit } from "lodash";
import React from "react";
import { RadioGroup, Submit, Textarea, useForm } from "../../components/forms";
import { Container, Page } from "../../components/Page";
import { Pane, PaneItem } from "../../components/Pane";
import { EDIT_ASF_PROFILE, EDIT_ASF_PROFILE_asfProfile } from "./__generated__/EDIT_ASF_PROFILE";
import {
  UPDATE_ASF_PROFILE,
  UPDATE_ASF_PROFILEVariables,
} from "./__generated__/UPDATE_ASF_PROFILE";

export default (props: { verticals: [number, string][] }) => {
  const { data } = useQuery<EDIT_ASF_PROFILE>(gql`
    query EDIT_ASF_PROFILE {
      asfProfile {
        ideas
        hopeToLearn
        when
        errors {
          field
          error
        }
      }
    }
  `);

  const [updateAsfProfile] = useMutation<UPDATE_ASF_PROFILE, UPDATE_ASF_PROFILEVariables>(gql`
    mutation UPDATE_ASF_PROFILE($input: AsfUpdateProfileInput!) {
      asfUpdateProfile(input: $input) {
        errors {
          field
          error
        }
      }
    }
  `);

  const onSubmit = async (fields: EDIT_ASF_PROFILE_asfProfile) => {
    const resp = await updateAsfProfile({
      variables: { input: omit(fields, ["__typename", "avatarUrl", "errors", "persisted"]) },
    });
    return resp?.data?.asfUpdateProfile;
  };

  // @ts-ignore
  const { ConnectedForm, connectedFormProps } = useForm(data?.asfProfile, onSubmit);

  return (
    <Page title="Aspiring Founder Profile">
      <Container css={css({ maxWidth: 900 })}>
        <ConnectedForm {...connectedFormProps}>
          <Pane>
            <PaneItem>
              <RadioGroup
                fieldName="when"
                label="How long until you think you'll start working on a startup?"
                required
                options={[
                  ["3_mo", "In the next 3 months"],
                  ["6_mo", "In the next 6 months"],
                  ["1_year", "In the next year"],
                  ["2_years", "In the next 2 years"],
                  ["3_5_years", "In the next 3-5 years"],
                  ["6_plus_years", "In the next 6+ years"],
                  ["no_plans", "No concrete plans"],
                ]}
              />
              <Textarea
                fieldName="ideas"
                label="Do you already have an idea or ideas in mind for a startup?  If so, what are they?"
              />
              <Textarea
                fieldName="hopeToLearn"
                label="What do you hope to learn from Startup School?"
              />
            </PaneItem>
          </Pane>
          <Submit />
        </ConnectedForm>
      </Container>
    </Page>
  );
};
