import React from "react";
import { addMinutes, formatDistanceStrict } from "date-fns";

import authorizedFetch from "@yc/shared/authorizedFetch";
import { MarkdownComponent } from "@yc/shared/renderMarkdown";
import Button from "../../components/statelessForms/Button";
import Feedback from "./Feedback";
import { Session } from "./Session";
import { UserItem } from "../companies/Company";
import { GROUP_SESSION_groupSession_feedback } from "./__generated__/GROUP_SESSION";

type Props = {
  preview?: boolean;
  session?: Session;
  startedAt: Date;
  currentTime: Date;
  mergeVisible: boolean;
  feedback?: GROUP_SESSION_groupSession_feedback | null | undefined;
  topic?: string | null;
  agenda?: string | null;
  submittingFeedback?: boolean;
};

export default function Agenda({
  preview,
  startedAt,
  currentTime,
  mergeVisible,
  feedback,
  session,
  topic,
  agenda,
  submittingFeedback,
}: Props): React.ReactElement {
  const mergeOpensAt = addMinutes(startedAt, 5);

  const mergeOpen = mergeVisible && currentTime > mergeOpensAt;

  const mergeGroups = async () => {
    if (
      !confirm(
        "Warning: merging sessions is permanent and will kick everyone out of this video call. Only do this if no one else joined your call. Merging sessions WILL NOT FIX call quality issues and may make them worse."
      )
    )
      return;

    await authorizedFetch(`/group_sessions/${session?.slug}/merge`, { method: "POST" });
    window.location.reload();
  };

  return (
    <>
      {mergeVisible && (
        <>
          <p style={{ fontStyle: "italic" }}>
            Only one here? If after 5 minutes no one else has joined the call, you can merge with
            another session.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {mergeOpen ? (
              <Button onClick={mergeGroups}>No one else is here</Button>
            ) : (
              <Button disabled>
                Merging available in {formatDistanceStrict(mergeOpensAt, currentTime)}
              </Button>
            )}
          </div>
        </>
      )}
      <h3>1. Introduction & One-Liner</h3>
      <p>
        Have each founder present themselves. If you have an active startup, give a one-sentence
        description of what you're building. Please be concise and respect each others' time. This
        should be an{" "}
        <a href="https://www.youtube.com/watch?v=pQnOBHNKlgs&feature=youtu.be" target="_blank">
          investor pitch
        </a>
        . If you're an aspiring founder, give a one-sentence description of what you're hoping to
        learn in Startup School. Introduce yourselves in the following order.
      </p>
      {session?.users.map((u, idx) => (
        <UserItem u={u} key={u.slug} moderator={idx === 0} />
      ))}
      <p>
        After each introduction, feel free to ask clarification questions if you didn't understand
        someone else's investor pitch.
      </p>
      <div>
        <b>Moderator responsibilities:</b>
        <ul>
          <li>Kick off the meeting</li>
          <li>Keep discussion focused/moving forward</li>
          <li>Make sure no one monopolizes the conversation</li>
        </ul>
        If your assigned moderator <b>({session?.users[0].name})</b> does not join the session, the
        next founder on the list should fill in.
      </div>
      <h3>2. {topic || session?.topic || "Goals & Obstacles"}</h3>
      {agenda || session?.agenda ? (
        <MarkdownComponent content={agenda || session?.agenda || ""} />
      ) : (
        <>
          <p>
            Go around in the same order as before. This time, each startup should share their{" "}
            <strong>biggest obstacle</strong> and <strong>goals for this week</strong>.
          </p>
          <p>
            Each time a founder finishes sharing, take a few minutes to discuss and give them
            feedback. You might want to share (1) the thing that excites you most about their
            startup, and (2) the thing that worries you the most. Be sincere and direct—good
            founders can learn from both positive and negative feedback.
          </p>
        </>
      )}

      {!preview && (
        <>
          <h3>Feedback</h3>
          {session?.feedback_open ? (
            <Feedback
              feedbackOpen={currentTime > addMinutes(startedAt, 20)}
              feedback={feedback}
              submitting={submittingFeedback}
            />
          ) : (
            "This group session happened over a week ago and feedback is now closed."
          )}
        </>
      )}
    </>
  );
}
