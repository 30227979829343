import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React, { useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import { FormControl, InputAdornment } from "@material-ui/core";
import Icon from "../../components/icon";
import Button from "../../components/statelessForms/Button";
import Input from "../../components/statelessForms/Input";
import { paneBorder } from "../../styles";
import { Update, UpdateContext } from "./shared";

const GoalContainer = styled.div({
  borderRadius: 4,
  border: paneBorder,
  display: "flex",
  alignItems: "center",
  padding: "5px 15px",
  marginTop: 5,
});

const Goals: React.FC<{
  setPendingUpdate: (update: Update) => void;
}> = ({ setPendingUpdate }) => {
  const { pendingUpdate } = useContext(UpdateContext);

  const [newGoalText, setNewGoalText] = React.useState<string>("");

  const addGoal = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    const existingGoals = pendingUpdate.completable_goals || [];
    setPendingUpdate({
      ...pendingUpdate,
      completable_goals: [
        ...existingGoals,
        { goal: newGoalText, key: Math.round(Math.random() * 100000).toString() },
      ],
    });
    setNewGoalText("");
  };

  const removeGoal = (key: string) => {
    const newGoals = (pendingUpdate.completable_goals || []).filter((g) => g.key !== key);
    setPendingUpdate({
      ...pendingUpdate,
      completable_goals: newGoals,
    });
  };

  return (
    <FormControl fullWidth>
      <label>What are your top 1-3 goals for the next week?</label>
      <Input
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                disableElevation
                onClick={addGoal}
                disabled={newGoalText.length === 0}
              >
                Add Goal
              </Button>
            </InputAdornment>
          ),
        }}
        placeholder="Enter a goal here"
        value={newGoalText}
        onChange={(e) => setNewGoalText(e.target.value)}
        onKeyDown={(e: React.KeyboardEvent) => e.keyCode === 13 && addGoal(e)}
      />
      <div css={css({ display: "flex", alignItems: "flex-start", flexDirection: "column" })}>
        {pendingUpdate.completable_goals?.map((cg) => (
          <GoalContainer key={cg.key}>
            <div>{cg.goal}</div>
            <Icon
              icon="times"
              css={css({
                padding: 5,
                paddingLeft: 15,
                width: 30,
                height: 25,
                cursor: "pointer",
                "&:hover": { color: "#666" },
              })}
              onClick={() => removeGoal(cg.key)}
            />
          </GoalContainer>
        ))}
      </div>
    </FormControl>
  );
};

export default Goals;
