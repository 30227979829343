import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Papa from "papaparse";
import { gql, useMutation } from "@apollo/client";
import { Dialog, DialogTitle } from "@material-ui/core";
import TextareaAutosize from "../../../../components/statelessForms/TextareaAutosize";
import Button from "../../../../components/statelessForms/Button";
import { YC_ORANGE } from "../../../../components/forms/util";
import {
  SET_MEETUP_ADDITIONAL_NAMETAGS,
  SET_MEETUP_ADDITIONAL_NAMETAGSVariables,
} from "../../__generated__/SET_MEETUP_ADDITIONAL_NAMETAGS";
import { AttendeeType } from "./NametagTypes";
import { Dropdown, useForm } from "../../../../components/forms";

type Props = {
  id: string | null | undefined;
  additionalNametags: string | null | undefined;
  setCustomAttendees: (attendees: AttendeeType[]) => void;
};

const ORDERED_HEADERS = [
  "firstName",
  "lastName",
  "bottomText",
  "subtitle",
  "bottomBarColor",
  "secondSubtitle",
  "bottomTextColor",
];

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const CsvUploadContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #86c471;
  background-color: #f4fff0;

  div {
    margin-right: 10px;
    font-weight: bold;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 16px;
    width: 250px;
    text-align: right;
    padding-right: 20px;
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

export default ({ id, additionalNametags, setCustomAttendees }: Props) => {
  const [customAttendeesText, setCustomAttendeesText] = useState("");
  const [csvData, setCsvData] = useState<any[]>([]);

  const [updateAdditionalNametags] = useMutation<
    SET_MEETUP_ADDITIONAL_NAMETAGS,
    SET_MEETUP_ADDITIONAL_NAMETAGSVariables
  >(gql`
    mutation SET_MEETUP_ADDITIONAL_NAMETAGS($id: ID!, $additionalNametags: String!) {
      setMeetupAdditionalNametags(id: $id, additionalNametags: $additionalNametags) {
        slug
        additionalNametags
      }
    }
  `);

  useEffect(() => {
    if (additionalNametags) {
      addNametagsForAdditionalAttendees(additionalNametags);
      setCustomAttendeesText(additionalNametags);
    }
  }, [additionalNametags]);

  const onConfirmHeaders = (fields: any) => {
    const newCustomNametags = csvData.map((row) => ({
      firstName: row[fields.firstNameField],
      lastName: row[fields.lastNameField],
      bottomText: row[fields.bottomBarField],
      bottomBarColor: row[fields.bottomBarColorField] || "orange",
      bottomTextColor: row[fields.bottomTextColorField] || "white",
      subtitle: row[fields.subtitleField],
      secondSubtitle: row[fields.secondSubtitleField],
    }));

    const newCsvText = Papa.unparse(newCustomNametags, {
      header: false,
      columns: ORDERED_HEADERS,
    });

    setCustomAttendeesText(newCsvText);
    onSetCustomAttendees(newCsvText);

    setCsvData([]);
  };

  const { ConnectedForm, connectedFormProps } = useForm(
    {
      firstNameField: "",
      lastNameField: "",
      subtitleField: "",
      secondSubtitleField: "",
      bottomBarField: "",
      bottomBarColorField: "",
      bottomTextColorField: "",
    },
    onConfirmHeaders
  );

  const addNametagsForAdditionalAttendees = (text: string) => {
    Papa.parse(`${ORDERED_HEADERS.join(",")}\n${text}`, {
      delimiter: ",",
      skipEmptyLines: true,
      header: true,
      transform: (val: string, col: string) => {
        if (!val) {
          return "";
        }
        const cleanVal = val.trim();

        if (col === "bottomTextColor" && !cleanVal) {
          return "white";
        }
        if (col === "bottomBarColor" && (!cleanVal || cleanVal === "orange")) {
          return YC_ORANGE;
        }
        return cleanVal;
      },
      complete: ({ data }: { data: any[] }) => {
        setCustomAttendees(data);
      },
    });
  };

  const onSetCustomAttendees = async (text: string) => {
    if (!id) {
      return;
    }

    await updateAdditionalNametags({
      variables: {
        id,
        additionalNametags: text,
      },
    });

    addNametagsForAdditionalAttendees(text);
  };

  const onSelectFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      transform: (val: string) => val.trim(),
      complete: ({ data }: { data: any[] }) => {
        setCsvData(data);
      },
    });
  };

  const renderRow = (fieldName: string, label: string, headerOptions: [string, string][]) => (
    <Row>
      <h2>{label}</h2>
      <Dropdown fieldName={fieldName} options={headerOptions} clearable />
    </Row>
  );

  const renderNametagHeaderFields = () => {
    const headerOptions = Object.keys(csvData[0] || {}).map((col) => [col, col]) as [
      string,
      string
    ][];
    return (
      <div css={css({ padding: 25, paddingTop: 0, width: 800 })}>
        <ConnectedForm {...connectedFormProps}>
          {renderRow("firstNameField", "First name / Full name", headerOptions)}
          {renderRow("lastNameField", "Last name", headerOptions)}
          {renderRow("subtitleField", "Subtitle", headerOptions)}
          {renderRow("secondSubtitleField", "Second subtitle", headerOptions)}
          {renderRow("bottomBarField", "Bottom bar text", headerOptions)}
          {renderRow("bottomBarColorField", "Bottom bar color", headerOptions)}
          {renderRow("bottomTextColorField", "Bottom text color", headerOptions)}

          <ButtonRow>
            <Button color="gray" onClick={() => setCsvData([])}>
              Cancel
            </Button>
            <Button color="orange" type="submit">
              Confirm choices
            </Button>
          </ButtonRow>
        </ConnectedForm>
      </div>
    );
  };

  return (
    <div
      css={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 2,
      })}
    >
      <div>
        The easiest way to add custom attendees is to upload from a CSV. Rows will be added to this
        textbox in the following format:
      </div>
      <div
        css={css({
          textAlign: "center",
          backgroundColor: "peachpuff",
          fontFamily: "monospace",
          padding: "3px 6px",
          margin: "10px 0",
          borderRadius: 3,
        })}
      >
        {ORDERED_HEADERS.join(", ")}
      </div>
      <div>Alternatively, you can manually enter attendees in that same format.</div>

      <ActionRow>
        <div css={css({ width: 200 })} />

        <div css={css({ flexGrow: 1 })}>
          <CsvUploadContainer>
            <div>Upload from CSV:</div>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={onSelectFiles}
            />
          </CsvUploadContainer>
        </div>

        <div css={css({ width: 200, textAlign: "right" })}>
          <Button color="gray" onClick={() => onSetCustomAttendees(customAttendeesText.trim())}>
            Set from text
          </Button>
        </div>
      </ActionRow>

      <TextareaAutosize
        fullWidth
        minRows={5}
        value={customAttendeesText}
        onChange={(e) => setCustomAttendeesText(e.target.value)}
        placeholder={ORDERED_HEADERS.join(", ")}
      />

      <Dialog open={!!csvData.length} onClose={() => {}} scroll="body" maxWidth="md">
        <DialogTitle>Select where each field from the CSV should be placed</DialogTitle>
        {renderNametagHeaderFields()}
      </Dialog>
    </div>
  );
};
