import { gql, useQuery } from "@apollo/client";
import React from "react";
import { AdminUserWithCfmProfileFragment } from "../cofounderMatching/fragments";
import RateUser from "./RateUser";
import { ADMIN_USER } from "./__generated__/ADMIN_USER";

type Props = {
  id: Number;
  hideVotes: Boolean;
};

export default function AdminUser({ id, hideVotes }: Props) {
  const { data, refetch } = useQuery<ADMIN_USER>(
    gql`
      query ADMIN_USER($id: ID!) {
        admin {
          user(id: $id) {
            ...AdminUserWithCfmProfileFragment
          }
        }
      }
      ${AdminUserWithCfmProfileFragment}
    `,
    { variables: { id } }
  );

  const user = data?.admin?.user;

  if (!user) {
    return <></>;
  }

  return <RateUser adminUser={user} hideVotes={hideVotes} refetch={refetch} />;
}
