import { css } from "@emotion/core";
import { format } from "date-fns";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { gql, useQuery } from "@apollo/client";
import { AttributeGroup, Attribute, StyledValue } from "../cofounderMatching/CFMAttributes";
import { ADMIN_UNRATED_USERS_admin_unratedUsers } from "./__generated__/ADMIN_UNRATED_USERS";
import { ADMIN_USER_admin_user } from "./__generated__/ADMIN_USER";
import NewBadActorReportModal from "./NewBadActorReportModal";
import BadActorReport from "./BadActorReport";
import EnrichedUserData from "./EnrichedUserData";
import AdminActions from "./AdminActions";
import UserYcAppsList from "./UserYcAppsList";
import AdminUserProfileLinks from "./AdminUserProfileLinks";
import { TextList } from "../cofounderMatching/CFMProfile";
import { ADMIN_USERS_TO_REVIEW_admin_usersToReview } from "./__generated__/ADMIN_USERS_TO_REVIEW";
import { ADMIN_RATED_USERS_admin_ratedUsers } from "./__generated__/ADMIN_RATED_USERS";
import { ADMIN_USER_CURRENT_USER } from "./__generated__/ADMIN_USER_CURRENT_USER";

type Props = {
  adminUser:
    | ADMIN_UNRATED_USERS_admin_unratedUsers
    | ADMIN_USER_admin_user
    | ADMIN_USERS_TO_REVIEW_admin_usersToReview
    | ADMIN_RATED_USERS_admin_ratedUsers;
  hideVotes?: Boolean;
  toastOnVote?: boolean;
  refetch?: () => void;
};

export default ({ adminUser, hideVotes = false, toastOnVote, refetch }: Props) => {
  const { user, adminCfmProfile } = adminUser;

  const { data } = useQuery<ADMIN_USER_CURRENT_USER>(gql`
    query ADMIN_USER_CURRENT_USER {
      currentUser {
        slug
        superAdmin
      }
    }
  `);

  return (
    <div css={css({ display: "flex" })}>
      <div css={css({ width: "100%", maxWidth: 650 })}>
        <div css={css({ display: "flex", alignItems: "center", marginBottom: 20 })}>
          <img
            key={user.avatarUrl}
            css={css({ borderRadius: "50%", marginRight: 40, backgroundColor: "#ccc" })}
            src={user.avatarUrl}
            height={150}
            width={150}
            alt="candidate avatar"
          />
          <div
            css={css({
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            })}
          >
            <h1 css={css({ fontSize: 30, marginBottom: 0 })}>{user.name}</h1>
            <p>{user.location}</p>
            <div css={css({ display: "flex" })}>
              {user.linkedin && (
                <span>
                  <a href={user.linkedin} target="_blank">
                    LinkedIn
                  </a>
                </span>
              )}
              {user.linkedin && user.age && <span css={css({ margin: "0 10px" })}>|</span>}
              {user.age && <span>Age: {user.age}</span>}
              {(user.linkedin || user.age) && adminUser.ycFounder && (
                <span css={css({ margin: "0 10px" })}>|</span>
              )}
              {adminUser.ycFounder && (
                <span
                  css={css({
                    backgroundColor: "orange",
                    color: "white",
                    fontSize: 14,
                    fontWeight: "bold",
                    padding: "3px 8px",
                    borderRadius: 3,
                    width: "fit-content",
                  })}
                >
                  {user.ycFounderLabel || "YC Founder"}
                </span>
              )}
            </div>
          </div>
        </div>
        <AttributeGroup>
          <Attribute
            title="Is technical"
            value={user.isTechnical ? <StyledValue text="Yes" /> : <StyledValue text="No" />}
          />
          <Attribute title="Impressive accomplishment" block value={user.impressiveThing ?? ""} />
          <Attribute title="Education" block value={<TextList text={user.education} />} />
          <Attribute title="Employment" block value={<TextList text={user.employment} />} />
          <Attribute title="Track" value={user.track} />
          {user.track === "active_founder" && !!user.company && (
            <>
              <Attribute title="Company name" block value={user.company.name} />
              <Attribute title="Company description" block value={user.company.description} />
            </>
          )}
          <Attribute title="Hope to learn" block value={user.hopeToLearn} />
          <Attribute title="Ideas" block value={user.ideas} />
          {"enrichedUserData" in user && !!user.enrichedUserData && (
            <tr>
              <td colSpan={2}>
                <EnrichedUserData
                  enrichedUserData={adminUser.adminUserStub}
                  monoidRating={adminUser.monoidRating}
                />
              </td>
            </tr>
          )}
        </AttributeGroup>
      </div>
      <div css={css({ marginLeft: 40, maxWidth: 350 })}>
        <AdminUserProfileLinks userSlug={user.slug} profileSlug={adminCfmProfile?.slug} />
        {!!adminUser.badActorReports?.length && (
          <div>
            <h3 css={css({ color: "red" })}>Blacklist reports</h3>
            {adminUser.badActorReports.map((report) => (
              <BadActorReport key={report.id} report={report} />
            ))}
          </div>
        )}
        {!hideVotes && !!adminUser.badActorReports && (
          <NewBadActorReportModal slug={user.slug} onSuccess={refetch} />
        )}
        {!hideVotes && (
          <AdminActions
            adminUser={adminUser}
            toastOnVote={toastOnVote}
            superAdmin={data?.currentUser?.superAdmin}
            refetch={refetch}
          />
        )}
        <AttributeGroup>
          <Attribute title="Created" value={format(new Date(user.createdAt), "PP p")} />
          <Attribute title="Last Seen" value={format(new Date(user.lastSeenAt), "PP p")} />
        </AttributeGroup>
        <AttributeGroup>
          <UserYcAppsList ycApps={adminUser.ycApps} />
        </AttributeGroup>
      </div>
    </div>
  );
};
