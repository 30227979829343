import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import { isBefore } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "@yc/shared/tailwind/components/ui/LoadingSpinner";
import { getSlugFromWindowLocation } from "../../components/forms/util";
import { MEETUP_RSVPS, MEETUP_RSVPS_admin_meetupRsvps } from "./__generated__/MEETUP_RSVPS";
import Button from "../../components/statelessForms/Button";
import {
  SEND_INVITES_FOR_MEETUP_RSVPS,
  SEND_INVITES_FOR_MEETUP_RSVPSVariables,
} from "./__generated__/SEND_INVITES_FOR_MEETUP_RSVPS";
import {
  SEND_REJECTIONS_FOR_MEETUP_RSVPS,
  SEND_REJECTIONS_FOR_MEETUP_RSVPSVariables,
} from "./__generated__/SEND_REJECTIONS_FOR_MEETUP_RSVPS";
import { MeetupRsvpFragment } from "../cofounderMatching/fragments";
import MeetupRsvpFilterBar, { STATUSES } from "./meetups/MeetupRsvpFilterBar";
import {
  DEFAULT_CHECK_IN_COLUMNS,
  DEFAULT_COLUMNS,
  DEFAULT_INELIGIBLE_USER_COLUMNS,
  SortType,
} from "./meetups/MeetupColumns";
import AdminMeetupRsvpTable from "./meetups/AdminMeetupRsvpTable";
import AdminMeetupBulkActions from "./meetups/AdminMeetupBulkActions";
import AdminMeetupOverviewStats from "./meetups/AdminMeetupOverviewStats";
import AdminMeetupCSVExport from "./meetups/AdminMeetupCSVExport";
import { formatRsvpWithQuestionResponses, RsvpType } from "./meetups/meetupUtils";
import ColumnToggle from "./meetups/ColumnToggle";
import useRealtime from "../../hooks/useRealtime";
import {
  HANDLE_UPDATE_PUSHED_TO_RSVPS,
  HANDLE_UPDATE_PUSHED_TO_RSVPSVariables,
} from "./__generated__/HANDLE_UPDATE_PUSHED_TO_RSVPS";
import AddAttendeesFromHnids from "./meetups/AddAttendeesFromHnids";
import SetAttendeesWarningModal from "./meetups/SetAttendeesWarningModal";
import CheckInBulkActions from "./meetups/CheckInBulkActions";

const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  gap: 10px;

  button {
    margin: 0 10px;
  }
`;

const CheckInStat = styled.div<{ subtle?: boolean }>`
  font-size: 18px;
  font-weight: ${({ subtle }) => (subtle ? 300 : 500)};
  padding: 6px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: fit-content;
  color: ${({ subtle }) => (subtle ? "gray" : "black")};
`;

const FilterAndActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

type Props = {
  capacity: number;
};

const STATUSES_PARAM = "statuses";
const SUS_ID = "154";

export default function MeetupRsvps({ capacity }: Props) {
  const id = getSlugFromWindowLocation() || "";
  const checkInView = !!new URLSearchParams(window.location.search).get("check_in");
  const setAttendeesView = !!new URLSearchParams(window.location.search).get("set_attendees");
  const [sortType, setSortType] = useState<SortType | null>(null);
  const [filter, setFilter] = useState("");
  const [apolloError, setApolloError] = useState<string | null>(null);
  const [totalRetries, setTotalRetries] = useState(0);

  const statusParam = new URLSearchParams(window.location.search).get(STATUSES_PARAM);
  const [statusFilters, setStatusFilters] = useState<string[]>(
    statusParam?.split(",") || (checkInView ? ["confirmed", "invite_pending"] : [])
  );
  const [meetupColumns, setMeetupColumns] = useState(
    checkInView ? DEFAULT_CHECK_IN_COLUMNS : DEFAULT_COLUMNS
  );

  const [initialLoadTime] = useState(new Date());
  const latestMessageTime: string | null = useRealtime<string | null>(`meetup/${id}`, null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const {
    data,
    loading,
    error,
    refetch: reloadRsvps,
  } = useQuery<MEETUP_RSVPS>(
    gql`
      query MEETUP_RSVPS($id: ID!, $bigEvent: Boolean!) {
        admin {
          adminMeetup(id: $id) {
            slug
            title
            eventType
            startsAt
            defaultColumns
            confirmationEmailEnabled
            questions {
              id
              questionText
              questionType
            }
            ohSlots {
              id
              owner {
                id
                name
              }
              rsvp {
                id
              }
            }
          }
          meetupRsvps(id: $id) {
            ...MeetupRsvpFragment
          }
          ineligibleMeetupRsvps(id: $id) {
            ...MeetupRsvpFragment
          }
        }
      }
      ${MeetupRsvpFragment}
    `,
    { variables: { id, bigEvent: id === SUS_ID } }
  );

  const [loadUpdatedMeetupData] = useMutation<
    HANDLE_UPDATE_PUSHED_TO_RSVPS,
    HANDLE_UPDATE_PUSHED_TO_RSVPSVariables
  >(
    gql`
      mutation HANDLE_UPDATE_PUSHED_TO_RSVPS(
        $meetupId: ID!
        $updatedAfter: ISO8601DateTime!
        $bigEvent: Boolean!
      ) {
        handleUpdatePushedToRsvps(meetupId: $meetupId, updatedAfter: $updatedAfter) {
          ...MeetupRsvpFragment
        }
      }
      ${MeetupRsvpFragment}
    `
  );

  useEffect(() => {
    if (initialLoadComplete || !!data?.admin.meetupRsvps) {
      return;
    }

    loadUpdatedMeetupData({
      variables: { meetupId: id, updatedAfter: initialLoadTime, bigEvent: id === SUS_ID },
    });
    setInitialLoadComplete(true);
  }, [data?.admin.meetupRsvps, initialLoadComplete]);

  useEffect(() => {
    if (!latestMessageTime || isBefore(new Date(latestMessageTime), initialLoadTime)) {
      return;
    }
    loadUpdatedMeetupData({
      variables: { meetupId: id, updatedAfter: latestMessageTime, bigEvent: id === SUS_ID },
    });
  }, [latestMessageTime]);

  useEffect(() => {
    const url = new URL(window.location.toString());
    if (statusFilters.length) {
      url.searchParams.set(STATUSES_PARAM, statusFilters.join(","));
    } else {
      url.searchParams.delete(STATUSES_PARAM);
    }
    window.history.pushState({}, "", url.toString());
  }, [statusFilters]);

  useEffect(() => {
    if (loading) {
      return;
    }
    setApolloError(error?.message || null);
  }, [error, loading]);

  useEffect(() => {
    if (!error || totalRetries > 5) {
      return;
    }
    toast.warning("Hang tight, this might take a minute...");
    setApolloError(null);
    setTotalRetries(totalRetries + 1);
    reloadRsvps();
  }, [apolloError]);

  const [sendInvites] = useMutation<
    SEND_INVITES_FOR_MEETUP_RSVPS,
    SEND_INVITES_FOR_MEETUP_RSVPSVariables
  >(gql`
    mutation SEND_INVITES_FOR_MEETUP_RSVPS($id: ID!) {
      sendInvitesForMeetupRsvps(id: $id) {
        id
        accepted
        status
      }
    }
  `);

  const [sendRejections] = useMutation<
    SEND_REJECTIONS_FOR_MEETUP_RSVPS,
    SEND_REJECTIONS_FOR_MEETUP_RSVPSVariables
  >(gql`
    mutation SEND_REJECTIONS_FOR_MEETUP_RSVPS($id: ID!) {
      sendRejectionsForMeetupRsvps(id: $id) {
        id
        status
      }
    }
  `);

  const filterRsvps = (
    rsvps: RsvpType[],
    currentFilter: string,
    currentStatusFilters: string[]
  ) => {
    let filteredRsvps = rsvps;

    if (!!currentStatusFilters.length && currentStatusFilters.length !== STATUSES.length) {
      const statusesAsSet = new Set(currentStatusFilters);
      filteredRsvps = filteredRsvps.filter((rsvp) => statusesAsSet.has(rsvp.status));
    }

    if (!currentFilter) {
      return filteredRsvps;
    }

    return filteredRsvps.filter((r) => {
      const relevantText = [
        r.ssoUser.enrichedEducation,
        r.ssoUser.enrichedEmployment,
        r.ssoUser.name,
        ...r.customQuestions.map((arr) => arr[1]),
      ]
        .join(" ")
        .toLowerCase();

      return relevantText.includes(currentFilter);
    });
  };

  const flattenAndFormatRsvps = (rsvps: MEETUP_RSVPS_admin_meetupRsvps[] | undefined) => {
    if (!rsvps) {
      return [];
    }

    return rsvps.map(formatRsvpWithQuestionResponses);
  };

  const sortRsvpIds = (
    rsvpList: RsvpType[],
    currentSortType: SortType | null,
    currentFilter: string,
    currentStatusFilters: string[]
  ) => {
    let filteredRsvps = filterRsvps(rsvpList, currentFilter, currentStatusFilters);
    if (currentSortType) {
      filteredRsvps = filteredRsvps.sort(currentSortType.sortFn);
    }

    return filteredRsvps.map((rsvp) => rsvp.id);
  };

  const sortedFilteredRsvpIds = useMemo(() => {
    const formattedRsvps = flattenAndFormatRsvps(data?.admin.meetupRsvps);
    return sortRsvpIds(formattedRsvps, sortType, filter, statusFilters);
  }, [sortType, filter, statusFilters, data?.admin.meetupRsvps.length]);

  const sortedFilteredRsvps = useMemo(() => {
    const rsvpsById: { [id: string]: RsvpType } = {};
    flattenAndFormatRsvps(data?.admin.meetupRsvps).forEach((rsvp) => {
      rsvpsById[rsvp.id] = rsvp;
    });

    return sortedFilteredRsvpIds.map((rsvpId) => rsvpsById[rsvpId]).filter((rsvp) => !!rsvp);
  }, [data?.admin.meetupRsvps, sortedFilteredRsvpIds]);

  const formattedIneligibleRsvps = useMemo(
    () => flattenAndFormatRsvps(data?.admin.ineligibleMeetupRsvps),
    [data?.admin.ineligibleMeetupRsvps]
  );

  const meetup = data?.admin.adminMeetup;
  const rsvps = data?.admin.meetupRsvps;

  const ohOwnerOptions = useMemo(() => {
    if (meetup?.eventType !== "office_hours") {
      return [];
    }
    const idToName: { [id: string]: string } = {};
    meetup?.ohSlots.forEach((slot) => {
      idToName[slot.owner.id] = slot.owner.name;
    });

    return Object.entries(idToName);
  }, [meetup]);

  let incompleteContent = null;

  if (loading) {
    incompleteContent = (
      <div css={css({ display: "flex", justifyContent: "center", width: "100%", marginTop: 20 })}>
        <LoadingSpinner size="2xl" />
      </div>
    );
  } else if (!meetup || !rsvps) {
    incompleteContent = <div />;
  }

  // the "|| !meetup || !rsvps" checks are implicit, and only here to appease the type checker
  if (incompleteContent || !meetup || !rsvps) {
    return (
      <div>
        {incompleteContent}
        <ToastContainer position="top-right" />
      </div>
    );
  }

  const isOver = isBefore(new Date(meetup.startsAt), new Date());

  const numRsvpsShown = sortedFilteredRsvps.flat().length;
  const numCheckedIn = rsvps.filter((r) => r.checkedIn).length;
  const numNoShows = rsvps.filter((r) => r.noShow).length;

  const onSendInvites = async () => {
    await sendInvites({ variables: { id } });
    toast.success("Invite emails sent");
  };

  const onSendRejections = async () => {
    await sendRejections({ variables: { id } });
    toast.success("Rejection emails sent");
  };

  return (
    <div css={css({ marginTop: 30 })}>
      {checkInView && (
        <CenteredRow>
          <CheckInStat>{numCheckedIn} checked in</CheckInStat>
          {!!numNoShows && <CheckInStat subtle>{numNoShows} no-showed</CheckInStat>}
        </CenteredRow>
      )}

      {setAttendeesView && <AddAttendeesFromHnids meetupId={id} reloadRsvps={reloadRsvps} />}

      {!checkInView && !setAttendeesView && (
        <>
          <CenteredRow>
            <Button onClick={onSendInvites}>Send invites to all accepted</Button>
            <Button onClick={onSendRejections}>Send rejections to all rejected</Button>
          </CenteredRow>

          <AdminMeetupOverviewStats
            rsvps={rsvps}
            capacity={capacity}
            eventType={meetup.eventType}
          />

          <AdminMeetupCSVExport rsvps={rsvps} meetup={meetup} />

          <AdminMeetupBulkActions rsvps={sortedFilteredRsvps} />
        </>
      )}

      <MeetupRsvpFilterBar
        initialFilter={filter}
        onApply={setFilter}
        statusFilters={statusFilters}
        setStatusFilters={setStatusFilters}
      />

      <FilterAndActionBar>
        <div css={css({ marginTop: 20, display: "flex", alignItems: "center" })}>
          <h4>
            {filter ? (
              <span>
                Showing results for "<b>{filter}</b>" ({numRsvpsShown})
              </span>
            ) : (
              `Showing all results (${numRsvpsShown})`
            )}
          </h4>
          <ColumnToggle
            id={id}
            columns={meetupColumns}
            meetup={meetup}
            onChange={setMeetupColumns}
            checkInView={checkInView}
          />
        </div>

        {checkInView && <CheckInBulkActions meetupId={id} />}
      </FilterAndActionBar>

      <AdminMeetupRsvpTable
        meetup={meetup}
        columns={meetupColumns}
        rsvps={sortedFilteredRsvps}
        isOver={isOver}
        sortType={sortType}
        setSortType={setSortType}
        checkInView={checkInView}
        setAttendeesView={setAttendeesView}
        ohOwnerOptions={ohOwnerOptions}
      />

      {meetup.eventType === "cfm" && !checkInView && (
        <>
          <h4>Ineligible RSVPs:</h4>
          <AdminMeetupRsvpTable
            meetup={meetup}
            columns={DEFAULT_INELIGIBLE_USER_COLUMNS}
            rsvps={formattedIneligibleRsvps}
            isOver={isOver}
            sortType={sortType}
            setSortType={setSortType}
            hideActions
          />
        </>
      )}

      {meetup.eventType !== "tracking_only" && setAttendeesView && <SetAttendeesWarningModal />}

      {/* Add toast container for UI feedback */}
      <ToastContainer position="top-right" />
    </div>
  );
}
