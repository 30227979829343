import React, { useState } from "react";
import { css } from "@emotion/core";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Input from "../../../components/statelessForms/Input";
import { BasicButton } from "../../../components/statelessForms/Button";

type Props = {
  initialFilter: string;
  onApply: (filter: string) => void;
  statusFilters: string[];
  setStatusFilters: (statuses: string[]) => void;
};

export const STATUSES = [
  "",
  "accepted",
  "rejected",
  "invite_pending",
  "invite_expired",
  "confirmed",
  "declined",
  "rejoined_waitlist",
];

export default ({ initialFilter, onApply, statusFilters, setStatusFilters }: Props) => {
  const [filter, setFilter] = useState(initialFilter || "");

  const onSubmit = () => {
    onApply(filter.trim().toLowerCase());
  };

  const renderStatusFilterCheckboxes = () => {
    const getOnChange = (status: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newStatusFilters = new Set(statusFilters);
      if (e.target.checked) newStatusFilters.add(status);
      else newStatusFilters.delete(status);

      setStatusFilters([...newStatusFilters]);
    };

    return (
      <FormGroup row>
        {STATUSES.map((status) => (
          <FormControlLabel
            key={status}
            control={
              <Checkbox
                name={status}
                checked={statusFilters.includes(status)}
                onChange={getOnChange(status)}
              />
            }
            label={status || "no status"}
          />
        ))}
      </FormGroup>
    );
  };

  return (
    <div css={css({ display: "flex", alignItems: "center", marginTop: 10 })}>
      <div css={css({ display: "flex", alignItems: "center" })}>
        <div>
          <Input
            size="small"
            outlined
            value={filter}
            placeholder="Optional filter text"
            onChange={(e) => setFilter(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
        </div>
        <BasicButton onClick={onSubmit}>Apply filter</BasicButton>
      </div>
      <div css={css({ padding: "0 10px", fontSize: 12, fontWeight: "bold" })}>or by status:</div>
      {renderStatusFilterCheckboxes()}
    </div>
  );
};
