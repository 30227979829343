import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import MarkdownEditor from "@yc/shared/editor/MarkdownEditor";
import { omit } from "lodash";
import { Autocomplete, Input, Submit, useForm } from "../../../components/forms";
import { NEW_EMAIL_PUSH_admin_emailCampaign } from "./__generated__/NEW_EMAIL_PUSH";
import { StyledFormLabel } from "../../../components/forms/Field";
import { UPDATE_EMAIL_PUSH, UPDATE_EMAIL_PUSHVariables } from "./__generated__/UPDATE_EMAIL_PUSH";
import { EDIT_EMAIL_PUSH_admin_emailCampaign_emailPush } from "./__generated__/EDIT_EMAIL_PUSH";
import Button, { BasicButton } from "../../../components/statelessForms/Button";
import { loadContinueUrl } from "../../../components/forms/util";
import {
  adminEmailCampaignPath,
  reviewPushAdminEmailCampaignPath,
} from "../../../__generated__/routes";
import EmailPushFiltersForm, {
  formatFilters,
  INITIAL_FILTER_DATA,
  parseFiltersFromEmailPush,
  Row,
} from "./EmailPushFiltersForm";
import EmailPushSettings from "./EmailPushSettings";
import EmailTemplateModal from "./EmailTemplateModal";
import EmailPushCustomRecipientsForm from "./EmailPushCustomRecipientsForm";
import { ExternalRecipientInput } from "../../../types/graphqlTypes";

const FormWrapper = styled.div`
  min-width: 800px;
  padding: 0 20px 20px 20px;

  h3 {
    font-size: 16px;
    font-style: italic;
  }
`;

const EmailBodyWrapper = styled.div`
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  width: 100%;
`;

type Props = {
  campaignId: string;
  emailCampaign: NEW_EMAIL_PUSH_admin_emailCampaign;
  emailPush?: any;
  title: string;
};

const INITIAL_FORM_DATA = {
  name: "",
  harmonicSavedSearchId: null,
  subject: "",
  emailBody: "",
  fromEmail: "",
  autoLoginLinks: false,
  sendsAt: null,
  disabled: false,
  ...INITIAL_FILTER_DATA,
};

export const parseEmailPush = (push: EDIT_EMAIL_PUSH_admin_emailCampaign_emailPush) => ({
  ...omit(push, ["filters", "slug", "__typename"]),
  ...parseFiltersFromEmailPush(push),
});

export default ({ title, campaignId, emailCampaign, emailPush }: Props) => {
  const [sendAfterSave, setSendAfterSave] = useState(true);
  const [recipients, setRecipients] = useState(
    emailPush?.externalEmailRecipients.map(({ email, firstName }: ExternalRecipientInput) => ({
      email,
      firstName,
    })) || []
  );

  const [updateEmailPush, { data: emailPushData }] = useMutation<
    UPDATE_EMAIL_PUSH,
    UPDATE_EMAIL_PUSHVariables
  >(gql`
    mutation UPDATE_EMAIL_PUSH($input: CreateOrUpdateEmailPushInput!) {
      createOrUpdateEmailPush(input: $input) {
        id
      }
    }
  `);

  useEffect(() => {
    const pushId = emailPushData?.createOrUpdateEmailPush.id;
    if (!pushId) {
      return;
    }

    if (sendAfterSave) {
      loadContinueUrl(reviewPushAdminEmailCampaignPath(campaignId, pushId));
    } else {
      loadContinueUrl(adminEmailCampaignPath(campaignId));
    }
  }, [emailPushData]);

  const onUpdateEmailPush = async (fields: any) => {
    const input = {
      campaignId,
      name: fields.name,
      subject: fields.subject,
      emailBody: fields.emailBody,
      fromEmail: fields.fromEmail,
      replyToEmail: fields.replyToEmail === "" ? null : fields.replyToEmail,
      autoLoginLinks: fields.autoLoginLinks,
      sendsAt: fields.sendsAt,
      disabled: fields.disabled,
      filters: formatFilters(fields),
      recipients,
    };

    if (fields.id) {
      Object.assign(input, { id: fields.id });
    }

    if (fields.harmonicSavedSearchId) {
      Object.assign(input, {
        harmonicSavedSearchId: Number.parseInt(fields.harmonicSavedSearchId, 10),
      });
    }

    await updateEmailPush({
      variables: { input },
    });
  };

  const initialFormData = { ...INITIAL_FORM_DATA, ...(emailPush || {}) };

  const { formMethods, ConnectedForm, connectedFormProps } = useForm(
    initialFormData,
    onUpdateEmailPush
  );

  const emailBody = formMethods.watch("emailBody");

  const onEmailBodyChange = (newValue: string) => {
    formMethods.setValue("emailBody", newValue);
  };

  return (
    <FormWrapper>
      <h2>{title}</h2>
      {!!emailCampaign.meetupTitle && <h3>For the meetup: {emailCampaign.meetupTitle}</h3>}
      <ConnectedForm {...connectedFormProps}>
        <Row>
          <Input required fieldName="name" label="Email push name (internal only)" />
        </Row>
        <Row>
          <Input fieldName="harmonicSavedSearchId" label="Harmonic saved search ID (optional)" />
        </Row>
        <Row>
          <Autocomplete
            required
            fieldName="fromEmail"
            label="Who should the email come from?"
            getOptionLabel={(option) => option.value}
            options={emailCampaign.fromEmailOptions.map((option) => ({ value: option }))}
          />
        </Row>
        <Row>
          <Autocomplete
            fieldName="replyToEmail"
            label="Where should email replies be sent to? (leaving blank defaults to the From email)"
            getOptionLabel={(option) => option.value}
            options={["", ...emailCampaign.replyToEmailOptions].map((option) => ({
              value: option,
            }))}
          />
        </Row>
        <Row>
          <Input required fieldName="subject" label="Subject line" />
        </Row>
        <Row>
          <div css={css({ width: "100%", display: "flex", flexDirection: "column" })}>
            <StyledFormLabel>Email body</StyledFormLabel>
            <p css={css({ fontStyle: "italic", fontSize: 14 })}>
              <EmailTemplateModal templateValues={JSON.parse(emailCampaign.templateValues)} />
            </p>
            <EmailBodyWrapper>
              <MarkdownEditor
                className="form-control body"
                value={emailBody || emailPush?.emailBody}
                onChange={onEmailBodyChange}
              />
            </EmailBodyWrapper>
          </div>
        </Row>

        <EmailPushFiltersForm emailPush={emailPush} emailCampaign={emailCampaign} />

        <EmailPushCustomRecipientsForm
          emailPush={emailPush}
          recipients={recipients}
          setRecipients={setRecipients}
        />

        <EmailPushSettings emailPush={emailPush} emailCampaign={emailCampaign} />

        <Row
          css={css({
            justifyContent: "center",
            button: { width: 300, height: 40, "&:first-of-type": { marginRight: 10 } },
          })}
        >
          <Submit hideStatusText>
            <BasicButton
              type="submit"
              disabled={formMethods.formState.isSubmitting}
              onClick={() => setSendAfterSave(false)}
            >
              Save & back to campaign
            </BasicButton>
          </Submit>
          <Submit hideStatusText>
            <Button
              type="submit"
              color="orange"
              disabled={formMethods.formState.isSubmitting}
              onClick={() => setSendAfterSave(true)}
            >
              Save & view email
            </Button>
          </Submit>
        </Row>
      </ConnectedForm>
    </FormWrapper>
  );
};
