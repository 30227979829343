import React from "react";
import { Page } from "../../components/Page";
import SpeedDatingEventPage from "../speedDating/SpeedDatingEventPage";

export default () => {
  const faqItems = [
    {
      question: "How do I join the event?",
      answer:
        'Click the "start matching" button. When prompted, you should let your browser allow video and audio.',
    },
    {
      question: "Who will I meet?",
      answer: "Other new Startup School founders!",
    },
    {
      question: "How do I follow up with founders I meet during the event?",
      answer:
        "You'll have the opportunity to invite founders to connect right after your meeting ends. If both of you opt in to being connected, we'll email you so that you can continue the conversation!",
    },
    {
      question: "What do I do if I experience technical issues?",
      answer: "If you experience any technical issues, email us at startupschool@ycombinator.com.",
    },
  ];

  const minutesToDescriptionFn = (
    meetingMinutes: number
  ) => `During this event you'll be paired with other Startup School founders for live 1:1 video
            calls, each lasting ${meetingMinutes} minutes. After every call, you'll have the
            option to opt in to be connected over email. If you both opt in, we'll email you both
            so you can continue the conversation!`;

  return (
    <Page title="Meet other founders">
      <SpeedDatingEventPage faqItems={faqItems} minutesToDescriptionFn={minutesToDescriptionFn} />
    </Page>
  );
};
