import { useQuery, gql } from "@apollo/client";
import pluralize from "pluralize";
import React, { useContext } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Section, Update, UpdateContext } from "./shared";
import { UPDATE_SPRINT_SECTION } from "./__generated__/UPDATE_SPRINT_SECTION";

const SprintSection: React.FC<{
  setPendingUpdate: (update: Update) => void;
}> = ({ setPendingUpdate }) => {
  const { pendingUpdate } = useContext(UpdateContext);

  const { data } = useQuery<UPDATE_SPRINT_SECTION>(gql`
    query UPDATE_SPRINT_SECTION {
      latestSprint {
        goal
        active
        enrolled
        totalWeeks
        currentWeek
        daysRemaining
      }
    }
  `);

  if (data == null || !data.latestSprint.active || !data.latestSprint.enrolled) return null;

  return (
    <Section
      title="YC Build Sprint"
      titleLink={
        <a href="/sprint" target="_blank">
          Edit Goal
        </a>
      }
    >
      <p>
        This is week {data.latestSprint.currentWeek}/{data.latestSprint.totalWeeks}, and there are{" "}
        <strong>{pluralize("day", data.latestSprint.daysRemaining, true)}</strong> remaining in the
        YC Build Sprint!
      </p>

      <p>
        Your sprint goal is <em>{data.latestSprint.goal ?? "please set a sprint goal"}</em>. As of
        right now, do you feel like you're on track to hit your goal?
      </p>

      <RadioGroup>
        <FormControlLabel
          control={<Radio color="default" />}
          label="Yes"
          checked={pendingUpdate.sprint_on_track === true}
          onChange={() => setPendingUpdate({ ...pendingUpdate, sprint_on_track: true })}
        />
        <FormControlLabel
          control={<Radio color="default" />}
          label="No"
          checked={pendingUpdate.sprint_on_track === false}
          onChange={() => setPendingUpdate({ ...pendingUpdate, sprint_on_track: false })}
        />
      </RadioGroup>
    </Section>
  );
};

export default SprintSection;
